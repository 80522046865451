import React, { useState } from 'react';
import './ddc.scss';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import {DetailCredit} from '../../components/shared/DetailCredit/DetailCredit';
import Direccion from '../../components/shared/modal/Direccion';
export const DetalleDelCredito = () => {
    
  const [popup, setPopup] = useState("close");
  return (
    <>
    
          {
            popup === "open"
            ?<>
                <Direccion setPopup={setPopup}/>
            </>
            :<></>
         }
      <ResponsiveAppBar />
      <DetailCredit setPopup={setPopup}/>
    </>
  )
}
