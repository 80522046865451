import React, {useState,useEffect} from 'react';
import {AppRoutes} from "../../../Router";
import {useNavigate} from 'react-router-dom';
import {Delete} from '@mui/icons-material';
import './sd.scss';
import './pagination.scss';
import { VscChevronLeft, VscChevronRight, VscChevronDown } from "react-icons/vsc";
import { WLOCreditAPIService } from '../../../services';


const SolicItem = ({info}) => {
    const navigate = useNavigate();
    const ira = (id,indentifi,tipoidenti)=>{
        navigate(AppRoutes.DETALLE_DEL_CREDITO+"?"+id+"-"+indentifi+"-"+tipoidenti, {replace: true});
    }
    const borrar= (id)=>{
        let valoragregado={
            Pws_num_solicitud:id,
        Pws_fec_solicitud_ini:"",
        Pws_fec_solicitud_fin:"",
        Pws_Tip_estado: "",
        Pws_Tip_Consulta: "2"
        }
         WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado).then((consulta)=>{
            info.funcionse()
         },(error)=>{
            console.log(error)
         })
    }
    let valor=""
    if(Number(info.info.Pws_Tip_Estado)===1){
        valor="Simulador"
    }else if(Number(info.info.Pws_Tip_Estado)===2){
        valor="Confirmacion informacion"
    }else if(Number(info.info.Pws_Tip_Estado)===3){
        valor="Firma Documentos"
    }else if(Number(info.info.Pws_Tip_Estado)===4){
        valor="Aprobado"
    }else if(Number(info.info.Pws_Tip_Estado)===5){
        valor="Rechazado"
    }
    return (
      <>
          <ul>
              <li className="listItem firstItem">{info.info.Pws_Nombres}</li>
              <li className="listItem">{info.info.Pws_ciudad}</li>
              <li className="listItem"><span style={{background:"#017D73"}}></span>{info.info.Pws_Estado_Firma}</li>
              <li className="listItem"><span style={{background:"#017D73"}}></span>{info.info.Pws_Estado_credito}</li>
              <li className="listItem">{info.info.Pws_Val_finan}</li>
              <li className="listItem">{info.info.Pws_Lincre}</li>
              <li className="listItem">{info.info.Pws_fec_solicitud.split("-00.")[0]}</li>
              <li className="listItem"><button data-id={info.info.Pws_num_solicitud}  data-ids={info.info.Pws_Identificacion} data-idss={info.info.Pws_Tip_Identificacion} onClick={(event)=>{
                ira(event.currentTarget.dataset.id,event.currentTarget.dataset.ids, event.currentTarget.dataset.idss)
              }} style={{"font-size":"small","padding":"4px 10px"}}>Ver detalle</button>
              <div  data-id={info.info.Pws_num_solicitud}  data-ids={info.info.Pws_Identificacion} data-idss={info.info.Pws_Tip_Identificacion}  onClick={(event)=>{
                borrar(event.currentTarget.dataset.id)
              }}>
              <Delete/>
              </div>
              
              </li>
          </ul>
      </>
    )
}
export const SolicTable = (valor) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [tableItems, setTableItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentItems, setCurrentItems] = useState([]);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [ipp, setIpp] = useState(false);
    const funcionse = ()=>{
        valor.value.functionss()
    }
      useEffect(()=>{
    
            setTableItems(valor.value.tablasva);
            
        if(valor.value.tablasva.length>0){
            setCurrentItems(valor.value.tablasva.slice(indexOfFirstItem,indexOfLastItem));
        }
      }, [valor]);
    useEffect(()=>{
        if(tableItems !== undefined){
            setCurrentItems(tableItems.slice(indexOfFirstItem,indexOfLastItem));
        }
    },[tableItems, indexOfFirstItem, indexOfLastItem]);
    
    const lastPage = Math.floor(Object.values(tableItems).length/itemsPerPage) + 1;
    const paginate = (pageNumber) => {
        if(typeof pageNumber === "number") {
            setCurrentPage(pageNumber);
        }else{
            console.log(pageNumber);
        }
    }
    const nextPaginate = (pageNumber) => {
        if(pageNumber===lastPage){
            setCurrentPage(pageNumber);
        }else{
            setCurrentPage(pageNumber+1);
        }
    }
    const backPaginate = (pageNumber) => {
        if(pageNumber===1){
            setCurrentPage(pageNumber);
        }else{
            setCurrentPage(pageNumber-1);
        }
    }
  const pageNumbers = [];
  if(tableItems!==undefined){
       for (let i = 1; i <= Math.ceil(tableItems.length / itemsPerPage); i++ ){
            pageNumbers.push(i);
        }
    }
    let tempNumberOfPages = [...pageNumbers];

    let dotsInitial = '...';
    let dotsLeft = '... ';
    let dotsRight = ' ...';
    let dotsEnd = ' ... ';

    if(currentPage >= 1 && currentPage<=3 && pageNumbers.length !== 0) {
        tempNumberOfPages = [1,2,3,4, dotsInitial, pageNumbers.length];  
    }else if(currentPage === 4){
        const sliced = pageNumbers.slice(0,5)
        tempNumberOfPages = [...sliced, dotsInitial, pageNumbers.length];
    }else if(currentPage > 4 && currentPage < pageNumbers.length-2){
        const sliced1 = pageNumbers.slice(currentPage-2, currentPage);
        const sliced2 = pageNumbers.slice(currentPage, currentPage+1);
        tempNumberOfPages = [1, dotsLeft, ...sliced1, ...sliced2, dotsRight , pageNumbers.length];
    }else if(currentPage > pageNumbers.length - 3 && pageNumbers.length !==0) {
        const sliced = pageNumbers.slice(pageNumbers.length -4);
        tempNumberOfPages = [1, dotsEnd, ...sliced];
    }else if(pageNumbers.length === 0){
        tempNumberOfPages = [];
    }
    
  const sliderRestrictionBack = () => {
      if(currentPage === 1){
        return 'disable';
      }
  }
  const sliderRestrictionNext = () => {
      if(currentPage === lastPage){
        return 'disable';
      }
  };
  const activePaginate = (pageNumber) => {
      if(currentPage === pageNumber){
          return "active";
      }
  }
  useEffect(()=>{
    document.body.addEventListener('click',(event)=>{
        try{
            if(event.path[0].tagName !== 'svg'){
                setIpp(false);
            }  
        }catch(error){

        }
    })
  })
  if(loading){
      return <h1>Loading...</h1>
  }

  return (
    <div className="soltable">         
        <div className="responsiveFix">
            <div className="responsiveAuto">
                <ul>
                    <li className='listHeader listItem'>{`Cliente`}</li>
                    <li className='listHeader listItem'>{`Ciudad`} </li>
                    <li className='listHeader listItem'>{`Estado firma`}</li>
                    <li className='listHeader listItem'>{`Estado crédito`}</li>
                    <li className='listHeader listItem'>{`Monto`}</li>
                    <li className='listHeader listItem'>{`Línea de crédito`}</li>
                    <li className='listHeader listItem'>{`Fecha de solicitud`}</li>
                    <li className='listHeader listItem'>{`Acciones`}</li>
                </ul>
                {
                    currentItems.map((info, i) => {
                        return(
                            <SolicItem key={i} info={{info:info,funcionse } } />
                        )
                    })
                }
            </div>
        </div>
        <div className="pagination">
            <ul className="itemsPerPage">
                <p>Rows for page:</p>
                <ul>
                    <p style={{margin: "0px"}} >{itemsPerPage}</p>
                    <ul className={ipp?`open`:`close`}>
                        <li><p onClick={()=>setItemsPerPage(5)}>5</p></li>
                        <li><p onClick={()=>setItemsPerPage(10)}>10</p></li>
                        <li><p onClick={()=>setItemsPerPage(15)}>15</p></li>
                    </ul>
                </ul>
                <span onClick={()=>{setIpp(!ipp);}} style={{cursor:'pointer', marginTop: '0px'}}><VscChevronDown /></span>
            </ul>
            <ul className="pageNum">
                <li onClick={()=>backPaginate(currentPage)} className={`paginate-item back ${sliderRestrictionBack()}`}><VscChevronLeft /> </li>
                {
                    tempNumberOfPages.map((number,i)=>{
                        return(
                            <li onClick={()=> paginate(number)} key={i} className={`paginate-item paginate-number ${activePaginate(number)}`}>
                                {number}
                            </li>
                        )
                    })
                }
                <li onClick={()=>nextPaginate(currentPage)} className={`paginate-item next ${sliderRestrictionNext()}`}><VscChevronRight /> </li>
            </ul>
        </div>
    </div>
  )
}