import React, { useEffect, useState } from "react";
import {useIntl} from "react-intl";
import { useNavigate,useLocation } from "react-router-dom";
import {DocumentDemo} from "../../assets/icons";
import { useSignContext } from "../../hooks/useSignContext";
import { Document,pdfjs,Page  } from 'react-pdf'
import { WLOCreditAPIService } from "../../services";
import {AppRoutes} from "../../Router";
import { useRequestContext } from "../../hooks/useRequestContext";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
console.log(pdfjs.GlobalWorkerOptions.workerSrc);
console.log("aca va la url del cloud")





export function PDFViewSign() {
    let buscar = useLocation();
    const {sign, setSign} = useSignContext();
    const {request, setRequest} = useRequestContext();
    const navigate = useNavigate();
    const intl = useIntl();
    const [details, setDetails] = useState(null) as any;
    const [numPages, setNumPages] = useState(null);
    const [sargilat, setSargilat] = useState(false);
    const [estudio, setEstudio] = useState(false);
    const [negado, SetDataNegado] = useState(false);
    const [DataCreditoCodeudor, setDataCreditoCodeudor] = useState(false);
    const [documento, setDocumento] = useState<string>('')
    const onDocumentLoadSuccess = (numPagess:any) => {
        setNumPages(numPagess._pdfInfo.numPages)
    };

    useEffect( () => {
        if(!documento){
          console.dir(documento)
          console.log("aca va el documento?")
            
            WLOCreditAPIService.getDocumento({ Pws_num_solicitud: request.numSol, Pws_Identificacion: request.personCygnus?.Pws_Identificacion,Pws_Tip_Identificacion: request.personCygnus?.Pws_Tip_Identificacion,otp:sign.otp}).then((valor)=>{

                setDocumento(valor.payload)
                
             })
        }
    }, [documento, setDocumento])
    useEffect(() => {
        getUserGeolocationDetails();
    }, []);
    useEffect(()=>{
        if(buscar){
          var valorBusqueda=buscar.search.replace("?","")
          
          var valorBusqueda2=valorBusqueda.split("-")
          if(Number(valorBusqueda2[0])===0){
            setSargilat(false)
          }else{
            setSargilat(true)
          }
          if(Number(valorBusqueda2[1])===0){
            SetDataNegado(false)
          }else{
            SetDataNegado(true)
          }
          if(Number(valorBusqueda2[2])===0){
            setDataCreditoCodeudor(false)
          }else{
            setDataCreditoCodeudor(true)
          }
          if(Number(valorBusqueda2[3])===0){
            setEstudio(false)
          }else{
            setEstudio(true)
          }
          
        }
      },[buscar]);
    const getUserGeolocationDetails = () => {
        fetch(
            "https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572"
        )
            .then(response => response.json())
            .then(data => setDetails(data));
    };
    const gotodata=()=>{
        const link = document.createElement('a');
    link.href = documento;
    link.setAttribute(
      'download',
      `Archio.pdf`,
    );
    link.setAttribute("target","_blank")
    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();
    let tipo=""
    let tipo1=""
    let tipo2=""
    let tipo3=""
    if(sargilat){
        tipo="1"
    }else{
        tipo="0"
    }
    if(negado){
        tipo1="1"
    }else{
        tipo1="0"
    }
    if(DataCreditoCodeudor){
        tipo2="1"
    }else{
        tipo2="0"
    }
    if(estudio){
        tipo3="1"
    }else{
        tipo3="0"
    }
    navigate(AppRoutes.PROFILE_OPTIONS)
    }
    return (
        <div className="row py-0 px-4 gx-0">
            <div className="row p-0 m-0">
                <br/>
                <h1 className="title-red">{'VISUALIZACIÓN DEL DOCUMENTO FIRMADO'}</h1>
                <br/>
                <br/>
                <br/>
                <br/>  
                 {!documento?<>
                    <DocumentDemo />
                </>:<>
                
                <div className="all-page-container">
      <Document
      file={documento} 
      
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}
      
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
      </div>
      </>}
      
                <div className="row justify-content-center mt-3">
                            <div className="col-lg-6 text-center text-dark">
                        
                            </div>
                        </div>
                <div className="col">
                    <br/><br/><br/>
                    <div className={"form-know-you-item-button"}  onClick={gotodata}>
                        {'Descargar Documento'}
                    </div>
                </div>
            </div>
        </div>
    )
}