import {BtnBack, IconAgent, IconLock, IconMoney} from "../../assets/icons";
import {AppRoutes} from "../../Router";
import {useNavigate} from 'react-router-dom';
import "./MenuAgent.scss";
import {useIntl} from "react-intl";
import {useLoginContext} from "../../hooks/useLoginContext";
import ResponsiveAppBar from "../../components/shared/Menubar/ResponsiveAppBar";
import { Fab } from "@mui/material";
import toast from 'react-hot-toast';
import { ArrowBack } from "@mui/icons-material";
import { useEffect } from "react";

export function MenuAgent() {
    const {user} = useLoginContext();
    const intl = useIntl();
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate(AppRoutes.LOGINAUTO, {replace: true});
    };

    const handleSubmit1 = () => {
        navigate(AppRoutes.CHANGE, {replace: true});
    };
    
    const handleSubmit2= () => {
        navigate(AppRoutes.SOLICITUDES_DE_CREDITO, {replace: true});
    };
    const handleSubmitBack = () => {
        navigate(AppRoutes.PROFILE_OPTIONS, {replace: true});
    };


    return (
        <div>
            <ResponsiveAppBar/>
            <div className="flex-container-catalog">
                <div className="flex-item-left-catalog">
                    <Fab variant="extended" color="neutral" aria-label="add"
                         onClick={handleSubmitBack} style={{float:"left"}} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                        {intl.formatMessage({id: "button_back"})}
                    </Fab>
                </div>
                <div className="flex-item-right-catalog">
                
                </div>
                <div className="flex-item-left-catalog">
                </div>
            </div>
           
            <div className="title-welcome">
                {`${intl.formatMessage({id: "welcome"})} ${" " + user.idUser}`}
                {}
            </div>
            <div className="subtitle-menu">
                {intl.formatMessage({id: "how_can_we_help_you_today"})}
            </div>


            
            <IconLock className="vector-lock"/>
            <div className="menu-text-change-password">
                {intl.formatMessage({id: "change_of_password"})}
            </div>
            <div className="rectangle-change-password"  onClick={handleSubmit1}/>
            <div className="rectangle-menu-solicitud" />
            <IconMoney className="vector-money"/>
            <div className="menu-text" >
                {intl.formatMessage({id: "credit_applications"})}
            </div>
            <div className="rectangle-menu-solicitud" onClick={handleSubmit2}/>
            <div className="menu-text-credit">
                {intl.formatMessage({id: "manage_credit"})}
            </div>
            <IconAgent className="vector-agent"/>
            <div className="rectangle-menu-credit" onClick={handleSubmit}/>
        </div>
    )
}