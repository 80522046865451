import '../../../Steps/ApplicantInfo/applicant-info.scss';
import {useIntl} from "react-intl";
import {Modal} from "react-bootstrap";

interface ReConfirmInfo{
    show: boolean;
    onHide: () => void;
    irPinc: () => void;
}

export function ModalPinc(props:ReConfirmInfo){
    const intl = useIntl();
    const {show, onHide, irPinc} = props;
    const AceptInfo = async (e: any) =>{
        e.preventDefault();
        onHide();

    }
    return (
  <Modal show={show} onHide={onHide}>
    <form onSubmit={AceptInfo}>
      <Modal.Header>
        <Modal.Title>
          <div className="title-reConfirmInfo" >
            {'¿DESEA IR A PINC?'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col">
            <div className={"form-know-you-item-button"} onClick={onHide}>
              {intl.formatMessage({ id: "button_cancel" })}
            </div>
          </div>
          <div className="col"></div>
          <div className="col">
            
            <button type="submit" className={"form-know-you-item-button-gray"} onClick={irPinc}>
              {intl.formatMessage({ id: "button_approved" })}
            </button>
           
          </div>
        </div>
      </Modal.Body>
    </form> {/* Cierra el formulario */}
  </Modal>
);

}
