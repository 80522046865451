
import {Modal} from "react-bootstrap";
import {useIntl} from "react-intl";
import React, {useCallback, useEffect, useState} from "react";
import './modals.scss';
import '../../Steps/RequestInfo/request-info.scss';
import {AdminService, AuthService, WLOCreditAPIService} from "../../../services";
import {Check} from "@mui/icons-material";
import {AppRoutes} from "../../../Router";
import {useNavigate} from "react-router-dom";
import { useSignContext } from "../../../hooks/useSignContext";
import { useRequestContext } from "../../../hooks/useRequestContext";
import {typeIdentification} from "../../../__mocks__/data"
import Moment from 'moment';
import { WloCreditoService } from "../../../types";
import { text } from "stream/consumers";
interface EnterCodeProps {
    token: string;
    show: boolean;
    onHide: () => void;
    sendCode: () => void;
    errors?: any;
    values?: any;
}

export function EnterCode(props: EnterCodeProps) {
    const {sign, setSign} = useSignContext();
    const {request, setRequest} = useRequestContext();
    const navigate = useNavigate();
    const {onHide, show, token, sendCode} = props;
    const [counter, setCounter] = useState(300);
    const timeOutCallback = useCallback(() => setCounter(currTimer => currTimer - 1), []);
    useEffect(() => {
        if(show){
            counter > 0 && setTimeout(timeOutCallback, 1000);
        }
      }, [counter, timeOutCallback,show]);
    const intl = useIntl();
    const defaultCode = new Array(6).fill('');
    const [modalShow, setModalShow] = React.useState(false);
    const [isSucessful, setIsSucessful] = React.useState(false);
    const [errorCode, setErrorCode] = useState<boolean>(false);
    const [viewNewCode, setViewNewCode] = useState<boolean>(false);
    const [code, setCode] = useState<string[]>(defaultCode);
    const [codigoClie, setCodigo] = useState("");
    const resetCode = () => {
        setCounter(300)
        setCode(defaultCode);
        onHide()
    }

    const handleSearchSagrilaft = async () => {
        onHide();
    }
    const verifyGas= async ()=> {
        let tipodocumento='';
        let valorpuntajeEntre0y150=false
        let presentaRiesgos=false
        let puntaje=0
        let cuotaData = 0
        let scorepuntaje=0
        let scorecygnus=false
        let enestudio=false
        let negado=false
        let valorDataCreditoCodeudor=false
        let documentRequest;
        let sagrilaftRiesgo
        let responseData
        if(sign.typeIdentification==="4")tipodocumento="ti"
        if(sign.typeIdentification==="1")tipodocumento="cc"
        if(sign.typeIdentification==="3")tipodocumento="ce"
        if(sign.typeIdentification==="2")tipodocumento="nit"
        if(sign.typeIdentification==="5")tipodocumento="pa"
            //consulta Estado entidad Tercero 
            const estadoTerce = await AdminService.entidadTer("");
            if(estadoTerce.payload){

                //consulta sagrilaft
                // const data = {
                //     datoConsultar: Number(sign.numIdentification!), //19123402
                //     tipoDocumento: tipodocumento,
                //     numeroSolicitud: Number(request.numSol!), //No tenemos un ws que nos diga el nro de solicitud
                //     fechaSolicitud: Moment(new Date()).format('MM/DD/YYYY'),
                //     email: `${sign.email!}`
                // }

                //Consulta Terceros
                if(estadoTerce.payload.data.ListRef.Datos_Referencias[1].s_descripcion_legal == "1"){
                    // const response = await WLOCreditAPIService.consultaConsolidada(data);
                    // if(response.payload.presentaRiesgo){
                    //     sagrilaftRiesgo = "1"
                    //     presentaRiesgos=true
                    // }else{
                    //     sagrilaftRiesgo = "0"
                    //     presentaRiesgos=false
                    // }
                    
                //consulta datacredito
                let consulta
                if(request.personCygnus?.Pws_Apellidos1 =="" ||request.personCygnus?.Pws_Apellidos1 !="" ){
                    let sends:WloCreditoService.SolicitudDetalla={
                        Pws_Num_Solicitud:request.numSol!,
                        Pws_Identificacion:sign.numIdentification!,
                        Pws_Tip_Identificacion:String(typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.filter(valor=>valor.s_codigo==Number(sign.typeIdentification!))[0].s_datacredito),
                        Pws_Perfil_Acceso:"",
                        Pws_Fec_Solicitud:"",
                    }
                    consulta = await WLOCreditAPIService.consultaSolicitudCygnusDetal(sends)
                }
                let apellido
                if(request.personCygnus?.Pws_Apellidos1 ==""){
                    apellido =consulta.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos1
                }else{
                    apellido = request.personCygnus?.Pws_Apellidos1
                }
                const data4= {
                    identificacion: sign.numIdentification!,
                    primer_apellido: apellido,
                    tipoIdentificacion: String(typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.filter(valor=>valor.s_codigo==Number(sign.typeIdentification!))[0].s_datacredito)
                }

                const response3 = await WLOCreditAPIService.consultDataCredito(data4);
                if(response3.payload){
                    if(!response3.payload.success){
                        //No responde el servidor
                        try {
                            if(response3.payload.data.codigo< 0){
                                puntaje = 0
                            }
                        } catch (error) {
                                puntaje = 0
                        }
                    }else{
                        // puntaje = response3.payload.data.Informes.Informe[0].Score[0].$.puntaje
                        puntaje = response3.payload.data.Informes.Informe[0].InfoAgregada[0].EvolucionDeuda[0].AnalisisPromedio[0].$.score
                    }
                }
                //Guardar Data credito
                // let estadoDatacredito = "0"
                // if(puntaje>=1 && puntaje<500){
                //     estadoDatacredito = "1"
                // }else if(puntaje>=500){
                //     estadoDatacredito = "0"
                // }else if (response3){
                //     estadoDatacredito = "3"
                // }

                //Guardar Datacredito
                const data5 = {
                    Pws_num_solicitud : request.numSol!,
                    Pws_Identificacion : sign.numIdentification!,
                    Pws_Tip_Identificacion: sign.typeIdentification!,
                    Pws_Fec_gen:  Moment(new Date()).format('MM/DD/YYYY'),
                    Pws_estado_resotp:"0",
                    Pws_Tip_val: '2', 
                    Pws_Puntaje: String(puntaje),
                    Pws_Entidad_Consul: '4', 
                    Pws_Num_cuodat: String(cuotaData*1000),
                }
                if(estadoTerce.payload.data.ListRef.Datos_Referencias[2].s_descripcion_legal == "1"){
                    const response5 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data5);
                }
                //evualua score cygnus
                if(Number(request.valoresLogin.tipodeudor)<2){
                    const response10 = await WLOCreditAPIService.EvaluaScore({
                        ps_radicado:request.valoresLogin.radicado,
                        ps_identificacion:String(sign.numIdentification),
                        ps_cod_lin:request.valoresLogin.lineacredito,
                        ps_tipoCliente:request.valoresLogin.tipocliente==""?"2": request.valoresLogin.tipocliente,
                        ps_tip_deucode:request.valoresLogin.tipodeudor    
                    })
                    scorepuntaje=response10.payload.data.puntaje_obtenido
                    const  consultaCupo= await WLOCreditAPIService.consultaCupo({
                        Pws_Solicitud: String(localStorage.getItem("num_solicitud")),
                        Pws_Identificacion: String(sign.numIdentification),
                        Pws_num_Tip_Ident: String(sign.typeIdentification!),
                    })
                    localStorage.setItem('cupoRotativo', consultaCupo.payload.data.Cupo);
                }else{
                //Si tipodeudor es mayor a 2 es porque es codeudor propietario, no valida score cygnus
                    scorepuntaje=0
                    scorecygnus=true
                }

            //Guarda Sagrilaft
            // if(estadoTerce.payload.data.ListRef.Datos_Referencias[1].s_descripcion_legal == "1"){
            //     const response2 = await WLOCreditAPIService.regDataCreditoAndSagrilaft( {
            //         Pws_num_solicitud : request.numSol!,
            //         Pws_Identificacion : sign.numIdentification!,
            //         Pws_Tip_Identificacion:sign.typeIdentification!,
            //         Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
            //         Pws_estado_resotp: sagrilaftRiesgo,
            //         Pws_Tip_val: '1',
            //         Pws_Puntaje: '0',
            //         Pws_Entidad_Consul: '2',
            //         Pws_Num_cuodat: '',
            //     });
            // }
                
                //Gurada Score Cygnus
                let estadocygnus = "0"
                if(scorepuntaje >=51){
                    estadocygnus = "0"
                }else{
                    estadocygnus = "1"
                }
                const data6 = {
                    Pws_num_solicitud : request.numSol!,
                    Pws_Identificacion : sign.numIdentification!,
                    Pws_Tip_Identificacion: sign.typeIdentification!,
                    Pws_Fec_gen:  Moment(new Date()).format('MM/DD/YYYY'),
                    Pws_estado_resotp: estadocygnus,
                    Pws_Tip_val: '4',
                    Pws_Puntaje: String(scorepuntaje),
                    Pws_Entidad_Consul: '5',
                    Pws_Num_cuodat: '',
                }
                if(estadoTerce.payload.data.ListRef.Datos_Referencias[4].s_descripcion_legal == "1"){
                    const response6 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data6);
                }
                //Listas restrictivas API
                if (sign.typeIdentification==="1"){
                    const listasR={
                    people: [
                                {
                                name: (consulta.payload.data.datos_persona.Datos_persona[0].Pws_Nombres +" " +consulta.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos1 + " " +consulta.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos2 ),
                                doc_id: sign.numIdentification!,
                                tipo_doc: "CC"
                                }
                        ]
                    };
                try {
                    const listas = await WLOCreditAPIService.getListasRestrictivas(listasR);
                    const documentRequest= {
                        Pws_num_solicitud: request.numSol!,
                        Pws_Identificacion: sign.numIdentification!,
                        Pws_Tip_Identificacion: sign.typeIdentification!,
                        Pws_Doc_Tipo: "5",
                        Pws_Doc_nombre: 'Listas restrictivas.pdf',
                        Pws_Doc_estado: "1",
                        Pws_Doc_ruta: "1",
                        Pws_Clave_doc: listas.payload,
                    };
                    const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
                } catch (error) {
                    console.log("E-LR");;
                }
                }

                //Guardar documento Sagrilaft
                // const downloadDocument= await AuthService.documentSagrilaft({idDatoConsultado:response.payload.idDatoConsultado})
                // documentRequest= {
                //     Pws_num_solicitud: request.numSol!,
                //     Pws_Identificacion: sign.numIdentification!,
                //     Pws_Tip_Identificacion: sign.typeIdentification!,
                //     Pws_Doc_Tipo: "4",
                //     Pws_Doc_nombre:response.payload.idDatoConsultado+".pdf" ,
                //     Pws_Doc_estado: "1",
                //     Pws_Doc_ruta: "1",
                //     Pws_Clave_doc:"data:application/pdf;base64,"+downloadDocument.payload,
                // };


            }

            // const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
            // console.log(solicitud)
            //codeudor o el cliente
            if(Number(request.valoresLogin.tipodeudor)<2){
                const solicitudResulado = await WLOCreditAPIService.addSolicitud({
                    Pws_num_solicitud:request.numSol!,
                    Pws_Identificacion:sign.numIdentification!,
                    Pws_Tip_Identificacion: sign.typeIdentification!
                });
               
                
                //NEGADO
                // if( (puntaje>=1 && puntaje <=149) || (scorepuntaje <51) ){
                //     presentaRiesgos=false
                //     valorDataCreditoCodeudor=false
                //     negado=true
                //     enestudio=false
                //     solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado="5"
                // }
                //aprobado
                // else if((puntaje >500) && (sagrilaftRiesgo='0') && (scorepuntaje >60)){
                //     presentaRiesgos=false
                //     valorDataCreditoCodeudor=false
                //     negado=false
                //     enestudio=false
                //     solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado="4"      
                // }
             
                //Codeudor Requerido
                // else if((puntaje>=150 && puntaje <=500) || (scorepuntaje >=51 && scorepuntaje <=60) && (sagrilaftRiesgo='0') ){
                //     presentaRiesgos=false
                //     valorDataCreditoCodeudor=true
                //     negado=false
                //     enestudio=false
                //     solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado="7"
                // }
                
                //En estudio
                if (puntaje != null){
                    valorDataCreditoCodeudor=false
                    negado=false
                    enestudio=true
                    solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado="12"   
                    }                
                const solicitudResulados = await WLOCreditAPIService.addSolicitud(solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0])

            // }else{
            //   let valueTipo=""
            //   let dataCodeudor=request.valoresLogin.radicado
            //    // if(!negado && !presentaRiesgos && !valorDataCreditoCodeudor && puntaje>500){
            //       if( !presentaRiesgos  && puntaje>500 && scorepuntaje >60){
            //         valueTipo="4"
            //         enestudio=false
            //         //cambio de estado a aprobado por el codeudor
            //         const solicitudResulado = await WLOCreditAPIService.addSolicitud({
            //             Pws_num_solicitud:request.numSol!,
            //             Pws_Identificacion:dataCodeudor.Pws_Identificacion!,
            //             Pws_Tip_Identificacion: dataCodeudor.Pws_Tip_Identificacion!
            //         });
            //         solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado="4"
            //         const solicitudResulados = await WLOCreditAPIService.addSolicitud(solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0])
            //         envioCupo()
            //     }else if(negado){
            //         valueTipo="5"
            //     }else{
            //         valueTipo="5"
            //     }
            //     dataCodeudor.Pws_Estado=valueTipo
            //     const solicitudResulado = await WLOCreditAPIService.addCodeudor(dataCodeudor)
            const datas={
                id:"hola",
                number:request.numSol!,
                email:sign.email!,
                typeIdent:sign.typeIdentification!,
                numbIdent:sign.numIdentification!,
                Negado:negado,
              }
              const response = await WLOCreditAPIService.getEmail(datas);
              

            }
         navigate(AppRoutes.VIEW_DOCUMENT_SIGN+"?"+(presentaRiesgos?"1":"0")+"-"+(negado?"1":"0")+"-"+(valorDataCreditoCodeudor?"1":"0")+"-"+(enestudio?"1":"0"), {replace: true});
        }
    }
    //guarda el cupo
    // const envioCupo= async()=>{
    //     let lineas;
    //     const valor= await AdminService.getlineascredito();
    //     lineas = valor.payload.data.ListLineaCredito.Datos_LineasCredito.length;
    //     let nume = 0
    //     let cupoRot;
    //     let codigo= 0;
    //     let result;
    //     let fecha_inicia
        
    //     let valoragregado={
    //       Pws_num_solicitud: request.numSol!,
    //       Pws_fec_solicitud_ini:"",
    //       Pws_fec_solicitud_fin:"",
    //       Pws_Tip_estado: "",
    //       Pws_Tip_Consulta: "1"
    //     }

    //     const consultaSolicitud = await WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado)
    //     //.then((data) => {
    //     //  fecha_inicia = data.payload.data.datos_soli.datos_Sol[0].Pws_fec_solicitud;
    //     //  result = data.payload.data.datos_soli.datos_Sol[0].Pws_Lincre
    //     //});


        // while (nume < lineas){
        //     if(valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_nombre == result){
        //       cupoRot = valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_rotativo
        //       codigo = valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_codigo
        //       setCodigo(valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_codigo)
        //     }
        //   nume+=1
        // }
    //     const cupo = String(localStorage.getItem('cupoRotativo'))
    //     var d = new Date,
    //       dformat = [d.getMonth()+1,
    //                 d.getDate(),
    //                 d.getFullYear()].join('/');
    //     const creaCupo={
    //       s_r_ident_cliente: String(sign.numIdentification),
    //       s_r_lin_cred: String(codigo),
    //       s_r_fec_ini_cupo: String(dformat),
    //       s_r_fec_fin_cupo: String(dformat),
    //       s_r_monto_cupo: String(cupo)
    //     }
    //     const responde  = await WLOCreditAPIService.registraCupo(creaCupo)
    // }

    const setCodeValue = (event: any, position: number) => {
        const {target} = event;
        const value = target.value.trim();
        if (isNaN(value) || value.length > 1) return;
        const newCode = JSON.parse(JSON.stringify(code));
        newCode[position] = value;
        setCode(newCode);
                


    }

    const clickVerifyCode = async () => {
        try {
            const codeStringValue = code.join('');
            if (codeStringValue.length < 6) return;
            const data = {
                codes: codeStringValue,
                token,
                identificacion: sign.numIdentification,
                tipoIdentificacion: sign.typeIdentification
            }
            const response = await WLOCreditAPIService.verifyCodeRegOtp(data);
            setSign({...sign, signValidated: true, otp: codeStringValue});
            setErrorCode(false);
            setIsSucessful(true);
            verifyGas()
        } catch (error: any) {
            const {errors} = error;
            console.log (errors)
            if (errors?.invalidCode || errors?.invalidToken) {
                setSign({...sign, signValidated: false});
                setErrorCode(true);
            }
            ;
        }
        
    }

    const sendNewCode = () => {
        sendCode();
        setErrorCode(false);
        setViewNewCode(true);
        setCounter(300)
    }

    const viewInputEnterCode = () => {
        setCode(defaultCode);
        setViewNewCode(false);
    }
    const handleKeyUp = (event:any, index:number) => {

    }
    const renderInputEnterCode = () => {
        return (
            <>
            <div>
                <h3 className="sub-title">
                    <p>Al ingresar el código, aceptas ser consultado en listas restrictivas y sagrilaft.</p>
                    <p>Por favor inserta aquí:</p>
                </h3>
                <div className="flex-container-modals p-0 m-0">
                    {code.map((value: string, index: number) => {
                        return <div key={index}>
                            <input value={value} className={"input-code-general"} type="text"
                                   onChange={event => setCodeValue(event, index)}  
                                   onKeyUp={event =>handleKeyUp(event, index)}/>
                        </div>
                    })}
                </div>
                <h3 className="sub-title">
                    <p>Falta {counter} seg para poder envia de nuevo el codigo</p>
                </h3>
                <br/>
                {counter>0?<>
                    <div className="button-center">
                    <div className="form-know-you-item-button button-red color-red"
                         onClick={clickVerifyCode}>Continuar
                    </div>
                </div>
                </>:<>
                <div className="button-center">
                    <div className="form-know-you-item-button button-red color-red"
                         onClick={sendNewCode}>Pedir Codigo
                    </div>
                </div>
                </>}
               
            </div>
            </>
            
        )
    }

    const renderErrorCode = () => {
        return (
            <div>
                <h3 className="sub-title color-red">Has ingresado mal el código o tu tiempo para ingresarlo a
                    caducado</h3>
                <br/>
                <div className="button-center">
                    <div className="form-know-you-item-button button-red color-red" onClick={sendNewCode}>Enviar otro
                        código
                    </div>
                </div>
            </div>
        )
    }

    const renderNewCode = () => {
        return (
            <div>
                <h3 className="sub-title color-green">Se ha enviado un nuevó código revisa tu correo o celular
                    registrado</h3>
                <br/>
                <div className="button-center">
                    <div className="form-know-you-item-button button-green color-green"
                         onClick={viewInputEnterCode}>Aceptar
                    </div>
                </div>
            </div>
        )
    }

    const renderFirmaCorrecta = () => {
        return (
            <div>
                <div className="row">
                    <Check/>
                </div>
                <br/>
                <h3 className="sub-title color-green">Has firmado correctamente para la autorización de los datos.
                    Espera un momento...</h3>
                <br/>
                <h1 className="sub-title color-green" style={{ textAlign:'center'}}  >NO CERRAR</h1>
                {/* <div className="button-center">
                    <div className="form-know-you-item-button button-red color-red"
                         >Aceptar
                    </div>
                </div> */}
            </div>
        )
    }

    const renderTemplate = () => {
        let template = (<></>);
        if (!isSucessful && !errorCode && !viewNewCode) template = renderInputEnterCode();
        if (viewNewCode) template = renderNewCode();
        if (errorCode && !isSucessful) template = renderErrorCode();
        if (isSucessful) template = renderFirmaCorrecta();
        return template;
    }

    return (
            <Modal
                        className="modal-otp"
                        {...props}
                        onHide={resetCode}
                        size="xl"
                        aria-labelledby="contained-modal-title-center"
                        centered
                        backdrop="static"
                    >
                        <Modal.Header >
                        </Modal.Header>
                        <Modal.Body>
                            {renderTemplate()}
                        </Modal.Body>
                    </Modal>
    )
}