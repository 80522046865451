import {Field} from "formik";
import {useIntl} from "react-intl";
import {Switch} from 'formik-mui';
import {useLoginContext} from "../../../hooks/useLoginContext";
import {useRequestContext} from "../../../hooks/useRequestContext";
import ParseNumberSmaller from "../../shared/Utilities/ParseNumberSmaller";
import { useEffect } from "react";

interface FormApplicationIncomeMobileProps {
    errors?: any;
    values?: any;
}

export function FormApplicationIncomeMobile(props: FormApplicationIncomeMobileProps) {
    const {user} = useLoginContext();
    const {request, setRequest} = useRequestContext();
    const {errors, values} = props;
    const intl = useIntl();
    useEffect(() => {
        if(values.Pws_Tip_vivienda==="3"){
            
        }else{
            values.s_arriendo=""           
            values.s_otros_ingresos=""           
            values.s_otros_egresos=""           
        }
    },[values.Pws_Tip_vivienda])
    return (
        <div className={"row  container-border"}>
            <div className="row">
                <div
                    className={"subtitle-medium display-flex"}>{intl.formatMessage({id: "form_financial_information_subtitle"})}</div>
                <br/>
            </div>
            <div className={"row"}>
                <div className="col-12">
                    <div
                        className={"label-fields-peps"}>{intl.formatMessage({id: "form_financial_main_income"})}</div>
                    <Field id={"s_ingreso_principal"}
                           name={"s_ingreso_principal"}
                           component={ParseNumberSmaller}
                           className={'form-know-you-item-input-smaller display-flex'}
                           placeholder={intl.formatMessage({id: "placeholder_initial_quote_symbol"})}/>
                </div>
                <div className="col-12">
                    <div
                        className={"label-fields-peps"}>{intl.formatMessage({id: "form_financial_other_income"})}</div>
                    <Field id={"s_otros_ingresos"}
                           name={"s_otros_ingresos"}
                           component={ParseNumberSmaller}
                           className={'form-know-you-item-input-smaller display-flex'}
                           placeholder={intl.formatMessage({id: "placeholder_initial_quote_symbol"})}/>
                </div>
            </div>
            <div className={"row"}>
                <div className="col-12">
                    <div
                        className={"label-fields-peps"}>{intl.formatMessage({id: "form_financial_expenditure_value"})}</div>
                    <Field id={"s_otros_egresos"}
                           name={"s_otros_egresos"}
                           component={ParseNumberSmaller}
                           className={'form-know-you-item-input-smaller display-flex'}
                           placeholder={intl.formatMessage({id: "placeholder_initial_quote_symbol"})}/>
                </div>
                <div className="col-12">
                    <div
                        className={"label-fields-peps"}>{intl.formatMessage({id: "rental_value"})}</div>
                    <Field id={"s_arriendo"}
                           name={"s_arriendo"}
                           component={ParseNumberSmaller}
                           className={'form-know-you-item-input-smaller display-flex'}
                           placeholder={intl.formatMessage({id: "placeholder_initial_quote_symbol"})}/>
                </div>
            </div>
            <div className={"row"}>
                <div className="col-8">
                    <br/>
                    <Field id={"s_concep_otr_ingre"}
                           name={"s_concep_otr_ingre"}
                           className={'form-know-you-item-input-text-area-mobile display-flex'}
                           placeholder={intl.formatMessage({id: "form_financial_other_income_placeholder"})}/>
                    <br/>
                </div>
            </div>
            <div className="row">
                <div
                    className={"subtitle-medium display-flex"}>{intl.formatMessage({id: "form_financial_applicant_properties"})}</div>
                <br/>
            </div>
            <div className="row">
                <div
                    className={"label-fields-peps"}>{intl.formatMessage({id: "form_financial_income_tax_filer"})}</div>
                <div className="col-3 toggle-peps">{intl.formatMessage({id: "button_no"})}<Field name="s_declarante_ren"
                                                                                                 component={Switch}
                                                                                                 type="checkbox"/>{intl.formatMessage({id: "button_yes"})}
                </div>
            </div>
        </div>
    )
}