import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import './new.scss';
export const New = () => {
  useEffect(() => {
    document.body.classList.add('body-login');
    return () => {
        document.body.classList.remove('body-login');
    };
  }, []);
  const Links1 = [
    {
      link: '/profile-options',
      text: 'profile-options'
    },
    {
      link: '/api/v1/soap/inventory',
      text: 'catalog'
    },
    {
      link: '/sign',
      text: 'sign'
    },
    {
      link: '/home_view',
      text: 'home'
    },
    {
      link: '/login_view',
      text: 'login'
    },
    {
      link: '/menu_agent_view',
      text: 'menu-agent'
    },
    {
      link: '/view_document_view',
      text: 'view_document'
    },
    {
      link: '/view_document_sign_view',
      text: 'view_document_sign'
    },
    {
      link: '/confirmation_view',
      text: 'confirmation'
    },
    {
      link: '/Change-password',
      text: 'Change-password'
    },
    {
      link: '/*',
      text: 'Error Page'
    }
  ]
  const Links2 = [
    {
      link: '/loginfirma17',
      text: 'loginfirma17'
    },
    {
      link: '/Referencias-y-documentos-13',
      text: 'Referencias-y-documentos-13'
    },
    {
      link: '/Informacion-financiera-10',
      text: 'Informacion-financiera-10'
    },
    {
      link: '/Solicitudes-de-credito',
      text: 'Solicitudes-de-credito'
    },
    {
      link: '/Detalle-del-credito',
      text: 'Detalle-del-credito'
    },
    {
      link: '/Detalle-del-codeudor-requerido',
      text: 'Detalle-del-codeudor-requerido'
    },
    {
      link: '/Mensaje-despues-de-validar-sargilat',
      text: 'Mensaje-despues-de-validar-sargilat'
    },
    {
      link: '/Confirm-information',
      text: 'Confirm-information'
    },
    {
      link: '/CoDebt-information',
      text: 'CoDebt-information'
    },
    {
      link: '/new-pdf',
      text: 'New PDF Page'
    },
  ]
  return (
    <div className="new" >
      <header>
        <h1>Sprint 1 - All Pages</h1>
      </header>
      <ol>
        {
          Links1.map((item,i)=>{
            return <Link key={i} to={item.link} ><li>{item.text}</li></Link>
          })
        }
      </ol>
      <header>
        <h1>Sprint 2 - All Pages</h1>
      </header>
      <ol>
        {
          Links2.map((item,i)=>{
            return <Link key={i} to={item.link} ><li>{item.text}</li></Link>
          })
        }
      </ol>
    </div>
  )
}
