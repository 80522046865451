import React, {useState} from 'react';
import { useLoginContext } from '../../../hooks/useLoginContext';
import './rating.scss';

export const Rating = (valor:any) => {
    const [rating, setRating] = useState(valor.valor);
    const {user, setUser} = useLoginContext();
  return (
    <div className="rating-graph">
        <div className="graph">
            <div className='half-donut'>
                <div className="hole">
                    <div className="line" style={{transform: `rotate(${(valor.valor)*1.8}deg)`}}></div>
                    <div className="center-point"></div>
                </div>
            </div>
            {Number(user.idProfile)==1?<>
            <p className="rating">{valor.valor}</p>
            <p>{`scoring `+(valor.valor)+ " point"}</p>
            
            </>:<>
            
            </>}
        </div>
    </div>
  )
}
