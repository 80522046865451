import axios from "axios";
import {WloCreditoService} from "../types";

export class AuthService {
    static async getPerfiles(): Promise<any> {
        try {
            localStorage.clear();
            const {data} = await axios.post(process.env.REACT_APP_API+'/profiles');
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async login(user: WloCreditoService.LoginRequest): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/login', user);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async changepassword(changepass: {
        Pws_Tip_cambio: string,
        Pws_Identificacion: string,
        Pws_Tip_identificacion: string,
        Pws_Tip_perfil: string,
        Pws_clave_enc1: string,
        Pws_clave_enc2: string,
        Pws_Base_datos: string,
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/changepass', changepass);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async loginFirma(user: {
        Pws_Correo: string,
        Pws_Telefono: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/login-firma-correo', user);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async loginValidacion(user: {
        Pws_Identificacion: string,
        Pws_Tip_identificacion: string,
        Pws_Correo: string,
        Pws_Telefono: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/login-firma-identidad', user);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async documentSagrilaft(datoconsul: {
        idDatoConsultado: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/documentoSagrilaft', datoconsul);
            return data;
        } catch (e) {
            throw e;
        }
    }
}