import { ArrowBack } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';
import { Link, To } from 'react-router-dom';
import {useIntl} from "react-intl";

function DirectionButton(props: { to: To; label: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; }) {
  return (
    <Link to={props.to} className="nav-link">
      {props.label}
      <div className="flex-item-left-login">
                    <Fab variant="extended" color="neutral" aria-label="add"
                         className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                        Atras
                        {/* {intl.formatMessage({id: "button_back"})} */}
                    </Fab>
                </div>
    </Link>
  );
}

export { DirectionButton };