import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './MenuBar.scss';
import Cookies from 'js-cookie';
import {useIntl} from "react-intl";
import { Fab } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export function MenuBar() {
    const handleLanguageEnglish = () => {
        Cookies.set('language', 'en');
    }
    const handleLanguageSpanish = () => {
        Cookies.set('language', 'es');
    }

    const language = Cookies.get('language') || 'es';
    const menuTitle = language.toUpperCase();
    const intl = useIntl();
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/">
                  
                <div className='img-logo-mobile-profiles'/>
                </Navbar.Brand>
                <div className="ms-auto basic-navbar-nav">
                        <NavDropdown title={menuTitle} id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={handleLanguageEnglish} href="#">{"EN"}</NavDropdown.Item>
                            <NavDropdown.Item href="#" onClick={handleLanguageSpanish}>
                                {"ES"}
                            </NavDropdown.Item>
                        </NavDropdown>
                </div>
            </Container>
        </Navbar>
    );
}