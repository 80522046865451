import { Navigate, Outlet } from "react-router-dom";
import {useLoginContext} from '../../hooks/useLoginContext';

export const ProtectedRoute = ({ user, redirectPath = '/profile-options' }: any) => {
  const { setUser} = useLoginContext();
    if (!user.idProfile) {
      const items = JSON.parse(localStorage.getItem('User')!);
      if(!items.idProfile){
        return <Navigate to={redirectPath} replace />;
      }else{
        setUser(items)
        if(items.idProfile=="4"){
          return <Navigate to={"menu-agent"} replace />;

        }else if(items.idProfile==="2"){
          return <Navigate to={"catalog"} replace />;
        }
      }
    }
    return <Outlet />;
};
  