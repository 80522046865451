import {useIntl} from "react-intl";
import {ErrorMessage, Field} from "formik";
import {cities, civilStatus, estractos, nivelEstudios, typeVivienda} from "../../../__mocks__/data";
import { useEffect , useState} from "react";
import {useRequestContext} from "../../../hooks/useRequestContext";
import './applicant-info.scss';
import {renderTypeIdentificationDescription} from "../../../utils/utils";
import { FormSpouse } from "./ModalSpouse/FormSpouse";
import _ from "lodash";
import { AdminService, WLOCreditAPIService } from "../../../services";
import moment from "moment";

interface FormPersonalInfoProps {
    errors?: any;
    values?: any;
    setPopup: React.Dispatch<React.SetStateAction<string>>;
}

export function FormPersonalInfo(props: FormPersonalInfoProps) {
    const {request} = useRequestContext();

    const {errors, values,setPopup} = props;
    const intl = useIntl();
    const [modal, setModal] = useState(false);
    const [activelugar, setactive] = useState(false);
    const [fechaexp, setactive2] = useState(false);
    const [ciudades, setCiudades] = useState(cities as any);
    const [ciudades2, setCiudades2] = useState(cities as any);
    const [EstadosCivil, setEstadosCivil] = useState([]);
    const [tipovivi, setTipoVivienda] = useState([]);
    const [NivelEsti, setNivelEstudio] = useState([]);
    const [entidadesFin, setEntidadesFin] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const [fechaExp, setFechaExp] = useState([]);
    const [telefonoMovil, settelefonoMovil] = useState(cities as any);
    const [telefonoMovil2, settelefonoMovil2] = useState(cities as any);
    const [departamentos, setDepartamentos] = useState(cities as any);
    const [allCities, setAllCiudades] = useState(cities as any);
    const [one] = useState(null);
    useEffect(()=>{
        allData()
        AdminService.getAllCiudades().then((Datos:any)=>{
            setAllCiudades(Datos.payload.data)
            if(!request.searchANI){
                if( request.personCygnus?.Pws_Lugar_exp){ 
                    values.Pws_Lugar_exp=request.personCygnus?.Pws_Lugar_exp
                    setactive(true)
                }else{
                    setactive(true)
                }
            }else{
                if(request.personANI?.municipioExpedicion){
                    setactive(true)
                values.Pws_Lugar_exp= _.filter( Datos.payload.data.ListCiudad.Datos_Ciudad, 
                    { s_nivel: '3',s_nombre: request.personANI?.municipioExpedicion}).length>0?
                    _.filter( Datos.payload.data.ListCiudad.Datos_Ciudad, 
                        {s_nivel: '3', s_nombre: request.personANI?.municipioExpedicion})[0].s_codigo:""
                }else{
                    setactive(true)
                }
            }
            // if(!request.searchANI){
            //     if( request.personCygnus?.Pws_Fec_expe){
            //         setactive2(false)
            //     }else{
            //         setactive2(true)

            //     }
            // }else{
            //     if(request.personANI?.fechaExpedicion){
            //         values.Pws_Fec_expe = new Date(request.personANI?.fechaExpedicion)
            //         setactive2(false)
            //     }else{
            //         setactive2(true)

            //     }
            // }
        })   
    },[one])
    useEffect(()=>{
        if(allCities){
        
        let citiesDepent = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        
        
        let citiesDepent2 = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '2'});
        setDepartamentos(citiesDepent)
        citiesDepent2.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3'});
        setCiudades2(citiesDepent2);
        settelefonoMovil(values.Pws_tel_emp1)
        settelefonoMovil2(values.Pws_tel_emp2)
    }
    },[allCities])
    useEffect(() => {
        const fetchSucursal = async () => {
        const sucursal = await AdminService.getSucursal({ s_nombre: values.p_cod_ent });
        try{
            if (sucursal.payload.data.ListSucursales.Datos_Sucursales.length > 0) {
                setSucursales(sucursal.payload.data.ListSucursales.Datos_Sucursales);
            }
                if (values.s_nombre="El Nombre de la entidad no existe o No cuenta con sucursales"){
                    values.s_nombre="1";
                }else{
                    values.s_nombre="1";
                }
        }catch (error){
            console.log(error)
        }
        const personANI = await WLOCreditAPIService.consultaSolicitudANI({
            Pws_Tip_Identificacion: values.Pws_Tip_Identificacion!,
            Pws_Identificacion: values.Pws_Identificacion!
        });
        setFechaExp(personANI.fechaExpedicion)
        };
    
        fetchSucursal();
    }, [values.p_cod_ent]);
    const allData= async ()=>{
        const estadosCvil= await AdminService.getEstadosCiviles();
        if(estadosCvil.payload.data.listEstCivil.Datos_EstCivil.length>0){
            setEstadosCivil(estadosCvil.payload.data.listEstCivil.Datos_EstCivil)
        }
        const tipovivienda= await AdminService.gettipovivienda();
        if(tipovivienda.payload.data.tip_viv.datos_tip_vivi.length>0){
            setTipoVivienda(tipovivienda.payload.data.tip_viv.datos_tip_vivi)
        }
        const nivelEstudi= await AdminService.getNivelEstudio();
        if(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio.length>0){
            setNivelEstudio(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio)
        }
        const entidadesFin= await AdminService.getEntidadFin();
        if (entidadesFin.payload.data.LstEntidadBanco.Datos_Entidades.length>0){
            setEntidadesFin(entidadesFin.payload.data.LstEntidadBanco.Datos_Entidades)
        }}
        
    const toggle = () => setModal(!modal);
    const handleCallModalConyugue =  (e: { target: { value: string; }; }) => {
        if (e.target.value === "C" || e.target.value === "U") {
            setModal(!modal);
        }
    }
    
    
    const handleCities = (e: { target: { value: string; }; }) => {
        let citiesDepent = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3', s_ubicacion: e.target.value });
        
        setCiudades(citiesDepent);
    }

    return (
        <div className="row container-border-app">
            <div className="row" >
                <div className="text-left">
                    
                <div className="col" style={{"textAlign":"left"}}>
                    <h3 >Información del solicitante</h3>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_field_names"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        {!request.searchANI ? request.personCygnus?.Pws_Nombres : request.personANI?.primerNombre + " " + request.personANI?.segundoNombre}
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_field_last_names"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        {!request.searchANI ? request.personCygnus?.Pws_Apellidos1 + ' ' + request.personCygnus?.Pws_Apellidos2 : request.personANI?.primerApellido + " " + request.personANI?.segundoApellido}
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_identification_type"})}</div>
                    <div
                        className={"label-fields-peps-mobile-ligth display-flex"}>
                        {!request.searchANI ? renderTypeIdentificationDescription(request.personCygnus?.Pws_Tip_Identificacion!) : renderTypeIdentificationDescription(values.Pws_Tip_Identificacion!)}
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_identification"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        {!request.searchANI ? request.personCygnus?.Pws_Identificacion : request.personANI?.nuip}
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_expedition_date"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                    {fechaexp?<>
                        <Field id={"Pws_Fec_expe"}
                                name={"Pws_Fec_expe"}
                                type={"date"}
                                className={`${errors.Pws_Fec_expe ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                                placeholder={'MM/dd/YYYY'}/>
                        
                        </>:<>
                        {fechaExp}
        
                        </>}
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_expedition_place"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        {activelugar?<>
                            <Field as="select"
                            className={`${errors.Pws_Lugar_exp ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            name="Pws_Lugar_exp"
                        >
                            <option>Lugar Expedicion</option>
                            {ciudades2 && ciudades2.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>
                        </>:<>
                        {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3',s_codigo:(!request.searchANI ? request.personCygnus?.Pws_Lugar_exp: values.Pws_Lugar_exp)}).length>0?_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3',s_codigo:(!request.searchANI ? request.personCygnus?.Pws_Lugar_exp: values.Pws_Lugar_exp)})[0].s_nombre:""}
        
                        </>}
                </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_date_birth"})}</div>
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        <div className="display-flex">
                            <Field id={"Pws_Fec_nacime"}
                                name={"Pws_Fec_nacime"}
                                type={"date"}
                                className={`${errors.Pws_Fec_nacime ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                                placeholder={'MM/dd/YYYY'}/>
                            <div className="label-fields-peps-warning">
                                <ErrorMessage name="Pws_Fec_nacime"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_marital_status"})}</div>
                    <div className="display-flex">
                        <Field as="select" onBlur={handleCallModalConyugue}
                            className={`${errors.Pws_Estado_Civil ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            name="Pws_Estado_Civil"
                        >
                            <option>{intl.formatMessage({id: "placeholder_marital_status"})}</option>
                            {EstadosCivil && EstadosCivil.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_descripcion}</option>
                                )
                            })}
                        </Field>
                        <div className="label-fields-peps-warning">
                            <ErrorMessage name="Pws_Estado_Civil"/>
                            <ErrorMessage name="Pws_Con_Tip_identif"/>
                            <ErrorMessage name="Pws_Con_identif"/>
                            <ErrorMessage name="Pws_Con_nomsol"/>
                            <ErrorMessage name="Pws_Con_tel"/>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "residence_address"})}</div>
                    <div className="display-flex">
                        <Field id={"Pws_Direccion_res"}
                        
                        
                            name={"Pws_Direccion_res"}
                            className={`${errors.Pws_Direccion_res ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            placeholder={intl.formatMessage({id: "enter"})}/>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "department"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                            className={`${errors.Pws_Departamento ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            name="Pws_Departamento"
                                onClick={handleCities}
                        >
                            <option>{intl.formatMessage({id: "department"})}</option>
                            {departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>
                    </div>

                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "form_financial_city_foreign_currency"})}</div>

                    <div className="display-flex">
                        <Field as="select"
                            className={`${errors.Pws_Ciudad_res ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            name="Pws_Ciudad_res"
                        >
                            <option>{intl.formatMessage({id: "form_financial_city_foreign_currency"})}</option>
                            {ciudades && ciudades.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_mobile_phone_1"})}</div>
                    <div
                        className={"label-fields-peps-mobile-ligth display-flex"}>{values.Pws_Telefono1}</div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_mobile_phone_2"})}</div>
                    <div
                        className={"label-fields-peps-mobile-ligth display-flex"}>{values.Pws_Telefono2}</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_mail"})}</div>
                    <div
                        className={"label-fields-peps-mobile-ligth display-flex"}>{values.Pws_Correo}</div>
                </div>
                <div className="col">
                    <div className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "gender"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                                className={`${errors.Pws_Genero ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            name="Pws_Genero">
                            <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                            <option value={'F'}>{'Femenino'}</option>
                            <option value={'M'}>{'Masculino'}</option>
                        </Field>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_social_stratum"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                            className={`${errors.Pws_Estrato_per ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Estrato_per">
                            <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                            {estractos && estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map((item, key: number) => {
                                return (
                                    <option key={key} value={item.i_codigo}>{item.c_descripcion}</option>
                                )
                            })}
                        </Field>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_number_of_people_in_charge"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                               className={`${errors.Pws_Per_cargo ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Per_cargo">
                            <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                            <option value={'0'}>{'0'}</option>
                            <option value={'1'}>{'1'}</option>
                            <option value={'2'}>{'2'}</option>
                            <option value={'3'}>{'3'}</option>
                            <option value={'4'}>{'4'}</option>
                            <option value={'5'}>{'5'}</option>
                            <option value={'6'}>{'6'}</option>
                            <option value={'7'}>{'7'}</option>
                            <option value={'8'}>{'8'}</option>
                            <option value={'9'}>{'9'}</option>
                            <option value={'10'}>{'10'}</option>
                        </Field>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_type_of_housing"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                               className={`${errors.Pws_Tip_vivienda ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Tip_vivienda">
                            <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                            {tipovivi && tipovivi.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.i_codigo}>{item.c_descripcion}</option>
                                )
                            })}
                        </Field>
                    </div>

                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_educational_level"})}</div>
                    <div className="display-flex"><Field as="select"
                        className={`${errors.Pws_Niv_estudio ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                        name="Pws_Niv_estudio">
                        <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                        {NivelEsti && NivelEsti.map((item:any, key: number) => {
                            return (
                                <option key={key} value={item.s_codigo}>{item.s_descripcion}</option>
                            )
                        })}
                    </Field>
                    </div>

                </div>
                <div className="col">
                    <div className="label-fields-peps-mobile display-flex">
                    Tipo de cuenta
                    </div>
                    <div className="display-flex">
                            <Field as="select"
                                    className={`${errors.p_cod_tipcue ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                                name="p_cod_tipcue">
                                <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                                <option value={'1'}>{'Giro'}</option>
                                <option value={'2'}>{'Cuenta corriente'}</option>
                                <option value={'3'}>{'Cuenta de ahorros'}</option>
                            </Field>
                    </div>
                </div>
                <div className="col">
                <div className="label-fields-peps-mobile display-flex">
                    Número de cuenta
                </div>
                <div className="display-flex">
                    <Field
                    type="text"
                    name="p_cod_cuent"
                    className={`${errors.p_cod_cuent ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                    placeholder="Ingrese el número de cuenta"
                    />
                </div>
                </div>
                <div className="col">
                <div className="label-fields-peps-mobile display-flex">
                    Banco
                    </div>
                    <div className="display-flex">
                    <Field as="select"
                        className={`${errors.p_cod_tipcue ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                        name="p_cod_ent"
                        id="campoEntidad"
                        >
                        <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option> 
                        {entidadesFin && entidadesFin.map((item:any, key: number) => {
                        return (
                        <option key={key} value={item.s_descripcion}>{item.s_descripcion}</option>
                        )
                        })}
                        </Field>
                    </div>
                </div>
                {/* <div className="col">
                <div className="label-fields-peps-mobile display-flex">
                    Sucursal
                    </div>
                    <div className="display-flex" >
                    <Field as="select"
                        className={`${errors.p_cod_tipcue ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                        name="s_nombre" id="campoSucursal">
                        <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                        {sucursales.map((item: any, key: number) => (
                        <option key={key} value={item.s_codigo}>
                            {item.s_nombre === "El Nombre de la entidad no existe o No cuenta con sucursales" ? "No cuenta con una sucursal" : item.s_nombre}
                        </option>
                        ))}
                    </Field>
                    </div>
                </div> */}
                <div className="col"/>
                <FormSpouse values={values} errors={errors} show={modal} onHide={toggle}/>
              
            </div>
        </div>
    )
}
