export const infoProfiles = {
    "Ws_Perfiles_consultaResult": {
        "Pws_R_s_cod": "1",
        "Pws_R_s_mensaje": "Consulta Exitosa",
        "Array_Perfiles": {
            "Datos_Perfiles": [
                {
                    "Pws_Tip_perfil": "1",
                    "Pws_Nom_perf": "Gestor",
                    "Pws_Est_perf": "G"
                },
                {
                    "Pws_Tip_perfil": "2",
                    "Pws_Nom_perf": "Autogestión",
                    "Pws_Est_perf": "AU"
                }
                // ,
                // {
                //     "Pws_Tip_perfil": "3",
                //     "Pws_Nom_perf": "Administrador",
                //     "Pws_Est_perf": "A"
                // }
            ]
        }
    }
};

export const typeIdentification = {
    "WS_TIPOS_IDENTIFICACION_IResult": {
        "LstTiposIdent": {
            "Datos_TiposIdentificacion": [
                {
                    "s_codigo": 4,
                    "s_descripcion": "TARJETA DE IDENTIDAD",
                    "s_datacredito":7
                },
                {
                    "s_codigo": 1,
                    "s_descripcion": "CEDULA",
                    "s_datacredito":1
                },
                {
                    "s_codigo": 4,
                    "s_descripcion": "CEDULA EXTRANJERIA",
                    "s_datacredito":4
                },
                {
                    "s_codigo": 2,
                    "s_descripcion": "NIT",
                    "s_datacredito":2
                },
                {
                    "s_codigo": 5,
                    "s_descripcion": "PASAPORTE",
                    "s_datacredito":5
                }
            ]
        }
    }
};
export const Parentescos=[
    {id:1,name:"PADRE"},
    {id:2,name:"MADRE"},
    {id:3,name:"HIJO(A)"},
    {id:4,name:"CONYUGE"},
    {id:5,name:"COMPAÑERO(A)"},
    {id:6,name:"ABUELO(A)"},
    {id:7,name:"TIO(A)"},
    {id:8,name:"SUEGRO(A)"},
    {id:9,name:"SOBRINO(A)"},
    {id:10,name:"HERMANO(A)"},
    {id:11,name:"PRIMO(A)"},
    {id:12,name:"CUÑADO(A)"}
]
export const Ocupaciones=[
    {id:1,name:"INDEPENDIENTE"},
    {id:2,name:"ASALARIADO"},
    {id:3,name:"PENSIONADO"},
    {id:4,name:"EMPRESA"},
    {id:5,name:"DIRECTORES"},
    {id:6,name:"ADMINISTRADOR"},
    {id:7,name:"CONTABILIDAD"},
    {id:8,name:"CREDITO"},
    {id:9,name:"ADMINISTRATIVO"},
    {id:10,name:"ASESORES"},
    {id:11,name:"CARTERA"},
    {id:12,name:"EMPRESA"}
]
export const terms = {
    "WS_PERIODOResult": {
        "ListPerido": {
            "Datos_Periodo": [
                {
                    "s_codigo": 1,
                    "s_descripcion": "Mensual"
                },
                {
                    "s_codigo": 2,
                    "s_descripcion": "Quincenal"
                },
                {
                    "s_codigo": 3,
                    "s_descripcion": "Anual"
                },
                {
                    "s_codigo": 4,
                    "s_descripcion": "Diaria"
                },
                {
                    "s_codigo": 5,
                    "s_descripcion": "Semestral"
                },
                {
                    "s_codigo": 6,
                    "s_descripcion": "Trimestral"
                },
                {
                    "s_codigo": 7,
                    "s_descripcion": "Bimestral"
                }
            ]
        }
    }
}

export const typeCredit = {
    "WS_LINEAS_CREDITOResult": {
        "ListLineaCredito": {
            "Datos_LineasCredito": [
                {
                    "s_codigo": 1,
                    "s_nombre": "CC CREDITOCONTADO"
                },
                {
                    "s_codigo": 2,
                    "s_nombre": "CS CREDICASARMA"
                },
                {
                    "s_codigo": 3,
                    "s_nombre": "CREDICASARMA EMPLEADOS"
                },
                {
                    "s_codigo": 4,
                    "s_nombre": "CSE25 CREDICASAR EMPLEADOS 2.5"
                },
                {
                    "s_codigo": 5,
                    "s_nombre": "CSEM10 CREDICASAR EMPLEADOS>10"
                },
                {
                    "s_codigo": 6,
                    "s_nombre": "CSEM3 CREDICASAR EMPLEADOS>3"
                },
                {
                    "s_codigo": 7,
                    "s_nombre": "CSEM5 CREDICASAR EMPLEADOS>5"
                },
                {
                    "s_codigo": 8,
                    "s_nombre": "CSEMP CREDICASAR EMPLEADOS&lt;3"
                },
                {
                    "s_codigo": 9,
                    "s_nombre": "MC CREDITO ORDINARIO"
                }
            ]
        }
    }
}

export const civilStatus = {
    "WS_TIPO_ESTADO_CIVIL_IResult": {
        "listEstCivil": {
            "Datos_EstCivil": [
                {
                    "s_codigo": 1,
                    "s_descripcion": "CASADO"
                },
                {
                    "s_codigo": 2,
                    "s_descripcion": "SOLTERO"
                },
                {
                    "s_codigo": 3,
                    "s_descripcion": "UNION LIBRE"
                },
                {
                    "s_codigo": 4,
                    "s_descripcion": "VIUDO"
                },
                {
                    "s_codigo": 5,
                    "s_descripcion": "SEPARADO"
                }
            ]
        }
    }
}

export const cities = {
    "ListCiudad": {
        "Datos_Ciudad": [
            {
                "s_codigo": "815",
                "s_nombre": "TUCHIN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "5001",
                "s_nombre": "MEDELLIN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5002",
                "s_nombre": "ABEJORRAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5004",
                "s_nombre": "ABRIAQUI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5021",
                "s_nombre": "ALEJANDRIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5030",
                "s_nombre": "AMAGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5031",
                "s_nombre": "AMALFI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5034",
                "s_nombre": "ANDES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5036",
                "s_nombre": "ANGELOPOLIS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5038",
                "s_nombre": "ANGOSTURA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5040",
                "s_nombre": "ANORI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5042",
                "s_nombre": "ANTIOQUIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5044",
                "s_nombre": "ANZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5045",
                "s_nombre": "APARTADO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5051",
                "s_nombre": "ARBOLETES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5055",
                "s_nombre": "ARGELIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5059",
                "s_nombre": "ARMENIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5079",
                "s_nombre": "BARBOSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5086",
                "s_nombre": "BELMIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5088",
                "s_nombre": "BELLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5091",
                "s_nombre": "BETANIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5093",
                "s_nombre": "BETULIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5101",
                "s_nombre": "BOLIVAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5107",
                "s_nombre": "BRICENO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5113",
                "s_nombre": "BURITICA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5120",
                "s_nombre": "CACERES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5125",
                "s_nombre": "CAICEDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5129",
                "s_nombre": "CALDAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5134",
                "s_nombre": "CAMPAMENTO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5138",
                "s_nombre": "CANASGORDAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5142",
                "s_nombre": "CARACOLI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5145",
                "s_nombre": "CARAMANTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5147",
                "s_nombre": "CAREPA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5148",
                "s_nombre": "CARMEN DE V",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5150",
                "s_nombre": "CAROLINA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5154",
                "s_nombre": "CAUCASIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5172",
                "s_nombre": "CHIGORODO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5190",
                "s_nombre": "CISNEROS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5197",
                "s_nombre": "COCORNA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5206",
                "s_nombre": "CONCEPCION",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5209",
                "s_nombre": "CONCORDIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5212",
                "s_nombre": "COPACABANA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5234",
                "s_nombre": "DABEIBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5237",
                "s_nombre": "DON MATIAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5240",
                "s_nombre": "EBEJICO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5250",
                "s_nombre": "EL BAGRE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5264",
                "s_nombre": "ENTRERRIOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5266",
                "s_nombre": "ENVIGADO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5282",
                "s_nombre": "FREDONIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5284",
                "s_nombre": "FRONTINO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5306",
                "s_nombre": "GIRALDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5308",
                "s_nombre": "GIRARDOTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5310",
                "s_nombre": "GOMEZ PLATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5313",
                "s_nombre": "GRANADA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5315",
                "s_nombre": "GUADALUPE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5318",
                "s_nombre": "GUARNE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5321",
                "s_nombre": "GUATAPE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5347",
                "s_nombre": "HELICONIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5353",
                "s_nombre": "HISPANIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5360",
                "s_nombre": "ITAGUI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5361",
                "s_nombre": "ITUANGO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5364",
                "s_nombre": "JARDIN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5368",
                "s_nombre": "JERICO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5376",
                "s_nombre": "LA CEJA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5380",
                "s_nombre": "LA ESTRELLA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5387",
                "s_nombre": "LA MAGDALEN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5400",
                "s_nombre": "LA UNION",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5411",
                "s_nombre": "LIBORINA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5425",
                "s_nombre": "MACEO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5440",
                "s_nombre": "MARINILLA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5467",
                "s_nombre": "MONTEBELLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5475",
                "s_nombre": "MURINDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5480",
                "s_nombre": "MUTATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5483",
                "s_nombre": "NARINO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5490",
                "s_nombre": "NECOCLI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5495",
                "s_nombre": "NECHI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5501",
                "s_nombre": "OLAYA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5541",
                "s_nombre": "PENOL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5543",
                "s_nombre": "PEQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5576",
                "s_nombre": "PUEBLORRICO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5579",
                "s_nombre": "PUERTO BERRIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5585",
                "s_nombre": "PTO. NARE L",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5591",
                "s_nombre": "PUERTO TRIU",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5604",
                "s_nombre": "REMEDIOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5607",
                "s_nombre": "RETIRO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5615",
                "s_nombre": "RIONEGRO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5628",
                "s_nombre": "SABANALARGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5631",
                "s_nombre": "SABANETA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5642",
                "s_nombre": "SALGAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5647",
                "s_nombre": "SAN ANDRES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5649",
                "s_nombre": "SAN CARLOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5656",
                "s_nombre": "SAN JERONIM",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5658",
                "s_nombre": "SAN JOSE DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5659",
                "s_nombre": "SAN JUAN DE URABA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5660",
                "s_nombre": "SAN LUIS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5664",
                "s_nombre": "SAN PEDRO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5665",
                "s_nombre": "SAN PEDRO U",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5667",
                "s_nombre": "SAN RAFAEL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5670",
                "s_nombre": "SAN ROQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5674",
                "s_nombre": "SAN VICENTE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5679",
                "s_nombre": "SANTA BARBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5686",
                "s_nombre": "SANTA ROSA DE OSOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5690",
                "s_nombre": "SANTO DOMIN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5697",
                "s_nombre": "SANTUARIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5736",
                "s_nombre": "SEGOVIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5756",
                "s_nombre": "SONSON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5761",
                "s_nombre": "SOPETRAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5789",
                "s_nombre": "TAMESIS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5790",
                "s_nombre": "TARAZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5792",
                "s_nombre": "TARSO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5809",
                "s_nombre": "TITIRIBI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5819",
                "s_nombre": "TOLEDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5837",
                "s_nombre": "TURBO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5842",
                "s_nombre": "URAMITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5847",
                "s_nombre": "URRAO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5854",
                "s_nombre": "VALDIVIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5856",
                "s_nombre": "VALPARAISO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5858",
                "s_nombre": "VEGACHI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5861",
                "s_nombre": "VENECIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5873",
                "s_nombre": "VIGIA DEL F",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5885",
                "s_nombre": "YALI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5887",
                "s_nombre": "YARUMAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5890",
                "s_nombre": "YOLOMBO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5893",
                "s_nombre": "YONDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "5895",
                "s_nombre": "ZARAGOZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "5000"
            },
            {
                "s_codigo": "8001",
                "s_nombre": "BARRANQUILLA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8078",
                "s_nombre": "BARANOA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8137",
                "s_nombre": "CAMPO DE LA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8141",
                "s_nombre": "CANDELARIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8296",
                "s_nombre": "GALAPA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8372",
                "s_nombre": "JUAN DE ACO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8421",
                "s_nombre": "LURUACO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8433",
                "s_nombre": "MALAMBO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8436",
                "s_nombre": "MANATI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8520",
                "s_nombre": "PALMAR DE V",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8549",
                "s_nombre": "PIOJO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8558",
                "s_nombre": "POLONUEVO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8560",
                "s_nombre": "PONEDERA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8573",
                "s_nombre": "PUERTO COLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8606",
                "s_nombre": "REPELON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8634",
                "s_nombre": "SABANAGRAND",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8638",
                "s_nombre": "SABANALARGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8675",
                "s_nombre": "SANTA LUCIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8685",
                "s_nombre": "SANTO TOMAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8758",
                "s_nombre": "SOLEDAD",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "8832",
                "s_nombre": "TUBARA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "8000"
            },
            {
                "s_codigo": "11102",
                "s_nombre": "BOSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "11000"
            },
            {
                "s_codigo": "11265",
                "s_nombre": "ENGATIVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "11000"
            },
            {
                "s_codigo": "11279",
                "s_nombre": "FONTIBON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "11000"
            },
            {
                "s_codigo": "11769",
                "s_nombre": "SUBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "11000"
            },
            {
                "s_codigo": "11848",
                "s_nombre": "USAQUEN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "11000"
            },
            {
                "s_codigo": "11850",
                "s_nombre": "USME",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "11000"
            },
            {
                "s_codigo": "13001",
                "s_nombre": "CARTAGENA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13006",
                "s_nombre": "ACHI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13052",
                "s_nombre": "ARJONA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13074",
                "s_nombre": "BARRANCO DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13140",
                "s_nombre": "CALAMAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13212",
                "s_nombre": "CORDOBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13244",
                "s_nombre": "EL CARMEN D",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13248",
                "s_nombre": "EL GUAMO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13430",
                "s_nombre": "MAGANGUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13433",
                "s_nombre": "MAHATES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13440",
                "s_nombre": "MARGARITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13442",
                "s_nombre": "MARIA LA BA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13468",
                "s_nombre": "MOMPOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13473",
                "s_nombre": "MORALES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13549",
                "s_nombre": "PINILLOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13600",
                "s_nombre": "RIO VIEJO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13647",
                "s_nombre": "SAN ESTANIS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13650",
                "s_nombre": "SAN FERNAND",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13654",
                "s_nombre": "SAN JACINTO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13657",
                "s_nombre": "SAN JUAN NE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13667",
                "s_nombre": "SAN MARTIN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13670",
                "s_nombre": "SAN PABLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13673",
                "s_nombre": "SANTA CATAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13683",
                "s_nombre": "SANTA ROSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13744",
                "s_nombre": "SIMITI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13760",
                "s_nombre": "SOPLAVIENTO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13836",
                "s_nombre": "TURBACO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13838",
                "s_nombre": "TURBANA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13873",
                "s_nombre": "VILLANUEVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "13894",
                "s_nombre": "ZAMBRANO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "13000"
            },
            {
                "s_codigo": "15001",
                "s_nombre": "TUNJA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15022",
                "s_nombre": "ALMEIDA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15047",
                "s_nombre": "AQUITANIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15051",
                "s_nombre": "ARCABUCO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15087",
                "s_nombre": "BELEN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15090",
                "s_nombre": "BERBEO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15092",
                "s_nombre": "BETEITIVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15097",
                "s_nombre": "BOAVITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15104",
                "s_nombre": "BOYACA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15106",
                "s_nombre": "BRICENO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15109",
                "s_nombre": "BUENAVISTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15114",
                "s_nombre": "BUSBANZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15131",
                "s_nombre": "CALDAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15135",
                "s_nombre": "CAMPOHERMOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15162",
                "s_nombre": "CERINZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15172",
                "s_nombre": "CHINAVITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15176",
                "s_nombre": "CHIQUINQUIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15180",
                "s_nombre": "CHISCAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15183",
                "s_nombre": "CHITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15185",
                "s_nombre": "CHITARAQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15187",
                "s_nombre": "CHIVATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15189",
                "s_nombre": "CIENEGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15204",
                "s_nombre": "COMBITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15212",
                "s_nombre": "COPER",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15215",
                "s_nombre": "CORRALES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15218",
                "s_nombre": "COVARACHIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15223",
                "s_nombre": "CUBARA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15224",
                "s_nombre": "CUCAITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15226",
                "s_nombre": "CUITIVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15232",
                "s_nombre": "CHIQUIZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15238",
                "s_nombre": "DUITAMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15244",
                "s_nombre": "EL COCUY",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15248",
                "s_nombre": "EL ESPINO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15272",
                "s_nombre": "FIRAVITOBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15276",
                "s_nombre": "FLORESTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15293",
                "s_nombre": "GACHANTIVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15296",
                "s_nombre": "GAMEZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15299",
                "s_nombre": "GARAGOA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15317",
                "s_nombre": "GUACAMAYAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15322",
                "s_nombre": "GUATEQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15325",
                "s_nombre": "GUAYATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15332",
                "s_nombre": "GUICAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15362",
                "s_nombre": "IZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15367",
                "s_nombre": "JENESANO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15368",
                "s_nombre": "JERICO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15377",
                "s_nombre": "LABRANZAGRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15380",
                "s_nombre": "LA CAPILLA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15401",
                "s_nombre": "LA VICTORIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15403",
                "s_nombre": "LA UVITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15407",
                "s_nombre": "LEIVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15425",
                "s_nombre": "MACANAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15442",
                "s_nombre": "MARIPI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15455",
                "s_nombre": "MIRAFLORES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15464",
                "s_nombre": "MONGUA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15466",
                "s_nombre": "MONGUI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15469",
                "s_nombre": "MONIQUIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15476",
                "s_nombre": "MOTAVITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15480",
                "s_nombre": "MUZO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15491",
                "s_nombre": "NOBSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15494",
                "s_nombre": "NUEVO COLON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15500",
                "s_nombre": "OICATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15507",
                "s_nombre": "OTANCHE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15511",
                "s_nombre": "PACHAVITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15514",
                "s_nombre": "PAEZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15516",
                "s_nombre": "PAIPA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15518",
                "s_nombre": "PAJARITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15522",
                "s_nombre": "PANQUEBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15531",
                "s_nombre": "PAUNA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15533",
                "s_nombre": "PAYA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15537",
                "s_nombre": "PAZ DE RIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15542",
                "s_nombre": "PESCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15550",
                "s_nombre": "PISVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15572",
                "s_nombre": "PUERTO BOYA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15599",
                "s_nombre": "RAMIRIQUI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15600",
                "s_nombre": "RAQUIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15621",
                "s_nombre": "RONDON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15632",
                "s_nombre": "SABOYA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15638",
                "s_nombre": "SACHICA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15646",
                "s_nombre": "SAMACA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15660",
                "s_nombre": "SAN EDUARDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15664",
                "s_nombre": "SAN JOSE DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15667",
                "s_nombre": "SAN LUIS DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15673",
                "s_nombre": "SAN MATEO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15676",
                "s_nombre": "SAN MIGUEL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15681",
                "s_nombre": "SAN PABLO B",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15686",
                "s_nombre": "SANTANA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15690",
                "s_nombre": "SANTA MARIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15693",
                "s_nombre": "SANTA ROSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15696",
                "s_nombre": "SANTA SOFIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15720",
                "s_nombre": "SATIVANORTE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15723",
                "s_nombre": "SATIVASUR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15740",
                "s_nombre": "SIACHOQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15753",
                "s_nombre": "SOATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15755",
                "s_nombre": "SOCOTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15757",
                "s_nombre": "SOCHA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15759",
                "s_nombre": "SOGAMOSO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15761",
                "s_nombre": "SOMONDOCO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15762",
                "s_nombre": "SORA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15763",
                "s_nombre": "SOTAQUIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15764",
                "s_nombre": "SORACA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15774",
                "s_nombre": "SUSACON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15776",
                "s_nombre": "SUTAMARCHAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15778",
                "s_nombre": "SUTATENZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15790",
                "s_nombre": "TASCO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15798",
                "s_nombre": "TENZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15804",
                "s_nombre": "TIBANA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15806",
                "s_nombre": "TIBASOSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15808",
                "s_nombre": "TINJACA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15810",
                "s_nombre": "TIPACOQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15814",
                "s_nombre": "TOCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15816",
                "s_nombre": "TOGUI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15820",
                "s_nombre": "TOPAGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15822",
                "s_nombre": "TOTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15832",
                "s_nombre": "TUNUNGUA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15835",
                "s_nombre": "TURMEQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15837",
                "s_nombre": "TUTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15839",
                "s_nombre": "TUTASA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15842",
                "s_nombre": "UMBITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15861",
                "s_nombre": "VENTAQUEMAD",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15879",
                "s_nombre": "VIRACACHA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "15897",
                "s_nombre": "ZETAQUIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "15000"
            },
            {
                "s_codigo": "17001",
                "s_nombre": "MANIZALES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17013",
                "s_nombre": "AGUADAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17042",
                "s_nombre": "ANSERMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17050",
                "s_nombre": "ARANZAZU",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17088",
                "s_nombre": "BELALCAZAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17174",
                "s_nombre": "CHINCHINA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17272",
                "s_nombre": "FILADELFIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17380",
                "s_nombre": "LA DORADA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17388",
                "s_nombre": "LA MERCED",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17433",
                "s_nombre": "MANZANARES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17442",
                "s_nombre": "MARMATO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17444",
                "s_nombre": "MARQUETALIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17446",
                "s_nombre": "MARULANDA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17486",
                "s_nombre": "NEIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17513",
                "s_nombre": "PACORA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17524",
                "s_nombre": "PALESTINA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17541",
                "s_nombre": "PENSILVANIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17614",
                "s_nombre": "RIOSUCIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17616",
                "s_nombre": "RISARALDA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17653",
                "s_nombre": "SALAMINA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17662",
                "s_nombre": "SAMANA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17777",
                "s_nombre": "SUPIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17867",
                "s_nombre": "VICTORIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17873",
                "s_nombre": "VILLAMARIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "17877",
                "s_nombre": "VITERBO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "17000"
            },
            {
                "s_codigo": "18001",
                "s_nombre": "FLORENCIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18029",
                "s_nombre": "ALBANIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18050",
                "s_nombre": "CARTAGENA D",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18094",
                "s_nombre": "BELEN DE LO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18185",
                "s_nombre": "CUEMANI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18247",
                "s_nombre": "EL DONCELLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18256",
                "s_nombre": "EL PAUJIL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18303",
                "s_nombre": "GETUCHA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18410",
                "s_nombre": "LA MONTAYIT",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18449",
                "s_nombre": "MATICURU",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18460",
                "s_nombre": "MILAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18479",
                "s_nombre": "MORELIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18592",
                "s_nombre": "PUERTO RICO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18753",
                "s_nombre": "SAN VICENTE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18765",
                "s_nombre": "SOLANO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18767",
                "s_nombre": "SOLITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "18860",
                "s_nombre": "VALPARAISO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "18000"
            },
            {
                "s_codigo": "19001",
                "s_nombre": "POPAYAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19022",
                "s_nombre": "ALMAGUER",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19050",
                "s_nombre": "ARGELIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19075",
                "s_nombre": "BALBOA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19100",
                "s_nombre": "BOLIVAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19110",
                "s_nombre": "BUENOS AIRE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19130",
                "s_nombre": "CAJIBIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19137",
                "s_nombre": "CALDONO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19142",
                "s_nombre": "CALOTO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19212",
                "s_nombre": "CORINTO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19256",
                "s_nombre": "EL TAMBO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19318",
                "s_nombre": "GUAPI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19355",
                "s_nombre": "INZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19364",
                "s_nombre": "JAMBALO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19392",
                "s_nombre": "LA SIERRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19397",
                "s_nombre": "LA VEGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19418",
                "s_nombre": "LOPEZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19450",
                "s_nombre": "MERCADERES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19455",
                "s_nombre": "MIRANDA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19473",
                "s_nombre": "MORALES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19513",
                "s_nombre": "PADILLA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19517",
                "s_nombre": "PAEZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19532",
                "s_nombre": "PATIA (EL B",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19548",
                "s_nombre": "PIENDAMO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19573",
                "s_nombre": "PUERTO TEJADA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19585",
                "s_nombre": "PURACE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19622",
                "s_nombre": "ROSAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19693",
                "s_nombre": "SAN SEBASTI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19698",
                "s_nombre": "SANTANDER D",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19701",
                "s_nombre": "SANTA ROSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19743",
                "s_nombre": "SILVIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19760",
                "s_nombre": "SOTARA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19807",
                "s_nombre": "TIMBIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19809",
                "s_nombre": "TIMBIQUI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19821",
                "s_nombre": "TORIBIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "19824",
                "s_nombre": "TOTORO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "19000"
            },
            {
                "s_codigo": "20001",
                "s_nombre": "VALLEDUPAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20011",
                "s_nombre": "AGUACHICA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20013",
                "s_nombre": "AGUSTIN COD",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20045",
                "s_nombre": "BECERRIL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20175",
                "s_nombre": "CHIMICHAGUA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20228",
                "s_nombre": "CURUMANI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20238",
                "s_nombre": "EL COPEY",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20250",
                "s_nombre": "EL PASO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20295",
                "s_nombre": "GAMARRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20310",
                "s_nombre": "GONZALEZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20383",
                "s_nombre": "LA GLORIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20400",
                "s_nombre": "L. JAGUA IB",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20443",
                "s_nombre": "MANAURE B.",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20517",
                "s_nombre": "PAILITAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20550",
                "s_nombre": "PELAYA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20614",
                "s_nombre": "RIO DE ORO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20621",
                "s_nombre": "ROBLES LA P",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20710",
                "s_nombre": "SAN ALBERTO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20750",
                "s_nombre": "SAN DIEGO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20770",
                "s_nombre": "SAN MARTIN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "20787",
                "s_nombre": "TAMALAMEQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "20000"
            },
            {
                "s_codigo": "23001",
                "s_nombre": "MONTERIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23068",
                "s_nombre": "AYAPEL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23079",
                "s_nombre": "BUENAVISTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23090",
                "s_nombre": "CANALETE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23162",
                "s_nombre": "CERETE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23168",
                "s_nombre": "CHIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23182",
                "s_nombre": "CHINU",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23189",
                "s_nombre": "CIENAGA DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23300",
                "s_nombre": "COTORRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23417",
                "s_nombre": "LORICA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23419",
                "s_nombre": "LOS CORDOBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23464",
                "s_nombre": "MOMIL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23466",
                "s_nombre": "MONTELIBANO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23500",
                "s_nombre": "MONITOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23555",
                "s_nombre": "PLANETA RIC",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23570",
                "s_nombre": "PUEBLO NUEV",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23574",
                "s_nombre": "PUERTO ESCO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23580",
                "s_nombre": "PUERTO LIBE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23586",
                "s_nombre": "PURISIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23660",
                "s_nombre": "SAHAGUN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23670",
                "s_nombre": "SAN ANDRES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23672",
                "s_nombre": "SAN ANTERO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23675",
                "s_nombre": "SAN BERNARD",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23678",
                "s_nombre": "SAN CARLOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23686",
                "s_nombre": "SAN PELAYO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23807",
                "s_nombre": "TIERRALTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "23855",
                "s_nombre": "VALENCIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "23000"
            },
            {
                "s_codigo": "25001",
                "s_nombre": "AGUA DE DIOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25019",
                "s_nombre": "ALBAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25035",
                "s_nombre": "ANAPOIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25040",
                "s_nombre": "ANOLAIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25053",
                "s_nombre": "ARBELAEZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25086",
                "s_nombre": "BELTRAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25095",
                "s_nombre": "BITUIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25099",
                "s_nombre": "BOJACA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25120",
                "s_nombre": "CABRERA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25123",
                "s_nombre": "CACHIPAY",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25126",
                "s_nombre": "CAJICA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25148",
                "s_nombre": "CAPARRAPI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25151",
                "s_nombre": "CAQUEZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25154",
                "s_nombre": "CARMEN DE C",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25168",
                "s_nombre": "CHAGUANI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25175",
                "s_nombre": "CHIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25178",
                "s_nombre": "CHIPAQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25181",
                "s_nombre": "CHOACHI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25183",
                "s_nombre": "CHOCONTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25200",
                "s_nombre": "COGUA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25214",
                "s_nombre": "COTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25224",
                "s_nombre": "CUCUNUBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25245",
                "s_nombre": "EL COLEGIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25258",
                "s_nombre": "EL PENON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25269",
                "s_nombre": "FACATATIVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25279",
                "s_nombre": "FOMEQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25281",
                "s_nombre": "FOSCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25286",
                "s_nombre": "FUNZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25288",
                "s_nombre": "FUQUENE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25290",
                "s_nombre": "FUSAGASUGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25293",
                "s_nombre": "GACHALA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25295",
                "s_nombre": "GACHANCIPA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25297",
                "s_nombre": "GACHETA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25299",
                "s_nombre": "GAMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25307",
                "s_nombre": "GIRARDOT",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25317",
                "s_nombre": "GUACHETA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25320",
                "s_nombre": "GUADUAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25322",
                "s_nombre": "GUASCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25324",
                "s_nombre": "GUATAQUI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25326",
                "s_nombre": "GUATAVITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25328",
                "s_nombre": "GUAYABAL SI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25335",
                "s_nombre": "GUAYABETAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25339",
                "s_nombre": "GUTIERREZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25368",
                "s_nombre": "JERUSALEN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25372",
                "s_nombre": "JUNIN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25377",
                "s_nombre": "LA CALERA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25386",
                "s_nombre": "LA MESA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25394",
                "s_nombre": "LA PALMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25398",
                "s_nombre": "LA PENA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25402",
                "s_nombre": "LA VEGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25407",
                "s_nombre": "LENGUAZAQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25426",
                "s_nombre": "MACHETA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25430",
                "s_nombre": "MADRID",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25436",
                "s_nombre": "MANTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25438",
                "s_nombre": "MEDINA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25473",
                "s_nombre": "MOSQUERA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25483",
                "s_nombre": "NARINO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25486",
                "s_nombre": "NEMOCON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25488",
                "s_nombre": "NILO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25489",
                "s_nombre": "NIMAIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25491",
                "s_nombre": "NOCAIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25506",
                "s_nombre": "OSPINA PERE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25513",
                "s_nombre": "PACHO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25518",
                "s_nombre": "PAIME",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25524",
                "s_nombre": "PANDI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25530",
                "s_nombre": "PARATEBUENO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25535",
                "s_nombre": "PASCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25572",
                "s_nombre": "PUERTO SALG",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25580",
                "s_nombre": "PULI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25592",
                "s_nombre": "QUEBRADANEG",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25594",
                "s_nombre": "QUETAME",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25596",
                "s_nombre": "QUIPILE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25599",
                "s_nombre": "RAFAEL REYE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25612",
                "s_nombre": "RICAURTE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25645",
                "s_nombre": "SAN ANTONIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25649",
                "s_nombre": "SAN BERNARD",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25653",
                "s_nombre": "SAN CAYETAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25658",
                "s_nombre": "SAN FRANCIS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25662",
                "s_nombre": "SAN JUAN DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25718",
                "s_nombre": "SASAIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25736",
                "s_nombre": "SESQUILE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25740",
                "s_nombre": "SIBATE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25743",
                "s_nombre": "SILVANIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25745",
                "s_nombre": "SIMIJACA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25754",
                "s_nombre": "SOACHA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25758",
                "s_nombre": "SOPO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25769",
                "s_nombre": "SUBACHOQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25772",
                "s_nombre": "SUESCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25777",
                "s_nombre": "SUPATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25779",
                "s_nombre": "SUSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25781",
                "s_nombre": "SUTATAUSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25785",
                "s_nombre": "TABIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25793",
                "s_nombre": "TAUSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25797",
                "s_nombre": "TENA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25799",
                "s_nombre": "TENJO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25805",
                "s_nombre": "TIBACUY",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25807",
                "s_nombre": "TIBIRITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25815",
                "s_nombre": "TOCAIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25817",
                "s_nombre": "TOCANCIPA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25823",
                "s_nombre": "TOPAIPI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25839",
                "s_nombre": "UBALA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25841",
                "s_nombre": "UBAQUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25843",
                "s_nombre": "UBATE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25845",
                "s_nombre": "UNE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25851",
                "s_nombre": "UTICA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25862",
                "s_nombre": "VERGARA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25867",
                "s_nombre": "VIANI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25871",
                "s_nombre": "VILLAGOMEZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25873",
                "s_nombre": "VILLAPINZON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25875",
                "s_nombre": "VILLETA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25878",
                "s_nombre": "VIOTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25885",
                "s_nombre": "YACOPI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25898",
                "s_nombre": "ZIPACON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "25899",
                "s_nombre": "ZIPAQUIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "25000"
            },
            {
                "s_codigo": "27001",
                "s_nombre": "QUIBDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27006",
                "s_nombre": "ACANDI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27025",
                "s_nombre": "ALTO BAUDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27073",
                "s_nombre": "BAGADO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27075",
                "s_nombre": "BAHIA SOLAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27077",
                "s_nombre": "BAJO BAUDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27099",
                "s_nombre": "BOJAYA (BEL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27205",
                "s_nombre": "CONDOTO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27245",
                "s_nombre": "EL CARMEN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27361",
                "s_nombre": "ISTMINA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27372",
                "s_nombre": "JURADO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27413",
                "s_nombre": "LLORO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27491",
                "s_nombre": "NOVITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27495",
                "s_nombre": "NUQUI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27615",
                "s_nombre": "RIOSUCIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27660",
                "s_nombre": "SAN JOSE DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27745",
                "s_nombre": "SIPI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27787",
                "s_nombre": "TADO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "27800",
                "s_nombre": "UNGUIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "27000"
            },
            {
                "s_codigo": "41001",
                "s_nombre": "NEIVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41006",
                "s_nombre": "ACEVEDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41013",
                "s_nombre": "AGRADO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41016",
                "s_nombre": "AIPE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41020",
                "s_nombre": "ALGECIRAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41026",
                "s_nombre": "ALTAMIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41078",
                "s_nombre": "BARAYA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41132",
                "s_nombre": "CAMPOALEGRE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41206",
                "s_nombre": "COLOMBIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41244",
                "s_nombre": "ELIAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41298",
                "s_nombre": "GARZON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41306",
                "s_nombre": "GIGANTE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41319",
                "s_nombre": "GUADALUPE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41349",
                "s_nombre": "HOBO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41357",
                "s_nombre": "IQUIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41359",
                "s_nombre": "ISNOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41378",
                "s_nombre": "LA ARGENTIN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41396",
                "s_nombre": "LA PLATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41483",
                "s_nombre": "NATAGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41503",
                "s_nombre": "OPORAPA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41518",
                "s_nombre": "PAICOL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41524",
                "s_nombre": "PALERMO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41548",
                "s_nombre": "PITAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41551",
                "s_nombre": "PITALITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41615",
                "s_nombre": "RIVERA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41660",
                "s_nombre": "SALADOBLANC",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41668",
                "s_nombre": "SAN AGUSTIN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41676",
                "s_nombre": "SANTA MARIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41770",
                "s_nombre": "SUAZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41791",
                "s_nombre": "TARQUI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41797",
                "s_nombre": "TESALIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41799",
                "s_nombre": "TELLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41801",
                "s_nombre": "TERUEL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41807",
                "s_nombre": "TIMANA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41872",
                "s_nombre": "VILLAVIEJA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "41885",
                "s_nombre": "YAGUARA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "41000"
            },
            {
                "s_codigo": "44001",
                "s_nombre": "RIOHACHA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "44000"
            },
            {
                "s_codigo": "44078",
                "s_nombre": "BARRANCAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "44000"
            },
            {
                "s_codigo": "44279",
                "s_nombre": "FONSECA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "44000"
            },
            {
                "s_codigo": "44430",
                "s_nombre": "MAICAO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "44000"
            },
            {
                "s_codigo": "44560",
                "s_nombre": "MANAURE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "44000"
            },
            {
                "s_codigo": "44650",
                "s_nombre": "SAN JUAN DEL CESAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "44000"
            },
            {
                "s_codigo": "44847",
                "s_nombre": "URIBIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "44000"
            },
            {
                "s_codigo": "44855",
                "s_nombre": "URUMITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "44000"
            },
            {
                "s_codigo": "44874",
                "s_nombre": "VILLANUEVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "44000"
            },
            {
                "s_codigo": "47001",
                "s_nombre": "SANTA MARTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47053",
                "s_nombre": "ARACATACA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47058",
                "s_nombre": "ARIGUANI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47161",
                "s_nombre": "CERRO SAN A",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47170",
                "s_nombre": "CHIVOLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47189",
                "s_nombre": "CIENAGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47245",
                "s_nombre": "EL BANCO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47258",
                "s_nombre": "EL PINON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47288",
                "s_nombre": "FUNDACION",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47318",
                "s_nombre": "GUAMAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47541",
                "s_nombre": "PEDRAZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47551",
                "s_nombre": "PIVIJAY",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47555",
                "s_nombre": "PLATO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47570",
                "s_nombre": "PUEBLO VIEJ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47605",
                "s_nombre": "REMOLINO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47675",
                "s_nombre": "SALAMINA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47692",
                "s_nombre": "SAN SEBASTI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47703",
                "s_nombre": "SAN ZENON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47707",
                "s_nombre": "SANTA ANA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47745",
                "s_nombre": "SITIONUEVO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "47798",
                "s_nombre": "TENERIFE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "47000"
            },
            {
                "s_codigo": "50001",
                "s_nombre": "VILLAVICENCIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50006",
                "s_nombre": "ACACIAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50124",
                "s_nombre": "CABUYARO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50150",
                "s_nombre": "CASTILLA LA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50223",
                "s_nombre": "CUBARRAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50226",
                "s_nombre": "CUMARAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50245",
                "s_nombre": "EL CALVARIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50251",
                "s_nombre": "EL CASTILLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50287",
                "s_nombre": "FUENTE DE O",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50313",
                "s_nombre": "GRANADA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50318",
                "s_nombre": "GUAMAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50330",
                "s_nombre": "MESETAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50350",
                "s_nombre": "LA MACARENA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50400",
                "s_nombre": "LEJANIAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50568",
                "s_nombre": "PUERTO GAIT",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50573",
                "s_nombre": "PUERTO LOPE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50577",
                "s_nombre": "PUERTO LLER",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50606",
                "s_nombre": "RESTREPO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50680",
                "s_nombre": "SAN CARLOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50683",
                "s_nombre": "SAN JUAN DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50686",
                "s_nombre": "SAN JUANITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50689",
                "s_nombre": "SAN MARTIN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "50711",
                "s_nombre": "VISTA HERMO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "50000"
            },
            {
                "s_codigo": "52001",
                "s_nombre": "PASTO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52019",
                "s_nombre": "ALBAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52022",
                "s_nombre": "ALDANA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52036",
                "s_nombre": "ANCUYA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52051",
                "s_nombre": "ARBOLEDA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52079",
                "s_nombre": "BARBACOAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52110",
                "s_nombre": "BUESACO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52203",
                "s_nombre": "COLON (GENO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52207",
                "s_nombre": "CONSACA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52210",
                "s_nombre": "CONTADERO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52215",
                "s_nombre": "CORDOBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52224",
                "s_nombre": "CUASPUD",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52227",
                "s_nombre": "CUMBAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52233",
                "s_nombre": "CUMBITARA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52250",
                "s_nombre": "EL CHARCO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52256",
                "s_nombre": "EL ROSARIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52258",
                "s_nombre": "EL TABLON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52260",
                "s_nombre": "EL TAMBO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52287",
                "s_nombre": "FUNES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52317",
                "s_nombre": "GUACHUCAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52320",
                "s_nombre": "GUAITARILLA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52323",
                "s_nombre": "GUALMATAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52352",
                "s_nombre": "ILES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52354",
                "s_nombre": "IMUES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52356",
                "s_nombre": "IPIALES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52378",
                "s_nombre": "LA CRUZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52381",
                "s_nombre": "LA FLORIDA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52399",
                "s_nombre": "LA UNION",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52405",
                "s_nombre": "LEIVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52411",
                "s_nombre": "LINARES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52418",
                "s_nombre": "LOS ANDES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52427",
                "s_nombre": "MAGUI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52435",
                "s_nombre": "MALLAMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52473",
                "s_nombre": "MOSQUERA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52490",
                "s_nombre": "OLAYA HERRE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52506",
                "s_nombre": "OSPINA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52520",
                "s_nombre": "FRANCISCO P",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52540",
                "s_nombre": "POLICARPA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52560",
                "s_nombre": "POTOSI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52573",
                "s_nombre": "PUERRES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52585",
                "s_nombre": "PUPIALES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52612",
                "s_nombre": "RICAURTE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52621",
                "s_nombre": "ROBERTO PAY",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52678",
                "s_nombre": "SAMANIEGO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52683",
                "s_nombre": "SANDONA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52687",
                "s_nombre": "SAN LORENZO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52693",
                "s_nombre": "SAN PABLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52696",
                "s_nombre": "SANTA BARBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52699",
                "s_nombre": "SANTACRUZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52720",
                "s_nombre": "SAPUYES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52786",
                "s_nombre": "TAMINANGO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52788",
                "s_nombre": "TANGUA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52835",
                "s_nombre": "TUMACO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52838",
                "s_nombre": "TUQUERRES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "52885",
                "s_nombre": "YACUANQUER",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "52000"
            },
            {
                "s_codigo": "54001",
                "s_nombre": "CUCUTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54003",
                "s_nombre": "ABREGO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54051",
                "s_nombre": "ARBOLEDAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54099",
                "s_nombre": "BOCHALEMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54109",
                "s_nombre": "BUCARASICA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54125",
                "s_nombre": "CACOTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54128",
                "s_nombre": "CACHIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54172",
                "s_nombre": "CHINACOTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54174",
                "s_nombre": "CHITAGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54206",
                "s_nombre": "CONVENCION",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54223",
                "s_nombre": "CUCUTILLA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54239",
                "s_nombre": "DURANIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54245",
                "s_nombre": "EL CARMEN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54261",
                "s_nombre": "EL ZULIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54313",
                "s_nombre": "GRAMALOTE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54344",
                "s_nombre": "HACARI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54347",
                "s_nombre": "HERRAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54377",
                "s_nombre": "LABATECA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54398",
                "s_nombre": "LA PLAYA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54418",
                "s_nombre": "LOURDES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54480",
                "s_nombre": "MUTISCUA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54498",
                "s_nombre": "OCAÑA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54518",
                "s_nombre": "PAMPLONA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54520",
                "s_nombre": "PAMPLONITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54599",
                "s_nombre": "RAGONVALIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54660",
                "s_nombre": "SALAZAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54670",
                "s_nombre": "SAN CALIXTO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54673",
                "s_nombre": "SAN CAYETAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54680",
                "s_nombre": "SANTIAGO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54720",
                "s_nombre": "SARDINATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54743",
                "s_nombre": "SILOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54800",
                "s_nombre": "TEORAMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54810",
                "s_nombre": "TIBU",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54820",
                "s_nombre": "TOLEDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54871",
                "s_nombre": "VILLA CARO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "54874",
                "s_nombre": "VILLA ROSAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "54000"
            },
            {
                "s_codigo": "63001",
                "s_nombre": "ARMENIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "63111",
                "s_nombre": "BUENAVISTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "63130",
                "s_nombre": "CALARCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "63190",
                "s_nombre": "CIRCASIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "63212",
                "s_nombre": "CORDOBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "63272",
                "s_nombre": "FILANDIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "63302",
                "s_nombre": "GENOVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "63401",
                "s_nombre": "LA TEBAIDA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "63470",
                "s_nombre": "MONTENEGRO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "63548",
                "s_nombre": "PIJAO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "63594",
                "s_nombre": "QUIMBAYA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "63690",
                "s_nombre": "SALENTO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "63000"
            },
            {
                "s_codigo": "66001",
                "s_nombre": "PEREIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66045",
                "s_nombre": "APIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66075",
                "s_nombre": "BALBOA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66088",
                "s_nombre": "BELEN DE UM",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66170",
                "s_nombre": "DOS QUEBRAD",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66318",
                "s_nombre": "GUATICA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66383",
                "s_nombre": "LA CELIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66400",
                "s_nombre": "LA VIRGINIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66440",
                "s_nombre": "MARSELLA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66456",
                "s_nombre": "MISTRATO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66572",
                "s_nombre": "PUEBLO RICO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66594",
                "s_nombre": "QUINCHIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66682",
                "s_nombre": "SANTA ROSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "66687",
                "s_nombre": "SANTUARIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "66000"
            },
            {
                "s_codigo": "68001",
                "s_nombre": "BUCARAMANGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68013",
                "s_nombre": "AGUADA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68020",
                "s_nombre": "ALBANIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68051",
                "s_nombre": "ARATOCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68077",
                "s_nombre": "BARBOSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68079",
                "s_nombre": "BARICHARA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68081",
                "s_nombre": "BARRANCABERMEJA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68092",
                "s_nombre": "BETULIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68101",
                "s_nombre": "BOLIVAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68121",
                "s_nombre": "CABRERA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68132",
                "s_nombre": "CALIFORNIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68147",
                "s_nombre": "CAPITANEJO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68152",
                "s_nombre": "CARCASI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68160",
                "s_nombre": "CEPITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68162",
                "s_nombre": "CERRITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68167",
                "s_nombre": "CHARALA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68169",
                "s_nombre": "CHARTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68176",
                "s_nombre": "CHIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68179",
                "s_nombre": "CHIPATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68190",
                "s_nombre": "CIMITARRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68207",
                "s_nombre": "CONCEPCION",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68209",
                "s_nombre": "CONFINES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68211",
                "s_nombre": "CONTRATACIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68217",
                "s_nombre": "COROMORO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68229",
                "s_nombre": "CURITI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68245",
                "s_nombre": "EL GUACAMAY",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68264",
                "s_nombre": "ENCINO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68266",
                "s_nombre": "ENCISO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68276",
                "s_nombre": "FLORIDABLAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68296",
                "s_nombre": "GALAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68298",
                "s_nombre": "GAMBITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68307",
                "s_nombre": "GIRON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68318",
                "s_nombre": "GUACA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68320",
                "s_nombre": "GUADALUPE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68322",
                "s_nombre": "GUAPOTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68324",
                "s_nombre": "GUAVATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68327",
                "s_nombre": "GUEPSA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68344",
                "s_nombre": "HATO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68368",
                "s_nombre": "JESUS MARIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68370",
                "s_nombre": "JORDAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68377",
                "s_nombre": "LA BELLEZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68385",
                "s_nombre": "LANDAZURI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68397",
                "s_nombre": "LA PAZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68406",
                "s_nombre": "LEBRIJA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68418",
                "s_nombre": "LOS SANTOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68425",
                "s_nombre": "MACARAVITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68432",
                "s_nombre": "MALAGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68444",
                "s_nombre": "MATANZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68464",
                "s_nombre": "MOGOTES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68468",
                "s_nombre": "MOLAGAVITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68498",
                "s_nombre": "OCAMONTE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68500",
                "s_nombre": "OIBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68502",
                "s_nombre": "ONZAGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68522",
                "s_nombre": "PALMAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68524",
                "s_nombre": "PALMAS SOCO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68533",
                "s_nombre": "PARAMO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68547",
                "s_nombre": "PIEDECUESTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68549",
                "s_nombre": "PINCHOTE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68572",
                "s_nombre": "PUENTE NACI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68573",
                "s_nombre": "PUERTO PARR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68575",
                "s_nombre": "PUERTO WILC",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68615",
                "s_nombre": "RIONEGRO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68655",
                "s_nombre": "SABANA DE T",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68669",
                "s_nombre": "SAN ANDRES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68671",
                "s_nombre": "FLORIAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68673",
                "s_nombre": "SAN BENITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68679",
                "s_nombre": "SAN GIL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68682",
                "s_nombre": "SAN JOAQUIN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68684",
                "s_nombre": "SAN JOSE DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68686",
                "s_nombre": "SAN MIGUEL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68689",
                "s_nombre": "SAN VICENTE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68705",
                "s_nombre": "SANTA BARBA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68720",
                "s_nombre": "SANTA HELEN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68745",
                "s_nombre": "SIMACOTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68755",
                "s_nombre": "SOCORRO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68770",
                "s_nombre": "SUAITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68773",
                "s_nombre": "SUCRE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68780",
                "s_nombre": "SURATA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68820",
                "s_nombre": "TONA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68844",
                "s_nombre": "UMPALA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68855",
                "s_nombre": "VALLE DE SA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68861",
                "s_nombre": "VELEZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68867",
                "s_nombre": "VETAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68872",
                "s_nombre": "VILLANUEVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "68895",
                "s_nombre": "ZAPATOCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "68000"
            },
            {
                "s_codigo": "70001",
                "s_nombre": "SINCELEJO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70110",
                "s_nombre": "BUENAVISTA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70124",
                "s_nombre": "CAIMITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70204",
                "s_nombre": "COLOSO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70215",
                "s_nombre": "COROZAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70230",
                "s_nombre": "CHALAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70235",
                "s_nombre": "GALERAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70400",
                "s_nombre": "LA UNION",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70418",
                "s_nombre": "LOS PALMITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70429",
                "s_nombre": "MAJAGUAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70473",
                "s_nombre": "MORROA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70508",
                "s_nombre": "OVEJAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70523",
                "s_nombre": "PALMITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70670",
                "s_nombre": "SAMPUES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70678",
                "s_nombre": "SAN BENITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70702",
                "s_nombre": "SAN JUAN DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70708",
                "s_nombre": "SAN MARCOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70713",
                "s_nombre": "SAN ONOFRE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70717",
                "s_nombre": "SAN PEDRO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70742",
                "s_nombre": "SINCE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70771",
                "s_nombre": "SUCRE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70820",
                "s_nombre": "TOLU",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "70823",
                "s_nombre": "TOLUVIEJO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "70000"
            },
            {
                "s_codigo": "73001",
                "s_nombre": "IBAGUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73024",
                "s_nombre": "ALPUJARRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73026",
                "s_nombre": "ALVARADO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73030",
                "s_nombre": "AMBALEMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73043",
                "s_nombre": "ANZOATEGUI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73055",
                "s_nombre": "ARMERO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73067",
                "s_nombre": "ATACO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73124",
                "s_nombre": "CAJAMARCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73148",
                "s_nombre": "CARMEN DE A",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73152",
                "s_nombre": "CASABIANCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73168",
                "s_nombre": "CHAPARRAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73200",
                "s_nombre": "COELLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73217",
                "s_nombre": "COYAIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73226",
                "s_nombre": "CUNDAY",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73236",
                "s_nombre": "DOLORES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73268",
                "s_nombre": "ESPINAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73270",
                "s_nombre": "FALAN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73275",
                "s_nombre": "FLANDES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73283",
                "s_nombre": "FRESNO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73319",
                "s_nombre": "GUAMO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73347",
                "s_nombre": "HERVEO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73349",
                "s_nombre": "HONDA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73352",
                "s_nombre": "ICONONZO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73408",
                "s_nombre": "LERIDA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73411",
                "s_nombre": "LIBANO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73443",
                "s_nombre": "MARIQUITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73449",
                "s_nombre": "MELGAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73483",
                "s_nombre": "NATAGAIMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73504",
                "s_nombre": "ORTEGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73547",
                "s_nombre": "PIEDRAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73555",
                "s_nombre": "PLANADAS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73563",
                "s_nombre": "PRADO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73585",
                "s_nombre": "PURIFICACIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73616",
                "s_nombre": "RIOBLANCO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73622",
                "s_nombre": "RONCESVALLE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73624",
                "s_nombre": "ROVIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73671",
                "s_nombre": "SALDANA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73675",
                "s_nombre": "SAN ANTONIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73678",
                "s_nombre": "SAN LUIS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73686",
                "s_nombre": "SANTA ISABE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73770",
                "s_nombre": "SUAREZ",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73854",
                "s_nombre": "VALLE DE SA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73861",
                "s_nombre": "VENADILLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73870",
                "s_nombre": "VILLAHERMOS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "73873",
                "s_nombre": "VILLARRICA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "73000"
            },
            {
                "s_codigo": "76001",
                "s_nombre": "CALI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76020",
                "s_nombre": "ALCALA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76036",
                "s_nombre": "ANDALUCIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76041",
                "s_nombre": "ANSERMANUEV",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76054",
                "s_nombre": "ARGELIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76100",
                "s_nombre": "BOLIVAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76109",
                "s_nombre": "BUENAVENTURA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76111",
                "s_nombre": "BUGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76113",
                "s_nombre": "BUGALAGRAND",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76122",
                "s_nombre": "CAICEDONIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76126",
                "s_nombre": "CALIMA (DAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76130",
                "s_nombre": "CANDELARIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76147",
                "s_nombre": "CARTAGO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76233",
                "s_nombre": "DAGUA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76243",
                "s_nombre": "EL AGUILA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76246",
                "s_nombre": "EL CAIRO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76248",
                "s_nombre": "EL CERRITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76250",
                "s_nombre": "EL DOVIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76275",
                "s_nombre": "FLORIDA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76306",
                "s_nombre": "GINEBRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76318",
                "s_nombre": "GUACARI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76364",
                "s_nombre": "JAMUNDI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76377",
                "s_nombre": "LA CUMBRE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76400",
                "s_nombre": "LA UNION",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76403",
                "s_nombre": "LA VICTORIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76497",
                "s_nombre": "OBANDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76520",
                "s_nombre": "PALMIRA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76563",
                "s_nombre": "PRADERA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76606",
                "s_nombre": "RESTREPO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76616",
                "s_nombre": "RIOFRIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76622",
                "s_nombre": "ROLDANILLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76670",
                "s_nombre": "SAN PEDRO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76736",
                "s_nombre": "SEVILLA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76823",
                "s_nombre": "TORO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76828",
                "s_nombre": "TRUJILLO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76834",
                "s_nombre": "TULUA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76845",
                "s_nombre": "ULLOA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76863",
                "s_nombre": "VERSALLES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76869",
                "s_nombre": "VIJES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76890",
                "s_nombre": "YOTOCO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76892",
                "s_nombre": "YUMBO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "76895",
                "s_nombre": "ZARZAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "76000"
            },
            {
                "s_codigo": "81001",
                "s_nombre": "ARAUCA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "81000"
            },
            {
                "s_codigo": "81065",
                "s_nombre": "ARAUQUITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "81000"
            },
            {
                "s_codigo": "81220",
                "s_nombre": "CRAVO NORTE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "81000"
            },
            {
                "s_codigo": "81591",
                "s_nombre": "PUERTO ROND",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "81000"
            },
            {
                "s_codigo": "81736",
                "s_nombre": "SARAVENA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "81000"
            },
            {
                "s_codigo": "81794",
                "s_nombre": "TAME",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "81000"
            },
            {
                "s_codigo": "85001",
                "s_nombre": "YOPAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85010",
                "s_nombre": "AGUAZUL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85015",
                "s_nombre": "CHAMEZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85125",
                "s_nombre": "HATO COROZA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85136",
                "s_nombre": "LA SALINA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85139",
                "s_nombre": "MANI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85162",
                "s_nombre": "MONTERREY",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85225",
                "s_nombre": "NUNCHIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85230",
                "s_nombre": "OROCUE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85250",
                "s_nombre": "PAZ DE ARIP",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85263",
                "s_nombre": "PORE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85279",
                "s_nombre": "RECETOR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85300",
                "s_nombre": "SABANALARGA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85315",
                "s_nombre": "SACAMA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85325",
                "s_nombre": "SAN LUIS DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85400",
                "s_nombre": "TAMARA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85410",
                "s_nombre": "TAURAMENA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85430",
                "s_nombre": "TRINIDAD",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "85440",
                "s_nombre": "VILLANUEVA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "85000"
            },
            {
                "s_codigo": "86001",
                "s_nombre": "MOCOA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "86000"
            },
            {
                "s_codigo": "86219",
                "s_nombre": "COLON",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "86000"
            },
            {
                "s_codigo": "86320",
                "s_nombre": "ORITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "86000"
            },
            {
                "s_codigo": "86568",
                "s_nombre": "PUERTO ASIS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "86000"
            },
            {
                "s_codigo": "86573",
                "s_nombre": "PUERTO LEGU",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "86000"
            },
            {
                "s_codigo": "86749",
                "s_nombre": "SIBUNDOY",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "86000"
            },
            {
                "s_codigo": "86755",
                "s_nombre": "SAN FRANCIS",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "86000"
            },
            {
                "s_codigo": "86760",
                "s_nombre": "SANTIAGO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "86000"
            },
            {
                "s_codigo": "86885",
                "s_nombre": "VILLA AMAZO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "86000"
            },
            {
                "s_codigo": "88001",
                "s_nombre": "SAN ANDRES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "88000"
            },
            {
                "s_codigo": "88564",
                "s_nombre": "PROVIDENCIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "88000"
            },
            {
                "s_codigo": "91001",
                "s_nombre": "LETICIA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "91000"
            },
            {
                "s_codigo": "91065",
                "s_nombre": "ARACUARA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "91000"
            },
            {
                "s_codigo": "91263",
                "s_nombre": "EL ENCANTO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "91000"
            },
            {
                "s_codigo": "91405",
                "s_nombre": "LA CHORRERA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "91000"
            },
            {
                "s_codigo": "91407",
                "s_nombre": "LA PEDRERA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "91000"
            },
            {
                "s_codigo": "91460",
                "s_nombre": "MIRITI-PARA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "91000"
            },
            {
                "s_codigo": "91540",
                "s_nombre": "PUERTO NARI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "91000"
            },
            {
                "s_codigo": "91669",
                "s_nombre": "PUERTO SANT",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "91000"
            },
            {
                "s_codigo": "91798",
                "s_nombre": "TARAPACA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "91000"
            },
            {
                "s_codigo": "94001",
                "s_nombre": "OBANDO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "94000"
            },
            {
                "s_codigo": "94343",
                "s_nombre": "GUAVIARE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "94000"
            },
            {
                "s_codigo": "95001",
                "s_nombre": "SAN JOSE DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "95000"
            },
            {
                "s_codigo": "95200",
                "s_nombre": "MIRAFLORES",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "95000"
            },
            {
                "s_codigo": "95220",
                "s_nombre": "MORICHAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "95000"
            },
            {
                "s_codigo": "97001",
                "s_nombre": "MITU",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "97000"
            },
            {
                "s_codigo": "97161",
                "s_nombre": "CARURU",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "97000"
            },
            {
                "s_codigo": "97210",
                "s_nombre": "EL RETORNO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "97000"
            },
            {
                "s_codigo": "97511",
                "s_nombre": "PACOA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "97000"
            },
            {
                "s_codigo": "97889",
                "s_nombre": "YAVARATE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "97000"
            },
            {
                "s_codigo": "99001",
                "s_nombre": "PUERTO CARR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99038",
                "s_nombre": "AMANAVEN",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99160",
                "s_nombre": "CASUARITO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99496",
                "s_nombre": "NUEVA ANTIO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99524",
                "s_nombre": "LA PRIMAVER",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99569",
                "s_nombre": "PUERTO MURI",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99572",
                "s_nombre": "SANTA RITA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99666",
                "s_nombre": "SANTA ROSAL",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99760",
                "s_nombre": "SAN JOSE DE",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99761",
                "s_nombre": "PUERTO BOLIVAR",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99762",
                "s_nombre": "HATONUEVO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99763",
                "s_nombre": "CALABAZO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99764",
                "s_nombre": "CALABAZO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99765",
                "s_nombre": "HATONUEVO",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            },
            {
                "s_codigo": "99766",
                "s_nombre": "FONSECA",
                "s_nivel": "3",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "99000"
            }
        ]
    }
}

export const departments = {
    "ListCiudad": {
        "Datos_Ciudad": [
            {
                "s_codigo": "5000",
                "s_nombre": "ANTIOQUIA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "8000",
                "s_nombre": "ATLANTICO",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "13000",
                "s_nombre": "BOLIVAR",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "15000",
                "s_nombre": "BOYACA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "17000",
                "s_nombre": "CALDAS",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "18000",
                "s_nombre": "CAQUETA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "19000",
                "s_nombre": "CAUCA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "20000",
                "s_nombre": "CESAR",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "23000",
                "s_nombre": "CORDOBA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "25000",
                "s_nombre": "CUNDINAMARCA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "27000",
                "s_nombre": "CHOCO",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "41000",
                "s_nombre": "HUILA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "44000",
                "s_nombre": "LA GUAJIRA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "47000",
                "s_nombre": "MAGDALENA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "50000",
                "s_nombre": "META",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "52000",
                "s_nombre": "NARIÑO",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "54000",
                "s_nombre": "NORTE DE SA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "63000",
                "s_nombre": "QUINDIO",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "66000",
                "s_nombre": "RISARALDA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "68000",
                "s_nombre": "SANTANDER",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "70000",
                "s_nombre": "SUCRE",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "73000",
                "s_nombre": "TOLIMA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "76000",
                "s_nombre": "VALLE",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "81000",
                "s_nombre": "ARAUCA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "85000",
                "s_nombre": "CASANARE",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "86000",
                "s_nombre": "PUTUMAYO",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "88000",
                "s_nombre": "SAN ANDRES",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "91000",
                "s_nombre": "AMAZONAS",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "94000",
                "s_nombre": "GUAINIA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "95000",
                "s_nombre": "GUAVIARE",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "97000",
                "s_nombre": "VAUPES",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            },
            {
                "s_codigo": "99000",
                "s_nombre": "VICHADA",
                "s_nivel": "2",
                "s_ind_nal": "57",
                "s_ind_internal": "0",
                "s_ubicacion": "57"
            }
        ]
    }
}


export const typeContract = {
    "WS_TIPO_CONTRATO_IResult": {
        "listTipoContr": {
            "Datos_TipoContrato": [
                {
                    "s_codigo": 1,
                    "s_descripcion": "INDEFINIDO"
                },
                {
                    "s_codigo": 2,
                    "s_descripcion": "TERMINO FIJO"
                },
                {
                    "s_codigo": 3,
                    "s_descripcion": "N/A"
                },
                {
                    "s_codigo": 4,
                    "s_descripcion": "OBRA O LABOR"
                },
                {
                    "s_codigo": 5,
                    "s_descripcion": "TEMPORAL"
                }
            ]
        }
    }
}

export const inventario = {
    "Ws_InventarioResult": {
        "R_s_codigo": "",
        "R_s_mensaje": "",
        "RespProd": {
            "RespuestaProd": {
                "R_s_cod_prod": 1,
                "R_s_nom_prod": "MOTO MT09",
                "R_s_precio_uni": "40000000,00",
                "R_s_precio_comer": "51000000,00",
                "R_s_unid_stock": "10,00",
                "R_s_link_img": "https://www.incolmotos-yamaha.com.co/vehiculo/mt09a-2/2317/",
                "RespAtr": {
                    "RespuestaAtr": [
                        {
                            "R_s_cod_atr": 1,
                            "R_s_nom_atr": "CILINDRAJE",
                            "R_s_valor_atr": 2000
                        },
                        {
                            "R_s_cod_atr": 2,
                            "R_s_nom_atr": "COLOR",
                            "R_s_valor_atr": "NEGRO"
                        },
                        {
                            "R_s_cod_atr": 3,
                            "R_s_nom_atr": "MODELO",
                            "R_s_valor_atr": 2023
                        }
                    ]
                }
            }
        }
    }
}

export const typeVivienda = {
          "WS_TIPOVIVIENDAResult": {
            "tip_viv": {
              "datos_tip_vivi": [
                {
                  "i_codigo": 1,
                  "c_descripcion": "CASA PROPIA"
                },
                {
                  "i_codigo": 2,
                  "c_descripcion": "FAMILIAR"
                },
                {
                  "i_codigo": 3,
                  "c_descripcion": "ARRIENDO"
                },
                {
                  "i_codigo": 4,
                  "c_descripcion": "MAYORDOMO"
                },
                {
                  "i_codigo": 5,
                  "c_descripcion": "COMODATO"
                }
              ]
            }
          }
  }

export const estractos = {
          "WS_ESTRACTOS_SOCResult": {
            "estratos_soc": {
              "Datos_estratos_soc": [
                {
                  "i_codigo": 1,
                  "c_descripcion": "Estrato 1"
                },
                {
                  "i_codigo": 2,
                  "c_descripcion": "Estrato 2"
                },
                {
                  "i_codigo": 3,
                  "c_descripcion": "Estrato 3"
                },
                {
                  "i_codigo": 4,
                  "c_descripcion": "Estrato 4"
                },
                {
                  "i_codigo": 5,
                  "c_descripcion": "Estrato 5"
                },
                {
                  "i_codigo": 6,
                  "c_descripcion": "Estrato 6"
                }
              ]
            }
          }
  }

  export const nivelEstudios = {
          "WS_TIPOS_NIV_ESTUDIO_IResult": {
            "listTipoNivEstudio": {
              "Datos_TiposNivelEstudio": [
                {
                  "s_codigo": 1,
                  "s_descripcion": "PRIMARIA"
                },
                {
                  "s_codigo": 2,
                  "s_descripcion": "UNIVERSITARIO"
                },
                {
                  "s_codigo": 3,
                  "s_descripcion": "TECNICO - TECNOLOGO"
                },
                {
                  "s_codigo": 4,
                  "s_descripcion": "BACHILLER"
                },
                {
                  "s_codigo": 5,
                  "s_descripcion": "NINGUNO"
                }
              ]
            }
          }
  }

  export const tipoActividad = {
          "WS_TIPOS_ACT_ECONOMICA_IResult": {
            "listActEcon": {
              "Datos_ActividadEcon": [
                {
                  "s_codigo": 111,
                  "s_descripcion": "Cultivo de cereales (excepto arroz), legumbres y semillas oleaginosas"
                },
                {
                  "s_codigo": 112,
                  "s_descripcion": "Cultivo de arroz"
                },
                {
                  "s_codigo": 113,
                  "s_descripcion": "Cultivo de hortalizas, raíces y tubérculos"
                },
                {
                  "s_codigo": 114,
                  "s_descripcion": "Cultivo de tabaco"
                },
                {
                  "s_codigo": 115,
                  "s_descripcion": "Cultivo de plantas textiles"
                },
                {
                  "s_codigo": 119,
                  "s_descripcion": "Otros cultivos transitorios n.c.p."
                },
                {
                  "s_codigo": 121,
                  "s_descripcion": "Cultivo de frutas tropicales y subtropicales"
                },
                {
                  "s_codigo": 122,
                  "s_descripcion": "Cultivo de plátano y banano"
                },
                {
                  "s_codigo": 123,
                  "s_descripcion": "Cultivo de café"
                },
                {
                  "s_codigo": 124,
                  "s_descripcion": "Cultivo de caña de azúcar"
                },
                {
                  "s_codigo": 125,
                  "s_descripcion": "Cultivo de flor de corte"
                },
                {
                  "s_codigo": 126,
                  "s_descripcion": "Cultivo de palma para aceite (palma africana) y otros frutos oleaginosos"
                },
                {
                  "s_codigo": 127,
                  "s_descripcion": "Cultivo de plantas con las que se prepararan bebidas"
                },
                {
                  "s_codigo": 128,
                  "s_descripcion": "Cultivo de especias y de plantas aromáticas y medicinales"
                },
                {
                  "s_codigo": 129,
                  "s_descripcion": "Otros cultivos permanentes n.c.p."
                },
                {
                  "s_codigo": 130,
                  "s_descripcion": "Propagación de plantas (actividades de los viveros, excepto viveros forestales)"
                },
                {
                  "s_codigo": 141,
                  "s_descripcion": "Cría de ganado bovino y bufalino"
                },
                {
                  "s_codigo": 142,
                  "s_descripcion": "Cría de caballos y otros equinos"
                },
                {
                  "s_codigo": 143,
                  "s_descripcion": "Cría de ovejas y cabras"
                },
                {
                  "s_codigo": 144,
                  "s_descripcion": "Cría de ganado porcino"
                },
                {
                  "s_codigo": 145,
                  "s_descripcion": "Cría de aves de corral"
                },
                {
                  "s_codigo": 149,
                  "s_descripcion": "Cría de otros animales n.c.p."
                },
                {
                  "s_codigo": 150,
                  "s_descripcion": "Explotación primaria mixta (agrícola y pecuaria)"
                },
                {
                  "s_codigo": 161,
                  "s_descripcion": "Actividades de apoyo a la agricultura"
                },
                {
                  "s_codigo": 162,
                  "s_descripcion": "Actividades de apoyo a la ganadería"
                },
                {
                  "s_codigo": 163,
                  "s_descripcion": "Actividades posteriores a la cosecha"
                },
                {
                  "s_codigo": 164,
                  "s_descripcion": "Tratamiento de semillas para propagación"
                },
                {
                  "s_codigo": 170,
                  "s_descripcion": "Caza ordinaria y mediante trampas y actividades de servicios conexas"
                },
                {
                  "s_codigo": 210,
                  "s_descripcion": "Silvicultura y otras actividades forestales"
                },
                {
                  "s_codigo": 220,
                  "s_descripcion": "Extracción de madera"
                },
                {
                  "s_codigo": 230,
                  "s_descripcion": "Recolección de productos forestales diferentes a la madera"
                },
                {
                  "s_codigo": 240,
                  "s_descripcion": "Servicios de apoyo a la silvicultura"
                },
                {
                  "s_codigo": 311,
                  "s_descripcion": "Pesca marítima"
                },
                {
                  "s_codigo": 312,
                  "s_descripcion": "Pesca de agua dulce"
                },
                {
                  "s_codigo": 321,
                  "s_descripcion": "Acuicultura marítima"
                },
                {
                  "s_codigo": 322,
                  "s_descripcion": "Acuicultura de agua dulce"
                },
                {
                  "s_codigo": 510,
                  "s_descripcion": "Extracción de hulla (carbón de piedra)"
                },
                {
                  "s_codigo": 520,
                  "s_descripcion": "Extracción de carbón lignito"
                },
                {
                  "s_codigo": 610,
                  "s_descripcion": "Extracción de petróleo crudo"
                },
                {
                  "s_codigo": 620,
                  "s_descripcion": "Extracción de gas natural"
                },
                {
                  "s_codigo": 710,
                  "s_descripcion": "Extracción de minerales de hierro"
                },
                {
                  "s_codigo": 721,
                  "s_descripcion": "Extracción de minerales de uranio y de torio"
                },
                {
                  "s_codigo": 722,
                  "s_descripcion": "Extracción de oro y otros metales preciosos"
                },
                {
                  "s_codigo": 723,
                  "s_descripcion": "Extracción de minerales de níquel"
                },
                {
                  "s_codigo": 729,
                  "s_descripcion": "Extracción de otros minerales metalíferos no ferrosos n.c.p."
                },
                {
                  "s_codigo": 811,
                  "s_descripcion": "Extracción de piedra, arena, arcillas comunes, yeso y anhidrita"
                },
                {
                  "s_codigo": 812,
                  "s_descripcion": "Extracción de arcillas de uso industrial, caliza, caolín y bentonitas"
                },
                {
                  "s_codigo": 820,
                  "s_descripcion": "Extracción de esmeraldas, piedras preciosas y semipreciosas"
                },
                {
                  "s_codigo": 891,
                  "s_descripcion": "Extracción de minerales para la fabricación de abonos y productos químicos"
                },
                {
                  "s_codigo": 892,
                  "s_descripcion": "Extracción de halita (sal)"
                },
                {
                  "s_codigo": 899,
                  "s_descripcion": "Extracción de otros minerales no metálicos n.c.p."
                },
                {
                  "s_codigo": 910,
                  "s_descripcion": "Actividades de apoyo para la extracción de petróleo y de gas natural"
                },
                {
                  "s_codigo": 990,
                  "s_descripcion": "Actividades de apoyo para otras actividades de explotación de minas y canteras"
                },
                {
                  "s_codigo": 1011,
                  "s_descripcion": "Procesamiento y conservación de carne y productos cárnicos"
                },
                {
                  "s_codigo": 1012,
                  "s_descripcion": "Procesamiento y conservación de pescados, crustáceos y moluscos"
                },
                {
                  "s_codigo": 1030,
                  "s_descripcion": "Elaboración de aceites y grasas de origen vegetal y animal"
                },
                {
                  "s_codigo": 1051,
                  "s_descripcion": "Elaboración de productos de molinería"
                },
                {
                  "s_codigo": 1052,
                  "s_descripcion": "Elaboración de almidones y productos derivados del almidón"
                },
                {
                  "s_codigo": 1061,
                  "s_descripcion": "Trilla de café"
                },
                {
                  "s_codigo": 1062,
                  "s_descripcion": "Descafeinado, tostión y molienda del café"
                },
                {
                  "s_codigo": 1063,
                  "s_descripcion": "Elaboración de otros derivados del café"
                },
                {
                  "s_codigo": 1071,
                  "s_descripcion": "Elaboración y refinación de azúcar"
                },
                {
                  "s_codigo": 1072,
                  "s_descripcion": "Elaboración de panela"
                },
                {
                  "s_codigo": 1081,
                  "s_descripcion": "Elaboración de productos de panadería"
                },
                {
                  "s_codigo": 1082,
                  "s_descripcion": "Elaboración de cacao, chocolate y productos de confitería"
                },
                {
                  "s_codigo": 1083,
                  "s_descripcion": "Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares"
                },
                {
                  "s_codigo": 1084,
                  "s_descripcion": "Elaboración de comidas y platos preparados"
                },
                {
                  "s_codigo": 1089,
                  "s_descripcion": "Elaboración de otros productos alimenticios n.c.p."
                },
                {
                  "s_codigo": 1090,
                  "s_descripcion": "Elaboración de alimentos preparados para animales"
                },
                {
                  "s_codigo": 1101,
                  "s_descripcion": "Destilación, rectificación y mezcla de bebidas alcohólicas"
                },
                {
                  "s_codigo": 1102,
                  "s_descripcion": "Elaboración de bebidas fermentadas no destiladas"
                },
                {
                  "s_codigo": 1103,
                  "s_descripcion": "Producción de malta, elaboración de cervezas y otras bebidas malteadas"
                },
                {
                  "s_codigo": 1104,
                  "s_descripcion": "Elaboración de bebidas no alcohólicas, producción de aguas minerales y de otras aguas embotelladas"
                },
                {
                  "s_codigo": 1200,
                  "s_descripcion": "Elaboración de productos de tabaco"
                },
                {
                  "s_codigo": 1311,
                  "s_descripcion": "Preparación e hilatura de fibras textiles"
                },
                {
                  "s_codigo": 1312,
                  "s_descripcion": "Tejeduría de productos textiles"
                },
                {
                  "s_codigo": 1313,
                  "s_descripcion": "Acabado de productos textiles"
                },
                {
                  "s_codigo": 1391,
                  "s_descripcion": "Fabricación de tejidos de punto y ganchillo"
                },
                {
                  "s_codigo": 1392,
                  "s_descripcion": "Confección de artículos con materiales textiles, excepto prendas de vestir"
                },
                {
                  "s_codigo": 1393,
                  "s_descripcion": "Fabricación de tapetes y alfombras para pisos"
                },
                {
                  "s_codigo": 1394,
                  "s_descripcion": "Fabricación de cuerdas, cordeles, cables, bramantes y redes"
                },
                {
                  "s_codigo": 1399,
                  "s_descripcion": "Fabricación de otros artículos textiles n.c.p."
                },
                {
                  "s_codigo": 1410,
                  "s_descripcion": "Confección de prendas de vestir, excepto prendas de piel"
                },
                {
                  "s_codigo": 1511,
                  "s_descripcion": "Curtido y recurtido de cueros; recurtido y teñido de pieles."
                },
                {
                  "s_codigo": 1512,
                  "s_descripcion": "Fabricación de artículos de viaje, bolsos de mano y artículos similares elaborados en cuero, y fabricación de artículos de talabartería y guarnicionería."
                },
                {
                  "s_codigo": 1513,
                  "s_descripcion": "Fabricación de artículos de viaje, bolsos de mano y artículos similares; artículos de talabartería y guarnicionería elaborados en otros materiales"
                },
                {
                  "s_codigo": 1521,
                  "s_descripcion": "Fabricación de calzado de cuero y piel, con cualquier tipo de suela"
                },
                {
                  "s_codigo": 1522,
                  "s_descripcion": "Fabricación de otros tipos de calzado, excepto calzado de cuero y piel"
                },
                {
                  "s_codigo": 1523,
                  "s_descripcion": "Fabricación de partes del calzado"
                },
                {
                  "s_codigo": 1610,
                  "s_descripcion": "Aserrado, acepillado e impregnación de la madera"
                },
                {
                  "s_codigo": 1620,
                  "s_descripcion": "Fabricación de hojas de madera para enchapado; fabricación de tableros contrachapados, tableros laminados, tableros de partículas y otros tableros y paneles"
                },
                {
                  "s_codigo": 1630,
                  "s_descripcion": "Fabricación de partes y piezas de madera, de carpintería y ebanistería para la construcción y para edificios"
                },
                {
                  "s_codigo": 1640,
                  "s_descripcion": "Fabricación de recipientes de madera"
                },
                {
                  "s_codigo": 1690,
                  "s_descripcion": "Fabricación de otros productos de madera; fabricación de artículos de corcho, cestería y espartería"
                },
                {
                  "s_codigo": 1701,
                  "s_descripcion": "Fabricación de pulpas (pastas) celulósicas; papel y cartón"
                },
                {
                  "s_codigo": 1702,
                  "s_descripcion": "Fabricación de papel y cartón ondulado (corrugado); fabricación de envases, empaques y de embalajes de papel y cartón."
                },
                {
                  "s_codigo": 1709,
                  "s_descripcion": "Fabricación de otros artículos de papel y cartón"
                },
                {
                  "s_codigo": 1811,
                  "s_descripcion": "Actividades de impresión"
                },
                {
                  "s_codigo": 1812,
                  "s_descripcion": "Actividades de servicios relacionados con la impresión"
                },
                {
                  "s_codigo": 1820,
                  "s_descripcion": "Producción de copias a partir de grabaciones originales"
                },
                {
                  "s_codigo": 1910,
                  "s_descripcion": "Fabricación de productos de hornos de coque"
                },
                {
                  "s_codigo": 1921,
                  "s_descripcion": "Fabricación de productos de la refinación del petróleo"
                },
                {
                  "s_codigo": 1922,
                  "s_descripcion": "Actividad de mezcla de combustibles"
                },
                {
                  "s_codigo": 2011,
                  "s_descripcion": "Fabricación de sustancias y productos químicos básicos"
                },
                {
                  "s_codigo": 2012,
                  "s_descripcion": "Fabricación de abonos y compuestos inorgánicos nitrogenados"
                },
                {
                  "s_codigo": 2013,
                  "s_descripcion": "Fabricación de plásticos en formas primarias"
                },
                {
                  "s_codigo": 2014,
                  "s_descripcion": "Fabricación de caucho sintético en formas primarias"
                },
                {
                  "s_codigo": 2021,
                  "s_descripcion": "Fabricación de plaguicidas y otros productos químicos de uso agropecuario"
                },
                {
                  "s_codigo": 2022,
                  "s_descripcion": "Fabricación de pinturas, barnices y revestimientos similares, tintas para impresión y masillas"
                },
                {
                  "s_codigo": 2023,
                  "s_descripcion": "Fabricación de jabones y detergentes, preparados para limpiar y pulir; perfumes y preparados de tocador"
                },
                {
                  "s_codigo": 2029,
                  "s_descripcion": "Fabricación de otros productos químicos n.c.p."
                },
                {
                  "s_codigo": 2030,
                  "s_descripcion": "Fabricación de fibras sintéticas y artificiales"
                },
                {
                  "s_codigo": 2100,
                  "s_descripcion": "Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos de uso farmacéutico"
                },
                {
                  "s_codigo": 2211,
                  "s_descripcion": "Fabricación de llantas y neumáticos de caucho"
                },
                {
                  "s_codigo": 2212,
                  "s_descripcion": "Reencauche de llantas usadas"
                },
                {
                  "s_codigo": 2219,
                  "s_descripcion": "Fabricación de formas básicas de caucho y otros productos de caucho n.c.p."
                },
                {
                  "s_codigo": 2221,
                  "s_descripcion": "Fabricación de formas básicas de plástico"
                },
                {
                  "s_codigo": 2229,
                  "s_descripcion": "Fabricación de artículos de plástico n.c.p."
                },
                {
                  "s_codigo": 2310,
                  "s_descripcion": "Fabricación de vidrio y productos de vidrio"
                },
                {
                  "s_codigo": 2391,
                  "s_descripcion": "Fabricación de productos refractarios"
                },
                {
                  "s_codigo": 2392,
                  "s_descripcion": "Fabricación de materiales de arcilla para la construcción"
                },
                {
                  "s_codigo": 2393,
                  "s_descripcion": "Fabricación de otros productos de cerámica y porcelana"
                },
                {
                  "s_codigo": 2394,
                  "s_descripcion": "Fabricación de cemento, cal y yeso"
                },
                {
                  "s_codigo": 2395,
                  "s_descripcion": "Fabricación de artículos de hormigón, cemento y yeso"
                },
                {
                  "s_codigo": 2396,
                  "s_descripcion": "Corte, tallado y acabado de la piedra"
                },
                {
                  "s_codigo": 2399,
                  "s_descripcion": "Fabricación de otros productos minerales no metálicos n.c.p."
                },
                {
                  "s_codigo": 2410,
                  "s_descripcion": "Industrias básicas de hierro y de acero"
                },
                {
                  "s_codigo": 2421,
                  "s_descripcion": "Industrias básicas de metales preciosos"
                },
                {
                  "s_codigo": 2429,
                  "s_descripcion": "Industrias básicas de otros metales no ferrosos"
                },
                {
                  "s_codigo": 2431,
                  "s_descripcion": "Fundición de hierro y de acero"
                },
                {
                  "s_codigo": 2432,
                  "s_descripcion": "Fundición de metales no ferrosos"
                },
                {
                  "s_codigo": 2511,
                  "s_descripcion": "Fabricación de productos metálicos para uso estructural"
                },
                {
                  "s_codigo": 2512,
                  "s_descripcion": "Fabricación de tanques, depósitos y recipientes de metal, excepto los utilizados para el envase o transporte de mercancías"
                },
                {
                  "s_codigo": 2513,
                  "s_descripcion": "Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central"
                },
                {
                  "s_codigo": 2520,
                  "s_descripcion": "Fabricación de armas y municiones"
                },
                {
                  "s_codigo": 2591,
                  "s_descripcion": "Forja, prensado, estampado y laminado de metal; pulvimetalurgia"
                },
                {
                  "s_codigo": 2592,
                  "s_descripcion": "Tratamiento y revestimiento de metales; mecanizado"
                },
                {
                  "s_codigo": 2593,
                  "s_descripcion": "Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería"
                },
                {
                  "s_codigo": 2599,
                  "s_descripcion": "Fabricación de otros productos elaborados de metal n.c.p."
                },
                {
                  "s_codigo": 2610,
                  "s_descripcion": "Fabricación de componentes y tableros electrónicos"
                },
                {
                  "s_codigo": 2620,
                  "s_descripcion": "Fabricación de computadoras y de equipo periférico"
                },
                {
                  "s_codigo": 2630,
                  "s_descripcion": "Fabricación de equipos de comunicación"
                },
                {
                  "s_codigo": 2640,
                  "s_descripcion": "Fabricación de aparatos electrónicos de consumo"
                },
                {
                  "s_codigo": 2651,
                  "s_descripcion": "Fabricación de equipo de medición, prueba, navegación y control"
                },
                {
                  "s_codigo": 2652,
                  "s_descripcion": "Fabricación de relojes"
                },
                {
                  "s_codigo": 2660,
                  "s_descripcion": "Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico"
                },
                {
                  "s_codigo": 2670,
                  "s_descripcion": "Fabricación de instrumentos ópticos y equipo fotográfico"
                },
                {
                  "s_codigo": 2680,
                  "s_descripcion": "Fabricación de soportes magnéticos y ópticos"
                },
                {
                  "s_codigo": 2711,
                  "s_descripcion": "Fabricación de motores, generadores y transformadores eléctricos."
                },
                {
                  "s_codigo": 2712,
                  "s_descripcion": "Fabricación de aparatos de distribución y control de la energía eléctrica"
                },
                {
                  "s_codigo": 2720,
                  "s_descripcion": "Fabricación de pilas, baterías y acumuladores eléctricos"
                },
                {
                  "s_codigo": 2731,
                  "s_descripcion": "Fabricación de hilos y cables eléctricos y de fibra óptica"
                },
                {
                  "s_codigo": 2732,
                  "s_descripcion": "Fabricación de dispositivos de cableado"
                },
                {
                  "s_codigo": 2740,
                  "s_descripcion": "Fabricación de equipos eléctricos de iluminación"
                },
                {
                  "s_codigo": 2750,
                  "s_descripcion": "Fabricación de aparatos de uso domestico"
                },
                {
                  "s_codigo": 2790,
                  "s_descripcion": "Fabricación de otros tipos de equipo eléctrico n.c.p."
                },
                {
                  "s_codigo": 2811,
                  "s_descripcion": "Fabricación de motores, turbinas, y partes para motores de combustión interna"
                },
                {
                  "s_codigo": 2812,
                  "s_descripcion": "Fabricación de equipos de potencia hidráulica y neumática"
                },
                {
                  "s_codigo": 2813,
                  "s_descripcion": "Fabricación de otras bombas, compresores, grifos y válvulas"
                },
                {
                  "s_codigo": 2814,
                  "s_descripcion": "Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión"
                },
                {
                  "s_codigo": 2815,
                  "s_descripcion": "Fabricación de hornos, hogares y quemadores industriales"
                },
                {
                  "s_codigo": 2816,
                  "s_descripcion": "Fabricación de equipo de elevación y manipulación"
                },
                {
                  "s_codigo": 2817,
                  "s_descripcion": "Fabricación de maquinaria y equipo de oficina (excepto computadoras y equipo periférico)"
                },
                {
                  "s_codigo": 2818,
                  "s_descripcion": "Fabricación de herramientas manuales con motor"
                },
                {
                  "s_codigo": 2819,
                  "s_descripcion": "Fabricación de otros tipos de maquinaria y equipo de uso general n.c.p."
                },
                {
                  "s_codigo": 2821,
                  "s_descripcion": "Fabricación de maquinaria agropecuaria y forestal"
                },
                {
                  "s_codigo": 2822,
                  "s_descripcion": "Fabricación de máquinas formadoras de metal y de máquinas herramienta"
                },
                {
                  "s_codigo": 2823,
                  "s_descripcion": "Fabricación de maquinaria para la metalurgia"
                },
                {
                  "s_codigo": 2824,
                  "s_descripcion": "Fabricación de maquinaria para explotación de minas y canteras y para obras de construcción"
                },
                {
                  "s_codigo": 2825,
                  "s_descripcion": "Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco"
                },
                {
                  "s_codigo": 2826,
                  "s_descripcion": "Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros"
                },
                {
                  "s_codigo": 2829,
                  "s_descripcion": "Fabricación de otros tipos de maquinaria y equipo de uso especial n.c.p."
                },
                {
                  "s_codigo": 2910,
                  "s_descripcion": "Fabricación de vehículos automotores y sus motores"
                },
                {
                  "s_codigo": 2920,
                  "s_descripcion": "Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques"
                },
                {
                  "s_codigo": 2930,
                  "s_descripcion": "Fabricación de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores"
                },
                {
                  "s_codigo": 3011,
                  "s_descripcion": "Construcción de barcos y de estructuras flotantes"
                },
                {
                  "s_codigo": 3012,
                  "s_descripcion": "Construcción de embarcaciones de recreo y deporte"
                },
                {
                  "s_codigo": 3020,
                  "s_descripcion": "Fabricación de locomotoras y de material rodante para ferrocarriles"
                },
                {
                  "s_codigo": 3030,
                  "s_descripcion": "Fabricación de aeronaves, naves espaciales y de maquinaria conexa"
                },
                {
                  "s_codigo": 3040,
                  "s_descripcion": "Fabricación de vehículos militares de combate"
                },
                {
                  "s_codigo": 3091,
                  "s_descripcion": "Fabricación de motocicletas"
                },
                {
                  "s_codigo": 3092,
                  "s_descripcion": "Fabricación de bicicletas y de sillas de ruedas para personas con discapacidad"
                },
                {
                  "s_codigo": 3099,
                  "s_descripcion": "Fabricación de otros tipos de equipo de transporte n.c.p."
                },
                {
                  "s_codigo": 3110,
                  "s_descripcion": "Fabricación de muebles"
                },
                {
                  "s_codigo": 3120,
                  "s_descripcion": "Fabricación de colchones y somieres"
                },
                {
                  "s_codigo": 3210,
                  "s_descripcion": "Fabricación de joyas, bisutería y artículos conexos"
                },
                {
                  "s_codigo": 3220,
                  "s_descripcion": "Fabricación de instrumentos musicales"
                },
                {
                  "s_codigo": 3230,
                  "s_descripcion": "Fabricación de artículos y equipo para la práctica del deporte   (excepto prendas de vestir y calzado)"
                },
                {
                  "s_codigo": 3240,
                  "s_descripcion": "Fabricación de juegos, juguetes y rompecabezas"
                },
                {
                  "s_codigo": 3250,
                  "s_descripcion": "Fabricación de instrumentos, aparatos y materiales médicos y odontológicos (incluido mobiliario)"
                },
                {
                  "s_codigo": 3290,
                  "s_descripcion": "Otras industrias manufactureras n.c.p."
                },
                {
                  "s_codigo": 3311,
                  "s_descripcion": "Mantenimiento y reparación especializado de productos elaborados en metal"
                },
                {
                  "s_codigo": 3312,
                  "s_descripcion": "Mantenimiento y reparación especializado de maquinaria y equipo"
                },
                {
                  "s_codigo": 3313,
                  "s_descripcion": "Mantenimiento y reparación especializado de equipo electrónico y óptico"
                },
                {
                  "s_codigo": 3314,
                  "s_descripcion": "Mantenimiento y reparación especializado de equipo eléctrico"
                },
                {
                  "s_codigo": 3315,
                  "s_descripcion": "Mantenimiento y reparación especializado de equipo de transporte, excepto los vehículos automotores, motocicletas y bicicletas"
                },
                {
                  "s_codigo": 3319,
                  "s_descripcion": "Mantenimiento y reparación de otros tipos de equipos y sus componentes n.c.p."
                },
                {
                  "s_codigo": 3320,
                  "s_descripcion": "Instalación especializada de maquinaria y equipo industrial"
                },
                {
                  "s_codigo": 3511,
                  "s_descripcion": "Generación de energía eléctrica"
                },
                {
                  "s_codigo": 3512,
                  "s_descripcion": "Transmisión de energía eléctrica"
                },
                {
                  "s_codigo": 3513,
                  "s_descripcion": "Distribución de energía eléctrica"
                },
                {
                  "s_codigo": 3514,
                  "s_descripcion": "Comercialización de energía eléctrica"
                },
                {
                  "s_codigo": 3530,
                  "s_descripcion": "Suministro de vapor y aire acondicionado"
                },
                {
                  "s_codigo": 3700,
                  "s_descripcion": "Evacuación y tratamiento de aguas residuales"
                },
                {
                  "s_codigo": 3811,
                  "s_descripcion": "Recolección de desechos no peligrosos"
                },
                {
                  "s_codigo": 3812,
                  "s_descripcion": "Recolección de desechos peligrosos"
                },
                {
                  "s_codigo": 3821,
                  "s_descripcion": "Tratamiento y disposición de desechos no peligrosos"
                },
                {
                  "s_codigo": 3822,
                  "s_descripcion": "Tratamiento y disposición de desechos peligrosos"
                },
                {
                  "s_codigo": 3830,
                  "s_descripcion": "Recuperación de materiales"
                },
                {
                  "s_codigo": 4111,
                  "s_descripcion": "Construcción de edificios residenciales"
                },
                {
                  "s_codigo": 4112,
                  "s_descripcion": "Construcción de edificios no residenciales"
                },
                {
                  "s_codigo": 4210,
                  "s_descripcion": "Construcción de carreteras y vías de ferrocarril"
                },
                {
                  "s_codigo": 4220,
                  "s_descripcion": "Construcción de proyectos de servicio público"
                },
                {
                  "s_codigo": 4290,
                  "s_descripcion": "Construcción de otras obras de ingeniería civil"
                },
                {
                  "s_codigo": 4311,
                  "s_descripcion": "Demolición"
                },
                {
                  "s_codigo": 4312,
                  "s_descripcion": "Preparación del terreno"
                },
                {
                  "s_codigo": 4321,
                  "s_descripcion": "Instalaciones eléctricas de la construcción"
                },
                {
                  "s_codigo": 4322,
                  "s_descripcion": "Instalaciones de fontanería, calefacción y aire acondicionado de la construcción"
                },
                {
                  "s_codigo": 4329,
                  "s_descripcion": "Otras instalaciones especializadas de la construcción"
                },
                {
                  "s_codigo": 4330,
                  "s_descripcion": "Terminación y acabado de edificios y obras de ingeniería civil"
                },
                {
                  "s_codigo": 4390,
                  "s_descripcion": "Otras actividades especializadas para la construcción de edificios y obras de ingeniería civil"
                },
                {
                  "s_codigo": 4511,
                  "s_descripcion": "Comercio de vehículos automotores nuevos"
                },
                {
                  "s_codigo": 4512,
                  "s_descripcion": "Comercio de vehículos automotores usados"
                },
                {
                  "s_codigo": 4520,
                  "s_descripcion": "Mantenimiento y reparación de vehículos automotores."
                },
                {
                  "s_codigo": 4530,
                  "s_descripcion": "Comercio de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores"
                },
                {
                  "s_codigo": 4542,
                  "s_descripcion": "Mantenimiento y reparación de motocicletas y de sus partes y piezas"
                },
                {
                  "s_codigo": 4610,
                  "s_descripcion": "Comercio al por mayor a cambio de una retribución o por contrata"
                },
                {
                  "s_codigo": 4631,
                  "s_descripcion": "Comercio al por mayor de productos alimenticios"
                },
                {
                  "s_codigo": 4641,
                  "s_descripcion": "Comercio al por mayor de productos textiles y productos confeccionados para uso doméstico"
                },
                {
                  "s_codigo": 4642,
                  "s_descripcion": "Comercio al por mayor de prendas de vestir"
                },
                {
                  "s_codigo": 4643,
                  "s_descripcion": "Comercio al por mayor de calzado"
                },
                {
                  "s_codigo": 4644,
                  "s_descripcion": "Comercio al por mayor de aparatos y equipo de uso doméstico"
                },
                {
                  "s_codigo": 4651,
                  "s_descripcion": "Comercio al por mayor de computadores, equipo periférico y programas de informática"
                },
                {
                  "s_codigo": 4652,
                  "s_descripcion": "Comercio al por mayor de equipo, partes y piezas electrónicos y de telecomunicaciones"
                },
                {
                  "s_codigo": 4653,
                  "s_descripcion": "Comercio al por mayor de maquinaria y equipo agropecuarios"
                },
                {
                  "s_codigo": 4659,
                  "s_descripcion": "Comercio al por mayor de otros tipos de maquinaria y equipo n.c.p."
                },
                {
                  "s_codigo": 4662,
                  "s_descripcion": "Comercio al por mayor de metales y productos metalíferos"
                },
                {
                  "s_codigo": 4664,
                  "s_descripcion": "Comercio al por mayor de productos químicos básicos, cauchos y plásticos en formas primarias y productos químicos de uso agropecuario"
                },
                {
                  "s_codigo": 4665,
                  "s_descripcion": "Comercio al por mayor de desperdicios, desechos y chatarra"
                },
                {
                  "s_codigo": 4669,
                  "s_descripcion": "Comercio al por mayor de otros productos n.c.p."
                },
                {
                  "s_codigo": 4690,
                  "s_descripcion": "Comercio al por mayor no especializado"
                },
                {
                  "s_codigo": 4721,
                  "s_descripcion": "Comercio al por menor de productos agrícolas para el consumo en establecimientos especializados"
                },
                {
                  "s_codigo": 4722,
                  "s_descripcion": "Comercio al por menor de leche, productos lácteos y huevos, en establecimientos especializados"
                },
                {
                  "s_codigo": 4723,
                  "s_descripcion": "Comercio al por menor de carnes (incluye aves de corral), productos cárnicos, pescados y productos de mar, en establecimientos especializados"
                },
                {
                  "s_codigo": 4729,
                  "s_descripcion": "Comercio al por menor de otros productos alimenticios n.c.p., en establecimientos especializados"
                },
                {
                  "s_codigo": 4731,
                  "s_descripcion": "Comercio al por menor de combustible para automotores"
                },
                {
                  "s_codigo": 4732,
                  "s_descripcion": "Comercio al por menor de lubricantes (aceites, grasas), aditivos y productos de limpieza para vehículos automotores"
                },
                {
                  "s_codigo": 4741,
                  "s_descripcion": "Comercio al por menor de computadores, equipos periféricos, programas de informática y equipos de telecomunicaciones en establecimientos especializados"
                },
                {
                  "s_codigo": 4742,
                  "s_descripcion": "Comercio al por menor de equipos y aparatos de sonido y de video, en establecimientos especializados"
                },
                {
                  "s_codigo": 4751,
                  "s_descripcion": "Comercio al por menor de productos textiles en establecimientos especializados"
                },
                {
                  "s_codigo": 4753,
                  "s_descripcion": "Comercio al por menor de tapices, alfombras y cubrimientos para paredes y pisos en establecimientos especializados."
                },
                {
                  "s_codigo": 4754,
                  "s_descripcion": "Comercio al por menor de electrodomésticos y gasodomesticos de uso doméstico, muebles y equipos de iluminación"
                },
                {
                  "s_codigo": 4755,
                  "s_descripcion": "Comercio al por menor de artículos y utensilios de uso domestico"
                },
                {
                  "s_codigo": 4759,
                  "s_descripcion": "Comercio al por menor de otros artículos domésticos en establecimientos especializados"
                },
                {
                  "s_codigo": 4762,
                  "s_descripcion": "Comercio al por menor de artículos deportivos, en establecimientos especializados"
                },
                {
                  "s_codigo": 4769,
                  "s_descripcion": "Comercio al por menor de otros artículos culturales y de entretenimiento n.c.p. en establecimientos especializados"
                },
                {
                  "s_codigo": 4771,
                  "s_descripcion": "Comercio al por menor de prendas de vestir y sus accesorios (incluye artículos de piel) en establecimientos especializados"
                },
                {
                  "s_codigo": 4772,
                  "s_descripcion": "Comercio al por menor de todo tipo de calzado y artículos de cuero y sucedáneos del cuero en establecimientos especializados."
                },
                {
                  "s_codigo": 4774,
                  "s_descripcion": "Comercio al por menor de otros productos nuevos en establecimientos especializados"
                },
                {
                  "s_codigo": 4775,
                  "s_descripcion": "Comercio al por menor de artículos de segunda mano"
                },
                {
                  "s_codigo": 4782,
                  "s_descripcion": "Comercio al por menor de productos textiles, prendas de vestir y calzado, en puestos de venta móviles"
                },
                {
                  "s_codigo": 4789,
                  "s_descripcion": "Comercio al por menor de otros productos en puestos de venta móviles"
                },
                {
                  "s_codigo": 4911,
                  "s_descripcion": "Transporte férreo de pasajeros"
                },
                {
                  "s_codigo": 4912,
                  "s_descripcion": "Transporte férreo de carga"
                },
                {
                  "s_codigo": 4921,
                  "s_descripcion": "Transporte de pasajeros"
                },
                {
                  "s_codigo": 4922,
                  "s_descripcion": "Transporte mixto"
                },
                {
                  "s_codigo": 4923,
                  "s_descripcion": "Transporte de carga por carretera"
                },
                {
                  "s_codigo": 4930,
                  "s_descripcion": "Transporte por tuberías"
                },
                {
                  "s_codigo": 5011,
                  "s_descripcion": "Transporte de pasajeros marítimo y de cabotaje"
                },
                {
                  "s_codigo": 5012,
                  "s_descripcion": "Transporte de carga marítimo y de cabotaje"
                },
                {
                  "s_codigo": 5021,
                  "s_descripcion": "Transporte fluvial de pasajeros"
                },
                {
                  "s_codigo": 5022,
                  "s_descripcion": "Transporte fluvial de carga"
                },
                {
                  "s_codigo": 5111,
                  "s_descripcion": "Transporte aéreo nacional de pasajeros"
                },
                {
                  "s_codigo": 5112,
                  "s_descripcion": "Transporte aéreo internacional de pasajeros"
                },
                {
                  "s_codigo": 5121,
                  "s_descripcion": "Transporte aéreo nacional de carga"
                },
                {
                  "s_codigo": 5122,
                  "s_descripcion": "Transporte aéreo internacional de carga"
                },
                {
                  "s_codigo": 5210,
                  "s_descripcion": "Almacenamiento y depósito"
                },
                {
                  "s_codigo": 5221,
                  "s_descripcion": "Actividades de estaciones, vías y servicios complementarios para el transporte terrestre"
                },
                {
                  "s_codigo": 5222,
                  "s_descripcion": "Actividades de puertos y servicios complementarios para el transporte acuático"
                },
                {
                  "s_codigo": 5223,
                  "s_descripcion": "Actividades de aeropuertos, servicios de navegación aérea y demás actividades conexas al transporte aéreo"
                },
                {
                  "s_codigo": 5224,
                  "s_descripcion": "Manipulación de carga"
                },
                {
                  "s_codigo": 5229,
                  "s_descripcion": "Otras actividades complementarias al transporte"
                },
                {
                  "s_codigo": 5310,
                  "s_descripcion": "Actividades postales nacionales"
                },
                {
                  "s_codigo": 5320,
                  "s_descripcion": "Actividades de mensajería"
                },
                {
                  "s_codigo": 5511,
                  "s_descripcion": "Alojamiento en hoteles"
                },
                {
                  "s_codigo": 5512,
                  "s_descripcion": "Alojamiento en aparta-hoteles"
                },
                {
                  "s_codigo": 5513,
                  "s_descripcion": "Alojamiento en centros vacacionales"
                },
                {
                  "s_codigo": 5514,
                  "s_descripcion": "Alojamiento rural"
                },
                {
                  "s_codigo": 5519,
                  "s_descripcion": "Otros tipos de alojamientos para visitantes"
                },
                {
                  "s_codigo": 5520,
                  "s_descripcion": "Actividades de zonas de camping y parques para vehículos recreacionales"
                },
                {
                  "s_codigo": 5530,
                  "s_descripcion": "Servicio por horas  de alojamiento"
                },
                {
                  "s_codigo": 5590,
                  "s_descripcion": "Otros tipos de alojamiento n.c.p."
                },
                {
                  "s_codigo": 5611,
                  "s_descripcion": "Expendio a la mesa de comidas preparadas"
                },
                {
                  "s_codigo": 5612,
                  "s_descripcion": "Expendio por autoservicio de comidas preparadas"
                },
                {
                  "s_codigo": 5613,
                  "s_descripcion": "Expendio de comidas preparadas en cafeterías"
                },
                {
                  "s_codigo": 5619,
                  "s_descripcion": "Otros tipos de expendio de comidas preparadas n.c.p."
                },
                {
                  "s_codigo": 5621,
                  "s_descripcion": "Catering para eventos"
                },
                {
                  "s_codigo": 5629,
                  "s_descripcion": "Actividades de otros servicios de comidas"
                },
                {
                  "s_codigo": 5630,
                  "s_descripcion": "Expendio de bebidas alcohólicas para el consumo dentro del establecimiento"
                },
                {
                  "s_codigo": 5812,
                  "s_descripcion": "Edición de directorios y listas de correo"
                },
                {
                  "s_codigo": 5813,
                  "s_descripcion": "Edición de periódicos, revistas y otras publicaciones periódicas"
                },
                {
                  "s_codigo": 5819,
                  "s_descripcion": "Otros trabajos de edición"
                },
                {
                  "s_codigo": 5820,
                  "s_descripcion": "Edición de programas de informática (software)"
                },
                {
                  "s_codigo": 5911,
                  "s_descripcion": "Actividades de producción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión (excepto programación de televisión)"
                },
                {
                  "s_codigo": 5912,
                  "s_descripcion": "Actividades de postproducción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión  (excepto programación de televisión)"
                },
                {
                  "s_codigo": 5913,
                  "s_descripcion": "Actividades de distribución de películas cinematográficas, videos, programas, anuncios y comerciales de televisión"
                },
                {
                  "s_codigo": 5914,
                  "s_descripcion": "Actividades de exhibición de películas cinematográficas y videos"
                },
                {
                  "s_codigo": 5920,
                  "s_descripcion": "Actividades de grabación de sonido y edición de música"
                },
                {
                  "s_codigo": 6010,
                  "s_descripcion": "Actividades de programación y transmisión en el servicio de radiodifusión sonora"
                },
                {
                  "s_codigo": 6110,
                  "s_descripcion": "Actividades de telecomunicaciones alámbricas"
                },
                {
                  "s_codigo": 6120,
                  "s_descripcion": "Actividades de telecomunicaciones inalámbricas"
                },
                {
                  "s_codigo": 6130,
                  "s_descripcion": "Actividades de telecomunicación satelital"
                },
                {
                  "s_codigo": 6190,
                  "s_descripcion": "Otras actividades de telecomunicaciones"
                },
                {
                  "s_codigo": 6201,
                  "s_descripcion": "Actividades de desarrollo de sistemas informáticos (planificación, análisis, diseño, programación, pruebas)"
                },
                {
                  "s_codigo": 6202,
                  "s_descripcion": "Actividades de consultoría informática y actividades de administración de instalaciones informáticas"
                },
                {
                  "s_codigo": 6209,
                  "s_descripcion": "Otras actividades de tecnologías de información y actividades de servicios informáticos"
                },
                {
                  "s_codigo": 6311,
                  "s_descripcion": "Procesamiento de datos, alojamiento (hosting) y actividades relacionadas"
                },
                {
                  "s_codigo": 6312,
                  "s_descripcion": "Portales Web"
                },
                {
                  "s_codigo": 6391,
                  "s_descripcion": "Actividades de agencias de noticias"
                },
                {
                  "s_codigo": 6399,
                  "s_descripcion": "Otras actividades de servicio de información n.c.p."
                },
                {
                  "s_codigo": 6411,
                  "s_descripcion": "Banca Central"
                },
                {
                  "s_codigo": 6412,
                  "s_descripcion": "Bancos comerciales"
                },
                {
                  "s_codigo": 6421,
                  "s_descripcion": "Actividades de las corporaciones financieras"
                },
                {
                  "s_codigo": 6422,
                  "s_descripcion": "Actividades de las compañías de financiamiento"
                },
                {
                  "s_codigo": 6423,
                  "s_descripcion": "Banca de segundo piso"
                },
                {
                  "s_codigo": 6424,
                  "s_descripcion": "Actividades de las cooperativas financieras"
                },
                {
                  "s_codigo": 6431,
                  "s_descripcion": "Fideicomisos, fondos y entidades financieras similares"
                },
                {
                  "s_codigo": 6491,
                  "s_descripcion": "Leasing financiero (arrendamiento financiero)"
                },
                {
                  "s_codigo": 6492,
                  "s_descripcion": "Actividades financieras de fondos de empleados y otras formas asociativas del sector solidario"
                },
                {
                  "s_codigo": 6493,
                  "s_descripcion": "Actividades de compra de cartera o factoring"
                },
                {
                  "s_codigo": 6494,
                  "s_descripcion": "Otras actividades de distribución de fondos"
                },
                {
                  "s_codigo": 6495,
                  "s_descripcion": "Instituciones especiales oficiales"
                },
                {
                  "s_codigo": 6511,
                  "s_descripcion": "Seguros generales"
                },
                {
                  "s_codigo": 6512,
                  "s_descripcion": "Seguros de vida"
                },
                {
                  "s_codigo": 6513,
                  "s_descripcion": "Reaseguros"
                },
                {
                  "s_codigo": 6514,
                  "s_descripcion": "Capitalización"
                },
                {
                  "s_codigo": 6521,
                  "s_descripcion": "Servicios de seguros sociales de salud"
                },
                {
                  "s_codigo": 6522,
                  "s_descripcion": "Servicios de seguros sociales de riesgos profesionales"
                },
                {
                  "s_codigo": 6531,
                  "s_descripcion": "Régimen de prima media con prestación definida (RPM)"
                },
                {
                  "s_codigo": 6532,
                  "s_descripcion": "Régimen de ahorro individual (RAI)"
                },
                {
                  "s_codigo": 6612,
                  "s_descripcion": "Corretaje de valores y de contratos de productos básicos"
                },
                {
                  "s_codigo": 6613,
                  "s_descripcion": "Otras actividades relacionadas con el mercado de valores"
                },
                {
                  "s_codigo": 6614,
                  "s_descripcion": "Actividades de las casas de cambio"
                },
                {
                  "s_codigo": 6615,
                  "s_descripcion": "Actividades de los profesionales de compra y venta de divisas"
                },
                {
                  "s_codigo": 6619,
                  "s_descripcion": "Otras actividades auxiliares de las actividades de servicios financieros n.c.p."
                },
                {
                  "s_codigo": 6621,
                  "s_descripcion": "Actividades de agentes y corredores de seguros"
                },
                {
                  "s_codigo": 6629,
                  "s_descripcion": "Evaluación de riesgos y daños, y otras actividades de servicios auxiliares"
                },
                {
                  "s_codigo": 6630,
                  "s_descripcion": "Actividades de administración de fondos"
                },
                {
                  "s_codigo": 6810,
                  "s_descripcion": "Actividades inmobiliarias realizadas con bienes propios o arrendados"
                },
                {
                  "s_codigo": 6820,
                  "s_descripcion": "Actividades inmobiliarias realizadas a cambio de una retribución o por contrata"
                },
                {
                  "s_codigo": 7310,
                  "s_descripcion": "Publicidad"
                },
                {
                  "s_codigo": 7420,
                  "s_descripcion": "Actividades de fotografía"
                },
                {
                  "s_codigo": 7500,
                  "s_descripcion": "Actividades veterinarias"
                },
                {
                  "s_codigo": 7710,
                  "s_descripcion": "Alquiler y arrendamiento de vehículos automotores"
                },
                {
                  "s_codigo": 7721,
                  "s_descripcion": "Alquiler y arrendamiento de equipo recreativo y deportivo"
                },
                {
                  "s_codigo": 7722,
                  "s_descripcion": "Alquiler de videos y discos"
                },
                {
                  "s_codigo": 7729,
                  "s_descripcion": "Alquiler y arrendamiento de otros efectos personales y enseres domésticos n.c.p."
                },
                {
                  "s_codigo": 7730,
                  "s_descripcion": "Alquiler y arrendamiento de otros tipos de maquinaria, equipo y bienes tangibles n.c.p."
                },
                {
                  "s_codigo": 7740,
                  "s_descripcion": "Arrendamiento de propiedad intelectual y productos similares, excepto obras protegidas por derechos de autor"
                },
                {
                  "s_codigo": 7810,
                  "s_descripcion": "Actividades de agencias de empleo"
                },
                {
                  "s_codigo": 7820,
                  "s_descripcion": "Actividades de agencias de empleo temporal"
                },
                {
                  "s_codigo": 7830,
                  "s_descripcion": "Otras actividades de suministro de recurso humano"
                },
                {
                  "s_codigo": 7911,
                  "s_descripcion": "Actividades de las agencias de viaje"
                },
                {
                  "s_codigo": 7912,
                  "s_descripcion": "Actividades de operadores turísticos"
                },
                {
                  "s_codigo": 7990,
                  "s_descripcion": "Otros servicios de reserva y actividades relacionadas"
                },
                {
                  "s_codigo": 8010,
                  "s_descripcion": "Actividades de seguridad privada"
                },
                {
                  "s_codigo": 8020,
                  "s_descripcion": "Actividades de servicios de sistemas de seguridad"
                },
                {
                  "s_codigo": 8030,
                  "s_descripcion": "Actividades de detectives e investigadores privados"
                },
                {
                  "s_codigo": 8110,
                  "s_descripcion": "Actividades combinadas de apoyo a instalaciones"
                },
                {
                  "s_codigo": 8121,
                  "s_descripcion": "Limpieza general interior de edificios"
                },
                {
                  "s_codigo": 8129,
                  "s_descripcion": "Otras actividades de limpieza de edificios e instalaciones industriales"
                },
                {
                  "s_codigo": 8130,
                  "s_descripcion": "Actividades de paisajismo y servicios de mantenimiento conexos"
                },
                {
                  "s_codigo": 8211,
                  "s_descripcion": "Actividades combinadas de servicios administrativos de oficina"
                },
                {
                  "s_codigo": 8219,
                  "s_descripcion": "Fotocopiado, preparación de documentos y otras actividades especializadas de apoyo a oficina"
                },
                {
                  "s_codigo": 8220,
                  "s_descripcion": "Actividades de centros de llamadas (Call center)"
                },
                {
                  "s_codigo": 8230,
                  "s_descripcion": "Organización de convenciones y eventos comerciales"
                },
                {
                  "s_codigo": 8291,
                  "s_descripcion": "Actividades de agencias de cobranza y oficinas de calificación crediticia"
                },
                {
                  "s_codigo": 8292,
                  "s_descripcion": "Actividades de envase y empaque"
                },
                {
                  "s_codigo": 8299,
                  "s_descripcion": "Otras actividades de servicio de apoyo a las empresas n.c.p."
                },
                {
                  "s_codigo": 8411,
                  "s_descripcion": "Actividades legislativas de la administración pública"
                },
                {
                  "s_codigo": 8412,
                  "s_descripcion": "Actividades ejecutivas de la administración pública"
                },
                {
                  "s_codigo": 8413,
                  "s_descripcion": "Regulación de las actividades de organismos que prestan servicios de salud, educativos, culturales y otros servicios sociales, excepto servicios de seguridad social"
                },
                {
                  "s_codigo": 8414,
                  "s_descripcion": "Actividades reguladoras y facilitadoras de la actividad económica"
                },
                {
                  "s_codigo": 8415,
                  "s_descripcion": "Actividades de los otros órganos de control"
                },
                {
                  "s_codigo": 8421,
                  "s_descripcion": "Relaciones exteriores"
                },
                {
                  "s_codigo": 8422,
                  "s_descripcion": "Actividades de defensa"
                },
                {
                  "s_codigo": 8423,
                  "s_descripcion": "Orden público y actividades de seguridad publica"
                },
                {
                  "s_codigo": 8424,
                  "s_descripcion": "Administración de justicia"
                },
                {
                  "s_codigo": 8430,
                  "s_descripcion": "Actividades de planes de Seguridad Social de afiliación obligatoria"
                },
                {
                  "s_codigo": 8511,
                  "s_descripcion": "Educación de la primera infancia"
                },
                {
                  "s_codigo": 8512,
                  "s_descripcion": "Educación preescolar"
                },
                {
                  "s_codigo": 8513,
                  "s_descripcion": "Educación básica primaria"
                },
                {
                  "s_codigo": 8521,
                  "s_descripcion": "Educación básica secundaria"
                },
                {
                  "s_codigo": 8522,
                  "s_descripcion": "Educación media académica"
                },
                {
                  "s_codigo": 8530,
                  "s_descripcion": "Establecimientos que combinan diferentes niveles de educación inicial, preescolar, básica primaria, básica secundaria y media"
                },
                {
                  "s_codigo": 8541,
                  "s_descripcion": "Educación técnica profesional"
                },
                {
                  "s_codigo": 8542,
                  "s_descripcion": "Educación tecnológica"
                },
                {
                  "s_codigo": 8543,
                  "s_descripcion": "Educación de instituciones universitarias o de escuelas tecnológicas"
                },
                {
                  "s_codigo": 8544,
                  "s_descripcion": "Educación de universidades"
                },
                {
                  "s_codigo": 8552,
                  "s_descripcion": "Enseñanza deportiva y recreativa"
                },
                {
                  "s_codigo": 8553,
                  "s_descripcion": "Enseñanza cultural"
                },
                {
                  "s_codigo": 8559,
                  "s_descripcion": "Otros tipos de educación n.c.p."
                },
                {
                  "s_codigo": 8560,
                  "s_descripcion": "Actividades de apoyo a la educación"
                },
                {
                  "s_codigo": 8610,
                  "s_descripcion": "Actividades de hospitales y clínicas, con internación"
                },
                {
                  "s_codigo": 8720,
                  "s_descripcion": "Actividades de atención residencial, para el cuidado de pacientes con retardo mental, enfermedad mental y consumo de sustancias psicoactivas"
                },
                {
                  "s_codigo": 8730,
                  "s_descripcion": "Actividades de atención en instituciones para el cuidado de personas mayores y/o discapacitadas"
                },
                {
                  "s_codigo": 8790,
                  "s_descripcion": "Otras actividades de atención en instituciones con alojamiento"
                },
                {
                  "s_codigo": 8810,
                  "s_descripcion": "Actividades de asistencia social sin alojamiento para personas mayores y discapacitadas"
                },
                {
                  "s_codigo": 8890,
                  "s_descripcion": "Otras actividades de asistencia social sin alojamiento"
                },
                {
                  "s_codigo": 9001,
                  "s_descripcion": "Creación literaria"
                },
                {
                  "s_codigo": 9002,
                  "s_descripcion": "Creación musical"
                },
                {
                  "s_codigo": 9003,
                  "s_descripcion": "Creación teatral"
                },
                {
                  "s_codigo": 9004,
                  "s_descripcion": "Creación audiovisual"
                },
                {
                  "s_codigo": 9005,
                  "s_descripcion": "Artes plásticas y visuales"
                },
                {
                  "s_codigo": 9006,
                  "s_descripcion": "Actividades teatrales"
                },
                {
                  "s_codigo": 9007,
                  "s_descripcion": "Actividades de espectáculos musicales en vivo"
                },
                {
                  "s_codigo": 9008,
                  "s_descripcion": "Otras actividades de espectáculos en vivo"
                },
                {
                  "s_codigo": 9101,
                  "s_descripcion": "Actividades de Bibliotecas y archivos"
                },
                {
                  "s_codigo": 9102,
                  "s_descripcion": "Actividades y funcionamiento de museos, conservación de edificios y sitios históricos"
                },
                {
                  "s_codigo": 9103,
                  "s_descripcion": "Actividades de jardines botánicos, zoológicos y reservas naturales"
                },
                {
                  "s_codigo": 9311,
                  "s_descripcion": "Gestión de instalaciones deportivas"
                },
                {
                  "s_codigo": 9312,
                  "s_descripcion": "Actividades de clubes deportivos"
                },
                {
                  "s_codigo": 9319,
                  "s_descripcion": "Otras actividades deportivas"
                },
                {
                  "s_codigo": 9321,
                  "s_descripcion": "Actividades de parques de atracciones y parques temáticos"
                },
                {
                  "s_codigo": 9411,
                  "s_descripcion": "Actividades de asociaciones empresariales y de empleadores"
                },
                {
                  "s_codigo": 9412,
                  "s_descripcion": "Actividades de asociaciones profesionales y gremiales sin ánimo de lucro"
                },
                {
                  "s_codigo": 9420,
                  "s_descripcion": "Actividades de sindicatos"
                },
                {
                  "s_codigo": 9491,
                  "s_descripcion": "Actividades de asociaciones religiosas"
                },
                {
                  "s_codigo": 9492,
                  "s_descripcion": "Actividades de partidos políticos"
                },
                {
                  "s_codigo": 9499,
                  "s_descripcion": "Actividades de otras asociaciones n.c.p."
                },
                {
                  "s_codigo": 9511,
                  "s_descripcion": "Mantenimiento y reparación de computadores y de equipo periférico"
                },
                {
                  "s_codigo": 9512,
                  "s_descripcion": "Mantenimiento y reparación de equipos de comunicación"
                },
                {
                  "s_codigo": 9521,
                  "s_descripcion": "Mantenimiento y reparación de aparatos electrónicos de consumo"
                },
                {
                  "s_codigo": 9522,
                  "s_descripcion": "Mantenimiento y reparación de aparatos domésticos y equipos domésticos y de jardinería"
                },
                {
                  "s_codigo": 9523,
                  "s_descripcion": "Reparación de calzado y artículos de cuero"
                },
                {
                  "s_codigo": 9524,
                  "s_descripcion": "Reparación de muebles y accesorios para el hogar"
                },
                {
                  "s_codigo": 9529,
                  "s_descripcion": "Mantenimiento y reparación de otros efectos personales y enseres domésticos"
                },
                {
                  "s_codigo": 9601,
                  "s_descripcion": "Lavado y limpieza, incluso la limpieza en seco, de productos textiles y de piel"
                },
                {
                  "s_codigo": 9602,
                  "s_descripcion": "Peluquería y otros tratamientos de belleza"
                },
                {
                  "s_codigo": 9603,
                  "s_descripcion": "Pompas fúnebres y actividades relacionadas"
                },
                {
                  "s_codigo": 9609,
                  "s_descripcion": "Otras actividades de servicios personales n.c.p."
                },
                {
                  "s_codigo": 9700,
                  "s_descripcion": "Actividades de los hogares individuales como empleadores de personal doméstico"
                },
                {
                  "s_codigo": 9810,
                  "s_descripcion": "Actividades no diferenciadas de los hogares individuales como productores de bienes para uso propio"
                },
                {
                  "s_codigo": 9820,
                  "s_descripcion": "Actividades no diferenciadas de los hogares individuales como productores de servicios para uso propio"
                },
                {
                  "s_codigo": 9900,
                  "s_descripcion": "Actividades de organizaciones y entidades extraterritoriales signatarios de la Convención de Viena"
                },
                {
                  "s_codigo": 10201,
                  "s_descripcion": "Procesamiento y conservación de frutas, legumbres, hortalizas y tubérculos (excepto elaboración de jugos de frutas)"
                },
                {
                  "s_codigo": 10202,
                  "s_descripcion": "Elaboración de jugos de frutas"
                },
                {
                  "s_codigo": 10401,
                  "s_descripcion": "Elaboración de productos lácteos (excepto bebidas)"
                },
                {
                  "s_codigo": 10402,
                  "s_descripcion": "Elaboración de bebidas lácteas"
                },
                {
                  "s_codigo": 14201,
                  "s_descripcion": "Fabricación de prendas de vestir de piel"
                },
                {
                  "s_codigo": 14202,
                  "s_descripcion": "Fabricación de artículos de piel (excepto prendas de vestir)"
                },
                {
                  "s_codigo": 14301,
                  "s_descripcion": "Fabricación de prendas de vestir  de punto y ganchillo"
                },
                {
                  "s_codigo": 14302,
                  "s_descripcion": "Fabricación de artículos de punto y ganchillo (excepto prendas de vestir)"
                },
                {
                  "s_codigo": 35201,
                  "s_descripcion": "Producción de gas"
                },
                {
                  "s_codigo": 35202,
                  "s_descripcion": "Distribución de combustibles gaseosos por tuberías"
                },
                {
                  "s_codigo": 36001,
                  "s_descripcion": "Captación y tratamiento de agua"
                },
                {
                  "s_codigo": 36002,
                  "s_descripcion": "Distribución de agua"
                },
                {
                  "s_codigo": 39001,
                  "s_descripcion": "Actividades de saneamiento ambiental y otros servicios de gestión de desechos (excepto los servicios prestados por contratistas de construcción, constructores y urbanizadores)"
                },
                {
                  "s_codigo": 39002,
                  "s_descripcion": "Actividades de saneamiento ambiental y otros servicios de gestión de desechos prestados por contratistas de construcción, constructores y urbanizadores"
                },
                {
                  "s_codigo": 45411,
                  "s_descripcion": "Comercio de motocicletas"
                },
                {
                  "s_codigo": 45412,
                  "s_descripcion": "Comercio de partes, piezas y accesorios de motocicletas"
                },
                {
                  "s_codigo": 46201,
                  "s_descripcion": "Comercio al por mayor de materias primas agrícolas en bruto (alimentos)"
                },
                {
                  "s_codigo": 46202,
                  "s_descripcion": "Comercio al por mayor de materias primas pecuarias y animales vivos"
                },
                {
                  "s_codigo": 46321,
                  "s_descripcion": "Comercio al por mayor de bebidas y tabaco (diferentes a licores y cigarrillos)"
                },
                {
                  "s_codigo": 46322,
                  "s_descripcion": "Comercio al por mayor de licores y cigarrillos"
                },
                {
                  "s_codigo": 46451,
                  "s_descripcion": "Comercio al por mayor de productos farmacéuticos y medicinales"
                },
                {
                  "s_codigo": 46452,
                  "s_descripcion": "Comercio al por mayor de productos cosméticos y de tocador (excepto productos farmacéuticos y medicinales)"
                },
                {
                  "s_codigo": 46491,
                  "s_descripcion": "Comercio al por mayor de otros utensilios domésticos n.c.p. (excepto joyas)"
                },
                {
                  "s_codigo": 46492,
                  "s_descripcion": "Venta de joyas"
                },
                {
                  "s_codigo": 46611,
                  "s_descripcion": "Comercio al por mayor de combustibles sólidos, líquidos, gaseosos y productos conexos (excepto combustibles derivados del petróleo)"
                },
                {
                  "s_codigo": 46612,
                  "s_descripcion": "Comercio al por mayor de combustibles  derivados del petróleo"
                },
                {
                  "s_codigo": 46631,
                  "s_descripcion": "Comercio al por mayor de materiales de construcción"
                },
                {
                  "s_codigo": 46632,
                  "s_descripcion": "Comercio al por mayor de  artículos de ferretería, pinturas, productos de vidrio, equipo y materiales de fontanería y calefacción"
                },
                {
                  "s_codigo": 47111,
                  "s_descripcion": "Comercio al por menor en establecimientos no especializados con surtido compuesto principalmente por alimentos, bebidas o tabaco (excepto licores y cigarrillos)"
                },
                {
                  "s_codigo": 47112,
                  "s_descripcion": "Comercio al por menor en establecimientos no especializados con surtido compuesto principalmente  por licores y cigarrillos"
                },
                {
                  "s_codigo": 47191,
                  "s_descripcion": "Comercio al por menor en establecimientos no especializados con surtido compuesto principalmente por productos diferentes de alimentos"
                },
                {
                  "s_codigo": 47192,
                  "s_descripcion": "Comercio al por menor en establecimientos no especializados, con surtido compuesto principalmente por drogas, medicamentos, textos escolares, libros y cuadernos."
                },
                {
                  "s_codigo": 47241,
                  "s_descripcion": "Comercio al por menor de bebidas y productos del tabaco, en establecimientos especializados  (excepto licores y cigarrillos)"
                },
                {
                  "s_codigo": 47242,
                  "s_descripcion": "Comercio al por menor de licores y cigarrillos"
                },
                {
                  "s_codigo": 47521,
                  "s_descripcion": "Comercio al por menor de materiales de construcción"
                },
                {
                  "s_codigo": 47522,
                  "s_descripcion": "Comercio al por menor de artículos de ferretería, pinturas y productos de vidrio en establecimientos especializados (excepto materiales de construcción)"
                },
                {
                  "s_codigo": 47611,
                  "s_descripcion": "Comercio al por menor y al por mayor  de libros, textos escolares y cuadernos"
                },
                {
                  "s_codigo": 47612,
                  "s_descripcion": "Comercio al por menor de periódicos, materiales y artículos de papelería y escritorio, en establecimientos especializados (excepto libros, textos escolares y cuadernos)"
                },
                {
                  "s_codigo": 47731,
                  "s_descripcion": "Comercio al por menor de productos farmacéuticos y medicinales en establecimientos especializados"
                },
                {
                  "s_codigo": 47732,
                  "s_descripcion": "Comercio al por menor de productos cosméticos y artículos de tocador en establecimientos especializados (excepto productos  farmacéuticos y medicinales)"
                },
                {
                  "s_codigo": 47811,
                  "s_descripcion": "Comercio al por menor de alimentos en puestos de venta móviles"
                },
                {
                  "s_codigo": 47812,
                  "s_descripcion": "Comercio al por menor de  bebidas y tabaco en puestos de venta móviles (excepto licores y cigarrillos)"
                },
                {
                  "s_codigo": 47813,
                  "s_descripcion": "Comercio al por menor de cigarrillos y licores en puestos de venta móviles"
                },
                {
                  "s_codigo": 47911,
                  "s_descripcion": "Comercio al por menor de alimentos y productos agrícolas en bruto; venta de textos escolares y libros (incluye cuadernos escolares); venta de drogas y medicamentos realizado a través de internet"
                },
                {
                  "s_codigo": 47912,
                  "s_descripcion": "Comercio al por menor y al por mayor de madera y materiales para construcción; venta de automotores (incluidas motocicletas)  realizado a través de internet"
                },
                {
                  "s_codigo": 47913,
                  "s_descripcion": "Comercio al por menor de cigarrillos y licores; venta de combustibles derivados del petróleo y venta de joyas  realizado a través de internet"
                },
                {
                  "s_codigo": 47914,
                  "s_descripcion": "Comercio al por menor de demás productos n.c.p.  realizado a través de internet"
                },
                {
                  "s_codigo": 47921,
                  "s_descripcion": "Comercio al por menor de alimentos y productos agrícolas en bruto; venta de textos escolares y libros (incluye cuadernos escolares); venta de drogas y medicamentos realizado a través de casas de venta o por correo"
                },
                {
                  "s_codigo": 47922,
                  "s_descripcion": "Comercio al por menor y al por mayor de madera y materiales para construcción; venta de automotores (incluidas motocicletas)  realizado a través de casas de venta o por correo"
                },
                {
                  "s_codigo": 47923,
                  "s_descripcion": "Comercio al por menor de cigarrillos y licores; venta de combustibles derivados del petróleo y venta de joyas  realizado a través de casas de venta o por correo"
                },
                {
                  "s_codigo": 47924,
                  "s_descripcion": "Comercio al por menor de demás productos n.c.p.  realizado a través de casas de venta o por correo"
                },
                {
                  "s_codigo": 47991,
                  "s_descripcion": "Otros tipos de comercio al por menor no realizado en establecimientos, puestos de venta o mercados de textos escolares y libros (incluye cuadernos escolares); venta de drogas y medicamentos"
                },
                {
                  "s_codigo": 47992,
                  "s_descripcion": "Otros tipos de comercio al por menor no realizado en establecimientos, puestos de venta o mercados de  materiales para construcción; venta de automotores (incluidas motocicletas)"
                },
                {
                  "s_codigo": 47993,
                  "s_descripcion": "Otros tipos de comercio al por menor no realizado en establecimientos, puestos de venta o mercados de cigarrillos y licores; venta de combustibles derivados del petróleo y venta de joyas"
                },
                {
                  "s_codigo": 47994,
                  "s_descripcion": "Otros tipos de comercio al por menor no realizado en establecimientos, puestos de venta o mercados de demás productos n.c.p."
                },
                {
                  "s_codigo": 58111,
                  "s_descripcion": "Servicio de edición de libros"
                },
                {
                  "s_codigo": 58112,
                  "s_descripcion": "Edición y publicación de libros"
                },
                {
                  "s_codigo": 58113,
                  "s_descripcion": "Edición y publicación de libros (Tarifa especial para los contribuyentes que cumplen condiciones del Acuerdo 98 de 2003)"
                },
                {
                  "s_codigo": 60201,
                  "s_descripcion": "Actividades de programación de televisión"
                },
                {
                  "s_codigo": 60202,
                  "s_descripcion": "Actividades de transmisión de televisión"
                },
                {
                  "s_codigo": 64991,
                  "s_descripcion": "Otras actividades de servicio financiero, excepto las de seguros y pensiones n.c.p."
                },
                {
                  "s_codigo": 64992,
                  "s_descripcion": "Actividades comerciales de las casas de empeño o compraventa"
                },
                {
                  "s_codigo": 64993,
                  "s_descripcion": "Servicios de las casas de empeño o compraventas"
                },
                {
                  "s_codigo": 66111,
                  "s_descripcion": "Administración de mercados financieros (excepto actividades de las bolsas de valores)"
                },
                {
                  "s_codigo": 66112,
                  "s_descripcion": "Actividades de las bolsas de valores"
                },
                {
                  "s_codigo": 69101,
                  "s_descripcion": "Actividades jurídicas como consultoría profesional"
                },
                {
                  "s_codigo": 69102,
                  "s_descripcion": "Actividades jurídicas en el ejercicio de una profesión liberal"
                },
                {
                  "s_codigo": 69201,
                  "s_descripcion": "Actividades de contabilidad, teneduría de libros, auditoría financiera y asesoría tributaria como consultoría profesional"
                },
                {
                  "s_codigo": 69202,
                  "s_descripcion": "Actividades de contabilidad, teneduría de libros, auditoría financiera y asesoría tributaria en el ejercicio de una profesión liberal"
                },
                {
                  "s_codigo": 70101,
                  "s_descripcion": "Actividades de administración empresarial como consultoría profesional"
                },
                {
                  "s_codigo": 70102,
                  "s_descripcion": "Actividades de administración empresarial en el ejercicio de una profesión liberal"
                },
                {
                  "s_codigo": 70201,
                  "s_descripcion": "Actividades de consultoría de gestión"
                },
                {
                  "s_codigo": 70202,
                  "s_descripcion": "Actividades de  gestión en el ejercicio de una profesión liberal"
                },
                {
                  "s_codigo": 71101,
                  "s_descripcion": "Actividades de arquitectura e ingeniería y otras actividades conexas de consultoría técnica"
                },
                {
                  "s_codigo": 71102,
                  "s_descripcion": "Actividades de arquitectura e ingeniería y otras actividades conexas en el ejercicio de una profesión liberal"
                },
                {
                  "s_codigo": 71201,
                  "s_descripcion": "Ensayos y análisis técnicos como consultoría profesional"
                },
                {
                  "s_codigo": 71202,
                  "s_descripcion": "Ensayos y análisis técnicos como consultoría profesional en el ejercicio de una profesión liberal"
                },
                {
                  "s_codigo": 72101,
                  "s_descripcion": "Investigaciones y desarrollo experimental en el campo de las ciencias naturales y la ingeniería  como consultoría profesional"
                },
                {
                  "s_codigo": 72102,
                  "s_descripcion": "Investigaciones y desarrollo experimental en el campo de las ciencias naturales y la ingeniería  en el ejercicio de una profesión liberal"
                },
                {
                  "s_codigo": 72201,
                  "s_descripcion": "Investigaciones y desarrollo experimental en el campo de las ciencias sociales y las humanidades  como consultoría profesional"
                },
                {
                  "s_codigo": 72202,
                  "s_descripcion": "Investigaciones y desarrollo experimental en el campo de las ciencias sociales y las humanidades  en el ejercicio de una profesión liberal"
                },
                {
                  "s_codigo": 73201,
                  "s_descripcion": "Estudios de mercado y realización de encuestas de opinión pública como consultoría profesional"
                },
                {
                  "s_codigo": 73202,
                  "s_descripcion": "Estudios de mercado y realización de encuestas de opinión pública en el ejercicio de una profesión liberal"
                },
                {
                  "s_codigo": 74101,
                  "s_descripcion": "Actividades especializadas de diseño como consultoría profesional"
                },
                {
                  "s_codigo": 74102,
                  "s_descripcion": "Actividades especializadas de diseño en el ejercicio de una profesión liberal"
                },
                {
                  "s_codigo": 74901,
                  "s_descripcion": "Otras actividades profesionales, científicas y técnicas n.c.p. como consultoría profesional (incluye actividades de periodistas)"
                },
                {
                  "s_codigo": 74902,
                  "s_descripcion": "Otras actividades profesionales, científicas y técnicas n.c.p. en el ejercicio de una profesión liberal"
                },
                {
                  "s_codigo": 85231,
                  "s_descripcion": "Educación media técnica"
                },
                {
                  "s_codigo": 85232,
                  "s_descripcion": "Educación de formación laboral"
                },
                {
                  "s_codigo": 85511,
                  "s_descripcion": "Educación académica no formal (excepto programas de educación básica primaria, básica secundaria y media no gradual con fines de validación)"
                },
                {
                  "s_codigo": 85512,
                  "s_descripcion": "Educación académica no formal impartida mediante programas de educación básica primaria, básica secundaria y media no gradual con fines de validación"
                },
                {
                  "s_codigo": 86911,
                  "s_descripcion": "Actividades de apoyo diagnóstico (excepto actividades de promoción y prevención que realicen las entidades e instituciones promotoras y prestadoras de servicios de salud de naturaleza pública o privada, con recursos que provengan  del Sistema General  de Seguridad Social en  Salud.)"
                },
                {
                  "s_codigo": 86921,
                  "s_descripcion": "Actividades de apoyo terapéutico (excepto actividades de promoción y prevención que realicen las entidades e instituciones promotoras y prestadoras de servicios de salud de naturaleza pública o privada, con recursos que provengan  del Sistema General  de Seguridad Social en  Salud.)"
                },
                {
                  "s_codigo": 86991,
                  "s_descripcion": "Otras actividades de atención de la salud humana (excepto actividades de promoción y prevención que realicen las entidades e instituciones promotoras y prestadoras de servicios de salud de naturaleza pública o privada, con recursos que provengan  del Sistema General  de Seguridad Social en  Salud.)"
                },
                {
                  "s_codigo": 92001,
                  "s_descripcion": "Actividades de juegos de destreza, habilidad, conocimiento y fuerza"
                },
                {
                  "s_codigo": 93291,
                  "s_descripcion": "Otras actividades recreativas y de esparcimiento n.c.p. (excepto juegos de suerte y azar, discotecas y similares )"
                }
              ]
            }
          }
  }

  export const cargos = {
          "WS_CARGOS_IResult": {
            "ListCargos": {
              "Datos_Cargos": [
                {
                  "s_codigo": 1,
                  "s_descripcion": "SIN CARGO"
                },
                {
                  "s_codigo": 2,
                  "s_descripcion": "RECEPCIONISTA"
                },
                {
                  "s_codigo": 3,
                  "s_descripcion": "TRAMITADOR(A)"
                },
                {
                  "s_codigo": 4,
                  "s_descripcion": "AUXILIAR DE CONTABILIDAD"
                },
                {
                  "s_codigo": 5,
                  "s_descripcion": "AUXILIAR DPTO SERV.A LA O"
                },
                {
                  "s_codigo": 6,
                  "s_descripcion": "SECRETARIA"
                },
                {
                  "s_codigo": 7,
                  "s_descripcion": "VENDEDOR (A)"
                },
                {
                  "s_codigo": 8,
                  "s_descripcion": "SECRETARIO SUBDIRECCION J"
                },
                {
                  "s_codigo": 9,
                  "s_descripcion": "OFICIOS VARIOS"
                },
                {
                  "s_codigo": 10,
                  "s_descripcion": "ASESORA DE COMUNICACIONES"
                },
                {
                  "s_codigo": 11,
                  "s_descripcion": "MENSAJERO"
                },
                {
                  "s_codigo": 12,
                  "s_descripcion": "VIGILANTE"
                },
                {
                  "s_codigo": 13,
                  "s_descripcion": "JEFE DEPARTAMENTO DE PRES"
                },
                {
                  "s_codigo": 14,
                  "s_descripcion": "AUXILIAR DE PRESUPUESTOS"
                },
                {
                  "s_codigo": 15,
                  "s_descripcion": "DIBUJANTE"
                },
                {
                  "s_codigo": 16,
                  "s_descripcion": "SUBDIRECTOR DE PROMOCION"
                },
                {
                  "s_codigo": 17,
                  "s_descripcion": "AUXILIAR DE SUBRROGACIONE"
                },
                {
                  "s_codigo": 18,
                  "s_descripcion": "AYUDANTE"
                },
                {
                  "s_codigo": 19,
                  "s_descripcion": "JEFE DEPTO DE FINANZAS"
                },
                {
                  "s_codigo": 20,
                  "s_descripcion": "SUBDIRECTOR JURIDICA"
                },
                {
                  "s_codigo": 21,
                  "s_descripcion": "SUBDIRECTOR DE DISE?O"
                },
                {
                  "s_codigo": 22,
                  "s_descripcion": "DIRECTOR"
                },
                {
                  "s_codigo": 23,
                  "s_descripcion": "JEFE DEPARTAMENTO ATENCIO"
                },
                {
                  "s_codigo": 24,
                  "s_descripcion": "AUXILIAR DEPARTAMENTO DE"
                },
                {
                  "s_codigo": 25,
                  "s_descripcion": "MANTENIMIENTO"
                },
                {
                  "s_codigo": 26,
                  "s_descripcion": "CARPINTERO"
                },
                {
                  "s_codigo": 27,
                  "s_descripcion": "RESIDENTE"
                },
                {
                  "s_codigo": 28,
                  "s_descripcion": "AUXILIAR DE INGENIERIA"
                },
                {
                  "s_codigo": 29,
                  "s_descripcion": "JEFE DE SEGURIDAD INDUSTR"
                },
                {
                  "s_codigo": 30,
                  "s_descripcion": "MAESTRO DE OBRA"
                },
                {
                  "s_codigo": 31,
                  "s_descripcion": "CONTADOR GENERAL"
                },
                {
                  "s_codigo": 32,
                  "s_descripcion": "DIRECTOR ADMINISTRATIVO"
                },
                {
                  "s_codigo": 33,
                  "s_descripcion": "COORDINADOR GENERAL DE OB"
                },
                {
                  "s_codigo": 34,
                  "s_descripcion": "ARQUITECTO (A)"
                },
                {
                  "s_codigo": 35,
                  "s_descripcion": "CONDUCTOR"
                },
                {
                  "s_codigo": 36,
                  "s_descripcion": "AUXILIAR DE ARQUITECTURA"
                },
                {
                  "s_codigo": 37,
                  "s_descripcion": "AUXILIAR DE ALMACEN"
                },
                {
                  "s_codigo": 38,
                  "s_descripcion": "JEFE DE ALMACEN"
                },
                {
                  "s_codigo": 39,
                  "s_descripcion": "SUBDIRECTOR DE CONSTRUCCI"
                },
                {
                  "s_codigo": 40,
                  "s_descripcion": "JEFE DE COMPRAS"
                },
                {
                  "s_codigo": 41,
                  "s_descripcion": "ASESOR JURIDICO"
                },
                {
                  "s_codigo": 42,
                  "s_descripcion": "ASISTENTE CAPACITACION"
                },
                {
                  "s_codigo": 43,
                  "s_descripcion": "GERENTE DE VENTAS"
                },
                {
                  "s_codigo": 44,
                  "s_descripcion": "SOCIO GERENTE"
                },
                {
                  "s_codigo": 45,
                  "s_descripcion": "GERENTE TECNICO"
                },
                {
                  "s_codigo": 47,
                  "s_descripcion": "ASISTENTE GERENCIA TECNIC"
                },
                {
                  "s_codigo": 48,
                  "s_descripcion": "TESORERO"
                },
                {
                  "s_codigo": 49,
                  "s_descripcion": "DELINEANTE DE ARQUITECTUR"
                },
                {
                  "s_codigo": 50,
                  "s_descripcion": "SUPERVISOR (A)"
                },
                {
                  "s_codigo": 51,
                  "s_descripcion": "GERENTE DE OFICINA"
                },
                {
                  "s_codigo": 52,
                  "s_descripcion": "VERIFICADOR"
                },
                {
                  "s_codigo": 53,
                  "s_descripcion": "GERENTE"
                },
                {
                  "s_codigo": 54,
                  "s_descripcion": "AUXILIAR DE OPERACIONES"
                },
                {
                  "s_codigo": 55,
                  "s_descripcion": "AUXILIAR DE CARTERA"
                },
                {
                  "s_codigo": 56,
                  "s_descripcion": "OPERARIO (A)"
                },
                {
                  "s_codigo": 57,
                  "s_descripcion": "CLASIFICADOR"
                },
                {
                  "s_codigo": 58,
                  "s_descripcion": "SUBDIRECTOR REGIONAL"
                },
                {
                  "s_codigo": 59,
                  "s_descripcion": "ASISTENTE DE CONTABILIDAD"
                },
                {
                  "s_codigo": 60,
                  "s_descripcion": "AUXILIAR"
                },
                {
                  "s_codigo": 61,
                  "s_descripcion": "SECRETARIO COMERCIAL"
                },
                {
                  "s_codigo": 62,
                  "s_descripcion": "DIRECTOR COMERCIAL"
                },
                {
                  "s_codigo": 63,
                  "s_descripcion": "ASESOR"
                },
                {
                  "s_codigo": 64,
                  "s_descripcion": "ASISTENTE DE DIRECCION"
                },
                {
                  "s_codigo": 65,
                  "s_descripcion": "MECANICO"
                },
                {
                  "s_codigo": 66,
                  "s_descripcion": "PENSIONADO"
                },
                {
                  "s_codigo": 67,
                  "s_descripcion": "SUPERNUMERARIO"
                },
                {
                  "s_codigo": 68,
                  "s_descripcion": "COORDINADOR"
                },
                {
                  "s_codigo": 69,
                  "s_descripcion": "COMERCIANTE"
                },
                {
                  "s_codigo": 70,
                  "s_descripcion": "ANALISTA"
                },
                {
                  "s_codigo": 71,
                  "s_descripcion": "CAJERO"
                },
                {
                  "s_codigo": 72,
                  "s_descripcion": "JEFE DE CREDITO"
                },
                {
                  "s_codigo": 73,
                  "s_descripcion": "OFICIAL DE PRUEBA"
                },
                {
                  "s_codigo": 74,
                  "s_descripcion": "DIGITADOR (A)"
                },
                {
                  "s_codigo": 75,
                  "s_descripcion": "SUBGERENTE"
                },
                {
                  "s_codigo": 76,
                  "s_descripcion": "INSTRUCTOR"
                },
                {
                  "s_codigo": 77,
                  "s_descripcion": "NEGOCIO"
                },
                {
                  "s_codigo": 78,
                  "s_descripcion": "ENFERMERA"
                },
                {
                  "s_codigo": 79,
                  "s_descripcion": "INFORMADOR (A)"
                },
                {
                  "s_codigo": 80,
                  "s_descripcion": "OFICIAL DE SERVICIOS"
                },
                {
                  "s_codigo": 81,
                  "s_descripcion": "INGENIERO"
                },
                {
                  "s_codigo": 82,
                  "s_descripcion": "TECNICO"
                },
                {
                  "s_codigo": 83,
                  "s_descripcion": "PROFESOR"
                },
                {
                  "s_codigo": 84,
                  "s_descripcion": "RETIRADO"
                },
                {
                  "s_codigo": 85,
                  "s_descripcion": "EJECUTIVO"
                },
                {
                  "s_codigo": 86,
                  "s_descripcion": "REVISOR"
                },
                {
                  "s_codigo": 87,
                  "s_descripcion": "JEFE DE OPERACIONES"
                },
                {
                  "s_codigo": 88,
                  "s_descripcion": "IMPRESOR"
                },
                {
                  "s_codigo": 89,
                  "s_descripcion": "ADMINISTRADOR"
                },
                {
                  "s_codigo": 90,
                  "s_descripcion": "EJERCITO"
                },
                {
                  "s_codigo": 91,
                  "s_descripcion": "ABOGADO"
                },
                {
                  "s_codigo": 92,
                  "s_descripcion": "CONSTRUCTOR"
                },
                {
                  "s_codigo": 94,
                  "s_descripcion": "CONTRALOR"
                },
                {
                  "s_codigo": 95,
                  "s_descripcion": "ALMACENISTA"
                },
                {
                  "s_codigo": 96,
                  "s_descripcion": "PRESIDENTE"
                },
                {
                  "s_codigo": 97,
                  "s_descripcion": "INSPECTOR"
                },
                {
                  "s_codigo": 98,
                  "s_descripcion": "JEFE DE ARCHIVO"
                },
                {
                  "s_codigo": 99,
                  "s_descripcion": "JEFE DE PROYECTO"
                },
                {
                  "s_codigo": 100,
                  "s_descripcion": "JEFE"
                },
                {
                  "s_codigo": 101,
                  "s_descripcion": "ELECTRICISTA"
                },
                {
                  "s_codigo": 102,
                  "s_descripcion": "BIBLIOTECOLOGO"
                },
                {
                  "s_codigo": 103,
                  "s_descripcion": "ASISTENTE"
                },
                {
                  "s_codigo": 104,
                  "s_descripcion": "AUXILIAR DE ARCHIVO"
                },
                {
                  "s_codigo": 105,
                  "s_descripcion": "GERENTE FINANCIERO"
                },
                {
                  "s_codigo": 106,
                  "s_descripcion": "GERENTE ADMINISTRATIVO"
                },
                {
                  "s_codigo": 107,
                  "s_descripcion": "PROFESIONAL"
                },
                {
                  "s_codigo": 108,
                  "s_descripcion": "PROPIETARIO"
                },
                {
                  "s_codigo": 109,
                  "s_descripcion": "SECRETARIA DE GERENCIA"
                },
                {
                  "s_codigo": 110,
                  "s_descripcion": "OFICIAL"
                },
                {
                  "s_codigo": 111,
                  "s_descripcion": "SOLDADOR"
                },
                {
                  "s_codigo": 112,
                  "s_descripcion": "SUBGERENTE ADMINISTRATIVO"
                },
                {
                  "s_codigo": 113,
                  "s_descripcion": "PROGRAMADOR"
                },
                {
                  "s_codigo": 114,
                  "s_descripcion": "ASESOR FINANCIERO"
                },
                {
                  "s_codigo": 115,
                  "s_descripcion": "CONSERGE"
                },
                {
                  "s_codigo": 116,
                  "s_descripcion": "CONSULTOR"
                },
                {
                  "s_codigo": 117,
                  "s_descripcion": "SICOLOGO"
                },
                {
                  "s_codigo": 118,
                  "s_descripcion": "PREPARADOR"
                },
                {
                  "s_codigo": 119,
                  "s_descripcion": "AUDITOR"
                },
                {
                  "s_codigo": 120,
                  "s_descripcion": "CONTROLADOR"
                },
                {
                  "s_codigo": 121,
                  "s_descripcion": "CUENTAS CORRIENTES"
                },
                {
                  "s_codigo": 122,
                  "s_descripcion": "ASISTENTE RECURSOS HUMANO"
                },
                {
                  "s_codigo": 123,
                  "s_descripcion": "VICEPRESIDENTE"
                },
                {
                  "s_codigo": 124,
                  "s_descripcion": "ENCUADERNADOR"
                },
                {
                  "s_codigo": 125,
                  "s_descripcion": "RELACIONISTA"
                },
                {
                  "s_codigo": 126,
                  "s_descripcion": "MULTIVENTAS"
                },
                {
                  "s_codigo": 127,
                  "s_descripcion": "ASESOR COMERCIAL"
                },
                {
                  "s_codigo": 128,
                  "s_descripcion": "LIQUIDADOR"
                },
                {
                  "s_codigo": 129,
                  "s_descripcion": "TERMINALISTA"
                },
                {
                  "s_codigo": 130,
                  "s_descripcion": "TRANSCRIPTOR (A)"
                },
                {
                  "s_codigo": 131,
                  "s_descripcion": "PERIODISTA"
                },
                {
                  "s_codigo": 132,
                  "s_descripcion": "TORNERO"
                },
                {
                  "s_codigo": 133,
                  "s_descripcion": "AGENTE TRANSITO"
                },
                {
                  "s_codigo": 134,
                  "s_descripcion": "SUBDIRECTOR"
                },
                {
                  "s_codigo": 135,
                  "s_descripcion": "PROMOTOR"
                },
                {
                  "s_codigo": 136,
                  "s_descripcion": "TRASPORTADOR"
                },
                {
                  "s_codigo": 137,
                  "s_descripcion": "ADUANAS"
                },
                {
                  "s_codigo": 138,
                  "s_descripcion": "ODONTOLOGO"
                },
                {
                  "s_codigo": 139,
                  "s_descripcion": "PAGADOR"
                },
                {
                  "s_codigo": 140,
                  "s_descripcion": "EDITORISTA"
                },
                {
                  "s_codigo": 141,
                  "s_descripcion": "TIQUETERO"
                },
                {
                  "s_codigo": 142,
                  "s_descripcion": "REPRESENTANTE"
                },
                {
                  "s_codigo": 143,
                  "s_descripcion": "MERCADEO"
                },
                {
                  "s_codigo": 144,
                  "s_descripcion": "GIROS Y REMESAS"
                },
                {
                  "s_codigo": 145,
                  "s_descripcion": "GERENTE REGIONAL"
                },
                {
                  "s_codigo": 146,
                  "s_descripcion": "AUXILIAR DEPARTAMENTO EXT"
                },
                {
                  "s_codigo": 147,
                  "s_descripcion": "AUDITORIA INTERNA"
                },
                {
                  "s_codigo": 148,
                  "s_descripcion": "AUDITORIA EXTERNA"
                },
                {
                  "s_codigo": 149,
                  "s_descripcion": "DISENADOR"
                },
                {
                  "s_codigo": 150,
                  "s_descripcion": "AUXILIAR DE SISTEMAS"
                },
                {
                  "s_codigo": 151,
                  "s_descripcion": "COBRADOR"
                },
                {
                  "s_codigo": 152,
                  "s_descripcion": "PLOMERO"
                },
                {
                  "s_codigo": 153,
                  "s_descripcion": "SECRETARIA GERENCIA CREDI"
                },
                {
                  "s_codigo": 154,
                  "s_descripcion": "DESARROLLADOR MASTER"
                },
                {
                  "s_codigo": 155,
                  "s_descripcion": "INVESTIGADOR"
                },
                {
                  "s_codigo": 156,
                  "s_descripcion": "SECRETARIA EJECUTIVA"
                },
                {
                  "s_codigo": 157,
                  "s_descripcion": "OPERADOR (A)"
                },
                {
                  "s_codigo": 158,
                  "s_descripcion": "DOCUMENTADOR"
                },
                {
                  "s_codigo": 159,
                  "s_descripcion": "DIRECTOR PROYECTO"
                },
                {
                  "s_codigo": 160,
                  "s_descripcion": "GERENTE COMERCIAL"
                },
                {
                  "s_codigo": 161,
                  "s_descripcion": "FACTURADOR"
                },
                {
                  "s_codigo": 162,
                  "s_descripcion": "EMPLEADO"
                },
                {
                  "s_codigo": 163,
                  "s_descripcion": "COMISIONISTA"
                },
                {
                  "s_codigo": 164,
                  "s_descripcion": "GRUPO APOYO"
                },
                {
                  "s_codigo": 165,
                  "s_descripcion": "CONFORMADOR"
                },
                {
                  "s_codigo": 166,
                  "s_descripcion": "CONTRATISTA"
                },
                {
                  "s_codigo": 167,
                  "s_descripcion": "INSTALADOR"
                },
                {
                  "s_codigo": 168,
                  "s_descripcion": "GERENTE JURIDICO"
                },
                {
                  "s_codigo": 169,
                  "s_descripcion": "PAGADURIA"
                },
                {
                  "s_codigo": 170,
                  "s_descripcion": "INGENIERO TRAFICO"
                },
                {
                  "s_codigo": 171,
                  "s_descripcion": "MEDICO"
                },
                {
                  "s_codigo": 172,
                  "s_descripcion": "INGENIERO SISTEMAS"
                },
                {
                  "s_codigo": 173,
                  "s_descripcion": "BOVEDA"
                },
                {
                  "s_codigo": 174,
                  "s_descripcion": "AUXILIAR ADMINISTRATIVO"
                },
                {
                  "s_codigo": 175,
                  "s_descripcion": "GRADO 17"
                },
                {
                  "s_codigo": 176,
                  "s_descripcion": "PRENSISTA"
                },
                {
                  "s_codigo": 177,
                  "s_descripcion": "EDITOR"
                },
                {
                  "s_codigo": 178,
                  "s_descripcion": "DPTO EXTRANJEROS"
                },
                {
                  "s_codigo": 179,
                  "s_descripcion": "DELINEANTE"
                },
                {
                  "s_codigo": 180,
                  "s_descripcion": "INSTRUMENTISTA"
                },
                {
                  "s_codigo": 181,
                  "s_descripcion": "ENSAMBLADOR"
                },
                {
                  "s_codigo": 182,
                  "s_descripcion": "GEOLOGO"
                },
                {
                  "s_codigo": 183,
                  "s_descripcion": "PROYECTOS"
                },
                {
                  "s_codigo": 184,
                  "s_descripcion": "INTERVENTOR"
                },
                {
                  "s_codigo": 185,
                  "s_descripcion": "ESTUDIANTE"
                },
                {
                  "s_codigo": 186,
                  "s_descripcion": "REDACTORA"
                },
                {
                  "s_codigo": 187,
                  "s_descripcion": "PINTOR"
                },
                {
                  "s_codigo": 188,
                  "s_descripcion": "FOTOGRAFO PROFESIONAL"
                },
                {
                  "s_codigo": 189,
                  "s_descripcion": "AGENTE"
                },
                {
                  "s_codigo": 190,
                  "s_descripcion": "PREVENDEDOR"
                },
                {
                  "s_codigo": 191,
                  "s_descripcion": "BACTERIOLOGO"
                },
                {
                  "s_codigo": 192,
                  "s_descripcion": "ENCARGADO"
                },
                {
                  "s_codigo": 193,
                  "s_descripcion": "RECEPCION VEHICULOS"
                },
                {
                  "s_codigo": 194,
                  "s_descripcion": "SECRETARIA BILINGUE"
                },
                {
                  "s_codigo": 195,
                  "s_descripcion": "AUXILIAR ODONTOLOGIA"
                },
                {
                  "s_codigo": 196,
                  "s_descripcion": "TELEMERCADERISTA"
                },
                {
                  "s_codigo": 197,
                  "s_descripcion": "TRABAJADORA SOCIAL"
                },
                {
                  "s_codigo": 198,
                  "s_descripcion": "RECAUDADOR"
                },
                {
                  "s_codigo": 199,
                  "s_descripcion": "HOGAR"
                },
                {
                  "s_codigo": 200,
                  "s_descripcion": "GUIA TURISTICO"
                },
                {
                  "s_codigo": 201,
                  "s_descripcion": "USUARIO"
                },
                {
                  "s_codigo": 202,
                  "s_descripcion": "CONTRAMAESTRE"
                },
                {
                  "s_codigo": 203,
                  "s_descripcion": "ECONOMISTA"
                },
                {
                  "s_codigo": 204,
                  "s_descripcion": "DESPACHADOR"
                },
                {
                  "s_codigo": 205,
                  "s_descripcion": "ADJUNTO"
                },
                {
                  "s_codigo": 206,
                  "s_descripcion": "SECRETARIO JURIDICO"
                },
                {
                  "s_codigo": 207,
                  "s_descripcion": "TAPICERO"
                },
                {
                  "s_codigo": 208,
                  "s_descripcion": "DIRECTOR DE VENTAS"
                },
                {
                  "s_codigo": 209,
                  "s_descripcion": "DIRECTOR DE ALOJAMIENTO"
                },
                {
                  "s_codigo": 210,
                  "s_descripcion": "JEFE DE LAVANDERIA"
                },
                {
                  "s_codigo": 211,
                  "s_descripcion": "INGENIERO MECANICO"
                },
                {
                  "s_codigo": 212,
                  "s_descripcion": "DIRECTOR CREATIVO"
                },
                {
                  "s_codigo": 213,
                  "s_descripcion": "DIAGRAMADOR"
                },
                {
                  "s_codigo": 214,
                  "s_descripcion": "DIAGRAMADORA"
                },
                {
                  "s_codigo": 215,
                  "s_descripcion": "INGENIERO CONSULTOR"
                },
                {
                  "s_codigo": 216,
                  "s_descripcion": "PORTERO"
                },
                {
                  "s_codigo": 217,
                  "s_descripcion": "ESTRUCTURERO"
                },
                {
                  "s_codigo": 218,
                  "s_descripcion": "ARCHIVADOR (A)"
                },
                {
                  "s_codigo": 219,
                  "s_descripcion": "SOLADOR (A)"
                },
                {
                  "s_codigo": 220,
                  "s_descripcion": "TERMINADOR (A)"
                },
                {
                  "s_codigo": 221,
                  "s_descripcion": "GUARNECEDOR (A)"
                },
                {
                  "s_codigo": 222,
                  "s_descripcion": "MODELISTA"
                },
                {
                  "s_codigo": 223,
                  "s_descripcion": "ASISTENTE DE IMPUESTOS"
                },
                {
                  "s_codigo": 224,
                  "s_descripcion": "ASISTENTE SUBGERENTE"
                },
                {
                  "s_codigo": 225,
                  "s_descripcion": "JEFE DE TALLER"
                },
                {
                  "s_codigo": 226,
                  "s_descripcion": "AUXILIAR ALISTAMIENTO"
                },
                {
                  "s_codigo": 227,
                  "s_descripcion": "PERITO"
                },
                {
                  "s_codigo": 228,
                  "s_descripcion": "AUXILIAR LAVADOR"
                },
                {
                  "s_codigo": 229,
                  "s_descripcion": "MONTAJISTA"
                },
                {
                  "s_codigo": 230,
                  "s_descripcion": "DIRECTOR DE PROYECTO"
                },
                {
                  "s_codigo": 231,
                  "s_descripcion": "DIRECTOR DE SOPORTE"
                },
                {
                  "s_codigo": 232,
                  "s_descripcion": "COORDINADOR DE EXPORTACIO"
                },
                {
                  "s_codigo": 233,
                  "s_descripcion": "AUXILIAR ALBAYILERIA"
                },
                {
                  "s_codigo": 234,
                  "s_descripcion": "MECANICO AUTOMOTRIZ"
                },
                {
                  "s_codigo": 235,
                  "s_descripcion": "PINTOR AUTOMOTRIZ"
                },
                {
                  "s_codigo": 236,
                  "s_descripcion": "NUTRICIONISTA"
                },
                {
                  "s_codigo": 237,
                  "s_descripcion": "ASESOR TECNICO"
                },
                {
                  "s_codigo": 238,
                  "s_descripcion": "DIRECTOR NACIONAL"
                },
                {
                  "s_codigo": 239,
                  "s_descripcion": "JEFE DE MERCADEO"
                },
                {
                  "s_codigo": 240,
                  "s_descripcion": "RESIDENTE DE OBRA"
                },
                {
                  "s_codigo": 241,
                  "s_descripcion": "ARQUITECTO RESIDENTE"
                },
                {
                  "s_codigo": 242,
                  "s_descripcion": "EJECUTIVO DE VENTAS"
                },
                {
                  "s_codigo": 243,
                  "s_descripcion": "AUXILIAR DE DIVISION"
                },
                {
                  "s_codigo": 244,
                  "s_descripcion": "ASISTENTE DE DIVISION"
                },
                {
                  "s_codigo": 245,
                  "s_descripcion": "COORDINADOR DE DIVISION"
                },
                {
                  "s_codigo": 246,
                  "s_descripcion": "DIRECTOR DE MERCADEO"
                },
                {
                  "s_codigo": 247,
                  "s_descripcion": "ATENCION A CLIENTES"
                },
                {
                  "s_codigo": 248,
                  "s_descripcion": "OPERARIO PRODUCCION"
                },
                {
                  "s_codigo": 249,
                  "s_descripcion": "MECANICO INDUSTRIAL"
                },
                {
                  "s_codigo": 250,
                  "s_descripcion": "OPERARIO DE MANTENIMIENTO"
                },
                {
                  "s_codigo": 251,
                  "s_descripcion": "INGENIERO DE VENTAS"
                },
                {
                  "s_codigo": 252,
                  "s_descripcion": "COORDINADOR MERCHADISING"
                },
                {
                  "s_codigo": 253,
                  "s_descripcion": "PROMOTOR DE VENTAS"
                },
                {
                  "s_codigo": 254,
                  "s_descripcion": "ASISTENTE DE SISTEMAS"
                },
                {
                  "s_codigo": 255,
                  "s_descripcion": "STOCKER ALMACEN"
                },
                {
                  "s_codigo": 256,
                  "s_descripcion": "ESPECIAL. MAQ. Y COMP."
                },
                {
                  "s_codigo": 257,
                  "s_descripcion": "AUXILIAR TELEMERCADEO"
                },
                {
                  "s_codigo": 258,
                  "s_descripcion": "FRONT &amp; END ALMACEN"
                },
                {
                  "s_codigo": 259,
                  "s_descripcion": "ASISTENTE OPERATIVO"
                },
                {
                  "s_codigo": 260,
                  "s_descripcion": "JEFE RECIBO ALMACEN"
                },
                {
                  "s_codigo": 261,
                  "s_descripcion": "RECOLECTOR TELEMERCADEO"
                },
                {
                  "s_codigo": 262,
                  "s_descripcion": "RECEPTOR DELIVERY"
                },
                {
                  "s_codigo": 263,
                  "s_descripcion": "GERENTE DE RECURSOS HUMAN"
                },
                {
                  "s_codigo": 264,
                  "s_descripcion": "SECRETARIA DE GERENCIA"
                },
                {
                  "s_codigo": 265,
                  "s_descripcion": "ANALISTA DE AUDITORIA"
                },
                {
                  "s_codigo": 270,
                  "s_descripcion": "AUXILIAR DE VENTAS"
                },
                {
                  "s_codigo": 271,
                  "s_descripcion": "CAFETERIA"
                },
                {
                  "s_codigo": 298,
                  "s_descripcion": "SUBOFICIAL"
                },
                {
                  "s_codigo": 299,
                  "s_descripcion": "BODEGUERO"
                },
                {
                  "s_codigo": 300,
                  "s_descripcion": "ROTULADOR"
                },
                {
                  "s_codigo": 301,
                  "s_descripcion": "TROQUELADOR"
                },
                {
                  "s_codigo": 302,
                  "s_descripcion": "ANALISTA COORDINADOR"
                },
                {
                  "s_codigo": 303,
                  "s_descripcion": "APRENDIZ SENA"
                },
                {
                  "s_codigo": 304,
                  "s_descripcion": "AUXILIAR DE BOVEDA"
                },
                {
                  "s_codigo": 305,
                  "s_descripcion": "AUXILIAR DE CENTRO"
                },
                {
                  "s_codigo": 306,
                  "s_descripcion": "AUXILIAR/OFICIAL DE OPERA"
                },
                {
                  "s_codigo": 307,
                  "s_descripcion": "CONTRATO A TERMINO FIJO"
                },
                {
                  "s_codigo": 308,
                  "s_descripcion": "COORDINARORA EVENTOS SOCI"
                },
                {
                  "s_codigo": 309,
                  "s_descripcion": "ESTUDIANTE EN PRACTICA"
                },
                {
                  "s_codigo": 310,
                  "s_descripcion": "JEFE DE DEPARTAMENTO"
                },
                {
                  "s_codigo": 311,
                  "s_descripcion": "JEFE DE SECCION"
                },
                {
                  "s_codigo": 312,
                  "s_descripcion": "JEFE/SUBGERENTE COMERCIAL"
                },
                {
                  "s_codigo": 313,
                  "s_descripcion": "JEFE/SUBGERENTE DE CREDIT"
                },
                {
                  "s_codigo": 314,
                  "s_descripcion": "JEFE/SUBGERENTE INTERNACI"
                },
                {
                  "s_codigo": 315,
                  "s_descripcion": "JEFE/SUBGTE.ADTIVO./SECRE"
                },
                {
                  "s_codigo": 316,
                  "s_descripcion": "ANALISTA III"
                },
                {
                  "s_codigo": 317,
                  "s_descripcion": "OFICIAL/AUX.DE VERIFICACI"
                },
                {
                  "s_codigo": 318,
                  "s_descripcion": "OPERADOR DE COMPUTADOR"
                },
                {
                  "s_codigo": 319,
                  "s_descripcion": "OPERADOR DE PERIFERICOS"
                },
                {
                  "s_codigo": 320,
                  "s_descripcion": "PERSONAL DISPONIBLE"
                },
                {
                  "s_codigo": 321,
                  "s_descripcion": "PERSONAL EN EL EXTERIOR"
                },
                {
                  "s_codigo": 322,
                  "s_descripcion": "PERSONAL EN ENTRENAMIENTO"
                },
                {
                  "s_codigo": 323,
                  "s_descripcion": "SUBCONTROLADOR GENERAL"
                },
                {
                  "s_codigo": 324,
                  "s_descripcion": "SUBJEFE DEPTO.DE SEGURIDA"
                },
                {
                  "s_codigo": 325,
                  "s_descripcion": "VISADOR/CUENTACORRENTISTA"
                },
                {
                  "s_codigo": 326,
                  "s_descripcion": "DIR. ADMINISTRATIVO Y FIN"
                },
                {
                  "s_codigo": 327,
                  "s_descripcion": "GERENTE DE SALARIOS Y BEN"
                },
                {
                  "s_codigo": 328,
                  "s_descripcion": "GERENTE GENERAL"
                },
                {
                  "s_codigo": 329,
                  "s_descripcion": "DIRECTOR DE PERSONAL"
                },
                {
                  "s_codigo": 330,
                  "s_descripcion": "GERENTE ADMNINISTRATIVO Y"
                },
                {
                  "s_codigo": 331,
                  "s_descripcion": "VICEPRESIDENTE ADMINISTR"
                },
                {
                  "s_codigo": 332,
                  "s_descripcion": "COORDINADOR DE RECURSOS H"
                },
                {
                  "s_codigo": 333,
                  "s_descripcion": "DIRECTOR GENERAL"
                },
                {
                  "s_codigo": 334,
                  "s_descripcion": "JEFE DE PERSONAL"
                },
                {
                  "s_codigo": 335,
                  "s_descripcion": "ADMINISTRADOR GENERAL"
                },
                {
                  "s_codigo": 336,
                  "s_descripcion": "ASISTENTE DE NOMINA"
                },
                {
                  "s_codigo": 337,
                  "s_descripcion": "JEFE DE NOMINA"
                },
                {
                  "s_codigo": 338,
                  "s_descripcion": "CAJERO LIQUIDADOR"
                },
                {
                  "s_codigo": 339,
                  "s_descripcion": "SUPERNUMERARIO III"
                },
                {
                  "s_codigo": 340,
                  "s_descripcion": "JEFE DE RECURSOS HUMANOS"
                },
                {
                  "s_codigo": 341,
                  "s_descripcion": "ANALISTA I"
                },
                {
                  "s_codigo": 342,
                  "s_descripcion": "COORDINADOR ADMINISTRATIV"
                },
                {
                  "s_codigo": 343,
                  "s_descripcion": "ASESOR II"
                },
                {
                  "s_codigo": 344,
                  "s_descripcion": "DIRECTOR RELACIONES INDUS"
                },
                {
                  "s_codigo": 345,
                  "s_descripcion": "ASISTENTE ADMINISTRATIVO"
                },
                {
                  "s_codigo": 346,
                  "s_descripcion": "DIRECTOR DE RECURSOS HUMA"
                },
                {
                  "s_codigo": 347,
                  "s_descripcion": "VICEPRESIDENTE JURIDICO A"
                },
                {
                  "s_codigo": 348,
                  "s_descripcion": "AUXILIAR SEV. GENERALES"
                },
                {
                  "s_codigo": 349,
                  "s_descripcion": "INGENIERO DE SOPORTE"
                },
                {
                  "s_codigo": 350,
                  "s_descripcion": "SECRETARIA GENERAL"
                },
                {
                  "s_codigo": 351,
                  "s_descripcion": "ANALISTA PROGRAMADOR"
                },
                {
                  "s_codigo": 352,
                  "s_descripcion": "GERENTE DESARROLLO"
                },
                {
                  "s_codigo": 353,
                  "s_descripcion": "JEFE DE TRANSPORTES"
                },
                {
                  "s_codigo": 354,
                  "s_descripcion": "JEFE DE DESARROLLO"
                },
                {
                  "s_codigo": 355,
                  "s_descripcion": "PREPARADOR DE PEDIDOS"
                },
                {
                  "s_codigo": 356,
                  "s_descripcion": "OPERARIO DE MONTACARGA"
                },
                {
                  "s_codigo": 357,
                  "s_descripcion": "SUPERVISOR DE BODEGA"
                },
                {
                  "s_codigo": 358,
                  "s_descripcion": "COORDINADOR COMERCIO EXTE"
                },
                {
                  "s_codigo": 359,
                  "s_descripcion": "SECRETARIA DIRECCION"
                },
                {
                  "s_codigo": 360,
                  "s_descripcion": "AGENTE DE SEGURIDAD"
                },
                {
                  "s_codigo": 400,
                  "s_descripcion": "SUPERVISOR DE DESPACHOS"
                },
                {
                  "s_codigo": 401,
                  "s_descripcion": "SUPERVISOR DE MERCADEO"
                },
                {
                  "s_codigo": 402,
                  "s_descripcion": "JEFE DE BODEGA"
                },
                {
                  "s_codigo": 403,
                  "s_descripcion": "SUPERVISOR DE PRODUCCION"
                },
                {
                  "s_codigo": 404,
                  "s_descripcion": "COORDINADOR VENTAS"
                },
                {
                  "s_codigo": 405,
                  "s_descripcion": "AUXILIAR DE SERVICIOS GEN"
                },
                {
                  "s_codigo": 406,
                  "s_descripcion": "ASISTENTE OFICINA DE PREN"
                },
                {
                  "s_codigo": 407,
                  "s_descripcion": "ASISTENTE PLANEACION"
                },
                {
                  "s_codigo": 408,
                  "s_descripcion": "INGENIERO DE PRODUCCION"
                },
                {
                  "s_codigo": 409,
                  "s_descripcion": "COMPRADOR"
                },
                {
                  "s_codigo": 410,
                  "s_descripcion": "ASISTENTE PROGRAMACION"
                },
                {
                  "s_codigo": 411,
                  "s_descripcion": "ASISTENTE DE FACTURACION"
                },
                {
                  "s_codigo": 412,
                  "s_descripcion": "REVISOR FISCAL"
                },
                {
                  "s_codigo": 413,
                  "s_descripcion": "JEFE DE COMERCIO EXTERIOR"
                },
                {
                  "s_codigo": 414,
                  "s_descripcion": "ANALISTA DE SEGUROS"
                },
                {
                  "s_codigo": 415,
                  "s_descripcion": "AUXILIAR DE PERSONAL"
                },
                {
                  "s_codigo": 416,
                  "s_descripcion": "JEFE DE SISTEMAS"
                },
                {
                  "s_codigo": 417,
                  "s_descripcion": "JEFE DE CONTABILIDAD"
                },
                {
                  "s_codigo": 418,
                  "s_descripcion": "ASISTENTE DE VENTAS"
                },
                {
                  "s_codigo": 419,
                  "s_descripcion": "NEGOCIANTE"
                },
                {
                  "s_codigo": 420,
                  "s_descripcion": "JEFE DIVISION ORGANIZACIO"
                },
                {
                  "s_codigo": 421,
                  "s_descripcion": "COORDINADOR CORRESPONDENC"
                },
                {
                  "s_codigo": 422,
                  "s_descripcion": "ANALISTA CREDITOS Y CARTE"
                },
                {
                  "s_codigo": 423,
                  "s_descripcion": "AUXILIAR DE CORRESPONDENC"
                },
                {
                  "s_codigo": 424,
                  "s_descripcion": "INGENIERO RESIDENTE"
                },
                {
                  "s_codigo": 425,
                  "s_descripcion": "ANALISTA II"
                },
                {
                  "s_codigo": 426,
                  "s_descripcion": "ABOGADO I"
                },
                {
                  "s_codigo": 427,
                  "s_descripcion": "ASISTENTE DE MERCADEO"
                },
                {
                  "s_codigo": 428,
                  "s_descripcion": "ASISTENTE DE SERVICIO AL"
                },
                {
                  "s_codigo": 429,
                  "s_descripcion": "AUXILIAR I"
                },
                {
                  "s_codigo": 430,
                  "s_descripcion": "AUXILIAR I DE OFICINA"
                },
                {
                  "s_codigo": 431,
                  "s_descripcion": "AUXILIAR II"
                },
                {
                  "s_codigo": 432,
                  "s_descripcion": "AUXILIAR II DE OFICINA"
                },
                {
                  "s_codigo": 433,
                  "s_descripcion": "AUXILIAR III"
                },
                {
                  "s_codigo": 434,
                  "s_descripcion": "ABOGADO II"
                },
                {
                  "s_codigo": 435,
                  "s_descripcion": "CAJERO I"
                },
                {
                  "s_codigo": 436,
                  "s_descripcion": "CAJERO I J.A."
                },
                {
                  "s_codigo": 437,
                  "s_descripcion": "CAJERO II"
                },
                {
                  "s_codigo": 438,
                  "s_descripcion": "CAJERO II J.A."
                },
                {
                  "s_codigo": 439,
                  "s_descripcion": "CONSULTOR DE NEGOCIOS"
                },
                {
                  "s_codigo": 440,
                  "s_descripcion": "DIRECTOR DE AREA"
                },
                {
                  "s_codigo": 441,
                  "s_descripcion": "EJECUTIVA COMERCIAL"
                },
                {
                  "s_codigo": 442,
                  "s_descripcion": "EJECUTIVA DE CUENTA"
                },
                {
                  "s_codigo": 443,
                  "s_descripcion": "GERENTE DE BANCA CORPORAC"
                },
                {
                  "s_codigo": 444,
                  "s_descripcion": "GERENTE BANCA ESTATAL"
                },
                {
                  "s_codigo": 445,
                  "s_descripcion": "GERENTE DE AREA"
                },
                {
                  "s_codigo": 446,
                  "s_descripcion": "GERENTE DE ZONA"
                },
                {
                  "s_codigo": 447,
                  "s_descripcion": "GERENTE DE MERCADEO EMPRE"
                },
                {
                  "s_codigo": 448,
                  "s_descripcion": "GERENTE SUPERNUMERARIO"
                },
                {
                  "s_codigo": 449,
                  "s_descripcion": "GERENTE ZONA MERCADEO.E"
                },
                {
                  "s_codigo": 450,
                  "s_descripcion": "JEFE DE DISE?O"
                },
                {
                  "s_codigo": 451,
                  "s_descripcion": "GERENTE DE ZONA MERC. F"
                },
                {
                  "s_codigo": 452,
                  "s_descripcion": "ADMINISTRADOR DE OBRAS"
                },
                {
                  "s_codigo": 453,
                  "s_descripcion": "DIRECTOR DE OBRAS"
                },
                {
                  "s_codigo": 454,
                  "s_descripcion": "INFORMADOR ASISTENTE J.A."
                },
                {
                  "s_codigo": 455,
                  "s_descripcion": "INFORMADOR CAJERO A"
                },
                {
                  "s_codigo": 456,
                  "s_descripcion": "INFORMADOR COMERCIAL"
                },
                {
                  "s_codigo": 457,
                  "s_descripcion": "INFORMADORA J.A."
                },
                {
                  "s_codigo": 458,
                  "s_descripcion": "INSPECTOR DE CAJA"
                },
                {
                  "s_codigo": 459,
                  "s_descripcion": "INSTRUCTOR CAPACITACION"
                },
                {
                  "s_codigo": 500,
                  "s_descripcion": "JEFE DESARROLLO PRODUCTO"
                },
                {
                  "s_codigo": 501,
                  "s_descripcion": "JEFE INV. DE MERCADEO"
                },
                {
                  "s_codigo": 502,
                  "s_descripcion": "OPERADOR CENTRO DE C"
                },
                {
                  "s_codigo": 503,
                  "s_descripcion": "PRACTICANTE"
                },
                {
                  "s_codigo": 504,
                  "s_descripcion": "SECRETARIA CONTRALORIA"
                },
                {
                  "s_codigo": 505,
                  "s_descripcion": "SECRETARIA DE DEPARTAMENT"
                },
                {
                  "s_codigo": 506,
                  "s_descripcion": "SECRETARIA GER. DE ZONA"
                },
                {
                  "s_codigo": 507,
                  "s_descripcion": "SECRETARIA SUPERNUMERARIA"
                },
                {
                  "s_codigo": 508,
                  "s_descripcion": "SECRETARIA DE VICEPRESIDE"
                },
                {
                  "s_codigo": 509,
                  "s_descripcion": "ASESOR III"
                },
                {
                  "s_codigo": 510,
                  "s_descripcion": "SUBJEFE DE DEPARTAMENTO"
                },
                {
                  "s_codigo": 511,
                  "s_descripcion": "SUPERNUMERARIO II"
                },
                {
                  "s_codigo": 512,
                  "s_descripcion": "SUPERNUMERARIO J.A"
                },
                {
                  "s_codigo": 513,
                  "s_descripcion": "TECNICO DE MANTENIMIENTO"
                },
                {
                  "s_codigo": 514,
                  "s_descripcion": "TECNICO DE MONITOREO"
                },
                {
                  "s_codigo": 515,
                  "s_descripcion": "ADMINISTRADOR DE SISTEMAS"
                },
                {
                  "s_codigo": 516,
                  "s_descripcion": "ASESOR IV"
                },
                {
                  "s_codigo": 517,
                  "s_descripcion": "ASESOR V"
                },
                {
                  "s_codigo": 518,
                  "s_descripcion": "ASESOR VI"
                },
                {
                  "s_codigo": 519,
                  "s_descripcion": "ASISTENTE I"
                },
                {
                  "s_codigo": 520,
                  "s_descripcion": "ASISTENTE II"
                },
                {
                  "s_codigo": 521,
                  "s_descripcion": "ASISTENTE TECNICO IV"
                },
                {
                  "s_codigo": 522,
                  "s_descripcion": "AUXILIAR DE CENTRO I"
                },
                {
                  "s_codigo": 523,
                  "s_descripcion": "AUXILIAR DE VACACIONES"
                },
                {
                  "s_codigo": 524,
                  "s_descripcion": "AUXILIAR DE VERIFICACION"
                },
                {
                  "s_codigo": 525,
                  "s_descripcion": "CAJERO PRINCIPAL III"
                },
                {
                  "s_codigo": 526,
                  "s_descripcion": "CAJERO UNIVERSAL"
                },
                {
                  "s_codigo": 527,
                  "s_descripcion": "CONTRALOR II"
                },
                {
                  "s_codigo": 528,
                  "s_descripcion": "DIRECTOR-REGION NACI"
                },
                {
                  "s_codigo": 529,
                  "s_descripcion": "GERENTE I-ZONA.D.G"
                },
                {
                  "s_codigo": 530,
                  "s_descripcion": "GERENTE II"
                },
                {
                  "s_codigo": 531,
                  "s_descripcion": "GERENTE II DE CUENTA"
                },
                {
                  "s_codigo": 532,
                  "s_descripcion": "GERENTE II-ZONA D.G."
                },
                {
                  "s_codigo": 533,
                  "s_descripcion": "GERENTE III"
                },
                {
                  "s_codigo": 534,
                  "s_descripcion": "GERENTE IV"
                },
                {
                  "s_codigo": 535,
                  "s_descripcion": "GERENTE V"
                },
                {
                  "s_codigo": 536,
                  "s_descripcion": "JEFE COMERCIAL III"
                },
                {
                  "s_codigo": 537,
                  "s_descripcion": "JEFE DE CREDITO II"
                },
                {
                  "s_codigo": 538,
                  "s_descripcion": "JEFE DE SECCION I"
                },
                {
                  "s_codigo": 539,
                  "s_descripcion": "JEFE DE SECCION II"
                },
                {
                  "s_codigo": 540,
                  "s_descripcion": "JEFE II-ZONA CENTRO"
                },
                {
                  "s_codigo": 541,
                  "s_descripcion": "JEFE III-CENTRO"
                },
                {
                  "s_codigo": 542,
                  "s_descripcion": "JEFE V-CENTRO"
                },
                {
                  "s_codigo": 543,
                  "s_descripcion": "JEFE VI-CENTRO"
                },
                {
                  "s_codigo": 544,
                  "s_descripcion": "OFICIAL DE OPERACION"
                },
                {
                  "s_codigo": 545,
                  "s_descripcion": "OFICIAL I"
                },
                {
                  "s_codigo": 546,
                  "s_descripcion": "OFICIAL II"
                },
                {
                  "s_codigo": 547,
                  "s_descripcion": "OFICIAL III"
                },
                {
                  "s_codigo": 548,
                  "s_descripcion": "OPERADOR I DE COMPUTADOR"
                },
                {
                  "s_codigo": 549,
                  "s_descripcion": "OPERADOR II"
                },
                {
                  "s_codigo": 550,
                  "s_descripcion": "PROYECTOS ESPECIALES"
                },
                {
                  "s_codigo": 551,
                  "s_descripcion": "SECRETARIA I"
                },
                {
                  "s_codigo": 552,
                  "s_descripcion": "SECRETARIA II"
                },
                {
                  "s_codigo": 553,
                  "s_descripcion": "SECRETARIA III"
                },
                {
                  "s_codigo": 554,
                  "s_descripcion": "SECRETARIA IV"
                },
                {
                  "s_codigo": 555,
                  "s_descripcion": "SECRETARIA V"
                },
                {
                  "s_codigo": 556,
                  "s_descripcion": "SECRETARIO III"
                },
                {
                  "s_codigo": 557,
                  "s_descripcion": "SUBGERENTE COMERCIAL"
                },
                {
                  "s_codigo": 558,
                  "s_descripcion": "SUBGERENTE DE CREDITO"
                },
                {
                  "s_codigo": 559,
                  "s_descripcion": "SUBGERENTE DE INTERN"
                },
                {
                  "s_codigo": 560,
                  "s_descripcion": "SUPERNUMERARIO I"
                },
                {
                  "s_codigo": 561,
                  "s_descripcion": "SUPERNUMERARIO IV"
                },
                {
                  "s_codigo": 562,
                  "s_descripcion": "SUPERNUMERARIO IX"
                },
                {
                  "s_codigo": 563,
                  "s_descripcion": "SUPERNUMERARIO V"
                },
                {
                  "s_codigo": 564,
                  "s_descripcion": "SUPERNUMERARIO VI"
                },
                {
                  "s_codigo": 565,
                  "s_descripcion": "SUPERNUMERARIO VII"
                },
                {
                  "s_codigo": 566,
                  "s_descripcion": "SUPERNUMERARIO VIII"
                },
                {
                  "s_codigo": 567,
                  "s_descripcion": "SUPERVISOR DE TURNO"
                },
                {
                  "s_codigo": 568,
                  "s_descripcion": "SUPERVISOR I"
                },
                {
                  "s_codigo": 569,
                  "s_descripcion": "AUXILIAR DE ASEO"
                },
                {
                  "s_codigo": 570,
                  "s_descripcion": "JEFE DEPARTAMENTO COMERCI"
                },
                {
                  "s_codigo": 571,
                  "s_descripcion": "AUXILIAR DE PRUEBA"
                },
                {
                  "s_codigo": 572,
                  "s_descripcion": "OPERARIO II"
                },
                {
                  "s_codigo": 573,
                  "s_descripcion": "AUXILIAR SOPORTE"
                },
                {
                  "s_codigo": 574,
                  "s_descripcion": "ANALISTA DE CUENTAS"
                },
                {
                  "s_codigo": 575,
                  "s_descripcion": "AUXILIAR DE INGRESOS"
                },
                {
                  "s_codigo": 576,
                  "s_descripcion": "AUXILIAR DE PAGOS"
                },
                {
                  "s_codigo": 577,
                  "s_descripcion": "ANALISTA DE PAGOS"
                },
                {
                  "s_codigo": 578,
                  "s_descripcion": "AUXILIAR DE CONTRATOS"
                },
                {
                  "s_codigo": 579,
                  "s_descripcion": "JEFE DIVISION ADMINISTRAT"
                },
                {
                  "s_codigo": 580,
                  "s_descripcion": "AUXILIAR OPERATIVO"
                },
                {
                  "s_codigo": 581,
                  "s_descripcion": "ASISTENTE DE SALUD"
                },
                {
                  "s_codigo": 582,
                  "s_descripcion": "SUBGERENTE TECNICO"
                },
                {
                  "s_codigo": 583,
                  "s_descripcion": "AUXILIAR TECNICO"
                },
                {
                  "s_codigo": 584,
                  "s_descripcion": "DIRECTOR UNIDAD"
                },
                {
                  "s_codigo": 585,
                  "s_descripcion": "DIRECTOR DE CONTABILIDAD"
                },
                {
                  "s_codigo": 586,
                  "s_descripcion": "ASESORIAS TECNICAS"
                },
                {
                  "s_codigo": 587,
                  "s_descripcion": "DIRECTOR TECNICO"
                },
                {
                  "s_codigo": 588,
                  "s_descripcion": "ADMINISTRADOR SUBCUENTA"
                },
                {
                  "s_codigo": 589,
                  "s_descripcion": "JEFE SUBCUENTA"
                },
                {
                  "s_codigo": 590,
                  "s_descripcion": "ASISTENTE DE OPERACIONES"
                },
                {
                  "s_codigo": 591,
                  "s_descripcion": "DIRECTOR DE TESORERIA"
                },
                {
                  "s_codigo": 592,
                  "s_descripcion": "ASISTENTE DE CARTERA"
                },
                {
                  "s_codigo": 593,
                  "s_descripcion": "ARMADOR"
                },
                {
                  "s_codigo": 594,
                  "s_descripcion": "ASISTENTE COMERCIAL"
                },
                {
                  "s_codigo": 595,
                  "s_descripcion": "ASISTENTE DE GERENCIA"
                },
                {
                  "s_codigo": 596,
                  "s_descripcion": "ASISTENTE DE TESORERIA"
                },
                {
                  "s_codigo": 597,
                  "s_descripcion": "ASISTENTE TECNICO"
                },
                {
                  "s_codigo": 598,
                  "s_descripcion": "AUXILIAR DE CAFETERIA"
                },
                {
                  "s_codigo": 599,
                  "s_descripcion": "AUXILIAR DE TESORERIA"
                },
                {
                  "s_codigo": 600,
                  "s_descripcion": "CONDUCTOR DE GERENCIA"
                },
                {
                  "s_codigo": 601,
                  "s_descripcion": "AUXILIAR DE FACTURACION"
                },
                {
                  "s_codigo": 602,
                  "s_descripcion": "COORDINADOR DE ALMACEN"
                },
                {
                  "s_codigo": 603,
                  "s_descripcion": "COORDINADOR DE DESPACHOS"
                },
                {
                  "s_codigo": 604,
                  "s_descripcion": "COORDINADOR FOTOELECTRICO"
                },
                {
                  "s_codigo": 605,
                  "s_descripcion": "COORDINADORA DE PROYECTOS"
                },
                {
                  "s_codigo": 606,
                  "s_descripcion": "COORDINADOR DE SALUD OCUP"
                },
                {
                  "s_codigo": 607,
                  "s_descripcion": "DIRECTOR GRAFICO"
                },
                {
                  "s_codigo": 608,
                  "s_descripcion": "DIRECTOR DE PLANEACION"
                },
                {
                  "s_codigo": 609,
                  "s_descripcion": "ASISTENTE DE COMPRAS"
                },
                {
                  "s_codigo": 610,
                  "s_descripcion": "DISENADOR GRAFICO"
                },
                {
                  "s_codigo": 611,
                  "s_descripcion": "JEFE DE ARMADA"
                },
                {
                  "s_codigo": 612,
                  "s_descripcion": "JEFE DE PRODUCCION"
                },
                {
                  "s_codigo": 613,
                  "s_descripcion": "JEFE DE CARTERA"
                },
                {
                  "s_codigo": 614,
                  "s_descripcion": "JEFE DE FIANZAS"
                },
                {
                  "s_codigo": 615,
                  "s_descripcion": "JEFE DE INDEMNIZACIONES"
                },
                {
                  "s_codigo": 616,
                  "s_descripcion": "OPERARIO DE AUTOEDICION"
                },
                {
                  "s_codigo": 617,
                  "s_descripcion": "OPERARIO AUXILIAR"
                },
                {
                  "s_codigo": 618,
                  "s_descripcion": "SECRETARIA ADMINISTRATIVA"
                },
                {
                  "s_codigo": 619,
                  "s_descripcion": "SECRETARIA DE VENTAS"
                },
                {
                  "s_codigo": 620,
                  "s_descripcion": "AUXILIAR DE OFICINA"
                },
                {
                  "s_codigo": 621,
                  "s_descripcion": "SUPERVISOR DE PLANTA"
                },
                {
                  "s_codigo": 622,
                  "s_descripcion": "JEFE DE ATENCION"
                },
                {
                  "s_codigo": 623,
                  "s_descripcion": "VICEPRESIDENTE COMERCIAL"
                },
                {
                  "s_codigo": 624,
                  "s_descripcion": "DIRECTOR DE CARTERA"
                },
                {
                  "s_codigo": 625,
                  "s_descripcion": "OPERARIO DE PLANTA"
                },
                {
                  "s_codigo": 626,
                  "s_descripcion": "JEFE DE EXPORTACIONES"
                },
                {
                  "s_codigo": 627,
                  "s_descripcion": "ASISTENTE DE EXPORTACIONE"
                },
                {
                  "s_codigo": 628,
                  "s_descripcion": "ANALISTA DE CONTABILIDAD"
                },
                {
                  "s_codigo": 629,
                  "s_descripcion": "ANALISTA OPERATIVO"
                },
                {
                  "s_codigo": 630,
                  "s_descripcion": "ANALISTA DE TESORERIA"
                },
                {
                  "s_codigo": 631,
                  "s_descripcion": "AUXILIAR DE CREDITO"
                },
                {
                  "s_codigo": 632,
                  "s_descripcion": "DIRECTOR DE SISTEMAS"
                },
                {
                  "s_codigo": 633,
                  "s_descripcion": "ASISTENTE DE PERSONAL"
                },
                {
                  "s_codigo": 634,
                  "s_descripcion": "AUXILIAR DE COSTOS"
                },
                {
                  "s_codigo": 635,
                  "s_descripcion": "SECRETARIA DE CONTABILIDA"
                },
                {
                  "s_codigo": 636,
                  "s_descripcion": "ENCONADOR"
                },
                {
                  "s_codigo": 637,
                  "s_descripcion": "AUXILIAR DE HILANDERIA"
                },
                {
                  "s_codigo": 638,
                  "s_descripcion": "RETORCEDOR"
                },
                {
                  "s_codigo": 641,
                  "s_descripcion": "TEJEDOR"
                },
                {
                  "s_codigo": 642,
                  "s_descripcion": "URDIDOR"
                },
                {
                  "s_codigo": 643,
                  "s_descripcion": "DESPINZADOR"
                },
                {
                  "s_codigo": 644,
                  "s_descripcion": "JEFE DE CADENAS"
                },
                {
                  "s_codigo": 645,
                  "s_descripcion": "JEFE REVISION DE PROCESOS"
                },
                {
                  "s_codigo": 646,
                  "s_descripcion": "AUXILIAR ALMACEN DE SALDO"
                },
                {
                  "s_codigo": 647,
                  "s_descripcion": "AUXILIAR DE ASEO Y CAFETE"
                },
                {
                  "s_codigo": 648,
                  "s_descripcion": "AUXILIAR DE AUDITORIA INT"
                },
                {
                  "s_codigo": 649,
                  "s_descripcion": "AUXILIAR DE COMPRAS"
                },
                {
                  "s_codigo": 650,
                  "s_descripcion": "AUXILIAR CONTROL DE PRODU"
                },
                {
                  "s_codigo": 651,
                  "s_descripcion": "AUXILIAR DE COSTURA"
                },
                {
                  "s_codigo": 652,
                  "s_descripcion": "AUXILIAR DE CUENTAS POR P"
                },
                {
                  "s_codigo": 653,
                  "s_descripcion": "AUXILIAR DE ALMACEN"
                },
                {
                  "s_codigo": 654,
                  "s_descripcion": "AUXILIAR DE PRODUCCION"
                },
                {
                  "s_codigo": 655,
                  "s_descripcion": "AUXILIAR DE PLATERO"
                },
                {
                  "s_codigo": 656,
                  "s_descripcion": "AUXILIAR DE DESPACHOS"
                },
                {
                  "s_codigo": 657,
                  "s_descripcion": "AUXILIAR DISE?O DE VESTUA"
                },
                {
                  "s_codigo": 658,
                  "s_descripcion": "AUXILIAR DE ESTADISTICA"
                },
                {
                  "s_codigo": 659,
                  "s_descripcion": "AUXILIAR DE LABORATORIO"
                },
                {
                  "s_codigo": 660,
                  "s_descripcion": "NEGOCIADOR"
                },
                {
                  "s_codigo": 661,
                  "s_descripcion": "DISENADOR CREATIVO"
                },
                {
                  "s_codigo": 662,
                  "s_descripcion": "AREAS PUBLICAS"
                },
                {
                  "s_codigo": 665,
                  "s_descripcion": "ASESOR CORPORATIVO"
                },
                {
                  "s_codigo": 666,
                  "s_descripcion": "ASISTENTE DE DISCIPLINA"
                },
                {
                  "s_codigo": 667,
                  "s_descripcion": "MONTACARGUISTA"
                },
                {
                  "s_codigo": 668,
                  "s_descripcion": "GERENTE DE PRODUCTO"
                },
                {
                  "s_codigo": 669,
                  "s_descripcion": "AUXILIAR DE MENSAJERIA"
                },
                {
                  "s_codigo": 700,
                  "s_descripcion": "AUXILIAR DE MANTENIMIENTO"
                },
                {
                  "s_codigo": 701,
                  "s_descripcion": "AUXILIAR DE MUESTRARIOS"
                },
                {
                  "s_codigo": 702,
                  "s_descripcion": "AUXILIAR DE RECURSOS HUMA"
                },
                {
                  "s_codigo": 703,
                  "s_descripcion": "AUXILIAR DE COMERCIO EXTE"
                },
                {
                  "s_codigo": 704,
                  "s_descripcion": "AUXILIAR DE DESARROLLO/OR"
                },
                {
                  "s_codigo": 705,
                  "s_descripcion": "AYUDANTE DE CAMION"
                },
                {
                  "s_codigo": 706,
                  "s_descripcion": "AYUDANTE DE DESPACHOS"
                },
                {
                  "s_codigo": 707,
                  "s_descripcion": "ADMINISTRADOR DE INFORMAC"
                },
                {
                  "s_codigo": 708,
                  "s_descripcion": "ANALISTA DE HILADOS"
                },
                {
                  "s_codigo": 709,
                  "s_descripcion": "ANALISTA DE QUIMICOS"
                },
                {
                  "s_codigo": 710,
                  "s_descripcion": "ANALISTA DE TEJIDOS"
                },
                {
                  "s_codigo": 711,
                  "s_descripcion": "ANALISTA DE ESTADISTICA"
                },
                {
                  "s_codigo": 712,
                  "s_descripcion": "ANALISTA DE LABORATORIO"
                },
                {
                  "s_codigo": 713,
                  "s_descripcion": "ANALISTA LINEA INDUSTRIAL"
                },
                {
                  "s_codigo": 714,
                  "s_descripcion": "ASISTENTE DE REPUESTOS"
                },
                {
                  "s_codigo": 715,
                  "s_descripcion": "AYUDANTE DE LABADORA"
                },
                {
                  "s_codigo": 716,
                  "s_descripcion": "CALDERISTA"
                },
                {
                  "s_codigo": 717,
                  "s_descripcion": "CARPINTERO"
                },
                {
                  "s_codigo": 718,
                  "s_descripcion": "CONTROL PURGADORES"
                },
                {
                  "s_codigo": 719,
                  "s_descripcion": "COORDINADOR DE CARTERA"
                },
                {
                  "s_codigo": 720,
                  "s_descripcion": "COORDINADOR DE NOMINA"
                },
                {
                  "s_codigo": 721,
                  "s_descripcion": "COORDINADOR DE DISE?O IND"
                },
                {
                  "s_codigo": 722,
                  "s_descripcion": "COORDINADOR FACTURACION"
                },
                {
                  "s_codigo": 723,
                  "s_descripcion": "COORDINADOR DE TESORERIA"
                },
                {
                  "s_codigo": 724,
                  "s_descripcion": "DIRECTOR DE CALIDAD"
                },
                {
                  "s_codigo": 725,
                  "s_descripcion": "GERENTE FINANCIERO ADMINI"
                },
                {
                  "s_codigo": 726,
                  "s_descripcion": "GERENTE DE PRODUCCION Y S"
                },
                {
                  "s_codigo": 727,
                  "s_descripcion": "JARDINERO"
                },
                {
                  "s_codigo": 728,
                  "s_descripcion": "JEFE DE IMPORTACIONES"
                },
                {
                  "s_codigo": 729,
                  "s_descripcion": "JEFE MANTENIMIENTO DE HIL"
                },
                {
                  "s_codigo": 730,
                  "s_descripcion": "JEFE MANTENIMIENTO TINTIR"
                },
                {
                  "s_codigo": 731,
                  "s_descripcion": "JEFE MANTENIMIENTO ELECTR"
                },
                {
                  "s_codigo": 732,
                  "s_descripcion": "JEFE MANTENIMIENTO TALLER"
                },
                {
                  "s_codigo": 733,
                  "s_descripcion": "LABORATORISTA TECNICO"
                },
                {
                  "s_codigo": 734,
                  "s_descripcion": "MAESTRA DE PESPUNTE"
                },
                {
                  "s_codigo": 735,
                  "s_descripcion": "MECANICO ANULADOR"
                },
                {
                  "s_codigo": 736,
                  "s_descripcion": "MECANICO HILANDERIA"
                },
                {
                  "s_codigo": 737,
                  "s_descripcion": "MECANICO PEINADORAS"
                },
                {
                  "s_codigo": 738,
                  "s_descripcion": "MECANICO TELAR INDUSTRIAL"
                },
                {
                  "s_codigo": 739,
                  "s_descripcion": "MECANICO GILLS MECHERAS"
                },
                {
                  "s_codigo": 740,
                  "s_descripcion": "MECANICO AJUSTADOR"
                },
                {
                  "s_codigo": 741,
                  "s_descripcion": "OPERADOR ANULADOR"
                },
                {
                  "s_codigo": 742,
                  "s_descripcion": "OPERADOR BARCAS"
                },
                {
                  "s_codigo": 743,
                  "s_descripcion": "OPERADOR BATANES"
                },
                {
                  "s_codigo": 744,
                  "s_descripcion": "OPERADOR BOBINADOR"
                },
                {
                  "s_codigo": 745,
                  "s_descripcion": "OPERADOR CARDAMECHERAS"
                },
                {
                  "s_codigo": 746,
                  "s_descripcion": "OPERADOR CARDAS"
                },
                {
                  "s_codigo": 747,
                  "s_descripcion": "OPERADOR DECATIZADORA"
                },
                {
                  "s_codigo": 748,
                  "s_descripcion": "OPERADOR DE DOBLADORA"
                },
                {
                  "s_codigo": 749,
                  "s_descripcion": "OPERADOR ENCAUCHADORA"
                },
                {
                  "s_codigo": 750,
                  "s_descripcion": "OPERADOR ENCONADORAS"
                },
                {
                  "s_codigo": 751,
                  "s_descripcion": "OPERADOR DE GILLS"
                },
                {
                  "s_codigo": 752,
                  "s_descripcion": "OPERADOR HILADORAS"
                },
                {
                  "s_codigo": 753,
                  "s_descripcion": "OPERADOR DE JET"
                },
                {
                  "s_codigo": 754,
                  "s_descripcion": "OPERADOR DE LAVADORA"
                },
                {
                  "s_codigo": 755,
                  "s_descripcion": "OPERADOR MECHERAS"
                },
                {
                  "s_codigo": 756,
                  "s_descripcion": "OPERADOR DE MEZCLA Y CARD"
                },
                {
                  "s_codigo": 757,
                  "s_descripcion": "OPERADOR DE PEINADORAS"
                },
                {
                  "s_codigo": 758,
                  "s_descripcion": "OPERADOR DE PESPUNTE"
                },
                {
                  "s_codigo": 759,
                  "s_descripcion": "OPERADOR DE PRENSA TINTOR"
                },
                {
                  "s_codigo": 760,
                  "s_descripcion": "OPERADOR DE REMETIDO"
                },
                {
                  "s_codigo": 761,
                  "s_descripcion": "OPERADOR DE RETORCEDORAS"
                },
                {
                  "s_codigo": 762,
                  "s_descripcion": "OPERADOR TELAR INDUSTRIAL"
                },
                {
                  "s_codigo": 763,
                  "s_descripcion": "OPERADOR TUNDIDORA"
                },
                {
                  "s_codigo": 764,
                  "s_descripcion": "OPERADOR VAPORIZADOR"
                },
                {
                  "s_codigo": 765,
                  "s_descripcion": "OPERARIO DE AUTOEDICION"
                },
                {
                  "s_codigo": 766,
                  "s_descripcion": "REVISOR ACABADO INDUSTRIA"
                },
                {
                  "s_codigo": 767,
                  "s_descripcion": "SECRETARIA DE PRESIDENCIA"
                },
                {
                  "s_codigo": 768,
                  "s_descripcion": "TECNICO DE TELAR INDUSTRI"
                },
                {
                  "s_codigo": 769,
                  "s_descripcion": "MECANICO TINTORERIA ACABA"
                },
                {
                  "s_codigo": 770,
                  "s_descripcion": "OPERADOR"
                },
                {
                  "s_codigo": 771,
                  "s_descripcion": "MECANICO RETOR-ENCONADO"
                },
                {
                  "s_codigo": 772,
                  "s_descripcion": "MOVILI.PANOTEL-ASEO"
                },
                {
                  "s_codigo": 773,
                  "s_descripcion": "MOVILIZADOR PANO TINTORER"
                },
                {
                  "s_codigo": 774,
                  "s_descripcion": "OPERADOR DESBORRADO"
                },
                {
                  "s_codigo": 775,
                  "s_descripcion": "OPERADOR DESMOTADORA"
                },
                {
                  "s_codigo": 776,
                  "s_descripcion": "OPERADOR GILLS MEZCLADO"
                },
                {
                  "s_codigo": 777,
                  "s_descripcion": "OPERADOR HILAN.CARDADO"
                },
                {
                  "s_codigo": 778,
                  "s_descripcion": "OPERADOR TUNDIDORA INDUST"
                },
                {
                  "s_codigo": 779,
                  "s_descripcion": "OPERADOR URDIDOR DISE?O"
                },
                {
                  "s_codigo": 780,
                  "s_descripcion": "OPERADOR RAMA SECADORA"
                },
                {
                  "s_codigo": 781,
                  "s_descripcion": "OPERADOR VIGOREAUX"
                },
                {
                  "s_codigo": 782,
                  "s_descripcion": "OPERADOR CENTRI. Y PLEGAD"
                },
                {
                  "s_codigo": 783,
                  "s_descripcion": "OPERADOR LAVADO-TINTORERI"
                },
                {
                  "s_codigo": 784,
                  "s_descripcion": "OPERADOR PLANTA TRATA.AG"
                },
                {
                  "s_codigo": 785,
                  "s_descripcion": "OPERARIO AUXILIAR"
                },
                {
                  "s_codigo": 786,
                  "s_descripcion": "PESADOR HILAZA CARDA"
                },
                {
                  "s_codigo": 787,
                  "s_descripcion": "PESADOR HILAZA INDUSTRIAL"
                },
                {
                  "s_codigo": 788,
                  "s_descripcion": "PESADOR HILAZA VESTUARIO"
                },
                {
                  "s_codigo": 789,
                  "s_descripcion": "PESADOR QUIM. Y COLOR"
                },
                {
                  "s_codigo": 790,
                  "s_descripcion": "REVISION FINAL"
                },
                {
                  "s_codigo": 791,
                  "s_descripcion": "REVISION PREVIA"
                },
                {
                  "s_codigo": 792,
                  "s_descripcion": "REVISION CRUDO INDUSTRIAL"
                },
                {
                  "s_codigo": 793,
                  "s_descripcion": "REVISOR CRUDO VESTUARIO"
                },
                {
                  "s_codigo": 794,
                  "s_descripcion": "SECRETARIA GER.PROD. Y S"
                },
                {
                  "s_codigo": 795,
                  "s_descripcion": "SECRETARIA SERVICIO"
                },
                {
                  "s_codigo": 796,
                  "s_descripcion": "SELECCIONADOR PANO"
                },
                {
                  "s_codigo": 797,
                  "s_descripcion": "TECNICO.HILAND.CARDADA"
                },
                {
                  "s_codigo": 798,
                  "s_descripcion": "TECNICO MANTENIMIENTO HIL"
                },
                {
                  "s_codigo": 799,
                  "s_descripcion": "TECNICO RETOR.ENCONADO"
                },
                {
                  "s_codigo": 800,
                  "s_descripcion": "TECNICO MECANICO TINTORER"
                },
                {
                  "s_codigo": 801,
                  "s_descripcion": "TENIDOR DE HILAZA"
                },
                {
                  "s_codigo": 802,
                  "s_descripcion": "TORNERO FRESADOR"
                },
                {
                  "s_codigo": 803,
                  "s_descripcion": "SERVICIOS GENERALES"
                },
                {
                  "s_codigo": 804,
                  "s_descripcion": "AUXILIAR DE ENFERMERIA"
                },
                {
                  "s_codigo": 805,
                  "s_descripcion": "SUPERVISOR DE ALMACEN"
                },
                {
                  "s_codigo": 806,
                  "s_descripcion": "INGENIERO DE SERVICIOS"
                },
                {
                  "s_codigo": 807,
                  "s_descripcion": "JEFE CUARTO FRIO"
                },
                {
                  "s_codigo": 808,
                  "s_descripcion": "AUXILIAR TRES"
                },
                {
                  "s_codigo": 809,
                  "s_descripcion": "ASISTENTE OPERATIVO"
                },
                {
                  "s_codigo": 810,
                  "s_descripcion": "PALETIZADOR"
                },
                {
                  "s_codigo": 811,
                  "s_descripcion": "AUXILIAR DE PLANTA"
                },
                {
                  "s_codigo": 812,
                  "s_descripcion": "JEFE DE VENTAS"
                },
                {
                  "s_codigo": 814,
                  "s_descripcion": "DIRECTOR DE NEGOCIOS"
                },
                {
                  "s_codigo": 815,
                  "s_descripcion": "AUDITOR SEMI-SENIOR III"
                },
                {
                  "s_codigo": 816,
                  "s_descripcion": "PROFESORA"
                },
                {
                  "s_codigo": 817,
                  "s_descripcion": "PREVENTISTA"
                },
                {
                  "s_codigo": 818,
                  "s_descripcion": "ASESORA DE CAPACITACION"
                },
                {
                  "s_codigo": 819,
                  "s_descripcion": "VENTAS"
                },
                {
                  "s_codigo": 820,
                  "s_descripcion": "SECRETARIA AUXILIAR CONTA"
                },
                {
                  "s_codigo": 821,
                  "s_descripcion": "TECNICO ADMINISTRATIVO"
                },
                {
                  "s_codigo": 822,
                  "s_descripcion": "FINANCIERA PRESUPUESTO"
                },
                {
                  "s_codigo": 823,
                  "s_descripcion": "ANALISTA DE PROCESOS"
                },
                {
                  "s_codigo": 824,
                  "s_descripcion": "ANALISTA DE SISTEMAS"
                },
                {
                  "s_codigo": 825,
                  "s_descripcion": "ASISTENTE DE LEASING"
                },
                {
                  "s_codigo": 826,
                  "s_descripcion": "ASISTENTE AUDITOR"
                },
                {
                  "s_codigo": 827,
                  "s_descripcion": "JEFE DE PINTURA"
                },
                {
                  "s_codigo": 828,
                  "s_descripcion": "JEFE DE PROCESOS"
                },
                {
                  "s_codigo": 829,
                  "s_descripcion": "EJECUTIVO DE CUENTA"
                },
                {
                  "s_codigo": 830,
                  "s_descripcion": "ANALISTA DE BIENESTAR"
                },
                {
                  "s_codigo": 831,
                  "s_descripcion": "DIRECTOR OPERATIVO"
                },
                {
                  "s_codigo": 832,
                  "s_descripcion": "OPERADORA MAQUINA"
                },
                {
                  "s_codigo": 833,
                  "s_descripcion": "SERVICIO AL CLIENTE"
                },
                {
                  "s_codigo": 834,
                  "s_descripcion": "AUXILIAR DE CONTENEDORES"
                },
                {
                  "s_codigo": 835,
                  "s_descripcion": "GERENTE DE OPER. - IMPORT"
                },
                {
                  "s_codigo": 836,
                  "s_descripcion": "PROMOTOR DE SEGUROS"
                },
                {
                  "s_codigo": 837,
                  "s_descripcion": "REPRESENTANTE TECNICO"
                },
                {
                  "s_codigo": 838,
                  "s_descripcion": "LUBRICADOR"
                },
                {
                  "s_codigo": 839,
                  "s_descripcion": "SERVICIO DE CAFETERIA"
                },
                {
                  "s_codigo": 840,
                  "s_descripcion": "PSICOLOGA"
                },
                {
                  "s_codigo": 841,
                  "s_descripcion": "TECNICO DE INFORMACION"
                },
                {
                  "s_codigo": 842,
                  "s_descripcion": "CONSULTORA"
                },
                {
                  "s_codigo": 843,
                  "s_descripcion": "PERSONERO"
                },
                {
                  "s_codigo": 844,
                  "s_descripcion": "ANALISTA DE COMUNICACIONE"
                },
                {
                  "s_codigo": 845,
                  "s_descripcion": "ANALISTA DE VALORES"
                },
                {
                  "s_codigo": 846,
                  "s_descripcion": "COORDINADOR DE SEGURIDAD"
                },
                {
                  "s_codigo": 847,
                  "s_descripcion": "COORDINADOR EMISOR"
                },
                {
                  "s_codigo": 848,
                  "s_descripcion": "OFICIAL DE CUENTA"
                },
                {
                  "s_codigo": 849,
                  "s_descripcion": "DIRECTORA UNIDAD SERVICIO"
                },
                {
                  "s_codigo": 850,
                  "s_descripcion": "ANALISTA FINANCIERO"
                },
                {
                  "s_codigo": 851,
                  "s_descripcion": "DIRECTORA DE INFORMATICA"
                },
                {
                  "s_codigo": 852,
                  "s_descripcion": "CAJERO DE VALORES"
                },
                {
                  "s_codigo": 853,
                  "s_descripcion": "COORDINADOR DE MENSAJERIA"
                },
                {
                  "s_codigo": 854,
                  "s_descripcion": "ADMINISTRACION DE VALORES"
                },
                {
                  "s_codigo": 855,
                  "s_descripcion": "ASESOR TELEFONICO"
                },
                {
                  "s_codigo": 856,
                  "s_descripcion": "AUXILIAR JURIDICO"
                },
                {
                  "s_codigo": 857,
                  "s_descripcion": "ASISTENTE ADMINISTRCION F"
                },
                {
                  "s_codigo": 858,
                  "s_descripcion": "SOPORTE A USUARIO"
                },
                {
                  "s_codigo": 859,
                  "s_descripcion": "DIRECTOR DE INVESTIGACION"
                },
                {
                  "s_codigo": 860,
                  "s_descripcion": "JEFE DE DESPACHOS"
                },
                {
                  "s_codigo": 861,
                  "s_descripcion": "DIBUJANTE DE ARQUITECTURA"
                },
                {
                  "s_codigo": 862,
                  "s_descripcion": "JEFE DE PLANEACION"
                },
                {
                  "s_codigo": 863,
                  "s_descripcion": "CAMAROGRAFO"
                },
                {
                  "s_codigo": 864,
                  "s_descripcion": "TECNICO SUPERVISOR"
                },
                {
                  "s_codigo": 865,
                  "s_descripcion": "BOTONES"
                },
                {
                  "s_codigo": 866,
                  "s_descripcion": "GUIA DE SEGURIDAD"
                },
                {
                  "s_codigo": 867,
                  "s_descripcion": "ASISTENTE PRESI*"
                },
                {
                  "s_codigo": 868,
                  "s_descripcion": "DIRECTORA DE SISTEMAS"
                },
                {
                  "s_codigo": 869,
                  "s_descripcion": "DIRECTOR DE PRODUCCION"
                },
                {
                  "s_codigo": 870,
                  "s_descripcion": "ASEADORA"
                },
                {
                  "s_codigo": 871,
                  "s_descripcion": "DOSIFICADOR"
                },
                {
                  "s_codigo": 872,
                  "s_descripcion": "AUXILIAR LABORATORIO"
                },
                {
                  "s_codigo": 873,
                  "s_descripcion": "EMPACADOR"
                },
                {
                  "s_codigo": 874,
                  "s_descripcion": "AUXILIAR DE IMPRESION"
                },
                {
                  "s_codigo": 875,
                  "s_descripcion": "JEFE DE SERVICIOS ADMINIS"
                },
                {
                  "s_codigo": 876,
                  "s_descripcion": "COORDINADORA ADMINISTR*"
                },
                {
                  "s_codigo": 877,
                  "s_descripcion": "TODERO"
                },
                {
                  "s_codigo": 878,
                  "s_descripcion": "DETECTIVE"
                },
                {
                  "s_codigo": 879,
                  "s_descripcion": "ALISTAMIENTO"
                },
                {
                  "s_codigo": 880,
                  "s_descripcion": "LATONERO"
                },
                {
                  "s_codigo": 881,
                  "s_descripcion": "JEFE VEHICULOS USADOS"
                },
                {
                  "s_codigo": 882,
                  "s_descripcion": "LAVADOR"
                },
                {
                  "s_codigo": 883,
                  "s_descripcion": "ESCRIBIENTE"
                },
                {
                  "s_codigo": 884,
                  "s_descripcion": "RADICADOR DE PAGOS"
                },
                {
                  "s_codigo": 885,
                  "s_descripcion": "AUXILIAR DE SEGURIDAD"
                },
                {
                  "s_codigo": 886,
                  "s_descripcion": "ASISTENTE DE RECURSOS HUM"
                },
                {
                  "s_codigo": 887,
                  "s_descripcion": "ING. DE CONTROL Y CALIDAD"
                },
                {
                  "s_codigo": 888,
                  "s_descripcion": "PESADOR"
                },
                {
                  "s_codigo": 889,
                  "s_descripcion": "PROGRAMADORA"
                },
                {
                  "s_codigo": 890,
                  "s_descripcion": "CAPITAN"
                },
                {
                  "s_codigo": 891,
                  "s_descripcion": "AUXILIAR DE COCINA"
                },
                {
                  "s_codigo": 892,
                  "s_descripcion": "AUXILIAR DE INVENTARIO"
                },
                {
                  "s_codigo": 893,
                  "s_descripcion": "COCINERA"
                },
                {
                  "s_codigo": 894,
                  "s_descripcion": "CAMARERA"
                },
                {
                  "s_codigo": 895,
                  "s_descripcion": "AUXILIAR DE NOMINA"
                },
                {
                  "s_codigo": 896,
                  "s_descripcion": "PASTELERO"
                },
                {
                  "s_codigo": 897,
                  "s_descripcion": "CHEF"
                },
                {
                  "s_codigo": 898,
                  "s_descripcion": "AUXILIAR DE PASTELEROS"
                },
                {
                  "s_codigo": 899,
                  "s_descripcion": "COCINERO"
                },
                {
                  "s_codigo": 900,
                  "s_descripcion": "SECRETARIA INMOBILIARIA"
                },
                {
                  "s_codigo": 901,
                  "s_descripcion": "AUXILIAR FOTOGRAFICO"
                },
                {
                  "s_codigo": 902,
                  "s_descripcion": "JEFE DE RESERVAS"
                },
                {
                  "s_codigo": 903,
                  "s_descripcion": "CAJERO AUDITOR"
                },
                {
                  "s_codigo": 904,
                  "s_descripcion": "DIRECTOR FINANCIERO"
                },
                {
                  "s_codigo": 905,
                  "s_descripcion": "JEFE DE TELEVISION"
                },
                {
                  "s_codigo": 906,
                  "s_descripcion": "INFORMADORA ATM"
                },
                {
                  "s_codigo": 907,
                  "s_descripcion": "INGENIERO DE PROYECTOS"
                },
                {
                  "s_codigo": 908,
                  "s_descripcion": "TALADRADOR"
                },
                {
                  "s_codigo": 909,
                  "s_descripcion": "ISOMETRISTA"
                },
                {
                  "s_codigo": 910,
                  "s_descripcion": "RESIDENTE"
                },
                {
                  "s_codigo": 911,
                  "s_descripcion": "PEGADOR"
                },
                {
                  "s_codigo": 912,
                  "s_descripcion": "KARDEX"
                },
                {
                  "s_codigo": 913,
                  "s_descripcion": "GERENTE SUCURSAL"
                },
                {
                  "s_codigo": 914,
                  "s_descripcion": "SECRETARIA DE IMPORTACION"
                },
                {
                  "s_codigo": 915,
                  "s_descripcion": "JEFE DE LABORATORIO"
                },
                {
                  "s_codigo": 916,
                  "s_descripcion": "GERENTE DE DESARROLLO TEC"
                },
                {
                  "s_codigo": 917,
                  "s_descripcion": "ASESOR ACADEMICO"
                },
                {
                  "s_codigo": 918,
                  "s_descripcion": "JEFE DE TELARES"
                },
                {
                  "s_codigo": 919,
                  "s_descripcion": "CELADOR"
                },
                {
                  "s_codigo": 920,
                  "s_descripcion": "CONCILIACION BANCARIA"
                },
                {
                  "s_codigo": 921,
                  "s_descripcion": "TECNICA ELECTRONICA"
                },
                {
                  "s_codigo": 922,
                  "s_descripcion": "PROFESOR DE CAPACITACION"
                },
                {
                  "s_codigo": 923,
                  "s_descripcion": "PORCIONADOR"
                },
                {
                  "s_codigo": 924,
                  "s_descripcion": "LITOGRAFO"
                },
                {
                  "s_codigo": 925,
                  "s_descripcion": "BIBLIOTECARIA"
                },
                {
                  "s_codigo": 926,
                  "s_descripcion": "DIRECTOR DE CREDITO"
                },
                {
                  "s_codigo": 927,
                  "s_descripcion": "SELLADOR"
                },
                {
                  "s_codigo": 928,
                  "s_descripcion": "JEFE DE PRENSA"
                },
                {
                  "s_codigo": 929,
                  "s_descripcion": "RESIDENTE ACABADOS"
                },
                {
                  "s_codigo": 930,
                  "s_descripcion": "ESPECIALISTA DE PRODUCTO"
                },
                {
                  "s_codigo": 931,
                  "s_descripcion": "MESERO (A)"
                },
                {
                  "s_codigo": 932,
                  "s_descripcion": "SUB-CHEFF"
                },
                {
                  "s_codigo": 933,
                  "s_descripcion": "INSTRUMENTADORA QUIRURGIC"
                },
                {
                  "s_codigo": 934,
                  "s_descripcion": "CAMILLERO(A)"
                },
                {
                  "s_codigo": 935,
                  "s_descripcion": "AUXILIAR DE FARMACIA"
                },
                {
                  "s_codigo": 936,
                  "s_descripcion": "FISOTERAPEUTA"
                },
                {
                  "s_codigo": 937,
                  "s_descripcion": "MODISTA"
                },
                {
                  "s_codigo": 938,
                  "s_descripcion": "DIRECTORA DE BENEFICIOS"
                },
                {
                  "s_codigo": 939,
                  "s_descripcion": "COORDINADORA DE ARCHIVO"
                },
                {
                  "s_codigo": 940,
                  "s_descripcion": "LECTOR"
                },
                {
                  "s_codigo": 941,
                  "s_descripcion": "DOCENTE"
                },
                {
                  "s_codigo": 942,
                  "s_descripcion": "AUXILIAR DE HABITACION"
                },
                {
                  "s_codigo": 943,
                  "s_descripcion": "ASESOR DE SISTEMAS"
                },
                {
                  "s_codigo": 944,
                  "s_descripcion": "ASESOR DE MERCADEO"
                },
                {
                  "s_codigo": 945,
                  "s_descripcion": "GUARDIA"
                },
                {
                  "s_codigo": 946,
                  "s_descripcion": "JEFE ADMINISTRATIVA"
                },
                {
                  "s_codigo": 947,
                  "s_descripcion": "MATRICERO"
                },
                {
                  "s_codigo": 948,
                  "s_descripcion": "AUXILIAR FINANCIERO"
                },
                {
                  "s_codigo": 949,
                  "s_descripcion": "ASISTENTE JURIDICA"
                },
                {
                  "s_codigo": 950,
                  "s_descripcion": "ASISTENTE DE DIVICION FIN"
                },
                {
                  "s_codigo": 951,
                  "s_descripcion": "CALZADOR"
                },
                {
                  "s_codigo": 952,
                  "s_descripcion": "TRADER"
                },
                {
                  "s_codigo": 953,
                  "s_descripcion": "AMA DE LLAVES"
                },
                {
                  "s_codigo": 954,
                  "s_descripcion": "STEWAR"
                },
                {
                  "s_codigo": 955,
                  "s_descripcion": "AUXILIAR DE RADIOLOGIA"
                },
                {
                  "s_codigo": 956,
                  "s_descripcion": "CAPITAN DE MESEROS"
                },
                {
                  "s_codigo": 957,
                  "s_descripcion": "AUXILIAR DE DIETAS"
                },
                {
                  "s_codigo": 958,
                  "s_descripcion": "JEFE DE MINIBARES"
                },
                {
                  "s_codigo": 959,
                  "s_descripcion": "DECORADOR(A)"
                },
                {
                  "s_codigo": 960,
                  "s_descripcion": "AUXILIAR DE ACCIONES"
                },
                {
                  "s_codigo": 961,
                  "s_descripcion": "COORDIANDOR OPERATIVO"
                },
                {
                  "s_codigo": 962,
                  "s_descripcion": "JEFE DE GESTION HUMANA"
                },
                {
                  "s_codigo": 963,
                  "s_descripcion": "PATINADOR"
                },
                {
                  "s_codigo": 964,
                  "s_descripcion": "FRACCIONADOR"
                },
                {
                  "s_codigo": 965,
                  "s_descripcion": "FABRICANTE"
                },
                {
                  "s_codigo": 966,
                  "s_descripcion": "GRAPADOR"
                },
                {
                  "s_codigo": 967,
                  "s_descripcion": "SUPER ENVASES"
                },
                {
                  "s_codigo": 968,
                  "s_descripcion": "ESPECIALISTA"
                },
                {
                  "s_codigo": 969,
                  "s_descripcion": "JEFE DE ENFERMERIA"
                },
                {
                  "s_codigo": 970,
                  "s_descripcion": "ESTADISTICO"
                },
                {
                  "s_codigo": 971,
                  "s_descripcion": "AUXILIAR DE BODEGA"
                },
                {
                  "s_codigo": 972,
                  "s_descripcion": "JEFE DE COCINA"
                },
                {
                  "s_codigo": 973,
                  "s_descripcion": "HOTELERIA Y RESERVAS"
                },
                {
                  "s_codigo": 974,
                  "s_descripcion": "ESCOLTA"
                },
                {
                  "s_codigo": 975,
                  "s_descripcion": "TERAPEUTA"
                },
                {
                  "s_codigo": 976,
                  "s_descripcion": "AJUSTADOR"
                },
                {
                  "s_codigo": 977,
                  "s_descripcion": "HERRAMIENTERO"
                },
                {
                  "s_codigo": 978,
                  "s_descripcion": "JEFE DE CARPINTERIA"
                },
                {
                  "s_codigo": 979,
                  "s_descripcion": "AUXILIAR DE CARPINTERIA"
                },
                {
                  "s_codigo": 980,
                  "s_descripcion": "BARMAN"
                },
                {
                  "s_codigo": 981,
                  "s_descripcion": "JEFE DEPTO ALIMENTACION"
                },
                {
                  "s_codigo": 982,
                  "s_descripcion": "PIZERO"
                },
                {
                  "s_codigo": 983,
                  "s_descripcion": "DOMICILIARIO"
                },
                {
                  "s_codigo": 984,
                  "s_descripcion": "DISCJOCKEY"
                },
                {
                  "s_codigo": 985,
                  "s_descripcion": "DISEYADORA"
                },
                {
                  "s_codigo": 986,
                  "s_descripcion": "FISCALIA"
                },
                {
                  "s_codigo": 987,
                  "s_descripcion": "COORDINADOR DEPTO.TECNICO"
                },
                {
                  "s_codigo": 988,
                  "s_descripcion": "ASISTENTE DE PRESIDENCIA"
                },
                {
                  "s_codigo": 989,
                  "s_descripcion": "SOPORTE TECNICO"
                },
                {
                  "s_codigo": 990,
                  "s_descripcion": "JEFE NAL.TRABAJO CAMPO"
                },
                {
                  "s_codigo": 991,
                  "s_descripcion": "EJECUTIVO DE MEDIOS"
                },
                {
                  "s_codigo": 992,
                  "s_descripcion": "EJECUTIVO PRODUCCION"
                },
                {
                  "s_codigo": 993,
                  "s_descripcion": "GERENTE CONTROL RIESGO"
                },
                {
                  "s_codigo": 994,
                  "s_descripcion": "EJECUTIVO LICENCIAS"
                },
                {
                  "s_codigo": 995,
                  "s_descripcion": "INGENIERO DE LUBRICACION"
                },
                {
                  "s_codigo": 996,
                  "s_descripcion": "INGENIERO MATERIALES"
                },
                {
                  "s_codigo": 997,
                  "s_descripcion": "ASESOR DE CREDITO"
                },
                {
                  "s_codigo": 998,
                  "s_descripcion": "AUTORIZADOR (A)"
                },
                {
                  "s_codigo": 999,
                  "s_descripcion": "FORRADOR"
                },
                {
                  "s_codigo": 1000,
                  "s_descripcion": "RECTOR (A)"
                },
                {
                  "s_codigo": 1001,
                  "s_descripcion": "ARTISTA DIGITAL"
                },
                {
                  "s_codigo": 1002,
                  "s_descripcion": "HORNERO"
                },
                {
                  "s_codigo": 1003,
                  "s_descripcion": "VISADOR (A)"
                },
                {
                  "s_codigo": 1004,
                  "s_descripcion": "TECNICO DE SISTEMAS"
                },
                {
                  "s_codigo": 1005,
                  "s_descripcion": "LIDER DE MANTENIMIENTO"
                },
                {
                  "s_codigo": 1006,
                  "s_descripcion": "OFICIAL DE RESPUESTA"
                },
                {
                  "s_codigo": 1007,
                  "s_descripcion": "OFICINISTA"
                },
                {
                  "s_codigo": 1008,
                  "s_descripcion": "CITOTECNOLOGO (A)"
                },
                {
                  "s_codigo": 1009,
                  "s_descripcion": "CARDISTA SISTEMAS"
                },
                {
                  "s_codigo": 1010,
                  "s_descripcion": "ENSERADOR"
                },
                {
                  "s_codigo": 1011,
                  "s_descripcion": "CORTADOR (A)"
                },
                {
                  "s_codigo": 1012,
                  "s_descripcion": "COORDINADOR DE PAGOS"
                },
                {
                  "s_codigo": 1013,
                  "s_descripcion": "SASTRE"
                },
                {
                  "s_codigo": 1014,
                  "s_descripcion": "ENTREGADOR"
                },
                {
                  "s_codigo": 1015,
                  "s_descripcion": "DESCARGADOR"
                },
                {
                  "s_codigo": 1016,
                  "s_descripcion": "DIRECTORA DE PRIMARIA"
                },
                {
                  "s_codigo": 1017,
                  "s_descripcion": "DIRECTOR DE PRIMARIA"
                },
                {
                  "s_codigo": 1018,
                  "s_descripcion": "MAITRE"
                },
                {
                  "s_codigo": 1019,
                  "s_descripcion": "TECNOLOGO"
                },
                {
                  "s_codigo": 1020,
                  "s_descripcion": "TECNOLOGO DE ESCANOGRAFIA"
                },
                {
                  "s_codigo": 1021,
                  "s_descripcion": "ANALISTA DE PERSONAL"
                },
                {
                  "s_codigo": 1022,
                  "s_descripcion": "AUXILIAR DE TALLER"
                },
                {
                  "s_codigo": 1023,
                  "s_descripcion": "TALLER"
                },
                {
                  "s_codigo": 1024,
                  "s_descripcion": "TRANFERENCISTA"
                },
                {
                  "s_codigo": 1025,
                  "s_descripcion": "CONTROL GESTION"
                },
                {
                  "s_codigo": 1026,
                  "s_descripcion": "PLOTTER"
                },
                {
                  "s_codigo": 1027,
                  "s_descripcion": "OFICIAL INTERNACIONAL"
                },
                {
                  "s_codigo": 1028,
                  "s_descripcion": "GERENTE DE SERVICIO"
                },
                {
                  "s_codigo": 1029,
                  "s_descripcion": "ALINEADOR"
                },
                {
                  "s_codigo": 1030,
                  "s_descripcion": "CREATIVO COPY"
                },
                {
                  "s_codigo": 1031,
                  "s_descripcion": "JEFE DE ENCUADERNACION"
                },
                {
                  "s_codigo": 1032,
                  "s_descripcion": "ENCUESTADOR (A)"
                },
                {
                  "s_codigo": 1033,
                  "s_descripcion": "ANALISTA COMERCIAL"
                },
                {
                  "s_codigo": 1034,
                  "s_descripcion": "GARANTIA"
                },
                {
                  "s_codigo": 1035,
                  "s_descripcion": "ESPUMADOR"
                },
                {
                  "s_codigo": 1036,
                  "s_descripcion": "HOSMAN"
                },
                {
                  "s_codigo": 1037,
                  "s_descripcion": "VEEDOR"
                },
                {
                  "s_codigo": 1038,
                  "s_descripcion": "LINE MANAGER"
                },
                {
                  "s_codigo": 1039,
                  "s_descripcion": "VISITADOR (A) SOCIAL"
                },
                {
                  "s_codigo": 1040,
                  "s_descripcion": "LOGI HOMBRES"
                },
                {
                  "s_codigo": 1041,
                  "s_descripcion": "OPTOMETRA"
                },
                {
                  "s_codigo": 1042,
                  "s_descripcion": "PULIDOR"
                },
                {
                  "s_codigo": 1043,
                  "s_descripcion": "ENFERMERA JEFE"
                },
                {
                  "s_codigo": 1044,
                  "s_descripcion": "AUXILIAR DE ROPERIA"
                },
                {
                  "s_codigo": 1045,
                  "s_descripcion": "AUXILIAR DE PATIO"
                },
                {
                  "s_codigo": 1046,
                  "s_descripcion": "CODIFICADOR (A)"
                },
                {
                  "s_codigo": 1047,
                  "s_descripcion": "VIDRIERO"
                },
                {
                  "s_codigo": 1048,
                  "s_descripcion": "INGENIERO DE ASEGURAMIENT"
                },
                {
                  "s_codigo": 1049,
                  "s_descripcion": "VICERECTOR (A)"
                },
                {
                  "s_codigo": 1050,
                  "s_descripcion": "FONOAUDIOLOGO (A)"
                },
                {
                  "s_codigo": 1051,
                  "s_descripcion": "INGENIERO CIVIL"
                },
                {
                  "s_codigo": 1052,
                  "s_descripcion": "QC CIVIL"
                },
                {
                  "s_codigo": 1053,
                  "s_descripcion": "AUXILIAR DE DIGITACION"
                },
                {
                  "s_codigo": 1054,
                  "s_descripcion": "MEDICO ESPECIALISTA"
                },
                {
                  "s_codigo": 1055,
                  "s_descripcion": "SEGURIDAD"
                },
                {
                  "s_codigo": 1056,
                  "s_descripcion": "SOPORTE ADMINISTRATIVO"
                },
                {
                  "s_codigo": 1057,
                  "s_descripcion": "GERENTE OPERACIONAL"
                },
                {
                  "s_codigo": 1058,
                  "s_descripcion": "ASESOR INMOBILIARIO"
                },
                {
                  "s_codigo": 1059,
                  "s_descripcion": "AUXILIAR DE CAJA"
                },
                {
                  "s_codigo": 1060,
                  "s_descripcion": "JEFE DEPTO MANTENIMIENTO"
                },
                {
                  "s_codigo": 1061,
                  "s_descripcion": "VALIDADOR"
                },
                {
                  "s_codigo": 1062,
                  "s_descripcion": "GERENTE DE CREDITO"
                },
                {
                  "s_codigo": 1063,
                  "s_descripcion": "AUXILIAR DE PORTERIA"
                },
                {
                  "s_codigo": 1064,
                  "s_descripcion": "TECNICO EN CONTABILIDAD"
                },
                {
                  "s_codigo": 1065,
                  "s_descripcion": "TECNICO RECAUDO Y CARTERA"
                },
                {
                  "s_codigo": 1066,
                  "s_descripcion": "JEFE DE AREA"
                },
                {
                  "s_codigo": 1067,
                  "s_descripcion": "PERSONAL ESPECIAL"
                },
                {
                  "s_codigo": 1068,
                  "s_descripcion": "CORREDOR"
                },
                {
                  "s_codigo": 1069,
                  "s_descripcion": "JEFE DE VERIFICACION"
                },
                {
                  "s_codigo": 1070,
                  "s_descripcion": "ORIENTADOR"
                },
                {
                  "s_codigo": 1071,
                  "s_descripcion": "SALVAVIDAS"
                },
                {
                  "s_codigo": 1072,
                  "s_descripcion": "FARMACEUTA"
                },
                {
                  "s_codigo": 1073,
                  "s_descripcion": "JEFE DE PRESUPUESTOS"
                },
                {
                  "s_codigo": 1074,
                  "s_descripcion": "PILOTO"
                }
              ]
            }
          }
  }

  export const references = {
            "datos_referencia": {
              "datos_refer": [
                {
                  "n_consecutivo": 1,
                  "i_pws_num_solicitud": 47,
                  "c_pws_identificacion": 262718328,
                  "i_pws_tip_identificacon": 13,
                  "i_ref_tip": 0,
                  "i_pws_vinculo": "PADRE",
                  "c_pws_ref_nomcomple": "",
                  "i_pws_ref_telfu": 1234,
                  "i_pws_ref_telcle": 1234
                },
                {
                  "n_consecutivo": 4,
                  "i_pws_num_solicitud": 47,
                  "c_pws_identificacion": 262718328,
                  "i_pws_tip_identificacon": 13,
                  "i_ref_tip": 1,
                  "i_pws_vinculo": "PADRE",
                  "c_pws_ref_nomcomple": "",
                  "i_pws_ref_telfu": 123,
                  "i_pws_ref_telcle": 123
                },
                {
                  "n_consecutivo": 5,
                  "i_pws_num_solicitud": 0,
                  "c_pws_identificacion": 262718328,
                  "i_pws_tip_identificacon": 13,
                  "i_ref_tip": 1,
                  "i_pws_vinculo": "PADRE",
                  "c_pws_ref_nomcomple": "",
                  "i_pws_ref_telfu": 123,
                  "i_pws_ref_telcle": 123
                },
                {
                  "n_consecutivo": 6,
                  "i_pws_num_solicitud": 0,
                  "c_pws_identificacion": 262718328,
                  "i_pws_tip_identificacon": 13,
                  "i_ref_tip": 0,
                  "i_pws_vinculo": "PADRE",
                  "c_pws_ref_nomcomple": "",
                  "i_pws_ref_telfu": "",
                  "i_pws_ref_telcle": ""
                }
              ]
            }
  }