import {Switch} from "@mui/material";
import {ErrorMessage, Field} from "formik";
import { useEffect, useState } from "react";
import {useIntl} from "react-intl";
import {Col, FormGroup, Label, Row} from "reactstrap";
import { AdminService } from "../../../services";
import './applicant-info.scss';


interface AplicantOptionsProps {
    errors?: any;
    values?: any;
    pwsCodCredi: string;
}
export function AplicantOptions(props: AplicantOptionsProps) {
    const {errors, pwsCodCredi} = props;
    const intl = useIntl();
    const [one] =useState(false)
    const [allOcupaciones, SetAllOcupaciones] = useState([]);
    
    useEffect(()=>{
        getAlldata()
    },[one])
    
    const getAlldata = async () => {
        const response = await AdminService.getOcupaciones();
        if (response.payload.data.ListOcup.Datos_Ocupaciones.length > 0) {
            SetAllOcupaciones(response.payload.data.ListOcup.Datos_Ocupaciones);
        }
      };
    
      const filterOcupaciones = () => {
        if (pwsCodCredi === "547") {
          return [{ s_codigo: "5", s_descripcion: "Asalariado" }];
        } else if (pwsCodCredi === "5") {
          return [{ s_codigo: "4", s_descripcion: "Pensionado" }];
        } else {
          return allOcupaciones;
        }
      };
      const renderSelectOptions = () => {
        if (pwsCodCredi === "5" || pwsCodCredi === "547") {
          return filterOcupaciones().map((item: any, key) => (
            <option value={item.s_codigo}>{item.s_descripcion}</option>
          ));
        } else {
          return (
            <>
              <option disabled value="">
                Seleccione...
              </option>
              {filterOcupaciones().map((item: any, key) => (
                <option value={item.s_codigo}>{item.s_descripcion}</option>
              ))}
            </>
          );
        }
      };
    return (
        <div>
            <Row className="container-border-app-options">
                <Col xl={"3"} lg={"3"} sm={"12"} md={"4"} xs={"12"}>
                    <section>
                        <Label className="label-form display-flex" for="Pws_Tip_person">
                            {intl.formatMessage({id: "who_accredits_motorcycle"})}
                        </Label>
                        <br/>
                        <div className="display-flex">
                            <Field as="select"
                                   className={`${errors.Pws_Tip_person ? 'form-know-you-item-input-smaller-2 display-flex input-error' : 'form-know-you-item-input-smaller-2 display-flex'}`}
                                   name="Pws_Tip_person">
                                <option>{'Seleccione...'}</option>
                                <option value={'N'}>{'Natural'}</option>
                                <option value={'J'}>{'Juridico'}</option>
                            </Field>
                        </div>
                    </section>
                </Col>
                <Col xl={"3"} lg={"3"} sm={"6"} md={"4"} xs={"12"}>
                    <FormGroup>
                        <Label className="label-form display-flex" for="Pws_Tip_ocupa">
                            {intl.formatMessage({id: "placeholder_occupation_type"})}
                        </Label>
                        <br/>
                        <div className="display-flex">
                            <Field as="select"
                                   className={`${errors.Pws_Tip_ocupa ? 'form-know-you-item-input-smaller-2 display-flex input-error' : 'form-know-you-item-input-smaller-2 display-flex'}`}
                                   name="Pws_Tip_ocupa">
                                {renderSelectOptions()}
                            </Field>
                        </div>
                    </FormGroup>
                </Col>
                <Col style={{ height: '23vh' }} xl={"3"} lg={"2"} sm={"6"} md={"2"}>
                    {/* <FormGroup>
                        <Label className="label-form display-flex" for="Pws_Fideliza">
                            {intl.formatMessage({id: "loyalty"})}
                        </Label>
                        <br/>
                        <div className="col-3 toggle-app">{intl.formatMessage({id: "button_no"})}<Field
                            name="Pws_Fideliza" component={Switch}
                            type="checkbox"/>{intl.formatMessage({id: "button_yes"})}</div>
                    </FormGroup> */}
                </Col>
                <Col xl={"1"} lg={"4"}/>
            </Row>
        </div>
    )
}