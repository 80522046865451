import { createContext, useState } from "react";

interface RequestProviderProps {
    children: React.ReactNode;
}

type Request = {
    numSol?: string,
    dateSol?: string,
    numIdentification?: string,
    typeIdentification?: string,
    email?: string,
    phoneNumber?: string,
    nameProduct?: string,
    priceProduct?: string,
    personCygnus?: CygnusPersona,
    personANI?: ANIPersona,
    simulator?: Simulator,
    valueOfInicialQuote?: string,
    valueToFinance?: string,
    valueOfQuote?: string,
    searchANI: boolean,
    errorConsulta: boolean,
    imagemoto?: string,
    datosFirma?: {
        numIdentification?: string;
        typeIdentification?: string,
        email?: string,
        phoneNumber?: string
    }
    references?: any,
    isRotativo?:boolean,
    direcciones?:any,
    valoresLogin?:any,
    valoresDireccion?:any,
    dataModifcable?:any,
    valorSolicitud?:any
}

export type CygnusPersona = {
    Pws_Identificacion: string;
    Pws_Tip_Identificacion: string;
    Pws_Tip_Estado: string;
    Pws_Tip_Perfil: string;
    Pws_Tip_person: string;
    Pws_Tip_ocupa: string;
    Pws_Nombres: string;
    Pws_Apellidos1: string;
    Pws_Apellidos2: string;
    Pws_Telefono1: string;
    Pws_Telefono2: string;
    Pws_Correo: string;
    Pws_Fec_expe: string;
    Pws_Lugar_exp: string;
    Pws_Fec_nacime: string;
    Pws_Estado_Civil: string;
    Pws_Direccion_res: string;
    Pws_Departamento: string;
    Pws_Ciudad_res: string;
    Pws_Genero: string;
    Pws_Estrato_per: string;
    Pws_Per_cargo: string;
    Pws_Tip_vivienda: string;
    Pws_Niv_estudio: string;
    Pws_Nom_empre: string;
    Pws_fec_ingemp: string;
    Pws_Tip_contra: string;
    Pws_Ant_labo: string;
    Pws_Car_emp: string;
    Pws_Nom_Jefedi: string;
    Pws_Direc_emp: string;
    Pws_Ciud_emp: string;
    Pws_tel_emp1: string;
    Pws_tel_emp2: string;
    Pws_Fideliza: string;
}

export type ANIPersona = {
    idTransaccion: string;
    nuip: string;
    codigoErrorDatosCedula: string;
    primerApellido: string;
    particula: string;
    segundoApellido: string;
    primerNombre: string;
    segundoNombre: string;
    municipioExpedicion: string;
    departamentoExpedicion: string;
    fechaExpedicion: string;
    estadoCedula: string;
    numeroResolucion: string;
    anioResolucion: string;
}

export type Simulator = {
    R_Val_cuota: string,
    R_s_tasa: string,
    R_ValorCuotaAsociado: string,
    R_Liquidacion: {
        LiquidacionAtr: []
    }
}

const requestDefault: Request = {searchANI: false, errorConsulta: false};
export const RequestContext = createContext({request: requestDefault, setRequest: (request: Request) => {}})

export const RequestProvider = ({children}: RequestProviderProps) => {
    const [request, setRequest] = useState({searchANI: false, errorConsulta: false});
    return (
       <RequestContext.Provider value={{request, setRequest}}>
            {children}
       </RequestContext.Provider> 
    )
}