import React, {useEffect, useState} from 'react';
import './md.scss';
import {MenuBar} from '../../components/shared/Menubar/Menubar';
import { useLocation, useNavigate } from 'react-router-dom';
import {AppRoutes} from "../../Router";
import {FUllPill} from '../../components/shared/Pill/Pill';
import { WLOCreditAPIService } from '../../services';
import { useSignContext } from '../../hooks/useSignContext';
import { useRequestContext } from '../../hooks/useRequestContext';
import { ArrowForward} from '@mui/icons-material';
import Fab from '@mui/material/Fab';

interface MensajProps {
}

export function MensajeDespuesdeValidarSargilat(props: MensajProps) {
  const {sign, setSign} = useSignContext();
  const {request, setRequest} = useRequestContext();
    const [texto, setTExto] = useState("");
    const [sargilat, setSargilat] = useState(false);
    const [button, setButton] = useState(false);
    const [estudio, setEstudio] = useState(false);
    const [Negado, setNEgado] = useState(false);
    const [DataCreditoCodeudor, setDataCreditoCodeudor] = useState(false);
    const [solicutde, setSolicutde] = useState(false);
    let buscar = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        document.body.classList.add('body-login17');
        return () => {
            document.body.classList.remove('body-login17');
        };
    }, []);
    useEffect(()=>{
        if(buscar){
          var valorBusqueda=buscar.search.replace("?","")
          var valorBusqueda2=valorBusqueda.split("-")
          if(Number(valorBusqueda2[0])===0){
            setTExto("NO")
            setSargilat(false)
            if(Number(request.valoresLogin.tipodeudor)<2){
              setButton(false)
            }else{
              setButton(true)

            }
          }else{
            setTExto("SI")   
            if(Number(request.valoresLogin.tipodeudor)<2){
              setButton(true)
            }else{
              setButton(true)

            }
            setSargilat(true)
          }
          
          if(Number(valorBusqueda2[1])===0){
            setNEgado(false)
          }else{
            setNEgado(true)
          }

          if(Number(valorBusqueda2[2])===0){
            setDataCreditoCodeudor(false)
          }else{
            setDataCreditoCodeudor(true)
          }
          
          if(Number(valorBusqueda2[3])===0){
            
      if(Number(request.valoresLogin.tipodeudor)<2){
            setEstudio(false)
          }
          }else{
            setEstudio(true)
          }
        }
      },[buscar]);
      const valorChange=async ()=>{
        let valorfalse=true
        let check=document.getElementById("checkox") as HTMLInputElement 
        if(check){
          valorfalse=check.checked
        } 
        if(!DataCreditoCodeudor && !Negado && !sargilat  && !estudio && valorfalse){
          setButton(true)

        }else{    
          if(Number(request.valoresLogin.tipodeudor)<2){
            const datas={
              id:"hola",
              number:request.numSol!,
              deseasMoto:!valorfalse,
              email:sign.email!,
              typeIdent:sign.typeIdentification!,
              numbIdent:sign.numIdentification!,
              codeudorReq:DataCreditoCodeudor,
              Negado:Negado,
              sargilaft:sargilat,
              enestudio:estudio
            }
            const response = await WLOCreditAPIService.getEmail(datas);
            setButton(true)
        }
          
        }
      
      }
    return (
      
        <div className="mansaje">
          
        {
      solicutde
      ?
      <>
        <div className="soliBody" onClick={()=>{
          valorChange()
          setSolicutde(false)}}></div>
        <div className="solicutud">
          <p>¿La moto es para ti?</p>
          <div className="swicth-tab">
              <span><FUllPill check={false}  disabled={false} id={"checkox"} name={"checkox"}/></span>
          </div>
          <div>
              <Fab variant="extended" color="error" aria-label="add" 
                    onClick={()=>{
                    valorChange()
                    setSolicutde(false)}}>
                  continuar
                  <ArrowForward sx={{mr: 1}}/>
              </Fab>
            </div>
        </div>
       
      </>
      :<></>
    }
            <MenuBar/>
            <div className="mansajeBody">
                <h4>cliente presenta riesgo: </h4>
                <h1 >{texto}</h1>
            </div>
            {
              button?<>
              
              </>:<>
              <Fab variant="extended" color="error" aria-label="add" 
                                             onClick={()=>{
                                              setSolicutde(true)}}>
                                            continuar
                                            <ArrowForward sx={{mr: 1}}/>
                                        </Fab>
              </>
            }
           
        </div>
    )
}