import React, {useEffect, useRef, useState} from 'react';
import './sd.scss';
import {ArrowBack} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {Search, ArrowDown} from '../../../assets/icons/component';
import {SolicTable} from './SolicTable.js';
import {useNavigate} from 'react-router-dom';
import {AppRoutes} from "../../../Router";
import { WLOCreditAPIService } from '../../../services';
import {WloCreditoService} from '../../../types';

const Options = [
    {
        option: 'Cliente'
    },
    {
        option: 'Ciudad'
    },
    {
        option: 'Estado Firma'
    },
    {
        option: 'Estado Credito'
    },
    {
        option: 'Linea de Credito'
    }
]

interface OptionProps {
    Options: {
        option: string
    }[];
    bg: string;
    clr: string;
    str: string;
}

export const Select: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
    return(
        <li>
            <ArrowDown stroke={str} />
            <select name="search" id="search" style={{background:bg,color:clr}}>
                {
                    Options.map((option,i)=>{
                        return(
                            <option key={i} value={option.option}>{option.option}</option>
                        )
                    })
                }
            </select>
        </li>
    )
}
export const Select2: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
    return(
        <>
            <ArrowDown stroke={str} />
            <select name="search" id="search" style={{background:bg,color:clr}}>
                {
                    Options.map((option,i)=>{
                        return(
                            <option key={i} value={option.option}>{option.option}</option>
                        )
                    })
                }
            </select>
        </>
    )
}

export const SolicDocument: React.FC = () => {
    const navigate = useNavigate();
    const textInputRef = useRef<HTMLInputElement>(null);
    const [one]= useState(false)
    const [table,setTable]= useState([])
    const [table2,setTable2]= useState([])
    const handleSubmit = (event: React.FormEvent) => {
        const select = document.getElementById('search') as HTMLSelectElement | null;

        event.preventDefault();
        let enteredText = textInputRef.current!.value;
        if(table.length>0){
            if(select?.value==="Cliente"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_Nombres.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }else if(select?.value==="Ciudad"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_ciudad.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }else if(select?.value==="Estado Firma"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_Estado_Firma.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }else if(select?.value==="Estado Credito"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_Estado_credito.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }else if(select?.value==="Linea de Credito"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_Lincre.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }
        }
    }

    useEffect(()=>{
        functionss()
    },[one])
    const functionss = ()=> {
        let valoragregado:WloCreditoService.SolicitudListadoRequest={
            Pws_num_solicitud:"",
        Pws_fec_solicitud_ini:"",
        Pws_fec_solicitud_fin:"",
        Pws_Tip_estado: "",
        Pws_Tip_Consulta: "1"
        }
        WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado).then((consulta)=>{
        if(consulta.payload.data.datos_soli){
        if(consulta.payload.data.datos_soli.datos_Sol.length>0){
            setTable(consulta.payload.data.datos_soli.datos_Sol)
            setTable2(consulta.payload.data.datos_soli.datos_Sol)
        }
        }
        },(error)=>{
            console.log(error)
        })
    }
    
    return (
    <div className="solicDoc">
        <Fab variant="extended" color="neutral" aria-label="add"
                        onClick={()=>{
                            navigate(AppRoutes.MENU_USER, {replace: true});
                        }} style={{float:"left"}} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                        {'Atrás'}
                    </Fab>
        <div className="solicDocument">
            <h4>Solicitudes de crédito</h4>
            <div className="solicTable">
                <div className="search">
                    <form action="" method="post" onSubmit={handleSubmit}>
                        <Select Options={Options} bg={`#4A4A4A`} clr={`#fff`} str={`#fff`} />
                        <li>
                            <div onClick={handleSubmit}>
                            <Search  />
                            </div>
                            <input type="text" name="seacrh" placeholder="search" ref={textInputRef}/>
                        </li>
                    </form>
                </div>
                <div className="table">
                    <SolicTable value={{tablasva:table2,functionss}} />
                </div>
            </div>
        </div>
    </div>
  )
}
