import {AppStrings} from '../messages';

export const English: AppStrings = {
    continue: 'continue',
    button_continue: 'Continue',
    button_send_mail: 'Send mail',
    button_calculate_quote: 'Calculate quota',
    button_back: 'Back',
    button_next: 'Next',
    button_answer: 'Answer',
    button_yes: 'YES',
    button_no: 'NO',
    button_cancel: 'Cancel',
    button_approved: 'Approved',
    button_process: 'Process',
    button_add: 'Add',
    button_step_credit_information: 'Credit Information',
    button_step_person_information: 'Applicant Information',
    button_step_finance_information: 'Financial Information',
    button_step_reference_information: 'References and documents',
    login_title: "First of all let's validate your identity",
    login_subtitle: 'Enter the following information to continue',
    form_login_document: 'Document',
    form_login_password: 'Password',
    form_login_lost_password: "I forgot my password.",
    form_credit_information_title: 'We want to get to know you a little more',
    form_credit_information_subtitle: 'Please fill in the following information',
    form_credit_information_selection: 'If you wish to continue, enter the initial installment value and verify the installments you wish to pay according to the term.',
    form_credit_information_note: 'The value of the installment is subject to the approval of the quota',
    form_credit_information_promotion_title: 'To fulfill your dream of buying THE MOTORCYCLE',
    form_credit_information_promotion_subtitle: 'We offer you a credit',
    form_applicant_information_title: "Now let's confirm your information",
    form_financial_information_title: 'Financial information',
    form_financial_operations_title: 'Tell us about your financial operations',
    form_financial_information_subtitle: 'Applicant Income',
    form_financial_main_income: 'Main Income',
    form_financial_other_income: 'Other Income',
    form_financial_other_income_placeholder: 'Describe the concept of other income',
    form_financial_expenditure_value: 'Expenditure value',
    form_financial_applicant_properties: 'Applicant Properties',
    form_financial_income_tax_filer: 'Income Filer',
    form_financial_foreign_currency: 'Do you carry out operations in foreign currency?',
    form_financial_foreign_currency_which: 'Which operations?',
    form_financial_currency: 'Currency',
    form_financial_bill_foreign_currency: 'You have foreign currency accounts',
    form_financial_bank_foreign_currency: 'Bank',
    form_financial_number_foreign_currency: 'Account number',
    form_financial_country_foreign_currency: 'Country',
    form_financial_city_foreign_currency: 'City',
    form_financial_pep_title: 'PEP information. (Publicly Exposed Persons)',
    form_financial_pep_subtitle: 'For your safety please answer the following questions',
    form_documents_information_title: 'References and documents',
    option_credit: 'Choose a credit option',
    initial_quote_value: 'Initial quota value',
    value_to_request:'Value to request',
    value_to_finance: 'Value to finance',
    value_of_quote: 'Quota Value',
    term: 'Deadline',
    placeholder_document_type: 'Document type',
    placeholder_occupation_type: 'Occupation Type',
    placeholder_identification_type: 'Identification Type',
    placeholder_identification: 'Identification',
    placeholder_document_number: 'Document number',
    placeholder_field_name: 'First and last names',
    placeholder_phone_1: 'Phone 1',
    placeholder_phone_2: 'Phone 2',
    placeholder_mail: 'Email',
    placeholder_password: 'Password',
    placeholder_mail_confirmation: 'Mail confirmation',
    placeholder_dropdown: 'Select',
    placeholder_initial_quote_text: 'Automatically calculated',
    placeholder_initial_quote_symbol: '$',
    placeholder_field_names: 'Names',
    placeholder_field_last_names: 'Last Names',
    placeholder_expedition_date: 'Issue date',
    placeholder_expedition_place: 'Place of expedition',
    placeholder_date_birth: 'Date of birth',
    placeholder_marital_status: 'Marital Status',
    placeholder_company_address: 'Company address',
    placeholder_direct_boss: 'Direct boss',
    placeholder_job_that_performs: 'Job that performs',
    placeholder_labor_old: 'Labor seniority',
    placeholder_type_of_contract: 'Type of contract',
    placeholder_date_of_admission: 'Date of admission',
    placeholder_company_name: 'Company Name',
    placeholder_spouse: 'Spouse',
    placeholder_educational_level: 'Level of studies',
    placeholder_type_of_housing: 'Type of housing',
    placeholder_number_of_people_in_charge: 'Number of people in charge',
    placeholder_gender: 'Gender',
    placeholder_social_stratum: 'Social stratum',
    title_confirm_your_profile_to_continue: 'CONFIRM YOUR PROFILE TO CONTINUE',
    welcome: 'WELCOME',
    motorcycles: 'MOTORCYCLES',
    loyalty: 'Loyalty',
    who_accredits_motorcycle: 'Who accredits the motorcycle?',
    credit_manager: 'Credit manager',
    admin: 'Administrator',
    seller: 'Seller',
    self_management: 'Self-management',
    how_can_we_help_you_today: 'How can we help you today?',
    credit_applications: 'Credit Applications',
    manage_credit: 'Manage credit',
    change_of_password: 'Change of password',
    choose_the_one_of_your_preference: 'Choose the one of your preference',
    it_is_calculated_automatically: "It is calculated automatically",
    peps_question_1: 'Do you manage public resources by your position?',
    peps_question_2: 'Because of your position or activity, do you exercise any degree of public power?',
    peps_question_3: 'Do you enjoy public recognition?',
    peps_question_4: 'Do you have any link with a person considered publicly exposed?',
    peps_question_5: 'Do you have 2° of affinity and 2° of consanguinity (Parents, siblings, wife, cousins, in-laws, brothers-in-law or brothers-in-law) with PEP?',
    relationship: 'Relationship',
    identification_card: 'Identification Card',
    rental_value: 'Rental value',
    describe: 'Describe',
    placeholder_mobile_phone_1: 'Mobile phone 1',
    placeholder_mobile_phone_2: 'Mobile phone 2',
    residence_address: 'Residence address',
    department: 'Department',
    gender: 'Gender',
    enter: 'Enter',
    full_name_spouse: 'Full Name (Spouse)',
    identification_spouse: 'Identification (Spouse)',
    spouse_data: 'Spouse data',
    add_reference: 'Agregar referencia',
    english: 'English',
    spanish: 'Spanish',
    languages: 'Languages',
    toast_validating: 'Validating...',
    toast_processing: 'Processing...',
    toast_charging: 'Charging...',
    toast_failed_identity_validation: 'At this time it is not possible to validate your identity',

    do_search_catalog: 'The search is done and filtered automatically',

    client_successfully_credit_application: 'the client entered successfully to continue with the credit application',
    client_successfully_credit_application_note: 'We will be communicating with the client through the previously registered email and cell phone so that they can sign the corresponding authorizations and be able to continue with the request.',
    button_go_to_init: 'Go to home',
    nro_quotes: 'Nro. Quotes',
    attach_documents: 'Attach documents',
    required_documentation: 'Required documentation ',
    required_documentation_note_1: '(Documents with * are required. ',
    required_documentation_note_2: 'PDF, JPG AND PNG are allowed. Maximum size of 5Mb)',
}