import React, { useEffect, useState }  from 'react';
import './dcrt.scss';
import {ArrowBack,ArrowForward, LocalConvenienceStoreOutlined} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {FUllPill} from '../Pill/Pill';
import {Select} from '../SolicDocument/SolicDocument';
import {useNavigate,useLocation} from 'react-router-dom';
import {Doc, Person, File, RedCheck, Edit,Document, Check} from '../../../assets/icons/component';
import {typeIdentification,estractos,cities,Ocupaciones} from '../../../__mocks__/data';
import {AppRoutes} from "../../../Router";
import { WLOCreditAPIService ,AdminService} from '../../../services';
import { WloCreditoService } from '../../../types';
import toast from 'react-hot-toast';
import { Field,Form, Formik } from "formik";
import {Switch} from 'formik-mui';
import Moment from 'moment';
import {useIntl} from "react-intl";
import { useLoginContext } from "../../../hooks/useLoginContext";
import ParseNumberThousand from "../../shared/Utilities/ParseNumberThousand";
import _ from "lodash";
import { useRequestContext } from '../../../hooks/useRequestContext';
export const Box2 = (valorPersonal?:any) => {
    const {person} = valorPersonal.valorPersonal;
    const {request, setRequest} = useRequestContext();
    const [persona, DatoPersona] = React.useState({} as any);
    const [update, setUpdate] = React.useState(false);
    const [NoReadCedula, setReadCedula] = React.useState(true);
    const intl = useIntl();
    const [one] =React.useState(false)
    const [ciudades, setCiudades] = React.useState(cities as any);
    const [ciudades2, setCiudades2] = React.useState(cities as any);
    const [departamentos, setDepartamentos] = React.useState(cities as any)
    const [allCities, setAllCiudades] = React.useState(cities as any);
    const [EstadosCivil, setEstadosCivil] = useState([]);
    const [tipovivi, setTipoVivienda] = useState([]);
    const [NivelEsti, setNivelEstudio] = useState([]);
    const [TipoContrato, setAllTipoContrato] = useState([]);
    const [allOcupaciones, SetAllOcupaciones] = useState([]);
    useEffect(()=>{
    AdminService.getAllCiudades().then((Datos:any)=>{
        setAllCiudades(Datos.payload.data)
    })
    getAlldata();
    },[one])
    const getAlldata=async ()=>{
    const estadosCvil= await AdminService.getEstadosCiviles();
    if(estadosCvil.payload.data.listEstCivil.Datos_EstCivil.length>0){
        setEstadosCivil(estadosCvil.payload.data.listEstCivil.Datos_EstCivil)
    }
    const tipovivienda= await AdminService.gettipovivienda();
    if(tipovivienda.payload.data.tip_viv.datos_tip_vivi.length>0){
        setTipoVivienda(tipovivienda.payload.data.tip_viv.datos_tip_vivi)
    }
    const nivelEstudi= await AdminService.getNivelEstudio();
    if(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio.length>0){
        setNivelEstudio(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio)
    }
    const tipocon= await AdminService.getTipoContrato();
    if(tipocon.payload.data.listTipoContr.Datos_TipoContrato.length>0){
    setAllTipoContrato(tipocon.payload.data.listTipoContr.Datos_TipoContrato)
    }
    
        
    const ocupaciones= await AdminService.getOcupaciones();
    if(ocupaciones.payload.data.ListOcup.Datos_Ocupaciones.length>0){
        SetAllOcupaciones(ocupaciones.payload.data.ListOcup.Datos_Ocupaciones)
    }
}
    useEffect(()=>{
        
            const codeudorRequerido = {
                Pws_IdentificacionCode: valorPersonal.valorPersonal.person.Pws_Identificacion ,
                Pws_GeneroCode: valorPersonal.valorPersonal.person.Pws_Genero,
                Pws_Tip_IdentificacioCode: valorPersonal.valorPersonal.person.Pws_Tip_Identificacion,
                Pws_Ciudad_resCode: valorPersonal.valorPersonal.person.Pws_Ciudad_res,
                Pws_CorreoCode:valorPersonal.valorPersonal.person.Pws_Correo,
                Pws_Fec_expeCode: valorPersonal.valorPersonal.person.Pws_Fec_expe,
                Pws_Lugar_expCode: valorPersonal.valorPersonal.person.Pws_Lugar_exp,
                Pws_Apellidos1Code: valorPersonal.valorPersonal.person.Pws_Apellidos1,
                Pws_Apellidos2Code: valorPersonal.valorPersonal.person.Pws_Apellidos2,
                Pws_Tip_ocupaCode: valorPersonal.valorPersonal.person.Pws_Tip_ocupa,
                Pws_DepartamentoCode:valorPersonal.valorPersonal.person.Pws_Departamento,
                Pws_Telefono1Code:valorPersonal.valorPersonal.person.Pws_Telefono1,
                Pws_Tip_EstadoCode:valorPersonal.valorPersonal.person.Pws_Tip_Estado,
                Pws_Tip_personCode:valorPersonal.valorPersonal.person.Pws_Tip_person,
                s_arriendoCode:valorPersonal.valorPersonal.person.s_arriendo,
                s_concep_otr_ingreCode:valorPersonal.valorPersonal.person.s_concep_otr_ingre,
                s_ingreso_principalCode:valorPersonal.valorPersonal.person.s_ingreso_principal,
                Pws_Estrato_perCode:valorPersonal.valorPersonal.person.Pws_Estrato_per,
                s_nombreCode:valorPersonal.valorPersonal.person.Pws_Nombres,
                Pws_Per_cargoCode:valorPersonal.valorPersonal.person.Pws_Per_cargo,
                Pws_Tip_viviendaCode:valorPersonal.valorPersonal.person.Pws_Tip_vivienda,
                Pws_Niv_estudioCode: valorPersonal.valorPersonal.person.Pws_Niv_estudio,
                Pws_Nom_empreCode: valorPersonal.valorPersonal.person.Pws_Nom_empre,
                Pws_fec_ingempCode: valorPersonal.valorPersonal.person.Pws_fec_ingemp,
                Pws_Tip_contraCode: valorPersonal.valorPersonal.person.Pws_Tip_contra,
                Pws_Ant_laboCode: valorPersonal.valorPersonal.person.Pws_Ant_labo,
                Pws_Car_empCode: valorPersonal.valorPersonal.person.Pws_Car_emp,
                Pws_Nom_JefediCode: valorPersonal.valorPersonal.person.Pws_Nom_Jefedi,
                Pws_Direc_empCode: valorPersonal.valorPersonal.person.Pws_Direc_emp,
                Pws_Ciud_empCode: valorPersonal.valorPersonal.person.Pws_Ciud_emp,
                Pws_tel_emp1Code: valorPersonal.valorPersonal.person.Pws_tel_emp1,
                Pws_tel_emp2Code: valorPersonal.valorPersonal.person.Pws_tel_emp2,
                Pws_FidelizaCode: valorPersonal.valorPersonal.person.Pws_Fideliza,
                Pws_Fec_nacimeCode: valorPersonal.valorPersonal.person.Pws_Fec_nacime,
                Pws_Telefono2Code: valorPersonal.valorPersonal.person.Pws_Telefono2,
                s_otros_ingresosCode: valorPersonal.valorPersonal.person.s_otros_ingresos ,
                s_otros_egresosCode: valorPersonal.valorPersonal.person.s_otros_egresos,
                s_declarante_renCode: valorPersonal.valorPersonal.person.s_declarante_ren,
                s_moneda_extCode: valorPersonal.valorPersonal.person.s_moneda_ext,
                s_monext_operCode: valorPersonal.valorPersonal.person.s_monext_oper,
                s_tip_monextCode: valorPersonal.valorPersonal.person.s_tip_monext,
                Pws_Direccion_resCode: valorPersonal.valorPersonal.person.Pws_Direccion_res,
                Pws_Estado_CivilCode: valorPersonal.valorPersonal.person.Pws_Estado_Civil,
                s_cuent_extCode: valorPersonal.valorPersonal.person.s_cuent_ext,
                s_cuen_extbanCode: valorPersonal.valorPersonal.person.s_cuen_extban,
                s_cuen_extnumCode: valorPersonal.valorPersonal.person.s_cuen_extnum,
                s_cuen_extpaisCode: valorPersonal.valorPersonal.person.s_cuen_extpais,
                s_cuen_extciudadCode: valorPersonal.valorPersonal.person.s_cuen_extciudad,
                s_pep_recpublicCode: valorPersonal.valorPersonal.person.s_pep_recpublic,
                s_pep_poderpublicCode: valorPersonal.valorPersonal.person.s_pep_poderpublic,
                s_pep_reconpublicCode: valorPersonal.valorPersonal.person.s_pep_reconpublic,
                s_pep_pubexpueCode: valorPersonal.valorPersonal.person.s_pep_pubexpue,
                s_pep_seggraconsaCode: valorPersonal.valorPersonal.person.s_pep_seggraconsa,
                s_pep_nompepsegCode: valorPersonal.valorPersonal.person.s_pep_nompepseg,
                s_pep_parenCode: valorPersonal.valorPersonal.person.s_pep_paren,
                s_pep_identifCode: valorPersonal.valorPersonal.person.s_pep_identif
            }
            localStorage.setItem('codeudor', JSON.stringify(codeudorRequerido));
        
    },[valorPersonal])


useEffect(()=>{
    if(request.direcciones){
        valorPersonal.valorPersonal.person.Pws_Direccion_res=request.direcciones.road+" "+request.direcciones.mainno+" "+request.direcciones.suffix+" "+request.direcciones.secondaryno+" "+request.direcciones.suffix2+" "+request.direcciones.complementaryno+" "+request.direcciones.Complement+" "+request.direcciones.complementaryno2+" "+request.direcciones.Complement3+" "+request.direcciones.complementaryno3+" "+request.direcciones.Neighborhood
        valorPersonal.valorPersonal.person.Pws_Direccion_res=request.direcciones.road+" "+request.direcciones.mainno+" "+request.direcciones.suffix+" "+request.direcciones.secondaryno+" "+request.direcciones.suffix2+" "+request.direcciones.complementaryno+" "+request.direcciones.Complement+" "+request.direcciones.complementaryno2+" "+request.direcciones.Complement3+" "+request.direcciones.complementaryno3+" "+request.direcciones.Neighborhood
    }
    if(request.dataModifcable){
        if(request.dataModifcable.idUser){
            setReadCedula(false)
        }
    }

},[request,setRequest])
const handleSearch = async () => {
    if (!_.isEmpty(valorPersonal.valorPersonal.person.Pws_Tip_Identificacion!) || !_.isEmpty(valorPersonal.valorPersonal.person.Pws_Identificacion!)) {
        if (valorPersonal.valorPersonal.person.Pws_Identificacion.length < 5) {
            toast.error('Debe indicar un Número de Identificación entre 5 y 10 caracteres',{
                duration:5000,
            });
        } else {
            try {
                const person2 = await WLOCreditAPIService.consultaSolicitudCygnus({
                    Pws_Tip_Identificacion: valorPersonal.valorPersonal.person.Pws_Tip_Identificacion!,
                    Pws_Identificacion: valorPersonal.valorPersonal.person.Pws_Identificacion!
                });
                if (!_.isEmpty(person2.payload)) {
                    if (person2.payload.result === "1") {
                        person.Pws_Apellidos1=person2.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos1
                        person.Pws_Apellidos2=person2.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos2
                        person.Pws_Tip_person=person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_person
                        person.Pws_Nombres=person2.payload.data.datos_persona.Datos_persona[0].Pws_Nombres
                        person.Pws_Tip_Perfil=person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_Perfil
                        person.Pws_Telefono1=person2.payload.data.datos_persona.Datos_persona[0].Pws_Telefono1
                        person.Pws_Telefono2=person2.payload.data.datos_persona.Datos_persona[0].Pws_Telefono2
                        person.Pws_Correo=person2.payload.data.datos_persona.Datos_persona[0].Pws_Correo
                        person.Pws_Tip_ocupa=person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_ocupa
                        person.Pws_Fec_expe=person2.payload.data.datos_persona.Datos_persona[0].Pws_Fec_expe.split("-00")[0]
                        person.Pws_Lugar_exp=person2.payload.data.datos_persona.Datos_persona[0].Pws_Lugar_exp
                        person.Pws_Fec_nacime=person2.payload.data.datos_persona.Datos_persona[0].Pws_Fec_nacime.split("-00")[0]
                        person.Pws_Estado_Civil=person2.payload.data.datos_persona.Datos_persona[0].Pws_Estado_Civil
                        person.Pws_Direccion_res=person2.payload.data.datos_persona.Datos_persona[0].Pws_Direccion_res
                        person.Pws_Departamento=person2.payload.data.datos_persona.Datos_persona[0].Pws_Departamento
                        person.Pws_Ciudad_res=person2.payload.data.datos_persona.Datos_persona[0].Pws_Ciudad_res
                        person.Pws_Genero=person2.payload.data.datos_persona.Datos_persona[0].Pws_Genero
                        person.Pws_Estrato_per=person2.payload.data.datos_persona.Datos_persona[0].Pws_Estrato_per
                        person.Pws_Per_cargo=person2.payload.data.datos_persona.Datos_persona[0].Pws_Per_cargo
                        person.Pws_Tip_vivienda=person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_vivienda
                        person.Pws_Niv_estudio=person2.payload.data.datos_persona.Datos_persona[0].Pws_Niv_estudio
                        person.Pws_Nom_empre=person2.payload.data.datos_persona.Datos_persona[0].Pws_Nom_empre
                        person.Pws_fec_ingemp=person2.payload.data.datos_persona.Datos_persona[0].Pws_fec_ingemp.split("-00")[0]
                        person.Pws_Fideliza=person2.payload.data.datos_persona.Datos_persona[0].Pws_Fideliza
                        person.Pws_Tip_contra=person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra
                        person.Pws_Ant_labo=person2.payload.data.datos_persona.Datos_persona[0].Pws_Ant_labo
                        person.Pws_Car_emp=person2.payload.data.datos_persona.Datos_persona[0].Pws_Car_emp
                        person.Pws_Nom_Jefedi=person2.payload.data.datos_persona.Datos_persona[0].Pws_Nom_Jefedi
                        person.Pws_Direc_emp=person2.payload.data.datos_persona.Datos_persona[0].Pws_Direc_emp
                        person.Pws_Ciud_emp=person2.payload.data.datos_persona.Datos_persona[0].Pws_Ciud_emp
                        person.Pws_tel_emp1=person2.payload.data.datos_persona.Datos_persona[0].Pws_tel_emp1
                        person.Pws_tel_emp2=person2.payload.data.datos_persona.Datos_persona[0].Pws_tel_emp2
                        setReadCedula(false)
                    } else {
                        const personANI = await WLOCreditAPIService.consultaSolicitudANI({
                            Pws_Tip_Identificacion: valorPersonal.valorPersonal.person.Pws_Tip_Identificacion!,
                            Pws_Identificacion: valorPersonal.valorPersonal.person.Pws_Identificacion!
                        });
                        person.Pws_Apellidos1=personANI.primerApellido
                        person.Pws_Apellidos2=personANI.segundoApellido
                        person.Pws_Tip_person="2"
                        person.Pws_Nombres=personANI.primerNombre+" "+personANI.segundoNombre
                        person.Pws_Telefono1=""
                        person.Pws_Telefono2=""
                        person.Pws_Correo=""
                        person.Pws_Tip_ocupa=""
                        person.Pws_Fec_expe=""
                        person.Pws_Lugar_exp= _.filter( ciudades.ListCiudad.Datos_Ciudad, { s_nivel: '3',s_nombre: personANI.municipioExpedicion}).length>0?_.filter( ciudades.ListCiudad.Datos_Ciudad, {s_nivel: '3', s_nombre: personANI.municipioExpedicion})[0].s_codigo:""
                        person.Pws_Fec_nacime=""
                        person.Pws_Estado_Civil=""
                        person.Pws_Direccion_res=""
                        person.Pws_Departamento=""
                        person.Pws_Ciudad_res=""
                        person.Pws_Genero=""
                        person.Pws_Estrato_per=""
                        person.Pws_Per_cargo=""
                        person.Pws_Tip_vivienda=""
                        person.Pws_Niv_estudio=""
                        person.Pws_Nom_empre=""
                        person.Pws_fec_ingemp=""
                        person.Pws_Fideliza=""
                        person.Pws_Tip_contra=""
                        person.Pws_Ant_labo=""
                        person.Pws_Car_emp=""
                        person.Pws_Nom_Jefedi=""
                        person.Pws_Direc_emp=""
                        person.Pws_Ciud_emp=""
                        person.Pws_tel_emp1=""
                        person.Pws_tel_emp2=""
                        setReadCedula(false)
                    }
                }
            } catch (e) {
                const error = e as any;
                console.log(error)
            }
        }
    }
}
useEffect(()=>{
    if(allCities){
    
    let citiesDepent = {
        "ListCiudad": {
            "Datos_Ciudad": [] as any
        }
    }
    citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '2'});
    setDepartamentos(citiesDepent)
    }
},[allCities])

    const handleCities = (e: { target: { value: string; }; }) => {
        let citiesDepent = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3', s_ubicacion: e.target.value });
        setCiudades(citiesDepent);
    }

    useEffect(()=>{
        const personainfo=valorPersonal.valorPersonal.person
        const personaRequest = {
            Pws_Identificacion: "",
            Pws_Tip_Identificacion: "",
            Pws_Tip_Estado: "2", // Valor constante para confirmación de información.
            Pws_Tip_Perfil:  "",
            Pws_Tip_person:  "",
            Pws_Tip_ocupa:  "",
            Pws_Nombres:  "",
            Pws_Apellidos1:  "",
            Pws_Apellidos2:  "",
            Pws_Telefono1:  "",
            Pws_Telefono2:  "",
            Pws_Correo:  "",
            Pws_Fec_expe:  "" ,
            Pws_Lugar_exp: "",
            Pws_Fec_nacime: "" ,
            Pws_Estado_Civil:  "",
            Pws_Direccion_res:  "",
            Pws_Departamento:  "",
            Pws_Ciudad_res:  "",
            Pws_Genero:  "",
            Pws_Estrato_per:  "",
            Pws_Per_cargo:  "",
            Pws_Tip_vivienda: "",
            Pws_Niv_estudio:  "",
            Pws_Nom_empre:  "",
            Pws_fec_ingemp:  "",
            Pws_Fideliza:  "",
            Pws_Tip_contra:  "",
            Pws_Ant_labo:  "",
            Pws_Car_emp:  "",
            Pws_Nom_Jefedi:  "",
            Pws_Direc_emp:  "",
            Pws_Ciud_emp:  "",
            Pws_tel_emp1:  "",
            Pws_tel_emp2: "",
        }
        const infoUpdatePerson = {...personaRequest, Pws_i_codigo:  personainfo.Pws_i_codigo};
        DatoPersona(infoUpdatePerson)
        if(!valorPersonal.valorPersonal.update){
        let citiesDepent = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3', s_ubicacion:persona.Pws_Departamento });
    
        setCiudades(citiesDepent);
    }
    
    let citiesDepent2 = {
        "ListCiudad": {
            "Datos_Ciudad": [] as any
        }
    }
    citiesDepent2.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3' });
    setCiudades2(citiesDepent2); 
        setUpdate(valorPersonal.valorPersonal.update)
        },[valorPersonal]);


        const cambios=(evento:any)=>{
        var dateStart= new Date(evento.target.value);
        var dateEnd=new Date();
        if(dateStart.getTime()>dateEnd.getTime()){
            toast("La fecha de ingreso no debe ser mayor a la fecha actual")
        }else{
            var one_day = 1000*60*60*24;
            let result
            result = Math.ceil(((dateEnd.getTime()-dateStart.getTime())/(one_day))/365)
            valorPersonal.valorPersonal.person.Pws_Ant_labo=result
        }
    }

const cambiarUpdat =()=>{
    valorPersonal.valorPersonal.setUpdate(true)
}
    return(
        
        <div className="box">
            
                    <ul className="title">
                <li>1.</li>
                <li>Información personal </li>
                <Person/>
                {valorPersonal.valorPersonal.arreglo.length>3?<>
                <div onClick={cambiarUpdat}>

<Edit/>
</div>
                </>:<>
                </>}
            
            </ul>
            <div className="sub-box">

                <ul>
                    <li>
                        <label>¿Quién acredita?</label>
                        {
                            update?<>
                            <Field as="select"
                            className="form-know-you-item-input"
                            disabled={NoReadCedula}
                            name="Pws_Tip_person">
                            <option>{'Seleccione...'}</option>
                            <option value={'N'}>{'Natural'}</option>
                            <option value={'J'}>{'Juridico'}</option>
                        </Field>
                        </>:<>
                        <p>{person?(person.Pws_Tip_person==="N"?"Natural":"Juridico"):""}</p>
                        </>
                    }
                </li>
                <li>
                    <label>Tipo de ocupación</label>
                    {
                        update?<>
                        <Field as="select"
                                className="form-know-you-item-input"
                                disabled={NoReadCedula}
                                name="Pws_Tip_ocupa">
                            {allOcupaciones && allOcupaciones.map((item:any, key) => {
                            
                            return (<option value={item.s_codigo}>{item.s_descripcion}</option>)

                                    })}
                        </Field>
                        </>:<>
                        <p>
                        {Ocupaciones && Ocupaciones.map((item:any, key: number) => {
                            if(Number(item.id)===Number(person.Pws_Tip_ocupa)){
                                return (item.name)
                            }else{
                                return ("")

                            }
                            
                            })}</p>
                        </>
                    }

                </li>
            </ul>
        </div>
        <div className="sub-box redBox">
            <h3>Información del solicitante</h3>
            <ul>
                <li>
                    <label>Nombres</label>
                    {
                    update?<>
                        <Field id={'Pws_Nombres'}
                                className="form-know-you-item-input"
                                name={'Pws_Nombres'}
                                type={"text"}
                                disabled/>
                        </>:<> 
                        <p>{person?(person.Pws_Nombres?person.Pws_Nombres:""):""}</p>
                    </>
                    }
                </li>
                <li>
                    <label>Apellidos</label>
                    {update ? (
                        <>
                        <Field
                            id={'Pws_Apellidos'}
                            className="form-know-you-item-input"
                            name={'Pws_Apellidos'}
                            type={"text"}
                            disabled
                            value={(person.Pws_Apellidos1 || "") + " " + (person.Pws_Apellidos2 || "")}
                        />
                        </>
                    ) : (
                        <p>
                        {person ? (person.Pws_Apellidos1 ? person.Pws_Apellidos1 + " " + person.Pws_Apellidos2 : "") : ""}
                        </p>
                    )}
                    </li>
                <li>
                    <label>Tipo de identificación</label>

                        {
                            update?<>
                            <Field as="select"
                                    className="form-know-you-item-input"
                                    name="Pws_Tip_Identificacion">
                                    {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key) => {
                            
                                    return (<option value={item.s_codigo}>{item.s_descripcion}</option>)

                                        })}
                        </Field>
                        </>:<>
                        <p> {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key) => {
                            if(Number(item.s_codigo)=== Number(person.Pws_Tip_Identificacion)){
                                return (item.s_descripcion)
                                
                            }else{
                                
                                return ("")
                            }
                        })}</p>
                        </>
                    }
                    
                </li>
                <li>
                    <label>Identificacion</label>
                    {
                        update?<>
                        <Field id={'Pws_Identificacion'}
                                    className="form-know-you-item-input"
                                    name={'Pws_Identificacion'}
                                    type={"text"}
                                    onBlur={handleSearch}/>
                            </>:<> 
                            <p>{person?(person.Pws_Identificacion?person.Pws_Identificacion:""):""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Fecha de expedición</label>
                        {
                            update?<>
                            <Field id={'Pws_Fec_expe'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Fec_expe'}
                                    type={"date"}
                                    placeholder={'MM/dd/YYYY'}/>
                            </>:<> 
                            <p>{person.Pws_Fec_expe?person.Pws_Fec_expe:""} </p>
                            </>
                        }
                
                
                </li>
                <li>
                    <label>Lugar de expedición</label>
                    {
                        update?<>
                        <Field as="select"
                                className="form-know-you-item-input"
                                disabled={NoReadCedula}
                            name="Pws_Lugar_exp"
                    >
                        {ciudades2 && ciudades2.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                            return (
                                <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                            )
                        })}
                    </Field>

                            </>:<> 
                            <p>{ciudades2 && ciudades2.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                if(item.s_codigo===person.Pws_Lugar_exp){
                                    return (item.s_nombre)

                                }else{
                                    return ("")
                                }
                            })}</p>
                            </>
                        }
                    </li>
                </ul>
                <ul>
                    <li>
                        <label>Fecha de nacimiento</label>
                        {
                            update?<>
                            <Field id={'Pws_Fec_nacime'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Fec_nacime'}
                                    type={"date"}
                                    placeholder={'MM/dd/YYYY'}/>
                            </>:<> 
                        <p>{person.Pws_Fec_nacime?person.Pws_Fec_nacime:""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Estado civil</label>
                        {
                            update?<>
                            <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Estado_Civil">
                                    <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                                    {EstadosCivil && EstadosCivil.map((item:any, key) => {
                                    return (<option value={item.s_codigo}>{item.s_descripcion}</option>)
                        })}
                            </Field>
                            </>:<>
                            <p>{EstadosCivil && EstadosCivil.map((item:any, key: number) => {
                            if(Number(item.s_codigo)===Number(person.Pws_Estado_Civil)){
                                return (item.s_descripcion)
                            }else{
                                return ("")

                            }
                                
                            })} </p>
                            </>
                        }
                        
                    </li>
                    <li>
                        <label>Dirección residencia</label>
                        {
                            update?<>
                            <Field id={"Pws_Direccion_res"}
                        name={"Pws_Direccion_res"}
                        className="form-know-you-item-input"
                        placeholder={intl.formatMessage({id: "enter"})}/>
                        </>:<> 
                        <p>{person?person.Pws_Direccion_res:""}</p>
                        </>
                    }
                    </li>
                    <li>
                        <label>Departamentos</label>
                        {
                            update?<>
                            <Field as="select"
                                    disabled={NoReadCedula}
                                    className="form-know-you-item-input"
                                name="Pws_Departamento"
                                onClick={handleCities}
                        >
                            {departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>

                            </>:<> 
                            <p>{departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                if(item.s_codigo===person.Pws_Departamento){
                                    return (item.s_nombre)

                                }else{
                                    return ("")
                                }
                            })}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Ciudad</label>
                        {
                            update?<>
                            <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                name="Pws_Ciudad_res"
                        >
                            {ciudades && ciudades.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>

                            </>:<> 
                            <p>{allCities.ListCiudad.Datos_Ciudad && allCities.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                if(Number(item.s_codigo)===Number(person.Pws_Ciudad_res)){
                                    return (item.s_nombre)

                                }else{
                                    return ("")
                                }
                            })}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Teléfono móvil 1</label>
                        {
                            update?<>
                            <Field id={'Pws_Telefono1'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Telefono1'}
                                    />
                            </>:<> 
                        <p>{person?person.Pws_Telefono1:""} </p>
                            </>
                        }
                    </li>
                </ul>
                <ul>
                    <li>
                        <label>Teléfono móvil 2</label>
                    {
                            update?<>
                            <Field id={'Pws_Telefono2'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Telefono2'}
                                    />
                            </>:<> 
                        <p>{person?person.Pws_Telefono2:""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Email</label>
                        {
                            update?<>
                            <Field id={'Pws_Correo'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Correo'}
                                    />
                            </>:<> 
                        <p>{person?person.Pws_Correo:""} </p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Género</label>
                        {
                            update?<>
                            <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                name="Pws_Genero">
                            <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                            <option value={'F'}>{'Femenino'}</option>
                            <option value={'M'}>{'Masculino'}</option>
                        </Field>
                            </>:<> 
                        <p>{person?(person.Pws_Genero==="F"?"Femenino":"Masculino"):""}</p>
                            </>
                        }
                        
                    </li>
                    <li>
                        <label>Estrato social</label>
                        {
                            update?<>
                            <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Estrato_per">
                                    {estractos && estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map((item, key) => {
                            
                                    return (<option value={item.i_codigo}>{item.c_descripcion}</option>)

                                            })}
                            </Field>
                            </>:<>
                            <p> {estractos && estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map((item, key: number) => {
                                if(Number(item.i_codigo)===Number(person.Pws_Estrato_per)){
                                    return (item.c_descripcion)
                                }else{
                                    return ("")

                            }
                        })} </p>
                        </>
                    }
                    
                    </li>
                    <li>
                        <label>N° de personas a cargo</label>
                        {
                            update?<>
                            <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                name="Pws_Per_cargo">
                            <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                            <option value={'0'}>{'0'}</option>
                            <option value={'1'}>{'1'}</option>
                            <option value={'2'}>{'2'}</option>
                            <option value={'3'}>{'3'}</option>
                            <option value={'4'}>{'4'}</option>
                            <option value={'5'}>{'5'}</option>
                            <option value={'6'}>{'6'}</option>
                            <option value={'7'}>{'7'}</option>
                            <option value={'8'}>{'8'}</option>
                            <option value={'9'}>{'9'}</option>
                            <option value={'10'}>{'10'}</option>
                        </Field>
                            </>:<> 
                        <p>{person?person.Pws_Per_cargo:""}</p>
                            </>
                        }
                        
                    </li>
                </ul>
                <ul>
                    <li>
                        <label>Tipo de vivienda </label>
                        {
                            update?<>
                            <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Tip_vivienda">
                                    {tipovivi && tipovivi.map((item:any, key) => {
                            
                                    return (<option value={item.i_codigo}>{item.c_descripcion}</option>)

                                            })}
                            </Field>
                            </>:<>
                            <p>{tipovivi && tipovivi.map((item:any, key: number) => {
                                if(Number(item.i_codigo)===Number(person.Pws_Tip_vivienda)){
                                    return (item.c_descripcion)
                                }else{
                                    return ("")
    
                                }
                            })}  </p>
                            </>
                        }
                        
                    </li>
                    <li>
                        <label>Nivel de estudios </label>
                        {
                            update?<>
                            <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Niv_estudio">
                                    {NivelEsti && NivelEsti.map((item:any, key) => {
                            
                                    return (<option value={item.s_codigo}>{item.s_descripcion}</option>)

                                            })}
                            </Field>
                            </>:<>
                            <p> {NivelEsti && NivelEsti.map((item:any, key: number) => {
                            if(Number(item.s_codigo)===Number(person.Pws_Niv_estudio)){
                                return (item.s_descripcion)
                            }else{
                                return ("")

                            }
                        })}</p>
                            </>
                        }
                        
                    </li>
                </ul>
            </div>
            <div className="sub-box redBox">
                <h3>Actividad del solicitante</h3>
                <ul>
                    <li>
                        <label>Nombre de la Compañia</label>
                        {
                            update?<>
                            <Field id={'Pws_Nom_empre'}
                                    className="form-know-you-item-input"
                                    name={'Pws_Nom_empre'}
                                    disabled={NoReadCedula}
                                    />
                            </>:<> 
                        <p>{person?person.Pws_Nom_empre:""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Fecha de ingreso</label>
                        {
                            update?<>
                            <Field id={'Pws_fec_ingemp'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_fec_ingemp'}
                                    type={"date"}
                                    onBlur={cambios}
                                    placeholder={'MM/dd/YYYY'}/>
                            </>:<> 
                        <p>{person.Pws_fec_ingemp?new Date(person.Pws_fec_ingemp).toLocaleDateString():""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Tipo de contrato</label>
                        {
                            update?<>
                            <Field as="select"
                                    disabled={NoReadCedula}
                                    className="form-know-you-item-input"
                                    name="Pws_Tip_contra">
                                    {TipoContrato && TipoContrato.map((item:any, key) => {
                            
                                    return (<option value={item.s_codigo}>{item.s_descripcion}</option>)

                                            })}
                            </Field>
                            </>:<>
                            <p>{TipoContrato && TipoContrato.map((item:any, key: number) => {
                                if(Number(item.s_codigo)===Number(person.Pws_Tip_contra)){
                                    return (item.s_descripcion)
                                }else{
                                    return ("")
    
                                }
                            })} </p>
                            </>
                        }
                    
                    </li>
                    <li>
                        <label>Antigüedad laboral (años)</label>
                        {
                            update?<>
                            <Field id={'Pws_Ant_labo'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Ant_labo'}
                                    />
                            </>:<> 
                        <p>{person?person.Pws_Ant_labo:""} año </p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Cargo que desempeña</label> 
                        {
                            update?<>
                            <Field id={'Pws_Car_emp'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Car_emp'}
                                    />
                            </>:<> 
                        <p>{person?person.Pws_Car_emp:""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Jefe directo</label>
                        {
                            update?<>
                            <Field id={'Pws_Nom_Jefedi'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Nom_Jefedi'}
                                    />
                            </>:<> 
                        <p>{person?person.Pws_Nom_Jefedi:""}</p>
                            </>
                        }
                    </li>
                </ul>
                <ul>
                    <li>
                        <label>Dirección de la empresa</label>
                        {
                            update?<>
                            <Field id={'Pws_Direc_emp'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Direc_emp'}
                                    />
                            </>:<> 
                        <p>{person?person.Pws_Direc_emp:""} </p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Ciudad</label>
                        {
                            update?<>
                            <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                name="Pws_Ciud_emp"
                        >
                            {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3'}) && _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3'}).map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>

                            </>:<> 
                            <p>{ _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3'}).map((item:any, key: number) => {
                                if(item.s_codigo===person.Pws_Ciud_emp){
                                    return (item.s_nombre)

                                }else{
                                    return ("")
                                }
                            })}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Teléfono 1</label> 
                        {
                            update?<>
                            <Field id={'Pws_tel_emp1'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_tel_emp1'}
                                    />
                            </>:<> 
                        <p>{person?person.Pws_tel_emp1:""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Teléfono 2</label>
                        {
                            update?<>
                            <Field id={'Pws_tel_emp2'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_tel_emp2'}
                                    />
                            </>:<> 
                        <p>{person?person.Pws_tel_emp2:""} </p>
                            </>
                        }
                    </li>
                </ul>
            </div>
        </div>
    );
}
export const Box8 = (valorFinancie: any)=>{
    const [option1, setoption1] = React.useState(false);
    const [option2, setoption2] = React.useState(false);
    const [option3, setoption3] = React.useState(false);
    const [option1s, setoption1s] = React.useState({} as any);
    const [option2s, setoption2s] = React.useState({} as any);
    const [option3s, setoption3s] = React.useState({} as any);
    useEffect(()=>{
        if(valorFinancie.valorFinancie.datosdocuments.length>0){
            console.log(valorFinancie.valorFinancie.datosdocuments)
            valorFinancie.valorFinancie.datosdocuments.forEach((element:any) => {
                if(Number(element.Pws_Doc_Tipo)==1){
                    setoption1(true)
                    setoption1s(element)
                }else if(Number(element.Pws_Doc_Tipo)==2){
                    setoption2(true)
                    setoption2s(element)

                }else if (Number(element.Pws_Doc_Tipo)==3){
                    setoption3(true)
                    setoption3s(element)

                }
            });
        }
        if(valorFinancie.valorFinancie.update && valorFinancie.valorFinancie.arreglo.length>3){
            setoption1(false)
            setoption3(false)
            setoption2(false)
        }
    },[valorFinancie.valorFinancie])
    const handleFileInputChange = (e: any) => {
        const target = e.target
        if (target.files && target.files[0]) {
        const maxAllowedSize = 5 * 1024 * 1024;
            if (target.files[0].size > maxAllowedSize) {
              toast.error('Seleccione un archivo de tamaño máximo 5mb.',{
                duration:5000,
            });
              target.value = ''
          }
      }
      let file: any = '';
      file = e.target.files[0];
      const dataElement = target.closest("[data-id]");
                if(Number(dataElement.dataset.id)===1){
                  setoption1(true)
                }
                if(Number(dataElement.dataset.id)===2){
                  setoption2(true)
                }
                if(Number(dataElement.dataset.id)===3){
                  setoption3(true)
                }
     
  };
  
  ;
    return(
        <div className="box">
        <div className="footer">
        <div className="footer-text">
            <h4>Adjuntar documentos</h4>
            <p><span>{`Documetación requerida`}</span>{`(Los documentos se permiten PDF, JPG Y PNG. Tamaño máximo de 5Mb) `}</p>
        </div>
        <div className="footer-body">
            <div className={option1?"document check":"document"} onClick={(eve) => {
            if(option1){
                if(option1s.Pws_Clave_doc){
                
                    const link = document.createElement('a');
                link.href = option1s.Pws_Clave_doc;
                link.setAttribute(
                  'download',
                  option1s.Pws_Doc_nombre,
                );
                link.setAttribute("target","_blank")
                // Append to html link element page
                document.body.appendChild(link);
            
                // Start download
                link.click();
                link.remove();
                }else{
                    eve.currentTarget.querySelector('input')?.click()

                }
             
            }else{
                eve.currentTarget.querySelector('input')?.click()

            }
  }}>
          <input
          
          data-id="1"
                          type="file"
                          name="file-input"
                          id="file-input"
                          className="file-input__input"
                          
                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                          onChange={handleFileInputChange}
                      />
                      {option1? <><Check/></>:<><Document/></>}
              <p>Copia de cedula</p>
          </div>
          <div className={option2?"document check":"document"} onClick={(eve) => {
            if(option2){
                if(option2s.Pws_Clave_doc){
               
                    const link = document.createElement('a');
                    link.href = option2s.Pws_Clave_doc;
                    link.setAttribute(
                      'download',
                      option2s.Pws_Doc_nombre,
                    );
                    link.setAttribute("target","_blank")
                    // Append to html link element page
                    document.body.appendChild(link);
                
                    // Start download
                    link.click();
                    link.remove();
                }else{
                    eve.currentTarget.querySelector('input')?.click()

                }
            }else{
                eve.currentTarget.querySelector('input')?.click()

            }
  }}>
          <input
          data-id="2"
                          type="file"
                          name="file-input"
                          id="file-input"
                          className="file-input__input"
                          
                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                          onChange={handleFileInputChange}
                      />
              {option2? <><Check/></>:<><Document/></>}
              
              <p>Recibo de servicio publico</p>
          </div>
          <div className={option3?"document check":"document"} onClick={(eve) => {
             if(option3){
                if(option3s.Pws_Clave_doc){
                    const link = document.createElement('a');
                    link.href = option3s.Pws_Clave_doc;
                    link.setAttribute(
                    'download',
                    option3s.Pws_Doc_nombre,
                    );
                    link.setAttribute("target","_blank")
                    // Append to html link element page
                    document.body.appendChild(link);
                
                    // Start download
                    link.click();
                    link.remove();
                }else{
                    eve.currentTarget.querySelector('input')?.click()

                }
                
            }else{
                eve.currentTarget.querySelector('input')?.click()

            }
    }}> 
        <input
        data-id="3"
                        type="file"
                        name="file-input"
                        id="file-input"
                        className="file-input__input"
                        
                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                            onChange={handleFileInputChange}
                        />
                        {option3? <><Check/></>:<><Document/></>}
                <p>Soportes de ingresos y/o garantías</p>
            </div>
        </div>
        </div>
    </div>  
    )
    }
    export const Box3 = (valorFinancie: any) => {
    const {valorinic} =valorFinancie.valorFinancie
    const [values, valoresIncial] = React.useState({} as any);
    const [update, setUpdate] = React.useState(false);
    useEffect(()=>{
        if(valorFinancie.valorFinancie.values){
        const operFinancieras = {
        s_num_solicitud: valorFinancie.valorFinancie.datosIngr[0],
        s_identificacion: valorFinancie.valorFinancie.datosIngr[1],
        s_tipo_identificacion:  valorFinancie.valorFinancie.datosIngr[2],
        s_ingreso_principal:  valorFinancie.valorFinancie.values.Pws_Ingreso_Principal,
        s_otros_ingresos:  valorFinancie.valorFinancie.values.Pws_otros_ingresos,
        s_otros_egresos:  valorFinancie.valorFinancie.values.Pws_Otros_egresos,
        s_arriendo:  valorFinancie.valorFinancie.values.Pws_Arriendo,
        s_concep_otr_ingre:  valorFinancie.valorFinancie.values.Pws_Concep_otr_ingre,
        s_declarante_ren:  valorFinancie.valorFinancie.values.Pws_Declarante_ren?true:false,
        s_moneda_ext:  valorFinancie.valorFinancie.values.Pws_Moneda_ext?true:false,
        s_monext_oper:  valorFinancie.valorFinancie.values.Pws_Monext_oper,
        s_tip_monext:  valorFinancie.valorFinancie.values.Pws_Tip_monext,
        s_cuent_ext:   valorFinancie.valorFinancie.values.Pws_Cuent_ext?true:false,
        s_cuen_extban:   valorFinancie.valorFinancie.values.Pws_Cuen_extban,
        s_cuen_extnum:   valorFinancie.valorFinancie.values.Pws_Cuen_extnum,
        s_cuen_extpais: valorFinancie.valorFinancie.values.Pws_Cuen_extpais,
        s_cuen_extciudad:  valorFinancie.valorFinancie.values.Pws_Cuen_extciudad,
        s_pep_recpublic:  valorFinancie.valorFinancie.values.Pws_Pep_recpublic?true:false,
        s_pep_poderpublic:  valorFinancie.valorFinancie.values.Pws_Pep_poderpublic?true:false,
        s_pep_reconpublic: valorFinancie.valorFinancie.values.Pws_Pep_Reconpublic?true:false,
        s_pep_pubexpue:  valorFinancie.valorFinancie.values.Pws_Pep_pubexpue?true:false,
        s_pep_seggraconsa: valorFinancie.valorFinancie.values.Pws_Pep_seggraconsa?true:false,
        s_pep_nompepseg:  valorFinancie.valorFinancie.values.Pws_Pep_nompepseg,
        s_pep_paren:  valorFinancie.valorFinancie.values.Pws_Pep_paren,
        s_pep_identif:  valorFinancie.valorFinancie.values.Pws_Pep_Identif,
    }
    valoresIncial(operFinancieras)
    }
    setUpdate(valorFinancie.valorFinancie.update)
      
      },[valorFinancie]);

  return(
        <div className="box">
            <ul className="title">
                <li>2.</li>
                <li>Información financiera </li>
                <Doc/> 
            </ul>
            <div className="sub-box redBox">
                <h3>Ingresos del solicitante</h3>
                <ul>
                    <li>
                        <label>Ingreso principal</label>
                        {
                            update?
                            <>
                            <Field id="s_ingreso_principal"
                            name="s_ingreso_principal" 
                            component={ParseNumberThousand}/>
                            </>:<>
                        <p>{valorinic.s_ingreso_principal} </p>
                            </>
                        }
                        
                    </li>
                    <li>
                        <label>Otros ingresos</label>
                        {
                            update?
                            <>
                            <Field id="s_otros_ingresos"
                            name="s_otros_ingresos" 
                            component={ParseNumberThousand}/>
                            </>:<>
                        <p>{valorinic.s_otros_ingresos} </p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Valor egresos</label>
                        {
                            update?
                            <>
                            <Field id="s_otros_egresos"
                            name="s_otros_egresos" 
                            component={ParseNumberThousand}/>
                            </>:<>
                        <p>{valorinic.s_otros_egresos}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Concepto de otros ingresos</label>
                        {
                            update?
                            <>
                            <Field id="s_concep_otr_ingre"
                                    className="form-know-you-item-input"
                            name="s_concep_otr_ingre" 
                            type="text"/>
                            </>:<>
                        <p>{valorinic.s_concep_otr_ingre}</p>
                            </>
                        }
                    </li>
                </ul>
                <h3>Propiedades del solicitante</h3>
                <ul>
                    <li>
                        <label>Declarante de renta</label>
                        {
                            update?
                            <>
                            <div >
                            No
                                <Field id={'s_declarante_ren'}
                            name="s_declarante_ren" component={Switch}
                            type="checkbox"/>
                            Si
                            </div>
                            
                            </>:<>
                        <FUllPill check={valorinic.s_declarante_ren} disabled={true} id={"s_declarante_ren"} name={"s_declarante_ren"}/>
                            </>
                        }
                    </li>
                    {/* <li>
                        <label>Vivienda</label>
                        <p>Propia </p>
                    </li> */}
                </ul>
            </div>
            <div className="sub-box redBox">
                <h3> operaciones financieras </h3>
                <div className="pillInfo">
                    <p>¿Realizas operaciones en moneda extranjera?</p>
                    {
                            update?
                            <>
                            <div >
                            No
                                <Field id={'s_moneda_ext'}
                            name="s_moneda_ext" component={Switch}
                            type="checkbox"/>
                            Si
                            </div>
                            
                            </>:<>
                            <FUllPill check={valorinic.s_moneda_ext}  disabled={true} id={'s_moneda_ext'}
                                    name={'s_moneda_ext'}/>
                            </>
                        }
                    
                </div>
                <div className="pillInfo">
                    <p>Posees cuentas en moneda extranjera</p>
                    {
                            update?
                            <>
                            <div >
                            No
                                <Field id={'s_cuent_ext'}
                            name="s_cuent_ext" component={Switch}
                            type="checkbox"/>
                            Si
                            </div>
                            
                            </>:<>
                    <FUllPill check={valorinic.s_cuent_ext} disabled={true}  id={"s_cuent_ext"} name={"s_cuent_ext"}/>
                            </>
                        }
                </div>
                <h3>{`Información PEP. (Personas expuestas Políticamente)`}</h3>
                <div className="pillInfo">
                    <p>¿Por tu cargo manejas recursos públicos? </p>
                    {
                            update?
                            <>
                            <div >
                            No
                                <Field id={'s_pep_recpublic'}
                            name="s_pep_recpublic" component={Switch}
                            type="checkbox"/>
                            Si
                            </div>
                            
                            </>:<>
                    <FUllPill check={valorinic.s_pep_recpublic} disabled={true}  id={"s_pep_recpublic"} name={"s_pep_recpublic"}/>
                            </>
                        }
                </div>
                <div className="pillInfo">
                    <p>¿Por tu cargo o actividad ejerces algún grado de poder público? </p>
                    {
                            update?
                            <>
                            <div >
                            No
                                <Field id={'s_pep_poderpublic'}
                            name="s_pep_poderpublic" component={Switch}
                            type="checkbox"/>
                            Si
                            </div>
                            
                            </>:<>
                    <FUllPill check={valorinic.s_pep_poderpublic} disabled={true}  id={"s_pep_poderpublic"} name={"s_pep_poderpublic"}/>
                            </>
                        }
                </div>
                <div className="pillInfo">
                    <p>¿Gozas de reconocimiento público? </p>{
                            update?
                            <>
                            <div >
                            No
                                <Field id={'s_pep_reconpublic'}
                            name="s_pep_reconpublic" component={Switch}
                            type="checkbox"/>
                            Si
                            </div>
                            
                            </>:<>
                    <FUllPill check={valorinic.s_pep_reconpublic} disabled={true}  id={"s_pep_reconpublic"} name={"s_pep_reconpublic"}/>
                            </>
                        }
                </div>
                <div className="pillInfo">
                    <p>¿Eres familiar de alguna persona políticamente expuesta? </p>
                    {
                            update?
                            <>
                            <div>
                            No
                                <Field id={'s_pep_pubexpue'}
                            name="s_pep_pubexpue" component={Switch}
                            type="checkbox"/>
                            Si
                            </div>
                            
                            </>:<>
                    <FUllPill check={valorinic.s_pep_pubexpue}  disabled={true}  id={"s_pep_pubexpue"} name={"s_pep_pubexpue"}/>
                            </>
                        }
                </div>
                <div className="pillInfo">
                    <p>{`¿Tienes 2° de afinidad y 2° de consanguinidad (Padres, hermanos, esposa, primos, suegros, cuñados o concuñados) con PEP?`}</p>
                    {
                            update?
                            <>
                            <div >
                            No
                                <Field id={'s_pep_seggraconsa'}
                            name="s_pep_seggraconsa" component={Switch}
                            type="checkbox"/>
                            Si
                            </div>
                            
                            </>:<>
                    <FUllPill check={valorinic.s_pep_seggraconsa} disabled={true} id={"s_pep_seggraconsa"} name={"s_pep_seggraconsa"}/>
                            </>
                        }
                </div>
            </div>
            
        </div>

);
}
interface RenderContainerProps {
    setPopup: React.Dispatch<React.SetStateAction<string>>;
}
export const DetailCreditRequire = (props:RenderContainerProps) => {
    const {setPopup} = props;
    const { user } = useLoginContext();
    const navigate = useNavigate();
    const buscar = useLocation();
    const [isupdate, setUpdate] = React.useState(true);
    const [persona, DatoPersona] = React.useState({});
    const [codigoCodeudor, setCodigoCodeudor] = React.useState({});
    const [alldocuments, setallDocuments] = React.useState([]);
    const {request, setRequest} = useRequestContext();
    const [one] = React.useState({});
    const [arregloValor, valorArreglo] = React.useState([] as any);
    useEffect(()=>{
        if(buscar){
        var valorBusqueda=buscar.search.replace("?","")
        let arreglo=String(valorBusqueda).split("-")
        valorArreglo(arreglo)
        if(arreglo.length==3){
        let codeudors:any={
            Pws_codigo: "",
            Pws_num_solicitud: arreglo[0],
            Pws_Identificacion: arreglo[1],
            Pws_Radic_Codeu: arreglo[0],
            Pws_Tip_Identificacion: arreglo[2],
            Pws_Iden_Code: "",
            Pws_Tip_Identificacion_Code: "",
            Pws_Tip_Codeu: ""
        }
        setRequest({
            ...request,
            dataModifcable: {}
        })
        WLOCreditAPIService.addCodeudor(codeudors).then((GetCodeudors)=>{
            if(Number(GetCodeudors.payload.result)>0){
            }
        })
        
    }else if (arreglo.length>3){
        let codeudors:any={
            Pws_codigo: "",
            Pws_num_solicitud: arreglo[0],
            Pws_Identificacion: arreglo[1],
            Pws_Radic_Codeu: arreglo[0],
            Pws_Tip_Identificacion: arreglo[2],
            Pws_Iden_Code: "",
            Pws_Tip_Identificacion_Code: "",
            Pws_Tip_Codeu: ""
        }
        WLOCreditAPIService.addCodeudor(codeudors).then((GetCodeudors)=>{
            if(Number(GetCodeudors.payload.result)>0){
                let paso=false
                let cedula=""
                let type=""
                GetCodeudors.payload.data.datos_codeu.Datos_codeudor.forEach((element:any) => {
                    if(Number(element.Pws_codigo)==Number(arreglo[3])){
                        paso=true
                        cedula=element.Pws_Iden_Code
                        type=element.Pws_Tip_Identificacion_Code
                    }
                });
                if(paso){
                    setUpdate(false)
                    putDatainView(cedula,type,arreglo)
                }else{
                    toast.error("No es el codeudor Requerido",{
                        duration:5000,
                    })
                }
            }
         })
        setCodigoCodeudor(arreglo[3])
        
     }
     
    }
    document.getElementsByClassName("title")[0].scrollIntoView()
    },[buscar,navigate])
 const putDatainView =(cedulaSearhc:any,typeCedul:any,arreglo:any)=>{
    let sends:WloCreditoService.SolicitudDetalla={
        Pws_Num_Solicitud:arreglo[0],
        Pws_Identificacion:cedulaSearhc,
        Pws_Tip_Identificacion:typeCedul,
        Pws_Perfil_Acceso:"",
        Pws_Fec_Solicitud:"",
    }
    WLOCreditAPIService.consultaSolicitudCygnusDetal(sends).then((valor)=>{
        if(Number(valor.payload.result)>0){
            
            if(valor.payload.data.datos_persona){
                if(valor.payload.data.datos_persona.Datos_persona.length>0){
                    if(valor.payload.data.datos_operfinan){
                        if(valor.payload.data.datos_peps){
                            if(valor.payload.data.datos_peps.Datos_peps.length>0){
                                if(valor.payload.data.datos_operfinan.Datos_operfinan.length>0){
                                    let dataCygnus=valor.payload.data.datos_persona.Datos_persona[0]
                                    let dataCygnusOperfin=valor.payload.data.datos_operfinan.Datos_operfinan[0]
                                    let dataCygnusPeps=valor.payload.data.datos_peps.Datos_peps[0]
                                    const datainicial = {
                                        Pws_Identificacion: dataCygnus.Pws_Identificacion,
                                        Pws_Tip_Identificacion: dataCygnus.Pws_Tip_Identificacion,
                                        Pws_Tip_Estado: dataCygnus.Pws_Tip_Estado, // Valor constante para confirmación de información.
                                        Pws_Tip_Perfil:  dataCygnus.Pws_Tip_Perfil,
                                        Pws_Tip_person:  dataCygnus.Pws_Tip_person,
                                        Pws_Tip_ocupa:  dataCygnus.Pws_Tip_ocupa,
                                        Pws_Nombres:  dataCygnus.Pws_Nombres,
                                        Pws_Apellidos1:  dataCygnus.Pws_Apellidos1,
                                        Pws_Apellidos2:  dataCygnus.Pws_Apellidos2,
                                        Pws_Telefono1:  dataCygnus.Pws_Telefono1,
                                        Pws_Telefono2:  dataCygnus.Pws_Telefono2,
                                        Pws_Correo:  dataCygnus.Pws_Correo,
                                        Pws_Fec_expe:  dataCygnus.Pws_Fec_expe.split("-00")[0] ,
                                        Pws_Lugar_exp: dataCygnus.Pws_Lugar_exp,
                                        Pws_Fec_nacime: dataCygnus.Pws_Fec_nacime.split("-00")[0] ,
                                        Pws_Estado_Civil:  dataCygnus.Pws_Estado_Civil,
                                        Pws_Direccion_res:  dataCygnus.Pws_Direccion_res,
                                        Pws_Departamento:  dataCygnus.Pws_Departamento,
                                        Pws_Ciudad_res:  dataCygnus.Pws_Ciudad_res,
                                        Pws_Genero:  dataCygnus.Pws_Genero,
                                        Pws_Estrato_per:  dataCygnus.Pws_Estrato_per,
                                        Pws_Per_cargo:  dataCygnus.Pws_Per_cargo,
                                        Pws_Tip_vivienda: dataCygnus.Pws_Tip_vivienda,
                                        Pws_Niv_estudio:  dataCygnus.Pws_Niv_estudio,
                                        Pws_Nom_empre:  dataCygnus.Pws_Nom_empre,
                                        Pws_fec_ingemp:  dataCygnus.Pws_fec_ingemp.split("-00")[0],
                                        Pws_Fideliza: dataCygnus.Pws_Fideliza,
                                        Pws_Tip_contra:  dataCygnus.Pws_Tip_contra,
                                        Pws_Ant_labo:  dataCygnus.Pws_Ant_labo,
                                        Pws_Car_emp:  dataCygnus.Pws_Car_emp,
                                        Pws_Nom_Jefedi:  dataCygnus.Pws_Nom_Jefedi,
                                        Pws_Direc_emp: dataCygnus.Pws_Direc_emp,
                                        Pws_Ciud_emp:  dataCygnus.Pws_Ciud_emp,
                                        Pws_tel_emp1:  dataCygnus.Pws_tel_emp1,
                                        Pws_tel_emp2: dataCygnus.Pws_tel_emp2,
                                        s_num_solicitud: dataCygnusOperfin.Pws_Num_Solicitud,
                                        s_identificacion: dataCygnus.Pws_Identificacion,
                                        s_tipo_identificacion:  dataCygnus.Pws_Tip_Identificacion,
                                        s_ingreso_principal:  dataCygnusOperfin.Pws_Ingreso_Principal,
                                        s_otros_ingresos:  dataCygnusOperfin.Pws_otros_ingresos,
                                        s_otros_egresos:  dataCygnusOperfin.Pws_Otros_egresos,
                                        s_arriendo:  dataCygnusOperfin.Pws_Arriendo,
                                        s_concep_otr_ingre:  dataCygnusOperfin.Pws_Concep_otr_ingre,
                                        s_declarante_ren:  Number(dataCygnusOperfin.Pws_Declarante_ren)==0?false:true,
                                        s_moneda_ext:  Number(dataCygnusOperfin.Pws_Moneda_ext)==0?false:true,
                                        s_monext_oper: dataCygnusOperfin.Pws_Monext_oper,
                                        s_tip_monext:  dataCygnusOperfin.Pws_Tip_monext,
                                        s_cuent_ext:   Number(dataCygnusOperfin.Pws_Cuent_ext)==0?false:true,
                                        s_cuen_extban:   dataCygnusOperfin.Pws_Cuen_extban,
                                        s_cuen_extnum:  dataCygnusOperfin.Pws_Cuen_extnum,
                                        s_cuen_extpais: dataCygnusOperfin.Pws_Cuen_extpais,
                                        s_cuen_extciudad:  dataCygnusOperfin.Pws_Cuen_extciudad,
                                        s_pep_recpublic:  Number(dataCygnusPeps.Pws_Pep_recpublic)==0?false:true,
                                        s_pep_poderpublic:  Number(dataCygnusPeps.Pws_Pep_poderpublic)==0?false:true,
                                        s_pep_reconpublic: Number(dataCygnusPeps.Pws_Pep_Reconpublic)==0?false:true,
                                        s_pep_pubexpue: Number(dataCygnusPeps.Pws_Pep_pubexpue)==0?false:true,
                                        s_pep_seggraconsa: Number(dataCygnusPeps.Pws_Pep_seggraconsa)==0?false:true,
                                        s_pep_nompepseg:  dataCygnusPeps.Pws_Pep_nompepseg,
                                        s_pep_paren:  dataCygnusPeps.Pws_Pep_paren,
                                        s_pep_identif:  dataCygnusPeps.Pws_Pep_Identif,
                                    }
                                    buscarDocumentos(arreglo[0],dataCygnus)
                                    DatoPersona(datainicial)
                                    setRequest({
                                    ...request,
                                    dataModifcable: {
                                        identificacion:dataCygnus.Pws_Identificacion,
                                        tipoindentificacion:dataCygnus.Pws_Tip_Identificacion,
                                        codeudorID:arreglo[3],
                                        idUser:dataCygnus.Pws_i_codigo
                                    }
                                    })
                                }
                            }
                        }
                      
                    }
                }
            }
            
         
        }
     },(error)=>{
     })

 }
  const onSubmit=async (values: any, formikBag: { setSubmitting: any; }) => {
    
    if(arregloValor.length>2){
        let momento=Moment(new Date())
        let diferencia=momento.diff(Moment(values.Pws_Fec_nacime!))
        let duration=Moment.duration(diferencia)
        if(duration.years()>=18){
            registarDatosreque(arregloValor[0],values,arregloValor[1],arregloValor[2])
        }else{
            toast.error("Debes tener al menos 18 años de edad",{
                duration:5000,
            })
        }
    }else{
        toast.error("No puede Grabar la informacion",{
            duration:5000,
        })
    }
}


useEffect(()=>{
    const datainicial = {
        Pws_Identificacion: "",
        Pws_Tip_Identificacion: "",
        Pws_Tip_Estado: "2", // Valor constante para confirmación de información.
        Pws_Tip_Perfil:  "",
        Pws_Tip_person:  "",
        Pws_Tip_ocupa:  "",
        Pws_Nombres:  "",
        Pws_Apellidos1:  "",
        Pws_Apellidos2:  "",
        Pws_Telefono1:  "",
        Pws_Telefono2:  "",
        Pws_Correo:  "",
        Pws_Fec_expe:  "" ,
        Pws_Lugar_exp: "",
        Pws_Fec_nacime: "" ,
        Pws_Estado_Civil:  "",
        Pws_Direccion_res:  "",
        Pws_Departamento:  "",
        Pws_Ciudad_res:  "",
        Pws_Genero:  "",
        Pws_Estrato_per:  "",
        Pws_Per_cargo:  "",
        Pws_Tip_vivienda: "",
        Pws_Niv_estudio:  "",
        Pws_Nom_empre:  "",
        Pws_fec_ingemp:  "",
        Pws_Fideliza:  "",
        Pws_Tip_contra:  "",
        Pws_Ant_labo:  "",
        Pws_Car_emp:  "",
        Pws_Nom_Jefedi:  "",
        Pws_Direc_emp:  "",
        Pws_Ciud_emp:  "",
        Pws_tel_emp1:  "",
        Pws_tel_emp2: "",
        s_num_solicitud: "",
        s_identificacion: "",
        s_tipo_identificacion: "",
        s_ingreso_principal:  "",
        s_otros_ingresos:  "",
        s_otros_egresos:  "",
        s_arriendo:  "",
        s_concep_otr_ingre:  "",
        s_declarante_ren:  false,
        s_moneda_ext:  false,
        s_monext_oper: "",
        s_tip_monext:  "",
        s_cuent_ext:   false,
        s_cuen_extban:   "",
        s_cuen_extnum:  "",
        s_cuen_extpais: "",
        s_cuen_extciudad:  "",
        s_pep_recpublic:  false,
        s_pep_poderpublic:  false,
        s_pep_reconpublic: false,
        s_pep_pubexpue: false,
        s_pep_seggraconsa: false,
        s_pep_nompepseg:  "",
        s_pep_paren:  "",
        s_pep_identif:  "",
}
DatoPersona(datainicial)
  },[one])
const buscarDocumentos = async (numsol:any,values:any)=>{
    const documentRequest= {
        Pws_num_solicitud: numsol!,
        Pws_Identificacion: values.Pws_Identificacion,
        Pws_Tip_Identificacion:values.Pws_Tip_Identificacion,
    };
    const solicitud = await WLOCreditAPIService.ConsultarDocumentos(documentRequest);
    if(solicitud.payload.data.Pws_R_s_cod=="1"){
        if(solicitud.payload.data.datos_doc.datos_Doc){
            setallDocuments(solicitud.payload.data.datos_doc.datos_Doc)

        }else{
            setallDocuments([])

        }
    }

}
const enviarDocumentos = (values:any,numsol:string)=>{
    document.getElementsByName("file-input").forEach((element ) => {
        var valor=element as HTMLInputElement
        if(valor.files){
            if(valor.files.length>0){
                var file = valor.files[0];
                getBase64(file)
                            .then(async result => {
                                const document = result as string;
                                const documentRequest= {
                                Pws_num_solicitud: numsol!,
                                Pws_Identificacion: values.Pws_Identificacion,
                                Pws_Tip_Identificacion:values.Pws_Tip_Identificacion,
                                Pws_Doc_Tipo: valor.dataset.id,
                                Pws_Doc_nombre: file.name,
                                Pws_Doc_estado: "1",
                                Pws_Doc_ruta: "1",
                                Pws_Clave_doc: document,
                            };
                            
                            (async () => {
                                const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
                                if(Number(solicitud.payload.result)>0){
                                    console.log(solicitud.payload.result)
                                }
                            
                            })();

                            })
                            .catch((err) => {
                                console.log(err);
                            });
            }
        }
    });
    
}

const valorCheck =  ():Boolean=>{
    var letValorAceptado=true
    if(alldocuments.length>2){

    }else{
        document.getElementsByName("file-input").forEach((element ) => {
            var valor=element as HTMLInputElement
            if(valor.files){
                if(valor.files.length>0){
    
                }else{
                    letValorAceptado=true
                }
            }else{
                letValorAceptado=true
            }
        })
    }
   
    
    return letValorAceptado
}
const valuesFinancieros= async (numsol:string,values:any,pws_identifica:string,pws_tip_identifica:string)=>{

    enviarDocumentos(values,arregloValor[0])
    const operFinancieras = {
        s_num_solicitud: numsol!,
        s_identificacion: values.Pws_Identificacion,
        s_tipo_identificacion: values.Pws_Tip_Identificacion,
        s_ingreso_principal: values.s_ingreso_principal,
        s_otros_ingresos: values.s_otros_ingresos,
        s_otros_egresos: values.s_otros_egresos,
        s_arriendo: values.s_arriendo,
        s_concep_otr_ingre: values.s_concep_otr_ingre,
        s_declarante_ren: values.s_declarante_ren ? "1" : "0",
        s_moneda_ext: values.s_moneda_ext ? "1" : "0",
        s_monext_oper: values.s_monext_oper,
        s_tip_monext: values.s_tip_monext,
        s_cuent_ext: values.s_cuent_ext,
        s_cuen_extban: values.s_cuen_extban,
        s_cuen_extnum: values.s_cuen_extnum,
        s_cuen_extpais: values.s_cuen_extpais,
        s_cuen_extciudad: values.s_cuen_extciudad
    }
    const result2 =await WLOCreditAPIService.addFinancialInformation(operFinancieras);
    const peps = {
        s_num_solicitud: numsol!,
        s_identificacion: values.Pws_Identificacion,
        s_tipo_identificacion: values.Pws_Tip_Identificacion,
        s_pep_recpublic: values.s_pep_recpublic ? "1" : "0",
        s_pep_poderpublic: values.s_pep_poderpublic ? "1" : "0",
        s_pep_reconpublic: values.s_pep_reconpublic ? "1" : "0",
        s_pep_pubexpue: values.s_pep_pubexpue ? "1" : "0",
        s_pep_seggraconsa: values.s_pep_seggraconsa ? "1" : "0",
        s_pep_nompepseg: values.s_pep_nompepseg,
        s_pep_paren: values.s_pep_paren,
        s_pep_identif: values.s_pep_identif,
    }
    const result = await WLOCreditAPIService.addPeps(peps);
    console.log(result)
    console.log(result2)
    agregarCygnus(numsol,values,pws_identifica,pws_tip_identifica)
   
}
const agregarCygnus= async(numsol:string,values:any,pws_identifica:string,pws_tip_identifica:string)=>{
    const cignus=await WLOCreditAPIService.grabar_cignus({
        Pws_Identificacion :values.Pws_Identificacion,
        Pws_Tip_Identificacion:values.Pws_Tip_Identificacion,
        Pws_Tip_Perfil:values.Pws_Tip_Perfil!
    })
    
    console.log(cignus)
    if(Number(cignus.payload.result)>0){
        let dataCodeudor={
            Pws_num_solicitud: numsol,
            Pws_Identificacion: pws_identifica,
            Pws_Radic_Codeu: numsol,
            Pws_Tip_Identificacion: pws_tip_identifica,
            Pws_Iden_Code: values.Pws_Identificacion,
            Pws_Tip_Identificacion_Code: values.Pws_Tip_Identificacion,
            Pws_Tip_Codeu:"2",
            Pws_Estado:"1"
        }
        if(codigoCodeudor>0){
            toast.success('Actualizado Correctamente',{
                duration:5000,
            });
            navigate(-1)
        
        }else{
            console.log(dataCodeudor)
            const codeudor = await WLOCreditAPIService.addCodeudor(dataCodeudor)
            console.log(codeudor.payload)
            if(Number(codeudor.payload.result)>0){
                console.log(codeudor.payload)
                SendEmailCodeudor(numsol,values,pws_identifica,pws_tip_identifica)
            }else{
                toast.error(codeudor.payload.message,{
                    duration:5000,
                })
                
            }   
        }
         
    }else{
        console.log({
            Pws_Identificacion :values.Pws_Identificacion,
            Pws_Tip_Identificacion:values.Pws_Tip_Identificacion,
            Pws_Tip_Perfil:values.Pws_Tip_Perfil!
        })
        toast.error('Tienes error en el Registro cignus',{
            duration:5000,
        });
    }
  }
  const SendEmailCodeudor = async(numsol:string,values:any,pws_identifica:string,pws_tip_identifica:string)=>{
    let codeudors:any={
        Pws_codigo: "",
        Pws_num_solicitud: numsol,
        Pws_Identificacion: pws_identifica,
        Pws_Radic_Codeu: numsol,
        Pws_Tip_Identificacion: pws_tip_identifica,
        Pws_Iden_Code: "",
        Pws_Tip_Identificacion_Code: "",
        Pws_Tip_Codeu: ""
    }
    const valorcodeudor = await WLOCreditAPIService.addCodeudor(codeudors)
    if(Number(valorcodeudor.payload.result)>0){
        let valorBusqueda={ } as any
        console.log(valorcodeudor)
        if(valorcodeudor.payload.data.datos_codeu.Datos_codeudor){
            valorcodeudor.payload.data.datos_codeu.Datos_codeudor.forEach((valorcode:any)=>{
                if(valorcode.Pws_Iden_Code==values.Pws_Identificacion && valorcode.Pws_Tip_Identificacion_Code==values.Pws_Tip_Identificacion){
                    valorBusqueda=valorcode
                    console.log(valorcode)
                }
            })
            if(valorBusqueda.Pws_Iden_Code){
                const emailInfo = {
                    email: String(values.Pws_Correo),
                    dateSolicitud: new Date().toDateString(),
                    numsol:String(numsol!),
                    ps_cod_lin:request.valorSolicitud.pws_cod_credi,
                    ps_tipoCliente:values.Pws_Tip_ocupa,
                    radicado:valorBusqueda,
                    Ps_tip_deucode:"2",
                    rotativo:false
                }
                const resultEnvioCorreo = await WLOCreditAPIService.sendEmailFirmaInicial(emailInfo);
                if(user.isSuccessfulLogin){
                    navigate(-1)
                }else{
            
                    navigate(AppRoutes.CONFIRMATION)
                }
                toast("Registrado Correctamente la informacion")
            }else{
                toast("Tienes error en la consulta del Envio")

            }
        }else{
            toast("Tienes error en el Registro Codeudor")

        }
      
      
    }else{
        toast.error('Tienes error en el Registro Codeudor',{
            duration:5000,
        });

    }
 
  }
const registarDatosreque = async (numsol:string,values:any,pws_identifica:string,pws_tip_identifica:string)=>{
    
   
    if(valorCheck()){
        const personaRequest = {
            Pws_Identificacion: values.Pws_Identificacion,
            Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
            Pws_Tip_Estado: values.Pws_Tip_Estado, // Valor constante para confirmación de información.
            Pws_Tip_Perfil: values.Pws_Tip_Perfil,
            Pws_Tip_person: values.Pws_Tip_person,
            Pws_Tip_ocupa: values.Pws_Tip_ocupa,
            Pws_Nombres: values.Pws_Nombres ,
            Pws_Apellidos1: values.Pws_Apellidos1,
            Pws_Apellidos2: values.Pws_Apellidos2,
            Pws_Telefono1: values.Pws_Telefono1,
            Pws_Telefono2: values.Pws_Telefono2,
            Pws_Correo: values.Pws_Correo,
            Pws_Fec_expe: Moment(new Date(values.Pws_Fec_expe!)).format('MM/DD/YYYY') ,
            Pws_Lugar_exp: values.Pws_Lugar_exp ,
            Pws_Fec_nacime: Moment(new Date(values.Pws_Fec_nacime!)).format('MM/DD/YYYY')  ,
            Pws_Estado_Civil: values.Pws_Estado_Civil,
            Pws_Direccion_res: values.Pws_Direccion_res,
            Pws_Departamento: values.Pws_Departamento,
            Pws_Ciudad_res: values.Pws_Ciudad_res,
            Pws_Genero: values.Pws_Genero,
            Pws_Estrato_per: values.Pws_Estrato_per,
            Pws_Per_cargo: values.Pws_Per_cargo,
            Pws_Tip_vivienda: values.Pws_Tip_vivienda,
            Pws_Niv_estudio: values.Pws_Niv_estudio,
            Pws_Nom_empre: values.Pws_Nom_empre,
            Pws_fec_ingemp: Moment(new Date(values.Pws_fec_ingemp!)).format('MM/DD/YYYY'),
            Pws_Fideliza: "0",
            Pws_Tip_contra: values.Pws_Tip_contra,
            Pws_Ant_labo: values.Pws_Ant_labo,
            Pws_Car_emp: values.Pws_Car_emp,
            Pws_Nom_Jefedi: values.Pws_Nom_Jefedi,
            Pws_Direc_emp: values.Pws_Direc_emp,
            Pws_Ciud_emp: values.Pws_Ciud_emp,
            Pws_tel_emp1: values.Pws_tel_emp1,
            Pws_tel_emp2: values.Pws_tel_emp2,
        }
        console.log(personaRequest)
        const resultUpdatePersona = await WLOCreditAPIService.addSolicitudPersona(personaRequest)
        console.log(resultUpdatePersona)
        if(Number(resultUpdatePersona.payload.result)>0 || Number(resultUpdatePersona.payload.result)==-4){
            if(Number(resultUpdatePersona.payload.result)==-4){
                const resultConsultaPersona = await WLOCreditAPIService.consultaSolicitudCygnus({Pws_Identificacion: values.Pws_Identificacion,
                    Pws_Tip_Identificacion: values.Pws_Tip_Identificacion});
                    console.log(resultConsultaPersona)
                if (!_.isEmpty(resultConsultaPersona.payload)) {
                    const personaConsultaResult = resultConsultaPersona.payload.data.datos_persona.Datos_persona[0];
                    const infoUpdatePerson = {...personaRequest, Pws_i_codigo: personaConsultaResult ? personaConsultaResult.Pws_i_codigo : 0};
       
                    console.log(infoUpdatePerson)
                    const resultUpdatePersona2 = await WLOCreditAPIService.addSolicitudPersona(infoUpdatePerson);
                    console.log(resultUpdatePersona2)
                    if (resultUpdatePersona2.payload.result > 0) {
                        valuesFinancieros(numsol,values,pws_identifica,pws_tip_identifica)
                    } else {
                        toast.error('No hemos podido completar tu solicitud.',{
                            duration:5000,
                        });
                    }
                }
            }else{
                valuesFinancieros(numsol,values,pws_identifica,pws_tip_identifica)
            }
        }else{
            toast.error('No hemos podido completar tu solicitud.',{
                duration:5000,
            });

        }
    }else{
        toast.error("Debes completar los Documentos Necesarios",{
            duration:5000,
        })
    }

}

const getBase64 = (file: any) => {
    return new Promise(resolve => {
        let baseURL: string | ArrayBuffer | null = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
}
return (
    <div className="detailCreditR" >
        <div className="header">
        {window.location.pathname === '/Detalle-del-codeudor-requerido' && ( 
                <Fab variant="extended" color="neutral" aria-label="add"
                        onClick={()=>{
                        navigate(-1)
                        }}
                        style={{float:"left"}} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                        {"Atras"}
                        </Fab>
            )}
            <h3 className="title mensaje">Detalle del codeudor requerido</h3>
            {!isupdate ? (
            <></>
            ) : window.location.pathname === '/Detalle-del-codeudor-requerido' ? (
            <Fab
                variant="extended"
                color="error"
                aria-label="add"
                onClick={() => {
                document.getElementById("saveutton")?.click();
                }}
                className="hooverButtonsubmit"
            >
                <ArrowForward className="hooverButtonRedsvgsubmit" sx={{ mr: 1 }} />
                {'Agregar   '}
            </Fab>
            ) : (
            <></>
            )}
            
        </div>
        <div className="mainDetail">
        <Formik
                enableReinitialize={true} 
                initialValues={persona}
                onSubmit={onSubmit}
            >
                 {({isSubmitting, values, errors,handleSubmit}) => (
                    <>
                    <Form>
            <Box2 valorPersonal={{person:values,update:isupdate,setPopup,arreglo:arregloValor,setUpdate}}/>
            <Box3 valorFinancie={{valorinic:values,datopeps:values,update:isupdate}} />
            <Box8 valorFinancie={{valorinic:values,update:isupdate,datosdocuments:alldocuments,arreglo:arregloValor}} />
                <button type="submit" style={{display:"none"}} id="saveutton"></button>  
            </Form>
                    </>)
                    }
                
            </Formik>
        </div>
    </div>
  )
}