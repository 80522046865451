import * as React from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import {StepIconProps} from '@mui/material/StepIcon';
import {AccountCircle, ArrowBack, ArrowForward, AttachMoney, UploadFileSharp} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {Form, Formik} from 'formik';
import {WLOCreditAPIService} from '../../../services';
import toast from 'react-hot-toast';
import {useIntl} from "react-intl";
import {AppRoutes} from "../../../Router";
import {useNavigate} from "react-router-dom";
import {useRequestContext} from '../../../hooks/useRequestContext';
import {useLoginContext} from '../../../hooks/useLoginContext';
import _ from 'lodash';
import {ColorlibConnector, ColorlibStepIconRoot, Item} from "../../../utils/utils";
import {ReferenceDocument} from '../ReferenceDocument/ReferenceDocument';

interface WizardsProps {
    steps: ({key: string, label: string, initialValues: any, validationSchema: any})[];
}

export function ReferenceStepperComp(props: WizardsProps) {
    const { steps } = props;
    const {user} = useLoginContext();
    const [activeStep, setActiveStep] = React.useState(0);
    const {request, setRequest} = useRequestContext();
    const navigate = useNavigate();
    const intl = useIntl();

    const handleNext = async (values: any) => {
        if (activeStep === 0) {
            try {
                    if (!request.errorConsulta) {
                        if (!request.numSol) {
                            const dateSolicitud = new Date().toLocaleDateString();
                            const result = await WLOCreditAPIService.addSolicitud({
                                Pws_Identificacion: values.Pws_Identificacion,
                                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                                Pws_Val_cuoini: "0",
                                Pws_Val_cuo: request.valueOfQuote,
                                Pws_Val_finan: values.pws_monto,
                                Pws_Correo: values.Pws_Correo,
                                Pws_Apellidos1: values.Pws_Nombres,
                                Pws_Telefono1: values.Pws_Telefono1,
                                Pws_Telefono2: values.Pws_Telefono2,
                                Pws_Nombres: values.Pws_Nombres,
                                Pws_Val_pla: values.Pws_Val_pla,
                                Pws_fec_solicitud: dateSolicitud,
                                Pws_Tip_Estado: "1", // Valor constante 1 Simulador S
                                Pws_Tip_Perfil: user.idProfile,
                                Pws_Tip_Person: "N" //Valor que pide el ws pero a nivel de UI no existe
                            });
                            //Preguntar si efectivamente se creo.
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            toast.success('Hemos generado una nueva solicitud');
                            if (result.payload.result === "1") {
                                const solicitudResult = await WLOCreditAPIService.addSolicitud({
                                    Pws_Identificacion: values.Pws_Identificacion,
                                    Pws_Tip_Identificacion: values.Pws_Tip_Identificacion
                                });
                                const numSolicitud = solicitudResult.payload.data.Datos_Sol.Datos_Sol[0].Pws_num_solicitud;
                                setRequest({
                                    ...request,
                                    numIdentification: values.Pws_Identificacion,
                                    typeIdentification: values.Pws_Tip_Identificacion,
                                    numSol: numSolicitud,
                                    email: values.Pws_Correo,
                                    dateSol: dateSolicitud
                                });
                            }
                        }
                        else {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                    else {
                        toast.error('No es posible validar su identidad');
                    }
            } catch (e) {
                toast.error('En este momento no podemos atender su solicitud');
                setTimeout(() => {
                }, 4000);
            }

        }

        if (activeStep === 1) {
            try {
                const personaRequest = {
                    Pws_Identificacion: values.Pws_Identificacion,
                    Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                    Pws_Tip_Estado: "2", // Valor constante para confirmación de información.
                    Pws_Tip_Perfil: user.idProfile,
                    Pws_Tip_person: values.Pws_Tip_person,
                    Pws_Tip_ocupa: values.Pws_Tip_ocupa,
                    Pws_Nombres: !request.searchANI ? request.personCygnus?.Pws_Nombres! : request.personANI?.primerNombre + " " + request.personANI?.segundoNombre,
                    Pws_Apellidos1: !request.searchANI ? request.personCygnus?.Pws_Apellidos1! : request.personANI?.primerApellido,
                    Pws_Apellidos2: !request.searchANI ? request.personCygnus?.Pws_Apellidos2! : request.personANI?.segundoApellido,
                    Pws_Telefono1: values.Pws_Telefono1,
                    Pws_Telefono2: values.Pws_Telefono2,
                    Pws_Correo: values.Pws_Correo,
                    Pws_Fec_expe: !request.searchANI ? request.personCygnus?.Pws_Fec_expe! : request.personANI?.fechaExpedicion,
                    Pws_Lugar_exp: !request.searchANI ? request.personCygnus?.Pws_Lugar_exp! : request.personANI?.municipioExpedicion,
                    Pws_Fec_nacime: !request.searchANI ? request.personCygnus?.Pws_Fec_nacime! : values.Pws_Fec_nacime,
                    Pws_Estado_Civil: values.Pws_Estado_Civil,
                    Pws_Direccion_res: values.Pws_Direccion_res,
                    Pws_Departamento: values.Pws_Departamento,
                    Pws_Ciudad_res: values.Pws_Ciudad_res,
                    Pws_Genero: values.Pws_Genero,
                    Pws_Estrato_per: values.Pws_Estrato_per,
                    Pws_Per_cargo: values.Pws_Per_cargo,
                    Pws_Tip_vivienda: values.Pws_Tip_vivienda,
                    Pws_Niv_estudio: values.Pws_Niv_estudio,
                    Pws_Nom_empre: values.Pws_Nom_empre,
                    Pws_fec_ingemp: values.Pws_fec_ingemp,
                    Pws_Fideliza: values.Pws_Fideliza,
                    Pws_Tip_contra: values.Pws_Tip_contra,
                    Pws_Ant_labo: values.Pws_Ant_labo,
                    Pws_Car_emp: values.Pws_Car_emp,
                    Pws_Nom_Jefedi: values.Pws_Nom_Jefedi,
                    Pws_Direc_emp: values.Pws_Direc_emp,
                    Pws_Ciud_emp: values.Pws_Ciud_emp,
                    Pws_tel_emp1: values.Pws_tel_emp1,
                    Pws_tel_emp2: values.Pws_tel_emp2,
                }
                    if (request?.searchANI) {
                            const result = await WLOCreditAPIService.addSolicitudPersona(personaRequest);
                            if (!_.isEmpty(result.payload)) {
                                if (result.payload.Resultado > 0) {
                                    //Preguntar si efectivamente se creo.
                                    toast.success('Hemos generado una nueva solicitud persona');
                                } else {
                                    toast.error('No hemos podido completar tu solicitud.');
                                }
                            }
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                       
                    } else {
                            const infoUpdatePerson = {...personaRequest, Pws_i_codigo: values.Pws_Identificacion};
                            const result = await WLOCreditAPIService.addSolicitudPersona(infoUpdatePerson);
                            if (!_.isEmpty(result.payload)) {
                                if (result.payload.Resultado > 0) {
                                    //Preguntar si efectivamente se creo.
                                    toast.success('Hemos actualizado su solicitud persona');
                                } else {
                                    toast.error('No hemos podido completar tu solicitud.');
                                }
                            }
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
            } catch (e) {
                toast.error('En este momento no podemos atender su solicitud');
                setTimeout(() => {
                }, 4000);
            }

        }

        if (activeStep === 2) {
            try {
                const operFinancieras = {
                    s_num_solicitud: request.numSol!,
                    s_identificacion: values.Pws_Identificacion,
                    s_tipo_identificacion: values.Pws_Tip_Identificacion,
                    s_ingreso_principal: values.s_ingreso_principal,
                    s_otros_ingresos: values.s_otros_ingresos,
                    s_otros_egresos: values.s_otros_egresos,
                    s_arriendo: values.s_arriendo,
                    s_concep_otr_ingre: values.s_concep_otr_ingre,
                    s_declarante_ren: values.s_declarante_ren ? "1" : "0",
                    s_moneda_ext: values.s_moneda_ext ? "1" : "0",
                    s_monext_oper: values.s_monext_oper,
                    s_tip_monext: values.s_tip_monext,
                    s_cuent_ext: values.s_cuent_ext,
                    s_cuen_extban: values.s_cuen_extban,
                    s_cuen_extnum: values.s_cuen_extnum,
                    s_cuen_extpais: values.s_cuen_extpais,
                    s_cuen_extciudad: values.s_cuen_extciudad
                }
                await WLOCreditAPIService.addFinancialInformation(operFinancieras);

                if (!_.isEmpty(values.s_pep_nompepseg) || !_.isEmpty(values.s_pep_paren) || !_.isEmpty(values.s_pep_identif)) {
                    const peps = {
                        s_num_solicitud: request.numSol!,
                        s_identificacion: values.Pws_Identificacion,
                        s_tipo_identificacion: values.Pws_Tip_Identificacion,
                        s_pep_recpublic: values.s_pep_recpublic ? "1" : "0",
                        s_pep_poderpublic: values.s_pep_poderpublic ? "1" : "0",
                        s_pep_reconpublic: values.s_pep_reconpublic ? "1" : "0",
                        s_pep_pubexpue: values.s_pep_pubexpue ? "1" : "0",
                        s_pep_seggraconsa: values.s_pep_seggraconsa ? "1" : "0",
                        s_pep_nompepseg: values.s_pep_nompepseg,
                        s_pep_paren: values.s_pep_paren,
                        s_pep_identif: values.s_pep_identif,
                    }
                    const result = await WLOCreditAPIService.addPeps(peps);
                }
                //Preguntar si efectivamente se creo.
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                toast.success('Hemos añadido la información financiera a su solicitud.');
            } catch (e) {
                toast.error('En este momento no podemos atender su solicitud');
                setTimeout(() => {
                }, 4000);
            }
        }

        if (activeStep === 3) {

            if (_.isEmpty(values.s_ref_nomcomple) || _.isEmpty(values.s_ref_tipo)) {
                toast.error('Debe completar al menos una referencia para continuar');
            } else {
                toast.success('Su solicitud se ha creado de manera exitosa');
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }

        }
    };

    const isLastStep = () => {
        return activeStep === steps.length - 1;
    };

    const onSubmit = async (values: any, formikBag: { setSubmitting: any; }) => {
        const {setSubmitting} = formikBag;
        if (!isLastStep()) {
            setSubmitting(false);
            handleNext(values).then();
            return;
        } else {
            toast.success('Hemos finalizado con su solicitud.');
            try {
                const emailInfo = {
                    email: request.email!,
                    dateSolicitud: new Date().toDateString(),
                    numsol: request.numSol!
                }
                const resultEnvioCorreo = await WLOCreditAPIService.sendEmailFirmaInicial(emailInfo);
                if (resultEnvioCorreo) {
                    toast.success('Se ha enviado con exito el email con la cotizacción');
                }
            } catch (error) {
                toast.error('En este momento no podemos atender su solicitud');
                setTimeout(() => {
                }, 4000);
            }
            navigate(AppRoutes.CONFIRMATION, {replace: true});
        }
        setTimeout(() => {
            setSubmitting(false);
        }, 1000);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            navigate(AppRoutes.CATALOG, {replace: true});
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }

    };

    function ColorlibStepIcon(props: StepIconProps) {
        const {active, completed, className} = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <Check/>,
            2: <AccountCircle/>,
            3: <AttachMoney/>,
            4: <UploadFileSharp/>
        };

        return (
            <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    const initialValues = steps.reduce(
        (values, {initialValues}) => ({
            ...values,
            ...initialValues
        }),
        {}
    );

    const validationSchema = steps[activeStep].validationSchema

    return (

        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({isSubmitting, values, errors}) => (
                    <>
                        <Form>
                            <Stack direction={{xs: 'column', sm: 'row'}}
                                   spacing={{xs: 1, sm: 1, md: 4}} justifyContent="space-between">
                                <Item elevation={0}>
                                    <div className="d-none d-md-none d-lg-block d-xl-block">
                                        <Fab variant="extended" color="neutral" aria-label="add"
                                             onClick={handleBack}>
                                            <ArrowBack sx={{mr: 1}}/>
                                            {intl.formatMessage({id: "button_back"})}
                                        </Fab>
                                    </div>
                                    <div className="d-block d-md-block d-lg-none d-xl-none">
                                        <Fab variant="extended" color="neutral" aria-label="add"
                                             onClick={handleBack}>
                                            <ArrowBack/>
                                        </Fab>
                                    </div>
                                </Item>
                                <Item elevation={0}>
                                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
                                        {steps.map((item) => (
                                            <Step key={item.label}>
                                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                                    <p className="step">{item.label}</p>
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Item>
                                <Item elevation={0}>
                                    <div className="d-none d-md-none d-lg-block d-xl-block">
                                        <Fab variant="extended" color="error" aria-label="add" disabled={isSubmitting}
                                             type="submit">
                                            {isLastStep() ? intl.formatMessage({id: "button_process"}) : intl.formatMessage({id: "button_next"})}
                                            <ArrowForward sx={{mr: 1}}/>
                                        </Fab>
                                    </div>
                                </Item>
                            </Stack>
                            <ReferenceDocument />
                            <Item elevation={0}>
                                <div className="d-block d-md-block d-lg-none d-xl-none">
                                    <Fab variant="extended" color="error" aria-label="add" disabled={isSubmitting}
                                         type="submit">
                                        {isLastStep() ? intl.formatMessage({id: "button_process"}) : intl.formatMessage({id: "button_next"})}
                                        <ArrowForward sx={{mr: 1}}/>
                                    </Fab>
                                </div>
                            </Item>
                        </Form>
                    </>
                )}
            </Formik>
        </div>
    );
}